import {
  PrismicGlobalResources,
  PrismicGlobalResourcesDataType,
} from '../../graphql';
import React, { Component, ComponentType, ReactNode } from 'react';
import { graphql, StaticQuery } from 'gatsby';
import { Subset } from '../../utils/UtilityTypes';

const WithGlobalResources = function WithGlobalResourcesFn<
  P extends InjectedGlobalResourcesProps
>(
  ComposedComponent: ComponentType<P>
): ComponentType<Subset<P, InjectedGlobalResourcesProps>> {
  return class extends Component<Subset<P, InjectedGlobalResourcesProps>> {
    render() {
      return (
        <GlobalResourcesProvider
          render={data => (
            <ComposedComponent
              {...(this.props as any)}
              globalResources={data}
            />
          )}
        />
      );
    }
  };
};
export default WithGlobalResources;

export class GlobalResourcesProvider extends Component<
  GlobalResourcesProviderProps
> {
  render() {
    const { render } = this.props;

    return (
      <StaticQuery
        query={GlobalResourcesQuery}
        render={queryResult => {
          const {
            prismicGlobalResources,
          } = queryResult as GlobalResourcesQueryResult;

          return render(prismicGlobalResources.data!);
        }}
      />
    );
  }
}

interface GlobalResourcesProviderProps {
  render: (data: PrismicGlobalResourcesDataType) => ReactNode;
}

const GlobalResourcesQuery = graphql`
  query GlobalResourcesQuery {
    prismicGlobalResources {
      data {
        default_cta_text
        load_more_cta_text
        label_skip_to_content
        page_title_template
        default_og_site_name
        default_og_image {
          alt
          url
          gatsbyImageData(
            width: 1200
            height: 630
            backgroundColor: "rgba(255,255,255,1)"
            placeholder: NONE
            layout: FIXED
          )
        }
        default_share_description
        facebook_app_id
        twitter_site
        agent_card_header_text
        agent_contact_agent_button
        agent_appointment_cta_text
        call_partner_cta_text
        travel_partner_page_breadcrumb {
          parent_page {
            ...PrismicLinkFragment
            type
            document {
              ...BreadcrumbPageTitleFragment
            }
          }
        }
        travel_partner_page_side_nav {
          link_text
          link_icon
          link_dest {
            ...PrismicLinkFragment
          }
        }
        travel_partner_default_related_section_image {
          alt
          url
          gatsbyImageData(layout: FULL_WIDTH)
        }
        travel_partner_card_learn_more_cta
        travel_partner_card_nav_items {
          link_text
          link_dest {
            ...PrismicLinkFragment
          }
        }
        travel_talk_card_header_text
        travel_talk_card_learn_more_cta
        travel_talk_card_rsvp_cta
        travel_talk_card_nav_items {
          link_text
          link_dest {
            ...PrismicLinkFragment
          }
        }
        travel_trip_card_learn_more_cta
        travel_trip_card_nav_items {
          link_text
          link_dest {
            ...PrismicLinkFragment
          }
        }
        travel_deal_card_header_text
        travel_deal_card_learn_more_cta
        travel_deal_card_expiry_label
        travel_deal_card_nav_items {
          link_text
          link_dest {
            ...PrismicLinkFragment
          }
        }
        merchandise_page_breadcrumb {
          parent_page {
            ...PrismicLinkFragment
            type
            document {
              ...BreadcrumbPageTitleFragment
            }
          }
        }
        merchandise_page_member_price_header
        merchandise_page_non_member_price_header
        merchandise_page_sale_price_header
        merchandise_page_sale_regular_price_sr_hint
        merchandise_page_sale_expiry_header
        merchandise_page_find_store_cta
        merchandise_page_tags_listing_header
        merchandise_card_sale_label
        merchandise_card_regular_price_label
        merchandise_card_member_price_sr_hint
        merchandise_card_non_member_price_label
        merchandise_card_learn_more_cta
        travel_deal_page_breadcrumb {
          parent_page {
            ...PrismicLinkFragment
            type
            document {
              ...BreadcrumbPageTitleFragment
            }
          }
        }
        travel_deal_page_expiry_header
        travel_deal_page_tags_listing_header
        travel_deal_page_additional_cta {
          link_text
          link_dest {
            ...PrismicLinkFragment
          }
        }
        travel_store_page_breadcrumb {
          parent_page {
            ...PrismicLinkFragment
            type
            document {
              ...BreadcrumbPageTitleFragment
            }
          }
        }
        travel_store_location_header
        travel_store_store_hours_header
        travel_store_contact_information_header
        travel_store_view_google_map_button
        travel_store_talks_section_header
        travel_store_talks_view_more_cta
        travel_store_talks_section_background {
          alt
          url
          gatsbyImageData(layout: FULL_WIDTH)
        }
        travel_store_agents_section_header
        travel_store_agents_view_mor_cta
        travel_talk_page_breadcrumb {
          parent_page {
            ...PrismicLinkFragment
            type
            document {
              ...BreadcrumbPageTitleFragment
            }
          }
        }
        travel_talk_page_location_header
        travel_talk_page_start_date_header
        travel_talk_page_end_date_header
        travel_talk_page_rsvp_cta
        travel_talk_page_tags_listing_header
        travel_talk_page_additional_cta {
          link_text
          link_dest {
            ...PrismicLinkFragment
          }
        }
        travel_trip_page_breadcrumb {
          parent_page {
            ...PrismicLinkFragment
            type
            document {
              ...BreadcrumbPageTitleFragment
            }
          }
        }
        travel_trip_page_date_header
        travel_trip_page_price_header
        travel_trip_page_tags_listing_header
        travel_trip_page_additional_cta {
          link_text
          link_dest {
            ...PrismicLinkFragment
          }
        }
        rewards_offer_card_view_cta
      }
    }
  }
`;

export interface InjectedGlobalResourcesProps {
  globalResources: PrismicGlobalResourcesDataType;
}

interface GlobalResourcesQueryResult {
  prismicGlobalResources: PrismicGlobalResources;
}
