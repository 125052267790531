import React, { Component, ReactNode } from 'react';
import { PrismicTravelPartner } from '../../../graphql';
import ImageLinkTile from '../../controls/ImageLinkTile';
import WithGlobalResources, { InjectedGlobalResourcesProps } from '../../higher-order/WithGlobalResources';
import PrismicLink from '../../controls/PrismicLink';
import PrismicImage from '../../controls/PrismicImage';
import { ResolveTravelLinkItems } from '../../../utils/DynamicLinksHelper';

const TravelPartner = WithGlobalResources(
  class TravelPartnerImpl extends Component<TravelPartnerProps & InjectedGlobalResourcesProps> {
    render(): ReactNode {
      const { partner, globalResources } = this.props;

      if (!partner) {
        return null;
      }

      let partnerNameNode: ReactNode = null;
      if (partner?.data?.page_title) {
        partnerNameNode = <div className="image-link-tile-partner-name">{partner?.data?.page_title}</div>;
      }

      let partnerPartnerLogoNode: ReactNode = null;
      if (partner?.data?.partner_logo) {
        partnerPartnerLogoNode = (
          <div className="columns is-mobile is-centered is-gapless">
            <div className="column is-two-thirds">
              <PrismicImage
                image={partner?.data?.partner_logo ?? null}
                className="has-max-height-120"
                imgStyle={{ objectFit: 'contain' }}
                aria-label={partner?.data?.page_title ?? undefined}
              />
            </div>
          </div>
        );
      }

      let partnerDescNode: ReactNode = null;
      if (partner?.data?.share_description) {
        partnerDescNode = <div className="image-link-tile-partner-description">{partner?.data?.share_description}</div>;
      }

      const learnMoreButton = (
        <PrismicLink to={partner} className="button has-text-weight-bold is-caa-redorange">
          {globalResources.travel_partner_card_learn_more_cta}
        </PrismicLink>
      );

      const bottomNavigationItems = ResolveTravelLinkItems(globalResources.travel_partner_card_nav_items, {}).map(
        (item, index) => (
          <li key={index}>
            <PrismicLink to={item.link_dest}>{item.link_text}</PrismicLink>
          </li>
        )
      );

      let bottomNavigation = null;
      if (bottomNavigationItems) {
        bottomNavigation = <ul className={'image-link-tile-bottom-links'}>{bottomNavigationItems}</ul>;
      }

      return (
        <ImageLinkTile
          className={'is-travel-partner-tile'}
          image={partner?.data?.og_image}
          custom_content={
            <React.Fragment>
              <div className="tile-content-main">
                {partnerNameNode}
                {partnerPartnerLogoNode}
                {partnerDescNode}
              </div>
              {learnMoreButton}
              {bottomNavigation}
            </React.Fragment>
          }
        />
      );
    }
  }
);
export default TravelPartner;

export interface TravelPartnerProps {
  partner?: PrismicTravelPartner | null;
}
