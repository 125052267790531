import React, { Component, ReactNode } from 'react';
import {
  PrismicTaxonomyTravelDestination,
  PrismicTaxonomyTravelType,
  PrismicTravelPartner,
  PrismicTravelTrip,
} from '../../../graphql';
import ImageLinkTile from '../../controls/ImageLinkTile';
import WithGlobalResources, {
  InjectedGlobalResourcesProps,
} from '../../higher-order/WithGlobalResources';
import PrismicLink from '../../controls/PrismicLink';
import { IsValidPrismicLink, ToMoment } from '../../../utils/PrismicHelpers';
import { ResolveTravelLinkItems } from '../../../utils/DynamicLinksHelper';

const TravelTrip = WithGlobalResources(
  class TravelTripImpl extends Component<
    TravelTripProps & InjectedGlobalResourcesProps
  > {
    render(): ReactNode {
      const { trip, globalResources } = this.props;

      if (!trip) {
        return null;
      }

      let tripNameNode: ReactNode = null;
      if (trip?.data?.page_title) {
        tripNameNode = (
          <div className="image-link-tile-trip-name">
            {trip?.data?.page_title}
          </div>
        );
      }

      let tripPriceNode: ReactNode = null;
      if (trip?.data?.price) {
        tripPriceNode = (
          <div className="image-link-tile-trip-price">{trip?.data?.price}</div>
        );
      }

      let tripDateNode: ReactNode = null;
      if (trip?.data?.date_display_override) {
        tripDateNode = (
          <div className="image-link-tile-trip-date">
            {trip?.data?.date_display_override}
          </div>
        );
      } else if (trip?.data?.precise_date_start) {
        const startDate = ToMoment(trip?.data?.precise_date_start);
        const endDate = ToMoment(trip?.data?.precise_date_end);

        if (startDate) {
          let formattedDate = startDate.format('MMMM Do, YYYY');

          if (endDate) {
            if (
              endDate.format('YYYY-MM-DD') === startDate.format('YYYY-MM-DD')
            ) {
              // Nothing to do
            } else if (
              endDate.format('YYYY-MM') === startDate.format('YYYY-MM')
            ) {
              formattedDate = `${startDate.format(
                'MMMM Do'
              )} – ${endDate.format('Do, YYYY')}`;
            } else if (endDate.format('YYYY') === startDate.format('YYYY')) {
              formattedDate = `${startDate.format(
                'MMMM Do'
              )} – ${endDate.format('MMMM Do, YYYY')}`;
            } else {
              formattedDate = `${startDate.format(
                'MMMM Do, YYYY'
              )} – ${endDate.format('MMMM Do, YYYY')}`;
            }
          }
          tripDateNode = (
            <div className="image-link-tile-trip-date">{formattedDate}</div>
          );
        }
      }

      const learnMoreButton = (
        <PrismicLink
          to={trip}
          className="button has-text-weight-bold is-caa-redorange"
        >
          {globalResources.default_cta_text}
        </PrismicLink>
      );

      const firstDestination = (trip?.data?.travel_destinations || [])[0]
        ?.travel_destination?.document as PrismicTaxonomyTravelDestination;
      const crownLabel = firstDestination?.data?.name;

      const firstTravelType = (trip?.data?.travel_types || [])[0]?.travel_type
        ?.document as PrismicTaxonomyTravelType;
      const tagIcon = firstTravelType?.data?.icon;
      const tagLabel = firstTravelType?.data?.name;

      const bottomNavigationItems = ResolveTravelLinkItems(
        globalResources.travel_trip_card_nav_items,
        {
          travelPartner: trip?.data?.travel_partner
            ?.document as PrismicTravelPartner,
        }
      ).map((item, index) => (
        <li key={index}>
          <PrismicLink to={item.link_dest}>{item.link_text}</PrismicLink>
        </li>
      ));

      if (IsValidPrismicLink(trip?.data?.cta_link_dest)) {
        bottomNavigationItems.push(
          <li key={'cta-item'}>
            <PrismicLink to={trip?.data?.cta_link_dest}>
              {trip?.data?.cta_title}
            </PrismicLink>
          </li>
        );
      }

      let bottomNavigation = null;
      if (bottomNavigationItems) {
        bottomNavigation = (
          <ul className={'image-link-tile-bottom-links'}>
            {bottomNavigationItems}
          </ul>
        );
      }

      return (
        <ImageLinkTile
          className={'is-travel-trip-tile'}
          image={trip?.data?.trip_image}
          tag_icon={tagIcon}
          tag_label={tagLabel}
          crown_label={crownLabel}
          crown_icon={'map-marker-alt'}
          custom_content={
            <React.Fragment>
              <div className="tile-content-main">
                {tripNameNode}
                {tripPriceNode}
                {tripDateNode}
              </div>
              {learnMoreButton}
              {bottomNavigation}
            </React.Fragment>
          }
        />
      );
    }
  }
);
export default TravelTrip;

export interface TravelTripProps {
  trip?: PrismicTravelTrip | null;
}
