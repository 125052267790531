import React, { Component, ReactNode } from 'react';
import { PrismicPage, PrismicPageDataBodyRewardsOffers, PrismicRewardsOffer } from '../../graphql';
import { graphql } from 'gatsby';
import shuffle from 'lodash/shuffle';
import PrismicLink from '../controls/PrismicLink';
import { IsValidPrismicLink } from '../../utils/PrismicHelpers';
import WithRewardsOfferData, { InjectedRewardsOfferDataProps } from '../higher-order/WithRewardsOfferData';
import ImageLinkTileList from '../controls/ImageLinkTileList';
import RewardsOffer from '../fragments/rewards-offer/RewardsOffer';
import SectionTitle from '../controls/SectionTitle';

export const RewardsOffersSliceKey = 'rewards_offers';
const RewardsOffersSlice = WithRewardsOfferData(
  class RewardsOffersSliceImpl extends Component<
    RewardsOffersSliceProps & InjectedRewardsOfferDataProps,
    RewardsOffersSliceState
  > {
    constructor(props: Readonly<RewardsOffersSliceProps & InjectedRewardsOfferDataProps>) {
      super(props);

      this.state = {
        showRandomItems: false,
      };
    }

    componentDidMount(): void {
      this.setState({
        showRandomItems: true,
      });
    }

    render(): ReactNode {
      const { sliceData, rewardsOfferData } = this.props;

      const showDesc = sliceData?.primary?.display_offer_description === 'Yes';

      let ctaElement: ReactNode = null;
      if (sliceData.primary?.cta_title && IsValidPrismicLink(sliceData.primary?.cta_dest)) {
        ctaElement = (
          <div className="neo-section-cta">
            <PrismicLink to={sliceData.primary.cta_dest}>{sliceData.primary.cta_title}</PrismicLink>
          </div>
        );
      }

      const offers = this.selectTagOffers(rewardsOfferData);
      const offerElements: ReactNode[] = offers.map((offer, index) => (
        <RewardsOffer offer={offer} key={index} showDescription={showDesc} />
      ));

      return (
        <div className="container neo-rewards-offers">
          <SectionTitle component={sliceData.primary?.section_title} />
          {ctaElement}
          <ImageLinkTileList>{offerElements}</ImageLinkTileList>
        </div>
      );
    }

    selectTagOffers(results: PrismicRewardsOffer[]): PrismicRewardsOffer[] {
      const { sliceData } = this.props;

      let offers: PrismicRewardsOffer[] = [];
      const maxPopulate =
        sliceData?.primary?.populate_max !== null && sliceData?.primary?.populate_max !== undefined
          ? sliceData?.primary?.populate_max
          : 4;

      if (maxPopulate > 0 && this.state.showRandomItems) {
        const tagId = sliceData?.primary?.tag?.document?.id;

        offers = shuffle(results || [])
          .filter((node) => {
            if (!tagId) {
              return true;
            }

            return node?.data?.tags?.some((tag) => tag?.tag?.document?.id === tagId);
          })
          .slice(0, maxPopulate);
      }

      return offers.concat(
        (sliceData.items
          ?.filter((p) => !!p && !!p.forced_offer?.document?.id)
          .map((p) => p?.forced_offer?.document) as PrismicRewardsOffer[]) || []
      );
    }
  }
);
export default RewardsOffersSlice;

export interface RewardsOffersSliceProps {
  sliceData: PrismicPageDataBodyRewardsOffers;
  pageData?: PrismicPage;
}

export interface RewardsOffersSliceState {
  showRandomItems: boolean;
}

// noinspection JSUnusedGlobalSymbols
export const SliceRewardsOffersFragment = graphql`
  fragment SliceRewardsOffersFragment on PrismicPageDataBodyRewardsOffers {
    id
    slice_type
    primary {
      cta_title
      cta_dest {
        ...PrismicLinkFragment
      }
      display_offer_description
      tag {
        document {
          ... on PrismicTag {
            id
          }
        }
      }
      populate_max
      section_title {
        html
      }
    }
    items {
      forced_offer {
        document {
          ... on PrismicRewardsOffer {
            ...RewardsOfferFragment
          }
        }
      }
    }
  }
`;
