import {
  PrismicGarage,
  PrismicGaragesSearchPage,
  PrismicGaragesSearchPageDataBodySearchEnginePlaceholder,
  PrismicTaxonomyGarageCity,
  PrismicTaxonomyGarageCityConnection,
  PrismicTaxonomyGarageServiceType,
  PrismicTaxonomyGarageServiceTypeConnection,
} from '../../../graphql';
import React, { Component, ReactNode } from 'react';
import { graphql, StaticQuery } from 'gatsby';
import WithGlobalResources, { InjectedGlobalResourcesProps } from '../../higher-order/WithGlobalResources';
import TagBasedSearch, { NodeListToOptions, TagBasedSearchFieldsConfig } from '../../controls/TagBasedSearch';
import WithGarageData, { InjectedGarageDataProps } from '../../higher-order/WithGarageData';
import PrismicStructuredText from '../../controls/PrismicStructuredText';
import { Subscription } from 'rxjs';
import { Breakpoint, CurrentBreakpoint$, IsBreakpointAtMost } from '../../../utils/BreakpointHelpers';
import { parsePhoneNumberFromString } from 'libphonenumber-js';
import { IsValidPrismicLink } from '../../../utils/PrismicHelpers';
import PrismicLink from '../../controls/PrismicLink';
import { Marker, Popup } from 'react-leaflet';
import { LeafletMap } from '../../controls/LeafletMap';
import SectionTitle from '../../controls/SectionTitle';

export const GaragesSearchSliceKey = '!internal_garages_search_slice';

const GaragesSearchSlice = WithGlobalResources(
  WithGarageData(
    class GaragesSearchSliceImpl extends Component<
      GaragesSearchSliceProps & InjectedGlobalResourcesProps & InjectedGarageDataProps,
      GaragesSearchSliceState
    > {
      private breakpointSubscription?: Subscription;
      private fieldConfig?: TagBasedSearchFieldsConfig<GaragesSearchParams>;

      constructor(props: Readonly<GaragesSearchSliceProps & InjectedGlobalResourcesProps & InjectedGarageDataProps>) {
        super(props);

        this.state = {
          isMobileView: false,
        };
      }

      componentDidMount(): void {
        this.breakpointSubscription = CurrentBreakpoint$.subscribe((newBreakpoint) => {
          this.setState({
            isMobileView: IsBreakpointAtMost(newBreakpoint.breakpoint, Breakpoint.Tablet),
          });
        });
      }

      componentWillUnmount(): void {
        if (this.breakpointSubscription) {
          this.breakpointSubscription.unsubscribe();
        }
      }

      render(): ReactNode {
        return <StaticQuery query={GaragesSearchSliceQuery} render={(results) => this.renderWithData(results)} />;
      }

      renderWithData(data: GaragesSearchSliceQueryResults): ReactNode {
        const { sliceData, garageData } = this.props;

        if (!this.fieldConfig) {
          this.fieldConfig = {
            city: {
              label: sliceData?.primary?.city_field_label || '',
              options: NodeListToOptions(data?.allPrismicTaxonomyGarageCity?.nodes, (node) => node.data?.name).filter(
                (option) => garageData.some((ga) => ga.data?.city?.document?.id === option.value)
              ),
              allowMultiple: true,
            },
            serviceType: {
              label: sliceData?.primary?.service_type_field_label || '',
              options: NodeListToOptions(
                data?.allPrismicTaxonomyGarageServiceType?.nodes,
                (node) => node.data?.name
              ).filter((option) => garageData.some((ga) => ga.data?.service_type?.document?.id === option.value)),
              allowMultiple: true,
            },
          };
        }

        return (
          <div className="container neo-tag-search-page neo-find-garage">
            <SectionTitle component={sliceData.primary?.section_title} />

            <TagBasedSearch
              fields={this.fieldConfig}
              search={this.executeSearch}
              render={this.renderSearchResults}
              renderBelowResults={this.renderBelowResults}
              maxPerPage={5}
            />
          </div>
        );
      }

      executeSearch = (params: GaragesSearchParams): PrismicGarage[] => {
        const { garageData } = this.props;

        if (params.city.length === 0 && params.serviceType.length === 0) {
          return [];
        }

        return garageData.filter((garage) => {
          let isMatch = true;

          if (params.city.length) {
            isMatch = params.city.some((city) => garage?.data?.city?.document?.id === city);
          }

          if (isMatch && params.serviceType.length) {
            isMatch = params.serviceType.some(
              (serviceType) => garage?.data?.service_type?.document?.id === serviceType
            );
          }

          return isMatch;
        });
      };

      renderSearchResults = (
        results: PrismicGarage[],
        allResults: PrismicGarage[],
        params: GaragesSearchParams
      ): ReactNode => {
        const { sliceData } = this.props;
        const { isMobileView } = this.state;

        if (params.city.length === 0 && params.serviceType.length === 0) {
          return (
            <div className="content has-text-centered">
              <PrismicStructuredText text={sliceData?.primary?.no_filters_applied_content} />
            </div>
          );
        }

        if (results.length === 0) {
          return (
            <div className="content has-text-centered">
              <PrismicStructuredText text={sliceData?.primary?.no_results_found_content} />
            </div>
          );
        }

        let mapNode: ReactNode = null;
        if (sliceData?.primary?.map_display_mode === 'Above Results') {
          mapNode = this.renderMap(allResults);
        }

        return (
          <React.Fragment>
            {mapNode}
            <div className="neo-find-garage-results">
              {isMobileView ? this.renderSearchResultsMobile(results) : this.renderSearchResultsDesktop(results)}
            </div>
          </React.Fragment>
        );
      };

      renderSearchResultsDesktop = (results: PrismicGarage[]): ReactNode => {
        const { sliceData } = this.props;

        return (
          <div className="table-responsive neo-find-garage-results-desktop">
            <table className="table is-striped is-fullwidth">
              <thead>
                <tr>
                  <th>{sliceData?.primary?.city_table_label}</th>
                  <th>{sliceData?.primary?.service_type_table_label}</th>
                  <th>{sliceData?.primary?.garage_name_table_label}</th>
                  <th>{sliceData?.primary?.address_table_label}</th>
                  <th>{sliceData?.primary?.phone_table_label}</th>
                </tr>
              </thead>
              <tbody>
                {results.map((garage, index) => {
                  let garageNameNode: ReactNode = garage?.data?.name;
                  let phoneNumberNode: ReactNode = null;
                  let addressNode: ReactNode = garage?.data?.address_short;

                  if (garage?.data?.phone) {
                    const phoneNumber = parsePhoneNumberFromString(garage?.data?.phone, 'CA');

                    if (phoneNumber && phoneNumber.isValid()) {
                      phoneNumberNode = <a href={phoneNumber?.getURI()}>{phoneNumber?.formatNational()}</a>;
                    }
                  }

                  if (IsValidPrismicLink(garage?.data?.website)) {
                    garageNameNode = <PrismicLink to={garage?.data?.website}>{garage?.data?.name}</PrismicLink>;
                  }

                  if (garage?.data?.address) {
                    addressNode = (
                      <a
                        href={`https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(
                          garage?.data?.address
                        )}`}
                        target="_blank"
                        rel="noreferrer nofollow noopener"
                      >
                        {garage?.data?.address_short}
                      </a>
                    );
                  }

                  const garageCity = (garage?.data?.city?.document || undefined) as
                    | PrismicTaxonomyGarageCity
                    | undefined;
                  const garageServiceType = (garage?.data?.service_type?.document || undefined) as
                    | PrismicTaxonomyGarageServiceType
                    | undefined;

                  return (
                    <tr key={index}>
                      <td>{garageCity?.data?.name}</td>
                      <td>{garageServiceType?.data?.name}</td>
                      <td>{garageNameNode}</td>
                      <td>{addressNode}</td>
                      <td>{phoneNumberNode}</td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        );
      };

      renderSearchResultsMobile = (results: PrismicGarage[]): ReactNode => {
        const { sliceData } = this.props;

        return (
          <div className="neo-find-garage-results-mobile">
            {results.map((garage, index) => {
              let garageNameNode: ReactNode = garage?.data?.name;
              let phoneNumberNode: ReactNode = null;
              let addressNode: ReactNode = garage?.data?.address_short;

              if (garage?.data?.phone) {
                const phoneNumber = parsePhoneNumberFromString(garage?.data?.phone, 'CA');

                if (phoneNumber && phoneNumber.isValid()) {
                  phoneNumberNode = <a href={phoneNumber?.getURI()}>{phoneNumber?.formatNational()}</a>;
                }
              }

              if (IsValidPrismicLink(garage?.data?.website)) {
                garageNameNode = <PrismicLink to={garage?.data?.website}>{garage?.data?.name}</PrismicLink>;
              }

              if (garage?.data?.address) {
                addressNode = (
                  <a
                    href={`https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(
                      garage?.data?.address
                    )}`}
                    target="_blank"
                    rel="noreferrer nofollow noopener"
                  >
                    {garage?.data?.address_short}
                  </a>
                );
              }

              const garageCity = (garage?.data?.city?.document || undefined) as PrismicTaxonomyGarageCity | undefined;

              return (
                <div
                  key={index}
                  className="neo-find-garage-result-mobile-item columns is-mobile is-variable is-2 is-multiline"
                >
                  <div
                    className="column is-full has-text-weight-bold"
                    aria-label={sliceData.primary?.city_table_label || undefined}
                  >
                    {garageCity?.data?.name}
                  </div>
                  <div className="column is-full" aria-label={sliceData.primary?.garage_name_table_label || undefined}>
                    {garageNameNode}
                  </div>
                  <div
                    className="column is-half is-size-7"
                    aria-label={sliceData.primary?.address_table_label || undefined}
                  >
                    {addressNode}
                  </div>
                  <div
                    className="column is-half is-size-7 has-text-right"
                    aria-label={sliceData.primary?.phone_table_label || undefined}
                  >
                    {phoneNumberNode}
                  </div>
                </div>
              );
            })}
          </div>
        );
      };

      renderMap = (allResults: PrismicGarage[]): ReactNode => {
        const validGaragesForMap = allResults.filter(
          (garage) =>
            garage.data?.location &&
            garage.data?.location?.latitude !== undefined &&
            garage.data?.location?.longitude !== undefined &&
            garage.data?.location?.latitude !== 0 &&
            garage.data?.location?.longitude !== 0
        );

        return (
          <div className="neo-find-garage-map">
            <div className="neo-find-garage-map-inner">
              <LeafletMap
                boundsOptions={{ maxZoom: 16, padding: [20, 20] }}
                bounds={validGaragesForMap.map((garage) => [
                  garage.data!.location!.latitude!,
                  garage.data!.location!.longitude!,
                ])}
                style={{ height: '100%' }}
              >
                {validGaragesForMap.map((garage, index) => {
                  let garageNameNode: ReactNode = garage?.data?.name;
                  let phoneNumberNode: ReactNode = null;
                  let addressNode: ReactNode = null;

                  if (garage?.data?.phone) {
                    const phoneNumber = parsePhoneNumberFromString(garage?.data?.phone, 'CA');

                    if (phoneNumber && phoneNumber.isValid()) {
                      phoneNumberNode = <a href={phoneNumber?.getURI()}>{phoneNumber?.formatNational()}</a>;
                    }
                  }

                  if (IsValidPrismicLink(garage?.data?.website)) {
                    garageNameNode = <PrismicLink to={garage?.data?.website}>{garage?.data?.name}</PrismicLink>;
                  }

                  if (garage?.data?.address) {
                    addressNode = (
                      <a
                        href={`https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(
                          garage?.data?.address
                        )}`}
                        target="_blank"
                        rel="noreferrer nofollow noopener"
                      >
                        {garage?.data?.address}
                      </a>
                    );
                  }

                  const garageServiceType = (garage?.data?.service_type?.document || undefined) as
                    | PrismicTaxonomyGarageServiceType
                    | undefined;

                  return (
                    <Marker
                      position={[garage.data!.location!.latitude!, garage.data!.location!.longitude!]}
                      key={index}
                    >
                      <Popup>
                        <strong>{garageNameNode}</strong>
                        <br />
                        {garageServiceType?.data?.name}
                        <br />
                        &nbsp;
                        <br />
                        <address>{addressNode}</address>
                        {phoneNumberNode}
                      </Popup>
                    </Marker>
                  );
                })}
              </LeafletMap>
            </div>
          </div>
        );
      };

      renderBelowResults = (_results: PrismicGarage[], allResults: PrismicGarage[]): ReactNode => {
        const { sliceData } = this.props;

        if (allResults.length === 0 || sliceData?.primary?.map_display_mode !== 'Below Results') {
          return null;
        }

        return this.renderMap(allResults);
      };
    }
  )
);
export default GaragesSearchSlice;

const GaragesSearchSliceQuery = graphql`
  query GaragesSearchSliceQuery {
    allPrismicTaxonomyGarageCity(sort: { fields: data___name, order: ASC }) {
      nodes {
        id
        data {
          name
        }
      }
    }
    allPrismicTaxonomyGarageServiceType(sort: { fields: data___name, order: ASC }) {
      nodes {
        id
        data {
          name
        }
      }
    }
  }
`;

interface GaragesSearchParams {
  city: string[];
  serviceType: string[];
}

interface GaragesSearchSliceQueryResults {
  allPrismicTaxonomyGarageCity?: PrismicTaxonomyGarageCityConnection;
  allPrismicTaxonomyGarageServiceType?: PrismicTaxonomyGarageServiceTypeConnection;
}

export interface GaragesSearchSliceProps {
  sliceData: PrismicGaragesSearchPageDataBodySearchEnginePlaceholder;
  pageData?: PrismicGaragesSearchPage;
}

interface GaragesSearchSliceState {
  isMobileView: boolean;
}
