import { PrismicTravelPartner } from '../../../graphql';
import React, { Component, ReactNode } from 'react';
import WithGlobalResources, {
  InjectedGlobalResourcesProps,
} from '../../higher-order/WithGlobalResources';
import TravelOffersControl from '../../controls/TravelOffersControl';

export const TravelPartnerRelatedSliceKey =
  '!internal_travel_partner_related_slice';
const TravelPartnerRelatedSlice = WithGlobalResources(
  class TravelPartnerRelatedSliceImpl extends Component<
    TravelPartnerContentSliceProps & InjectedGlobalResourcesProps
  > {
    render(): ReactNode {
      const { pageData, globalResources } = this.props;
      const image = pageData?.data?.hero_image ?? globalResources.travel_partner_default_related_section_image;

      return (
        <TravelOffersControl
          displayTrips={true}
          displayTalks={true}
          displayDeals={true}
          displayPartners={false}
          numberOfItems={3}
          travelPartnerId={pageData?.id}
          backgroundImage={image}
        />
      );
    }
  }
);
export default TravelPartnerRelatedSlice;

export interface TravelPartnerContentSliceProps {
  sliceData: any;
  pageData?: PrismicTravelPartner;
}
