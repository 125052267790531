import React, { Component, PropsWithChildren, ReactNode, RefObject } from 'react';
import WithGlobalResources, { InjectedGlobalResourcesProps } from '../higher-order/WithGlobalResources';
import {
  PrismicPage,
  PrismicPageDataBodyCollapsableContent,
  PrismicPageDataBodyCollapsableContentItem,
} from '../../graphql';
import { MakeRandomId } from '../../utils/RandomId';
import PrismicStructuredText from '../controls/PrismicStructuredText';
import PrismicMarkdownText from '../controls/PrismicMarkdownText';
import { graphql } from 'gatsby';
import SectionTitle from '../controls/SectionTitle';

export const CollapsableContentSliceKey = 'collapsable_content';
const CollapsableContentSlice = WithGlobalResources(
  class extends Component<
    PropsWithChildren<CollapsableContentSliceProps & InjectedGlobalResourcesProps>,
    CollapsableContentSliceState
  > {
    private readonly contentElementRefs: RefObject<HTMLDivElement>[] = [];
    private imgAttachedLisenters: HTMLImageElement[] = [];

    private animationFrameCancelToken: number | undefined;

    constructor(props: Readonly<React.PropsWithChildren<CollapsableContentSliceProps & InjectedGlobalResourcesProps>>) {
      super(props);
      this.state = {
        activeItemIndex: undefined,
        elementIdPrefix: MakeRandomId('CollapsableContentSlice', 10),
        elementHeights: [],
      };
    }

    componentDidMount(): void {
      window.addEventListener('resize', this.onWindowResize);
      this.refreshElementHeights();
    }

    componentWillUnmount(): void {
      window.removeEventListener('resize', this.onWindowResize);

      this.imgAttachedLisenters.forEach((img) => {
        img.removeEventListener('load', this.onWindowResize);
      });

      this.imgAttachedLisenters = [];
    }

    render() {
      const { sliceData } = this.props;

      const isTabs = sliceData.primary?.rendering_mode === 'tabs';

      const contentElements = isTabs ? this.renderTabs() : this.renderAccordion();

      return (
        <div className="container neo-collapsable-content">
          <SectionTitle component={sliceData.primary?.section_title} />
          {contentElements}
        </div>
      );
    }

    get validItems(): PrismicPageDataBodyCollapsableContentItem[] {
      const { sliceData } = this.props;

      return (sliceData.items || []).filter((item) => item?.item_title) as PrismicPageDataBodyCollapsableContentItem[];
    }

    renderAccordion(): ReactNode {
      const { activeItemIndex, elementIdPrefix, elementHeights } = this.state;
      const itemNodes: ReactNode[] = [];

      let index = 0;
      for (const item of this.validItems) {
        const currentIndex = index;
        const isActive = activeItemIndex === index;
        const elementHeight = elementHeights[index] || 0;

        if (!this.contentElementRefs[currentIndex]) {
          this.contentElementRefs[currentIndex] = React.createRef();
        }

        itemNodes.push(
          <button
            key={`button-${index}`}
            className={`button is-caa-forestgreen is-medium has-text-weight-bold is-accordion-item-button ${
              isActive ? 'is-active' : ''
            }`}
            id={`${elementIdPrefix}__${index}__Toggle`}
            aria-controls={`${elementIdPrefix}__${index}__Content`}
            aria-expanded={isActive ? 'true' : 'false'}
            onClick={(_event) => this.setActiveItem(currentIndex, true, _event)}
          >
            {item.item_title}
          </button>
        );

        itemNodes.push(this.renderContentNode(item, currentIndex, isActive));

        if (elementHeight > 0) {
          itemNodes.push(
            <style key={`style-${index}`} type="text/css">
              {`#${elementIdPrefix}__${index}__Content.neo-collapsable-region.is-active { height: ${elementHeight}px; }`}
            </style>
          );
        }
        index = index + 1;
      }

      return <div className={'neo-collapsable-content-accordion-container'}>{itemNodes}</div>;
    }

    renderTabs(): ReactNode {
      const { activeItemIndex, elementIdPrefix, elementHeights } = this.state;
      const tabNodes: ReactNode[] = [];
      const itemNodes: ReactNode[] = [];
      const validItems = this.validItems;

      let normalizedActiveItemIndex = activeItemIndex;

      if (
        normalizedActiveItemIndex === undefined ||
        normalizedActiveItemIndex < 0 ||
        normalizedActiveItemIndex >= validItems.length
      ) {
        normalizedActiveItemIndex = 0;
      }

      let index = 0;
      for (const item of this.validItems) {
        const currentIndex = index;
        const isActive = normalizedActiveItemIndex === index;
        const elementHeight = elementHeights[index] || 0;

        if (!this.contentElementRefs[currentIndex]) {
          this.contentElementRefs[currentIndex] = React.createRef();
        }

        tabNodes.push(
          <li key={`button-${index}`} className={`${isActive ? 'is-active' : ''}`}>
            <a
              id={`${elementIdPrefix}__${index}__Toggle`}
              aria-controls={`${elementIdPrefix}__${index}__Content`}
              aria-expanded={isActive ? 'true' : 'false'}
              href={`#${elementIdPrefix}__${index}__Content`}
              onClick={(_event) => this.setActiveItem(currentIndex, false, _event)}
            >
              {item.item_title}
            </a>
          </li>
        );

        itemNodes.push(this.renderContentNode(item, currentIndex, isActive));

        if (elementHeight > 0) {
          itemNodes.push(
            <style key={`style-${index}`} type="text/css">
              {`#${elementIdPrefix}__TabBox.neo-collapsable-content-tab-content-box[data-active-index="${currentIndex}"] { height: ${elementHeight}px; }`}
            </style>
          );
        }
        index = index + 1;
      }

      return (
        <div className={'neo-collapsable-content-tab-container'}>
          <div className="tabs">
            <ul>{tabNodes}</ul>
          </div>
          <div
            id={`${elementIdPrefix}__TabBox`}
            className={'neo-collapsable-content-tab-content-box'}
            data-active-index={normalizedActiveItemIndex}
          >
            {itemNodes}
          </div>
        </div>
      );
    }

    renderContentNode(item: PrismicPageDataBodyCollapsableContentItem, index: number, isActive: boolean) {
      const { elementIdPrefix } = this.state;

      const isMarkdown = item.item_rendering_mode === 'Markdown';

      let contentElement: ReactNode = null;
      if (isMarkdown) {
        contentElement = <PrismicMarkdownText text={item.item_markdown?.text} />;
      } else {
        contentElement = <PrismicStructuredText text={item.item_content} />;
      }

      return (
        <div
          key={`content-${index}`}
          className={`neo-collapsable-region ${isActive ? 'is-active' : ''}`}
          role="region"
          id={`${elementIdPrefix}__${index}__Content`}
          aria-labelledby={`${elementIdPrefix}__${index}__Toggle`}
        >
          <div className="content" ref={this.contentElementRefs[index]}>
            {contentElement}
          </div>
        </div>
      );
    }

    setActiveItem(index: number, allowReset: boolean, event: React.MouseEvent<HTMLAnchorElement | HTMLButtonElement>) {
      event.preventDefault();

      let newIndex: number | undefined = index;

      if (allowReset && this.state.activeItemIndex === index) {
        newIndex = undefined;
      }

      this.setState({
        activeItemIndex: newIndex,
      });
    }

    refreshElementHeights() {
      const heights: number[] = [];

      for (const i in this.contentElementRefs) {
        if (!Object.prototype.hasOwnProperty.call(this.contentElementRefs, i)) {
          continue;
        }

        const ref = this.contentElementRefs[i];
        heights[i] = ref?.current?.offsetHeight || 0;

        if (ref?.current) {
          const imgEls = ref?.current.getElementsByTagName('img');
          for (let x = 0; x < imgEls.length; x += 1) {
            const imgEl = imgEls[x];

            if (this.imgAttachedLisenters.includes(imgEl)) {
              continue;
            }

            imgEl.addEventListener('load', this.onWindowResize);
            this.imgAttachedLisenters.push(imgEl);
          }
        }
      }

      this.setState({
        elementHeights: heights,
      });
    }

    onWindowResize = () => {
      if (this.animationFrameCancelToken !== undefined) {
        window.cancelAnimationFrame(this.animationFrameCancelToken);
      }

      this.animationFrameCancelToken = window.requestAnimationFrame(() => {
        this.refreshElementHeights();
      });
    };
  }
);
export default CollapsableContentSlice;

export interface CollapsableContentSliceProps {
  sliceData: PrismicPageDataBodyCollapsableContent;
  pageData?: PrismicPage;
}

export interface CollapsableContentSliceState {
  activeItemIndex?: number;
  elementIdPrefix: string;
  elementHeights: number[];
}

// noinspection JSUnusedGlobalSymbols
export const SliceCollapsableContentFragment = graphql`
  fragment SliceCollapsableContentFragment on PrismicSlicesCollapsableContent {
    ... on PrismicPageDataBodyCollapsableContent {
      id
      slice_type
      primary {
        section_title {
          html
        }
        rendering_mode
      }
      items {
        item_title
        item_rendering_mode
        item_content {
          html
        }
        item_markdown {
          text
        }
      }
    }
    ... on PrismicErrorPageDataBodyCollapsableContent {
      id
      slice_type
      primary {
        section_title {
          html
        }
        rendering_mode
      }
      items {
        item_title
        item_rendering_mode
        item_content {
          html
        }
        item_markdown {
          text
        }
      }
    }
    ... on PrismicEmailSubscriptionPageDataBodyCollapsableContent {
      id
      slice_type
      primary {
        section_title {
          html
        }
        rendering_mode
      }
      items {
        item_title
        item_rendering_mode
        item_content {
          html
        }
        item_markdown {
          text
        }
      }
    }
    ... on PrismicGaragesSearchPageDataBodyCollapsableContent {
      id
      slice_type
      primary {
        section_title {
          html
        }
        rendering_mode
      }
      items {
        item_title
        item_rendering_mode
        item_content {
          html
        }
        item_markdown {
          text
        }
      }
    }
    ... on PrismicMerchandiseSearchPageDataBodyCollapsableContent {
      id
      slice_type
      primary {
        section_title {
          html
        }
        rendering_mode
      }
      items {
        item_title
        item_rendering_mode
        item_content {
          html
        }
        item_markdown {
          text
        }
      }
    }
    ... on PrismicSearchPageDataBodyCollapsableContent {
      id
      slice_type
      primary {
        section_title {
          html
        }
        rendering_mode
      }
      items {
        item_title
        item_rendering_mode
        item_content {
          html
        }
        item_markdown {
          text
        }
      }
    }
    ... on PrismicTravelAgentsSearchPageDataBodyCollapsableContent {
      id
      slice_type
      primary {
        section_title {
          html
        }
        rendering_mode
      }
      items {
        item_title
        item_rendering_mode
        item_content {
          html
        }
        item_markdown {
          text
        }
      }
    }
    ... on PrismicTravelDealsSearchPageDataBodyCollapsableContent {
      id
      slice_type
      primary {
        section_title {
          html
        }
        rendering_mode
      }
      items {
        item_title
        item_rendering_mode
        item_content {
          html
        }
        item_markdown {
          text
        }
      }
    }
    ... on PrismicTravelPartnersSearchPageDataBodyCollapsableContent {
      id
      slice_type
      primary {
        section_title {
          html
        }
        rendering_mode
      }
      items {
        item_title
        item_rendering_mode
        item_content {
          html
        }
        item_markdown {
          text
        }
      }
    }
    ... on PrismicTravelStoreSearchPageDataBodyCollapsableContent {
      id
      slice_type
      primary {
        section_title {
          html
        }
        rendering_mode
      }
      items {
        item_title
        item_rendering_mode
        item_content {
          html
        }
        item_markdown {
          text
        }
      }
    }
    ... on PrismicTravelTalksSearchPageDataBodyCollapsableContent {
      id
      slice_type
      primary {
        section_title {
          html
        }
        rendering_mode
      }
      items {
        item_title
        item_rendering_mode
        item_content {
          html
        }
        item_markdown {
          text
        }
      }
    }
    ... on PrismicTravelTripDataBodyCollapsableContent {
      id
      slice_type
      primary {
        section_title {
          html
        }
        rendering_mode
      }
      items {
        item_title
        item_rendering_mode
        item_content {
          html
        }
        item_markdown {
          text
        }
      }
    }
    ... on PrismicTravelTalksSearchPageDataBodyCollapsableContent {
      id
      slice_type
      primary {
        section_title {
          html
        }
        rendering_mode
      }
      items {
        item_title
        item_rendering_mode
        item_content {
          html
        }
        item_markdown {
          text
        }
      }
    }
    ... on PrismicTravelStoreDataBodyCollapsableContent {
      id
      slice_type
      primary {
        section_title {
          html
        }
        rendering_mode
      }
      items {
        item_title
        item_rendering_mode
        item_content {
          html
        }
        item_markdown {
          text
        }
      }
    }
    ... on PrismicTravelDealDataBodyCollapsableContent {
      id
      slice_type
      primary {
        section_title {
          html
        }
        rendering_mode
      }
      items {
        item_title
        item_rendering_mode
        item_content {
          html
        }
        item_markdown {
          text
        }
      }
    }
    ... on PrismicTravelTalkDataBodyCollapsableContent {
      id
      slice_type
      primary {
        section_title {
          html
        }
        rendering_mode
      }
      items {
        item_title
        item_rendering_mode
        item_content {
          html
        }
        item_markdown {
          text
        }
      }
    }
    ... on PrismicTravelTripDataBodyCollapsableContent {
      id
      slice_type
      primary {
        section_title {
          html
        }
        rendering_mode
      }
      items {
        item_title
        item_rendering_mode
        item_content {
          html
        }
        item_markdown {
          text
        }
      }
    }
    ... on PrismicMerchandiseDataBodyCollapsableContent {
      id
      slice_type
      primary {
        section_title {
          html
        }
        rendering_mode
      }
      items {
        item_title
        item_rendering_mode
        item_content {
          html
        }
        item_markdown {
          text
        }
      }
    }
    ... on PrismicTravelPartnerDataBodyCollapsableContent {
      id
      slice_type
      primary {
        section_title {
          html
        }
        rendering_mode
      }
      items {
        item_title
        item_rendering_mode
        item_content {
          html
        }
        item_markdown {
          text
        }
      }
    }
  }
`;
