import React, { Component, ReactNode } from 'react';
import { graphql, Link, StaticQuery } from 'gatsby';
import PrismicImage from './controls/PrismicImage';
import {
  PrismicHeader,
  PrismicHeaderDataType,
  PrismicMainNavTopic,
  PrismicMainNavTopicSection,
  PrismicSearchPage,
} from '../graphql';
import HeaderFlatNav, {
  HeaderLinkData,
} from './fragments/header/HeaderFlatNav';
import HeaderMegaMenu, {
  HeaderMegaMenuSection,
  HeaderMegaMenuSectionItem,
  HeaderMegaMenuTopic,
  HeaderMegaMenuTopicColumn,
  HeaderMegaMenuTopLevelItem,
} from './fragments/header/HeaderMegaMenu';
import { Subscription } from 'rxjs';
import {
  Breakpoint,
  CurrentBreakpoint$,
  IsBreakpointAtLeast,
  IsBreakpointAtMost,
} from '../utils/BreakpointHelpers';
import classNames from 'classnames';
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';
import { IsBrowserEnvironment } from '../utils/BrowserHelpers';
import AlertBar from './fragments/header/AlertBar';

const HeaderInternal = class extends Component<
  HeaderInternalProps,
  HeaderInternalState
> {
  private readonly headerNavsRef: React.RefObject<
    HTMLDivElement
  > = React.createRef();
  private breakpointSubscription?: Subscription;

  constructor(props: Readonly<HeaderInternalProps>) {
    super(props);

    this.state = {
      isMobileNavigationOpened: false,
      enableMobileTransitions: false,
    };
  }

  // noinspection JSUnusedGlobalSymbols
  componentDidMount = (): void => {
    this.breakpointSubscription = CurrentBreakpoint$.subscribe(
      newBreakpoint => {
        if (IsBreakpointAtLeast(newBreakpoint.breakpoint, Breakpoint.Desktop)) {
          this.setState({
            isMobileNavigationOpened: false,
            enableMobileTransitions: false,
          });
        }

        // Fix for CSS animations during breakpoint transitions
        if (IsBreakpointAtMost(newBreakpoint.breakpoint, Breakpoint.Tablet)) {
          this.setState({
            enableMobileTransitions: true,
          });
        }
      }
    );
  };

  // noinspection JSUnusedGlobalSymbols
  componentWillUnmount = (): void => {
    if (this.breakpointSubscription) {
      this.breakpointSubscription.unsubscribe();
    }

    if (this.headerNavsRef.current) {
      enableBodyScroll(this.headerNavsRef.current);
    }
  };

  componentDidUpdate(
    _prevProps: Readonly<HeaderInternalProps>,
    prevState: Readonly<HeaderInternalState>,
    _snapshot?: any
  ): void {
    if (
      IsBrowserEnvironment &&
      this.headerNavsRef.current &&
      prevState.isMobileNavigationOpened !== this.state.isMobileNavigationOpened
    ) {
      if (this.state.isMobileNavigationOpened) {
        disableBodyScroll(this.headerNavsRef.current);
      } else {
        enableBodyScroll(this.headerNavsRef.current);
      }
    }
  }

  render = () => {
    const headerData = this.props.queryResults.prismicHeader.data!;

    const headerClassNames = classNames({
      'neo-header': true,
      'is-mobile-menu-opened': this.state.isMobileNavigationOpened,
      'is-mobile-transitions-enabled': this.state.enableMobileTransitions,
    });

    return (
      <header className={headerClassNames}>
        <div className="container">
          <div className="neo-header-container">
            <div className="neo-header-logo-column">
              <Link
                to="/"
                className="neo-brand-link"
                title="Return to home page"
              >
                <div
                  className="neo-logo"
                  role="img"
                  aria-label="CAA North & East Ontario Logo"
                >
                  <PrismicImage image={headerData.logo!} imgStyle={{width: undefined, height: undefined}} />
                  <div className="neo-logo-subtitle">
                    {headerData.logo_subtitle}
                  </div>
                </div>
              </Link>
            </div>
            <div className="neo-header-navs" ref={this.headerNavsRef}>
              <div className="neo-header-navs-scroll-container">
                <div className="neo-header-nav-primary">
                  {this.renderPrimaryNavigation(headerData)}
                </div>
                <div className="neo-header-nav-secondary">
                  {this.renderSecondaryNavigation(headerData)}
                  {this.renderSearchNavigation(this.props.queryResults)}
                  {this.renderSocialNavigation(headerData)}
                </div>
              </div>
            </div>
            <div
              className="neo-header-nav-overlay"
              onClick={this.onNavOverlayClicked}
            />
            <div className="neo-nav-touch-controls">
              {this.renderSearchNavigation(this.props.queryResults)}
              <div className="neo-header-burger">
                <button
                  aria-expanded={
                    this.state.isMobileNavigationOpened ? 'true' : 'false'
                  }
                  onClick={this.onBurgerButtonClicked}
                  className={`neo-burger-button ${
                    this.state.isMobileNavigationOpened ? ' is-active' : ''
                  }`}
                  aria-label={'Open Menu'}
                >
                  <span />
                  <span />
                  <span />
                </button>
              </div>
            </div>
          </div>
        </div>
        <AlertBar />
      </header>
    );
  };

  renderSecondaryNavigation = (
    headerData: PrismicHeaderDataType
  ): ReactNode => {
    const navItems: HeaderLinkData[] = [];

    if (headerData.secondary_nav_item) {
      for (const navItemDef of headerData.secondary_nav_item) {
        if (!navItemDef || !navItemDef.link_dest) {
          continue;
        }

        navItems.push({
          title: navItemDef.link_title || undefined,
          link_type: navItemDef.link_dest.link_type,
          target: navItemDef.link_dest.target,
          url: navItemDef.link_dest.url,
        });
      }
    }

    return <HeaderFlatNav items={navItems} />;
  };

  renderSearchNavigation = (queryResults: HeaderDataQueryResults) => {
    if (!queryResults.prismicSearchPage) {
      return null;
    }

    return (
      <HeaderFlatNav
        className={'is-search-nav'}
        items={[
          {
            title:
              queryResults.prismicSearchPage?.data?.page_title || undefined,
            icon: 'fas-magnifying-glass',
            link_type: `Document`,
            url: queryResults.prismicSearchPage?.url || undefined,
          },
        ]}
        writeTitleAsAttribute={true}
      />
    );
  };

  renderSocialNavigation = (headerData: PrismicHeaderDataType): ReactNode => {
    const navItems: HeaderLinkData[] = [];

    if (headerData.social_nav_item) {
      for (const navItemDef of headerData.social_nav_item) {
        if (!navItemDef || !navItemDef.link_dest) {
          continue;
        }

        navItems.push({
          title: navItemDef.link_title || undefined,
          icon: navItemDef.link_icon || undefined,
          link_type: navItemDef.link_dest.link_type,
          target: navItemDef.link_dest.target,
          url: navItemDef.link_dest.url,
        });
      }
    }

    return (
      <HeaderFlatNav
        className={'is-social-nav'}
        items={navItems}
        writeTitleAsAttribute={true}
      />
    );
  };

  renderPrimaryNavigation = (headerData: PrismicHeaderDataType): ReactNode => {
    const navTree = this.buildPrimaryNavigationTree(headerData);

    const { isMobileNavigationOpened } = this.state;

    navTree.unshift({
      link_type: headerData.member_dest?.link_type || `Any`,
      url: headerData.member_dest?.url,
      icon: headerData.member_icon,
      title: headerData.member_title,
      itemClassName: 'neo-nav-item-become-member',
    });

    return (
      <HeaderMegaMenu
        items={navTree}
        className="is-primary"
        isOpened={isMobileNavigationOpened}
        onCloseButtonClick={this.onNavOverlayClicked}
      />
    );
  };

  buildPrimaryNavigationTree = (
    headerData: PrismicHeaderDataType
  ): HeaderMegaMenuTopLevelItem[] => {
    const navTree: HeaderMegaMenuTopLevelItem[] = [];

    if (!headerData.main_nav_topics) {
      return navTree;
    }

    for (const topicData of headerData.main_nav_topics) {
      if (!topicData) {
        continue;
      }

      const topicDocument = topicData.topic?.document as PrismicMainNavTopic | undefined;

      if (!topicDocument) {
        continue;
      }

      const topic: HeaderMegaMenuTopic = {
        id: topicDocument.id,
        title: topicDocument.data?.title ?? undefined,
        columns: [],
      };
      navTree.push(topic);

      if (!topicDocument.data?.items) {
        continue;
      }

      let currentColumn: HeaderMegaMenuTopicColumn | null = null;

      for (const sectionData of topicDocument.data.items) {
        if (!sectionData) {
          continue;
        }

        const sectionDocument = sectionData.section?.document as
          | PrismicMainNavTopicSection
          | undefined;

        if (!sectionDocument) {
          continue;
        }

        if (
          currentColumn === null ||
          sectionData.section_new_column === 'yes'
        ) {
          currentColumn = {
            sections: [],
          };

          topic.columns.push(currentColumn);
        }

        const section: HeaderMegaMenuSection = {
          id: sectionDocument.id,
          title: sectionDocument.data?.title ?? undefined,
          items: [],
        };

        currentColumn.sections.push(section);

        if (!sectionDocument.data?.items) {
          continue;
        }

        let currentTopLevelLink: HeaderMegaMenuSectionItem | null = null;

        for (const linkData of sectionDocument.data.items) {
          if (!linkData) {
            continue;
          }

          const link: HeaderLinkData = {
            title: linkData.link_title,
            link_type: linkData.link_dest?.link_type ?? 'Any',
            target: linkData.link_dest?.target,
            url: linkData.link_dest?.url,
          };

          if (currentTopLevelLink === null || linkData.link_is_child === 'no') {
            currentTopLevelLink = {
              link,
              children: [],
            };
            section.items.push(currentTopLevelLink);
          } else {
            currentTopLevelLink.children.push(link);
          }
        }
      }
    }

    return navTree;
  };

  onNavOverlayClicked = (_event?: React.MouseEvent) => {
    this.setState({
      isMobileNavigationOpened: false,
    });
    const scrollY = parseInt(document.body.style.top, 10);
    document.body.style.position = '';
    document.body.style.top = '';
    window.scrollTo(0, (scrollY || 0) * -1);
  };

  onBurgerButtonClicked = (_event?: React.MouseEvent) => {
    this.setState({
      isMobileNavigationOpened: true,
    });
  };
};

const Header = class extends Component {
  render() {
    return (
      <StaticQuery
        query={HeaderDataQuery}
        render={(queryResults: HeaderDataQueryResults) => (
          <HeaderInternal {...this.props} queryResults={queryResults} />
        )}
      />
    );
  }
};

interface HeaderInternalProps {
  queryResults: HeaderDataQueryResults;
}

interface HeaderInternalState {
  isMobileNavigationOpened: boolean;
  enableMobileTransitions: boolean;
}

export default Header;

const HeaderDataQuery = graphql`
  query HeaderDataQuery {
    prismicHeader {
      data {
        logo {
          alt
          url
          gatsbyImageData
        }
        logo_subtitle
        main_nav_topics {
          topic {
            document {
              ... on PrismicMainNavTopic {
                id
                data {
                  title
                  items {
                    section {
                      document {
                        ... on PrismicMainNavTopicSection {
                          id
                          data {
                            title
                            items {
                              link_dest {
                                ...PrismicLinkFragment
                              }
                              link_is_child
                              link_title
                            }
                          }
                        }
                      }
                    }
                    section_new_column
                  }
                }
              }
            }
          }
        }
        secondary_nav_item {
          link_title
          link_dest {
            ...PrismicLinkFragment
          }
        }
        social_nav_item {
          link_title
          link_dest {
            ...PrismicLinkFragment
          }
          link_icon
        }
        member_title
        member_dest {
          ...PrismicLinkFragment
        }
        member_icon
      }
    }
    prismicSearchPage {
      ...PrismicDocumentLinkFragment
      data {
        page_title
      }
    }
  }
`;

interface HeaderDataQueryResults {
  prismicHeader: PrismicHeader;
  prismicSearchPage: PrismicSearchPage;
}
