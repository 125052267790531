import React, { Component, ReactNode } from 'react';
import WithGlobalResources, { InjectedGlobalResourcesProps } from '../higher-order/WithGlobalResources';
import PrismicImage from './PrismicImage';
import PrismicLink from './PrismicLink';
import { PrismicAllDocumentTypes, PrismicLinkType, PrismicStructuredTextType } from '../../graphql';
import PrismicStructuredText from './PrismicStructuredText';
import PrismicFontAwesomeIcon from './PrismicFontAwesomeIcon';
import { IsValidPrismicLink } from '../../utils/PrismicHelpers';
import classNames from 'classnames';

const ImageLinkTile = WithGlobalResources(
  class ImageLinkTilesImpl extends Component<InjectedGlobalResourcesProps & IImageLinkTileData> {
    render() {
      const imageLinkData = this.props;
      let contentNode: ReactNode = imageLinkData.custom_content;

      if (contentNode === undefined) {
        let titleNode: ReactNode = undefined;
        let textNode: ReactNode = undefined;

        let ctaNode: ReactNode = null;

        if (
          (imageLinkData?.link_dest as PrismicAllDocumentTypes)?.id ||
          IsValidPrismicLink(imageLinkData?.link_dest as PrismicLinkType) ||
          imageLinkData?.link_cta
        ) {
          ctaNode = (
            <div className={'cta'} key={'cta'}>
              {imageLinkData.link_cta || imageLinkData.globalResources.default_cta_text}
            </div>
          );
        }

        if (imageLinkData.link_title) {
          titleNode = (
            <div className="tile-title" key={'title'}>
              {imageLinkData.link_title}
            </div>
          );
        }
        if (imageLinkData.link_desc?.html) {
          textNode = (
            <div className="tile-text" key={'text'}>
              <PrismicStructuredText text={imageLinkData.link_desc} />
            </div>
          );
        }

        contentNode = [titleNode, textNode, ctaNode];
      }

      let imageNode: ReactNode = undefined;
      if (imageLinkData.image) {
        let tagNode: ReactNode = undefined;
        let imageBottomNode: ReactNode = undefined;

        if (imageLinkData.tag_label) {
          let tagIconNode: ReactNode = undefined;

          if (imageLinkData.tag_icon) {
            tagIconNode = <PrismicFontAwesomeIcon icon={imageLinkData.tag_icon} />;
          }

          tagNode = (
            <div className={`image-link-tile-tag`}>
              {tagIconNode}
              <span>{imageLinkData.tag_label}</span>
            </div>
          );
        }

        if (imageLinkData.image_bottom_content) {
          let imageButtonIconNode: ReactNode = undefined;

          if (imageLinkData.image_bottom_icon) {
            imageButtonIconNode = <PrismicFontAwesomeIcon icon={imageLinkData.image_bottom_icon} />;
          }

          imageBottomNode = (
            <div className={`image-link-tile-bottom-image-content`}>
              <div className={`image-link-tile-bottom-image-content-interior`}>
                {imageButtonIconNode}
                <div>{imageLinkData.image_bottom_content}</div>
              </div>
            </div>
          );
        }
        imageNode = (
          <div
            className={classNames('neo-image-link-tile-padding-box', {
              'display-show': this.props.displayImageMobile,
            })}
          >
            <PrismicImage image={imageLinkData.image} srcIsFixed />
            {tagNode}
            {imageBottomNode}
          </div>
        );
      }

      let crownNode: ReactNode = undefined;
      if (imageLinkData.crown_label) {
        let crownIconNode: ReactNode = undefined;

        if (imageLinkData.crown_icon) {
          crownIconNode = <PrismicFontAwesomeIcon icon={imageLinkData.crown_icon} />;
        }

        crownNode = (
          <div className={`image-link-tile-crown ${imageLinkData.crown_className || ''}`}>
            {crownIconNode}
            <span>{imageLinkData.crown_label}</span>
          </div>
        );
      }

      if (imageLinkData.link_dest) {
        return (
          <PrismicLink
            to={imageLinkData.link_dest!}
            className={`image-link-tile ${crownNode ? 'has-crown' : ''} ${imageLinkData.className || ''}`}
          >
            {crownNode}
            <div className={'image-link-tile-interior'}>
              {imageNode}
              <div className="tile-content">{contentNode}</div>
            </div>
          </PrismicLink>
        );
      }

      return (
        <div className={`image-link-tile ${crownNode ? 'has-crown' : ''} ${imageLinkData.className || ''}`}>
          {crownNode}
          <div className={'image-link-tile-interior'}>
            {imageNode}
            <div className="tile-content">{contentNode}</div>
          </div>
        </div>
      );
    }
  }
);
export default ImageLinkTile;

export interface IImageLinkTileData {
  index?: number;
  image?: any;
  link_title?: string | null;
  link_desc?: PrismicStructuredTextType | null;
  link_cta?: string | null;
  link_dest?: PrismicLinkType | PrismicAllDocumentTypes | null;
  custom_content?: ReactNode;
  crown_icon?: string | null;
  crown_label?: string | null;
  crown_className?: string | null;
  tag_icon?: string | null;
  tag_label?: string | null;
  image_bottom_icon?: string | null;
  image_bottom_content?: ReactNode;
  className?: string | null;
  displayImageMobile?: boolean;
}
