import { PrismicPage, PrismicPageDataBodyContentListing } from '../../graphql';
import React, { Component, ReactNode } from 'react';
import { ToMoment } from '../../utils/PrismicHelpers';
import { graphql } from 'gatsby';
import ContentListing, { ContentListingItem } from '../controls/ContentListing';
import SectionTitle from '../controls/SectionTitle';

export const ContentListingSliceKey = 'content_listing';
export default class ContentListingSlice extends Component<ContentListingSliceProps> {
  render(): ReactNode {
    const { sliceData } = this.props;

    if (!sliceData.resolved_items?.length) {
      return <React.Fragment />;
    }

    const contentListingItems: ContentListingItem[] = [];
    const displayImage = sliceData.primary?.display_image === 'Yes';
    const displayDate = sliceData.primary?.display_date === 'Yes';
    const newestFirst = sliceData.primary?.newest_first === 'Yes';

    if (sliceData.resolved_items) {
      if (newestFirst) {
        sliceData.resolved_items.sort(
          (a, b) => new Date(b?.resolved_content_date).getTime() - new Date(a?.resolved_content_date).getTime()
        );
      }
      for (const item of sliceData.resolved_items) {
        if (!item) continue;
        let dateNode: ReactNode = null;

        if (displayDate) {
          const formattedDate = ToMoment(item.resolved_content_date)?.format('MMMM Do, YYYY');

          if (formattedDate) {
            dateNode = formattedDate;
          }
        }

        const clItem: ContentListingItem = {
          link: item,
          title: item?.data?.page_title,
          image: item?.data?.link_image ?? item?.data?.og_image,
          extraInfo: dateNode,
          description: item?.data?.share_description,
        };

        contentListingItems.push(clItem);
      }
    }

    return (
      <div className="container neo-content-listing-slice">
        <SectionTitle component={sliceData.primary?.section_title} />
        <ContentListing noImageColumn={!displayImage} items={contentListingItems} />
      </div>
    );
  }
}

export interface ContentListingSliceProps {
  sliceData: PrismicPageDataBodyContentListing;
  pageData?: PrismicPage;
}

// noinspection JSUnusedGlobalSymbols
export const SliceContentListingFragment = graphql`
  fragment SliceContentListingFragment on PrismicSlicesContentListing {
    ... on PrismicPageDataBodyContentListing {
      id
      slice_type
      primary {
        section_title {
          html
        }
        display_date
        display_image
        newest_first
      }
      resolved_items {
        id
        uid
        url
        resolved_content_date
        data {
          page_title
          share_description
          og_image {
            alt
            url
            gatsbyImageData(width: 960, layout: CONSTRAINED)
          }
          link_image {
            alt
            url
            gatsbyImageData(width: 960, layout: CONSTRAINED)
          }
        }
      }
    }
    ... on PrismicTravelPartnerDataBodyContentListing {
      id
      slice_type
      primary {
        section_title {
          html
        }
        display_date
        display_image
        newest_first
      }
      resolved_items {
        id
        uid
        url
        resolved_content_date
        data {
          page_title
          share_description
          og_image {
            alt
            url
            gatsbyImageData(width: 960, layout: CONSTRAINED)
          }
          link_image {
            alt
            url
            gatsbyImageData(width: 960, layout: CONSTRAINED)
          }
        }
      }
    }
    ... on PrismicMerchandiseDataBodyContentListing {
      id
      slice_type
      primary {
        section_title {
          html
        }
        display_date
        display_image
        newest_first
      }
      resolved_items {
        id
        uid
        url
        resolved_content_date
        data {
          page_title
          share_description
          og_image {
            alt
            url
            gatsbyImageData(width: 960, layout: CONSTRAINED)
          }
          link_image {
            alt
            url
            gatsbyImageData(width: 960, layout: CONSTRAINED)
          }
        }
      }
    }
    ... on PrismicTravelStoreDataBodyContentListing {
      id
      slice_type
      primary {
        section_title {
          html
        }
        display_date
        display_image
        newest_first
      }
      resolved_items {
        id
        uid
        url
        resolved_content_date
        data {
          page_title
          share_description
          og_image {
            alt
            url
            gatsbyImageData(width: 960, layout: CONSTRAINED)
          }
          link_image {
            alt
            url
            gatsbyImageData(width: 960, layout: CONSTRAINED)
          }
        }
      }
    }
    ... on PrismicTravelTripDataBodyContentListing {
      id
      slice_type
      primary {
        section_title {
          html
        }
        display_date
        display_image
        newest_first
      }
      resolved_items {
        id
        uid
        url
        resolved_content_date
        data {
          page_title
          share_description
          og_image {
            alt
            url
            gatsbyImageData(width: 960, layout: CONSTRAINED)
          }
          link_image {
            alt
            url
            gatsbyImageData(width: 960, layout: CONSTRAINED)
          }
        }
      }
    }
    ... on PrismicTravelDealDataBodyContentListing {
      id
      slice_type
      primary {
        section_title {
          html
        }
        display_date
        display_image
        newest_first
      }
      resolved_items {
        id
        uid
        url
        resolved_content_date
        data {
          page_title
          share_description
          og_image {
            alt
            url
            gatsbyImageData(width: 960, layout: CONSTRAINED)
          }
          link_image {
            alt
            url
            gatsbyImageData(width: 960, layout: CONSTRAINED)
          }
        }
      }
    }
    ... on PrismicTravelTalkDataBodyContentListing {
      id
      slice_type
      primary {
        section_title {
          html
        }
        display_date
        display_image
        newest_first
      }
      resolved_items {
        id
        uid
        url
        resolved_content_date
        data {
          page_title
          share_description
          og_image {
            alt
            url
            gatsbyImageData(width: 960, layout: CONSTRAINED)
          }
          link_image {
            alt
            url
            gatsbyImageData(width: 960, layout: CONSTRAINED)
          }
        }
      }
    }
  }
`;
