import PrismicLink from '../../controls/PrismicLink';
import React, { Component, ReactNode } from 'react';
import PrismicFontAwesomeIcon from '../../controls/PrismicFontAwesomeIcon';
import { IconPrefix } from '@fortawesome/fontawesome-common-types';
import { SizeProp } from '@fortawesome/fontawesome-svg-core';
import { PrismicLinkType } from '../../../graphql';

const HeaderFlatNav = class extends Component<HeaderFlatNavProps> {
  static defaultProps: Partial<HeaderFlatNavProps> = {
    writeTitleAsAttribute: false,
    iconPrefixPriority: ['fab', 'fas', 'fal', 'fad'],
  };

  renderAccessibilityIcon = (): ReactNode => {
    return (
      <a
        target={'_blank'}
        className={'neo-ea-icon'}
        aria-label={'essential accessibility website'}
        href={
          'https://www.essentialaccessibility.com/caaneo'
        }
      />
    );
  };

  render = () => {
    const {
      items,
      className,
      iconPrefixPriority,
      writeTitleAsAttribute,
      ...otherProps
    } = this.props;

    const navItems: ReactNode[] = [];

    let index = 0;
    for (const itemDef of items) {
      let icon: ReactNode = null;
      let label: ReactNode = null;

      if (itemDef.icon) {
        icon = (
          <PrismicFontAwesomeIcon
            icon={itemDef.icon}
            size={itemDef.iconSize}
            prefixPriority={iconPrefixPriority}
          />
        );
      }

      if (writeTitleAsAttribute === false) {
        label = <span>{itemDef.title}</span>;
      }

      navItems.push(
        <li
          className={`neo-nav-item ${itemDef.itemClassName || ''}`}
          key={`link-${(index += 1)}`}
        >
          <PrismicLink
            to={itemDef}
            title={
              writeTitleAsAttribute ? itemDef.title || undefined : undefined
            }
            className={itemDef.className}
            activeClassName={itemDef.activeClassName}
            partiallyActive={itemDef.partiallyActive}
          >
            {icon}
            {label}
          </PrismicLink>
        </li>
      );
    }

    return (
      <ul className={`neo-nav ${className || ''}`} {...otherProps}>
        {navItems}
        {className === 'is-social-nav' ? this.renderAccessibilityIcon() : ''}
      </ul>
    );
  };
};
export default HeaderFlatNav;

interface HeaderFlatNavProps {
  className?: string;
  items: HeaderLinkData[];
  iconPrefixPriority?: IconPrefix[];
  writeTitleAsAttribute?: boolean;
}

export interface HeaderLinkData extends PrismicLinkType {
  title?: string | null;
  icon?: string | null;
  iconSize?: SizeProp;
  activeClassName?: string;
  itemClassName?: string;
  className?: string;
  partiallyActive?: boolean;
}
