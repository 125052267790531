import React, { Component, ReactNode } from 'react';
import Prism from 'prismjs';

export default class JsonData extends Component<JsonDataProps> {
  render(): ReactNode {
    const dataHtml = Prism.highlight(
      JSON.stringify(this.props.data, undefined, 2) || 'undefined',
      Prism.languages.javascript,
      'javascript'
    );

    return (
      <pre
        className="neo-json-data language-javascript"
        dangerouslySetInnerHTML={{ __html: dataHtml }}
      />
    );
  }
}

export interface JsonDataProps {
  data: any;
}
