import {
  PrismicRewardsPartner,
  PrismicRewardsPartnerConnection,
} from '../../graphql';
import React, { Component, ComponentType } from 'react';
import { graphql, StaticQuery } from 'gatsby';
import { Subset } from '../../utils/UtilityTypes';

const WithRewardsPartnerData = function WithRewardsPartnerDataFn<
  P extends InjectedRewardsPartnerDataProps
>(
  ComposedComponent: ComponentType<P>
): ComponentType<Subset<P, InjectedRewardsPartnerDataProps>> {
  return class extends Component<Subset<P, InjectedRewardsPartnerDataProps>> {
    render() {
      return (
        <StaticQuery
          query={RewardsPartnerDataQuery}
          render={queryResult => {
            const {
              allPrismicRewardsPartner,
            } = queryResult as RewardsPartnerDataQueryResults;

            return (
              <ComposedComponent
                {...(this.props as any)}
                rewardsPartnerData={allPrismicRewardsPartner.nodes || []}
              />
            );
          }}
        />
      );
    }
  };
};
export default WithRewardsPartnerData;

const RewardsPartnerDataQuery = graphql`
  query RewardsPartnerDataQuery {
    allPrismicRewardsPartner {
      nodes {
        ...RewardsPartnerFragment
      }
    }
  }
`;

interface RewardsPartnerDataQueryResults {
  allPrismicRewardsPartner: PrismicRewardsPartnerConnection;
}

// noinspection JSUnusedGlobalSymbols
export const RewardsPartnerFragment = graphql`
  fragment RewardsPartnerFragment on PrismicRewardsPartner {
    id
    data {
      partner_name
      partner_tag_line
      partner_portal_link {
        ...PrismicLinkFragment
      }
      partner_logo {
        alt
        url
        gatsbyImageData(width: 960, placeholder: NONE, layout: CONSTRAINED)
      }
      tags {
        tag {
          document {
            ... on PrismicTag {
              id
            }
          }
        }
      }
    }
  }
`;

export interface InjectedRewardsPartnerDataProps {
  rewardsPartnerData: PrismicRewardsPartner[];
}
