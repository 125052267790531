import React, { Component, ReactNode } from 'react';
import TravelTalk from './TravelTalk';
import ImageLinkTileList from '../../controls/ImageLinkTileList';
import WithTravelTalkData, { InjectedTravelTalkDataProps } from '../../higher-order/WithTravelTalkData';
import { FilterTravelDataBasedOnTags } from '../../../utils/AgentDataHelpers';

const UpcomingTravelTalk = WithTravelTalkData(
  class UpcomingTravelTalkImpl extends Component<
    UpcomingTravelTalkProps & InjectedTravelTalkDataProps,
    UpcomingTravelTalkState
  > {
    static defaultProps: Partial<UpcomingTravelTalkProps & InjectedTravelTalkDataProps> = {
      numOfResults: 1,
    };

    constructor(props: Readonly<UpcomingTravelTalkProps & InjectedTravelTalkDataProps>) {
      super(props);

      this.state = {
        showLatestItems: false,
      };
    }

    componentDidMount(): void {
      this.setState({
        showLatestItems: true,
      });
    }

    render(): ReactNode {
      const {
        travelDestinationId,
        travelPartnerId,
        travelTypeId,
        travelStoreId,
        returnEmptyIfNoMatch,
        numOfResults,
        render,
        travelTalkData,
      } = this.props;

      if (!this.state.showLatestItems) {
        return null;
      }

      let eligibleTalks = FilterTravelDataBasedOnTags(travelTalkData, {
        travelDestinationId,
        travelPartnerId,
        travelTypeId,
        travelStoreId,
      });

      if (eligibleTalks.length === 0 && returnEmptyIfNoMatch === true) {
        return null;
      }

      if (eligibleTalks.length === 0) {
        eligibleTalks = travelTalkData;
      }

      eligibleTalks.sort((a, b) => {
        if (a?.data?.event_start_time < b?.data?.event_start_time) {
          return -1;
        }

        if (a?.data?.event_start_time > b?.data?.event_start_time) {
          return 1;
        }

        return 0;
      });

      const selectedTalks = eligibleTalks.slice(0, numOfResults || 1);

      if (selectedTalks.length === 0) {
        return null;
      }

      const outputNode: ReactNode =
        numOfResults === 1 ? (
          <TravelTalk talk={selectedTalks[0]} />
        ) : (
          <ImageLinkTileList>
            {selectedTalks.map((talk, index) => (
              <TravelTalk key={index} talk={talk} />
            ))}
          </ImageLinkTileList>
        );

      if (render) {
        return render(outputNode);
      }

      return outputNode;
    }
  }
);
export default UpcomingTravelTalk;

export interface UpcomingTravelTalkProps {
  travelPartnerId?: string | null;
  travelDestinationId?: string | null;
  travelTypeId?: string | null;
  travelStoreId?: string | null;
  returnEmptyIfNoMatch?: boolean;
  numOfResults?: number;
  render?: (node: ReactNode) => ReactNode;
}

export interface UpcomingTravelTalkState {
  showLatestItems: boolean;
}
