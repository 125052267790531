import { PrismicErrorPage, PrismicErrorPageDataBodySearchEnginePlaceholder, PrismicSearchPage } from '../../../graphql';
import React, { Component, ReactNode } from 'react';
import WithGlobalResources, { InjectedGlobalResourcesProps } from '../../higher-order/WithGlobalResources';
import { MakeRandomId } from '../../../utils/RandomId';
import { graphql, navigate, StaticQuery } from 'gatsby';
import { stringify as toQueryString } from 'querystring';
import SectionTitle from '../../controls/SectionTitle';

export const ErrorPageSearchSliceKey = '!internal_error_page_search_slice';

const ErrorPageSearchSlice = WithGlobalResources(
  class ErrorPageSearchSliceImpl extends Component<
    ErrorPageSearchSliceProps & InjectedGlobalResourcesProps,
    ErrorPageSearchSliceState
  > {
    private readonly inputRef: React.RefObject<HTMLInputElement> = React.createRef();

    constructor(props: Readonly<ErrorPageSearchSliceProps & InjectedGlobalResourcesProps>) {
      super(props);

      this.state = {
        elementIdPrefix: MakeRandomId('ErrorPageSearch', 10),
        query: '',
      };
    }

    componentDidMount(): void {
      if (this.inputRef.current) {
        this.inputRef.current.focus();
      }
    }

    render(): ReactNode {
      return <StaticQuery query={ErrorPageSearchSliceQuery} render={(results) => this.renderWithData(results)} />;
    }

    renderWithData(results: ErrorPageSearchSliceQueryResults): ReactNode {
      const { sliceData } = this.props;
      const { elementIdPrefix } = this.state;

      return (
        <div className="container neo-site-search">
          <SectionTitle component={sliceData.primary?.section_title} />

          <div className="columns is-variable is-4 neo-site-search-controls">
            <div className="column">
              <form onSubmit={(e) => this.onFormSubmit(e, results)}>
                <div className="field">
                  <label
                    className="label"
                    htmlFor={`${elementIdPrefix}__search__input`}
                    id={`${elementIdPrefix}__search__label`}
                  >
                    {sliceData?.primary?.search_field_label}
                  </label>
                </div>
                <div className="field has-addons">
                  <div className="control is-expanded">
                    <input
                      className="input"
                      type="text"
                      aria-labelledby={`${elementIdPrefix}__search__label`}
                      placeholder={sliceData?.primary?.search_field_placeholder || undefined}
                      ref={this.inputRef}
                      value={this.state.query}
                      onChange={this.onSearchInputChange}
                    />
                  </div>
                  <div className="control">
                    <button
                      type="submit"
                      className="button is-caa-forestgreen"
                      disabled={this.state.query.trim() === ''}
                    >
                      {sliceData?.primary?.search_button_label}
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      );
    }

    onSearchInputChange = () => {
      if (!this.inputRef.current) {
        return;
      }

      this.setState({
        query: this.inputRef.current.value,
      });
    };

    onFormSubmit = (event: React.FormEvent, results: ErrorPageSearchSliceQueryResults) => {
      event.preventDefault();

      const fragmentString = `?${toQueryString({
        q: this.state.query,
      })}`;

      if (this.state.query.trim() !== '') {
        // noinspection JSIgnoredPromiseFromCall
        navigate(`${results.prismicSearchPage.url!}${fragmentString}`);
      }
    };
  }
);
export default ErrorPageSearchSlice;

const ErrorPageSearchSliceQuery = graphql`
  query ErrorPageSearchSliceQuery {
    prismicSearchPage {
      ...PrismicDocumentLinkFragment
    }
  }
`;

interface ErrorPageSearchSliceQueryResults {
  prismicSearchPage: PrismicSearchPage;
}

export interface ErrorPageSearchSliceProps {
  sliceData: PrismicErrorPageDataBodySearchEnginePlaceholder;
  pageData?: PrismicErrorPage;
}

interface ErrorPageSearchSliceState {
  elementIdPrefix: string;
  query: string;
}
