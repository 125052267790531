import React, { Component, ReactNode } from 'react';
import { graphql, Link, StaticQuery } from 'gatsby';
import PrismicImage from './controls/PrismicImage';
import {
  File,
  PrismicFooter,
  PrismicFooterDataBody1FooterMainNav,
  PrismicFooterDataBody1Mailings,
  PrismicFooterDataBody1SocialNav,
  PrismicFooterDataType,
} from '../graphql';

import classNames from 'classnames';
import PrismicLink from './controls/PrismicLink';
import FooterFlatNav, {
  FooterLinkData,
} from './fragments/footer/FooterFlatNav';
import PrismicFontAwesomeIcon from './controls/PrismicFontAwesomeIcon';
import { GatsbyImage } from 'gatsby-plugin-image';

const FooterInternal = class extends Component<IFooterInternalProps> {
  constructor(props: Readonly<IFooterInternalProps>) {
    super(props);
  }

  render = () => {
    const footerData = this.props.queryResults.prismicFooter.data!;
    const footerClassNames = classNames({
      'neo-footer': true,
    });

    return (
      <footer className={footerClassNames}>
        <div className="neo-footer-container">
          <div className="neo-footer-top">
            <div className="container">
              <a href="#top" className="back-to-top-link">
                {footerData.back_to_top_text}
              </a>
              <div className="neo-footer-headoffice-column">
                <Link
                  to="/"
                  className="neo-brand-link"
                  title="Return to home page"
                >
                  <div
                    className="neo-logo"
                    role="img"
                    aria-label="CAA North & East Ontario Logo"
                  >
                    <PrismicImage image={footerData.logo!} imgStyle={{ width: undefined, height: undefined }} />
                    <div className="neo-logo-subtitle">
                      {footerData.logo_subtitle}
                    </div>
                  </div>
                </Link>
                <div
                  className="neo-main-contact-info"
                  dangerouslySetInnerHTML={{
                    __html: footerData.main_contact_info?.html || '',
                  }}
                />
                <PrismicLink
                  to={footerData.link_dest!}
                  className={'neo-red-btn small'}
                >
                  {footerData.link_title}
                </PrismicLink>
              </div>
              {this.renderContactInfo(
                footerData,
                this.props.queryResults.ticoImage
              )}
            </div>
            <GatsbyImage
              alt=""
              image={
                this.props.queryResults.backgroundImage.childImageSharp
                  ?.gatsbyImageData
              }
              className="neo-footer-top-bg"
            />
          </div>
          <div className="neo-footer-bottom">
            <div className="container">
              {this.renderFooterBottomNavigation(footerData)}
            </div>
          </div>
        </div>
        <script
          type="text/javascript"
          src="https://nebula-cdn.kampyle.com/wc/20083/onsite/embed.js"
          async
        />
      </footer>
    );
  };
  renderContactInfo = (
    footerData: PrismicFooterDataType,
    ticoImage: File
  ): ReactNode => {
    const footerBodyData = footerData.body || [];
    const footerContactInfoRendering: ReactNode[] = [];

    if (footerBodyData.length) {
      const footerContactInfoData = footerBodyData[0]?.items || [];
      if (footerContactInfoData.length) {
        let index = 0;
        for (const itemDef of footerContactInfoData) {
          let title: ReactNode = null;
          let text: ReactNode = null;
          let link: ReactNode = null;

          if (itemDef?.contact_info_title) {
            title = (
              <div
                className="neo-contact-info-title"
                dangerouslySetInnerHTML={{
                  __html: itemDef?.contact_info_title?.html || '',
                }}
              />
            );
          }
          if (itemDef?.contact_info_text) {
            text = (
              <div
                className="neo-contact-info-text"
                dangerouslySetInnerHTML={{
                  __html: itemDef?.contact_info_text?.html || '',
                }}
              />
            );
          }
          if (itemDef?.link_dest) {
            link = (
              <PrismicLink
                to={itemDef?.link_dest}
                className={'neo-red-btn small'}
              >
                {itemDef?.link_title || 'Learn more'}
              </PrismicLink>
            );
          }

          // Additional hardcoded TICO registration number section asked by the client.
          let additionalTextSection: React.ReactElement | undefined;

          if (
            itemDef?.contact_info_title?.html
              ?.toLowerCase()
              .includes('caa stores')
          ) {
            additionalTextSection = (
              <div className="neo-contact-info-text" style={{ marginTop: 20 }}>
                <p>
                  <GatsbyImage image={ticoImage.childImageSharp?.gatsbyImageData} alt="TICO image" />
                  <em>Registration 4561957</em>
                </p>
              </div>
            );
          }

          footerContactInfoRendering.push(
            <div
              key={`contact-info-${(index += 1)}`}
              className="neo-footer-contact-info"
            >
              {title}
              {text}
              {link}
              {additionalTextSection}
            </div>
          );
        }
      }
    }

    return footerContactInfoRendering;
  };

  renderFooterBottomNavigation = (
    footerData: PrismicFooterDataType
  ): ReactNode => {
    const pageComponents: ReactNode[] = [];
    const footerBottom = footerData.body1 || [];

    if (footerBottom.length) {
      let index = 0;
      for (const footerSection of footerBottom) {
        if (!footerSection) continue;

        const sliceKey = `slice-${index}`;
        index = index + 1;

        switch (footerSection.slice_type) {
          case 'mailings': {
            const footerMailingSlice =
              footerSection as PrismicFooterDataBody1Mailings;

            if (footerMailingSlice.primary) {
              const itemDef = footerMailingSlice.primary;
              let icon: ReactNode = null;
              let text: ReactNode = null;
              let link: ReactNode = null;

              if (itemDef.cta_icon) {
                icon = (
                  <PrismicFontAwesomeIcon
                    className={'has-text-caa-forestgreen'}
                    icon={itemDef.cta_icon}
                    size="3x"
                  />
                );
              }
              if (itemDef.cta_text) {
                text = (
                  <div
                    className="text"
                    dangerouslySetInnerHTML={{
                      __html: itemDef.cta_text.html || '',
                    }}
                  />
                );
              }
              if (itemDef?.link_dest) {
                link = (
                  <PrismicLink
                    to={itemDef.link_dest}
                    className={'neo-red-btn small'}
                  >
                    {itemDef.link_title || 'Learn more'}
                  </PrismicLink>
                );
              }

              pageComponents.push(
                <div className="neo-footer-cta" key="footerCta">
                  {icon}
                  {text}
                  {link}
                </div>
              );
            }
          }
            break;
          case 'social_nav': {
            const footerSocialNavSlice =
              footerSection as PrismicFooterDataBody1SocialNav;

            if (footerSocialNavSlice.items?.length) {
              const navItems: FooterLinkData[] = [];
              let sectionTitle: ReactNode = null;

              if (footerSocialNavSlice.primary?.social_nav_title) {
                sectionTitle = (
                  <div
                    dangerouslySetInnerHTML={{
                      __html:
                        footerSocialNavSlice.primary?.social_nav_title.html ||
                        '',
                    }}
                  />
                );
              }

              for (const navItemDef of footerSocialNavSlice.items) {
                if (!navItemDef || !navItemDef.link_dest) {
                  continue;
                }

                const navItem: FooterLinkData = {
                  icon: navItemDef.link_icon || undefined,
                  title: navItemDef.link_title || undefined,
                  link_type: navItemDef.link_dest.link_type,
                  target: navItemDef.link_dest.target,
                  url: navItemDef.link_dest.url,
                };
                navItems.push(navItem);
              }

              pageComponents.push(
                <div className="neo-footer-social-nav" key="footerSocialNav">
                  {sectionTitle}
                  <FooterFlatNav
                    className="is-social-nav"
                    items={navItems}
                    writeTitleAsAttribute={true}
                  />
                </div>
              );
            }
          }
            break;
          case 'footer_main_nav': {
            const footerMainNavSlice =
              footerSection as PrismicFooterDataBody1FooterMainNav;

            if (footerMainNavSlice.items?.length) {
              const navItems: FooterLinkData[][] = [];
              const navItemsFirstColumn: FooterLinkData[] = [];
              const navItemsSecondColumn: FooterLinkData[] = [];

              for (const navItemDef of footerMainNavSlice.items) {
                if (!navItemDef || !navItemDef.link_dest) {
                  continue;
                }

                const navItem: FooterLinkData = {
                  title: navItemDef.link_title || undefined,
                  link_type: navItemDef.link_dest.link_type,
                  target: navItemDef.link_dest.target,
                  url: navItemDef.link_dest.url,
                  column: navItemDef.column || undefined,
                };

                if (navItemDef.column === 'First') {
                  navItemsFirstColumn.push(navItem);
                } else if (
                  navItemDef.column === 'Second' ||
                  navItemDef.column === undefined
                ) {
                  navItemsSecondColumn.push(navItem);
                }
              }
              navItems.push(navItemsFirstColumn, navItemsSecondColumn);
              const navItemColumns: ReactNode[] = [];
              let itemIndex = 0;
              navItems.forEach((navItemsArr: FooterLinkData[]) => {
                const itemKey = `item-${itemIndex}`;
                itemIndex = itemIndex + 1;
                if (navItemsArr.length) {
                  navItemColumns.push(
                    <FooterFlatNav
                      className="is-footer-nav"
                      items={navItemsArr}
                      key={itemKey}
                    />
                  );
                }
              });
              pageComponents.push(
                <div className="neo-footer-main-nav" key="footerMainNav">
                  {navItemColumns}
                </div>
              );
            }
          }
            break;
          default:
            pageComponents.push(
              <div className="container content" key={sliceKey}>
                <h3>Unimplemented Slice: {footerSection.__typename}</h3>
                <pre>{JSON.stringify(footerSection, undefined, 2)}</pre>
              </div>
            );
            break;
        }
      }
    }
    return pageComponents;
  };
};

const Footer = (props: Record<string, unknown>) => (
  <StaticQuery
    query={FooterDataQuery}
    render={(queryResults: IFooterDataQueryResults) => (
      <FooterInternal {...props} queryResults={queryResults} />
    )}
  />
);

export default Footer;

interface IFooterInternalProps {
  queryResults: IFooterDataQueryResults;
}


const FooterDataQuery = graphql`
  query FooterDataQuery {
    prismicFooter {
      data {
        back_to_top_text
        logo {
          alt
          url
          gatsbyImageData
        }
        logo_subtitle
        main_contact_info {
          html
        }
        link_dest {
          ...PrismicLinkFragment
        }
        link_title
        body1 {
          ... on PrismicFooterDataBody1FooterMainNav {
            __typename
            id
            slice_type
            items {
              column
              link_dest {
                ...PrismicLinkFragment
              }
              link_title
            }
          }
          ... on PrismicFooterDataBody1Mailings {
            __typename
            id
            slice_type
            primary {
              cta_icon
              link_dest {
                ...PrismicLinkFragment
              }
              link_title
              cta_text {
                html
              }
            }
          }
          ... on PrismicFooterDataBody1SocialNav {
            __typename
            id
            slice_type
            items {
              link_title
              link_dest {
                ...PrismicLinkFragment
              }
              link_icon
            }
            primary {
              social_nav_title {
                html
              }
            }
          }
        }
        body {
          ... on PrismicFooterDataBodyContactInfo {
            __typename
            slice_type
            items {
              contact_info_title {
                html
              }
              contact_info_text {
                html
              }
              link_title
              link_dest {
                ...PrismicLinkFragment
              }
            }
          }
        }
      }
    }
    backgroundImage: file(
      relativePath: { eq: "images/caa-neo-footer-bg.jpg" }
    ) {
      childImageSharp {
        gatsbyImageData(
          breakpoints: [1920, 3280]
          placeholder: NONE
          layout: FULL_WIDTH
        )
      }
    }
    ticoImage: file(relativePath: { eq: "images/tico-ca.png" }) {
      childImageSharp {
        gatsbyImageData(
          width: 45
          height: 25
          placeholder: NONE
          layout: CONSTRAINED
        )
      }
    }
  }
`;

interface IFooterDataQueryResults {
  prismicFooter: PrismicFooter;
  backgroundImage: File;
  ticoImage: File;
}
