import {
  PrismicTravelStoreSearchPage,
  PrismicTravelStoreSearchPageDataBodySearchEnginePlaceholder,
} from '../../../graphql';
import React, { Component, ReactNode } from 'react';
import WithGlobalResources, { InjectedGlobalResourcesProps } from '../../higher-order/WithGlobalResources';
import WithTravelStoreData, { InjectedTravelStoreDataProps } from '../../higher-order/WithTravelStoreData';
import TravelStore from '../../fragments/travel-store/TravelStore';
import ImageLinkTileList from '../../controls/ImageLinkTileList';
import SectionTitle from '../../controls/SectionTitle';

export const TravelStoreSearchSliceKey = '!internal_travel_store_search_slice';

const TravelStoreSearchSlice = WithGlobalResources(
  WithTravelStoreData(
    class TravelStoreSearchSliceImpl extends Component<
      TravelStoreSearchSliceProps & InjectedGlobalResourcesProps & InjectedTravelStoreDataProps
    > {
      render(): ReactNode {
        const { sliceData, travelStoreData } = this.props;

        return (
          <div className="container neo-tag-search-page">
            <SectionTitle component={sliceData.primary?.section_title} />

            <ImageLinkTileList forceLayout="quarters">
              {travelStoreData
                .filter(
                  (store) =>
                    (store.data?.visibility ?? 'Visible') === 'Visible' &&
                    (store.data?.location_type ?? 'Geographical') !== 'Virtual'
                )
                .map((store, index) => (
                  <TravelStore store={store} key={index} />
                ))}
            </ImageLinkTileList>
          </div>
        );
      }
    }
  )
);
export default TravelStoreSearchSlice;

export interface TravelStoreSearchSliceProps {
  sliceData: PrismicTravelStoreSearchPageDataBodySearchEnginePlaceholder;
  pageData?: PrismicTravelStoreSearchPage;
}
