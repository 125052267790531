import React from 'react';
import { ColumnTable } from './ColumnTable';
import { RowTable } from './RowTable';
import { TableColorOptions } from '../../../utils/TableUtility';
import { PrismicStructuredTextType } from '../../../graphql';

export interface TableContentProps {
  isByRow: boolean;
  headers: TableHeader[];
  tableData: TableData[];
}

export interface TableHeader {
  index: number;
  header: PrismicStructuredTextType;
  color?: TableColorOptions;
}

export interface TableData {
  index: number;
  isHeader: boolean;
  content: PrismicStructuredTextType[];
  color: TableColorOptions;
}

/**
 * This component builds all the scafolding necessary to hold the table.
 * @param props : TableContentProps The data needed to build the table.
 */
export const TableContainer = ({ isByRow, headers, tableData }: TableContentProps) => {
  const table = isByRow ? (
    <RowTable
      headers={headers}
      rows={tableData}
      numberOfRows={headers.length >= tableData.length ? headers.length : tableData.length}
    />
  ) : (
    <ColumnTable
      headers={headers}
      columns={tableData}
      numberOfColumns={headers.length >= tableData.length ? headers.length : tableData.length}
    />
  );

  return (
    <div className="container neo-table-content">
      <div className="columns">
        <div className="column content">
          <div
            className={`table-container ${
              tableData[0].color === TableColorOptions.None ? 'no-colors' : 'alternating-colors'
            }`}
          >
            <table className="table">{table}</table>
          </div>
        </div>
      </div>
    </div>
  );
};
