import PrismicLink from '../../controls/PrismicLink';
import React, { Component, ReactNode } from 'react';
import PrismicFontAwesomeIcon from '../../controls/PrismicFontAwesomeIcon';
import { IconPrefix } from '@fortawesome/fontawesome-common-types';
import { HeaderLinkData } from './HeaderFlatNav';
import { Subscription } from 'rxjs';
import {
  Breakpoint,
  CurrentBreakpoint$,
  IsBreakpointAtMost,
} from '../../../utils/BreakpointHelpers';

const HeaderMegaMenu = class extends Component<
  HeaderMegaMenuProps,
  HeaderMegaMenuState
> {
  static defaultProps: Partial<HeaderMegaMenuProps> = {
    iconPrefixPriority: ['fab', 'fas', 'fal', 'fad'],
  };

  private breakpointSubscription?: Subscription;

  constructor(props: Readonly<HeaderMegaMenuProps>) {
    super(props);
    this.state = {
      isMobile: false,
      expandedThirdLevels: [],
    };
  }

  componentDidMount(): void {
    this.breakpointSubscription = CurrentBreakpoint$.subscribe(
      newBreakpoint => {
        this.setState({
          isMobile: IsBreakpointAtMost(
            newBreakpoint.breakpoint,
            Breakpoint.Tablet
          ),
        });
      }
    );

    if (document) {
      document.addEventListener('click', this.onDocumentClick);
    }
  }

  componentDidUpdate(
    prevProps: Readonly<HeaderMegaMenuProps>,
    _prevState: Readonly<HeaderMegaMenuState>,
    _snapshot?: any
  ): void {
    // Reset activeTopic and activeSection on menu closing
    if (
      (!this.props.isOpened && prevProps.isOpened) ||
      (this.state.isMobile && !_prevState.isMobile)
    ) {
      this.setState({
        activeTopic: undefined,
        activeSection: undefined,
        expandedThirdLevels: [],
      });
    }
  }

  componentWillUnmount(): void {
    if (this.breakpointSubscription) {
      this.breakpointSubscription.unsubscribe();
    }

    if (document) {
      document.removeEventListener('click', this.onDocumentClick);
    }
  }

  onTopicButtonClick = (topicId: string, _event: React.MouseEvent) => {
    if (this.state.activeTopic === topicId) {
      this.setState({
        activeTopic: undefined,
        activeSection: undefined,
        expandedThirdLevels: [],
      });

      return;
    }

    this.setState({
      activeTopic: topicId,
      activeSection: undefined,
      expandedThirdLevels: [],
    });
  };

  onTopicCollapseButtonClick = (topicId: string, _event: React.MouseEvent) => {
    if (this.state.isMobile === false) {
      return;
    }

    if (this.state.activeTopic === topicId) {
      this.setState({
        activeTopic: undefined,
        activeSection: undefined,
        expandedThirdLevels: [],
      });
    }
  };

  onSectionButtonClick = (
    topicId: string,
    sectionId: string,
    _event: React.MouseEvent
  ) => {
    if (this.state.isMobile === false) {
      return;
    }

    if (topicId !== this.state.activeTopic) {
      return;
    }

    this.setState({
      activeSection: sectionId,
    });
  };

  onSectionCollapseButtonClick = (
    topicId: string,
    sectionId: string,
    _event: React.MouseEvent
  ) => {
    if (this.state.isMobile === false) {
      return;
    }

    if (
      this.state.activeTopic === topicId &&
      this.state.activeSection === sectionId
    ) {
      this.setState({
        activeSection: undefined,
        expandedThirdLevels: [],
      });
    }
  };

  onSubSectionExpandButtonClick = (
    topicId: string,
    sectionId: string,
    subSectionId: string,
    _event: React.MouseEvent
  ) => {
    if (this.state.isMobile === false) {
      return;
    }

    if (
      this.state.activeTopic === topicId &&
      this.state.activeSection === sectionId
    ) {
      let thirdLevels = this.state.expandedThirdLevels.slice(0);

      if (thirdLevels.indexOf(subSectionId) === -1) {
        thirdLevels.push(subSectionId);
      } else {
        thirdLevels = thirdLevels.filter(id => id !== subSectionId);
      }

      this.setState({
        expandedThirdLevels: thirdLevels,
      });
    }
  };

  onDocumentClick = (_event: MouseEvent) => {
    if (this.state.isMobile || this.state.activeTopic === undefined) {
      return;
    }

    let target = _event.target as HTMLElement | null;
    while (target !== null) {
      if (target.getAttribute('data-topic-id') !== null) {
        return;
      }

      target = target.parentElement;
    }

    this.setState({
      activeTopic: undefined,
      activeSection: undefined,
      expandedThirdLevels: [],
    });
  };

  render = () => {
    const {
      items,
      className,
      iconPrefixPriority,
      isOpened,
      onCloseButtonClick,
      ...otherProps
    } = this.props;
    const { isMobile } = this.state;

    const navItems: ReactNode[] = [];

    let index = 0;
    for (const itemDef of items) {
      if ('columns' in itemDef) {
        navItems.push(this.renderTopic(itemDef));
      } else {
        navItems.push(this.renderLink(itemDef, `neo-megamenu-${(index += 1)}`));
      }
    }

    return (
      <ul className={`neo-nav neo-megamenu ${className || ''}`} {...otherProps}>
        <li
          className="neo-megamenu-main-title"
          aria-hidden={isMobile ? 'false' : 'true'}
          onClick={onCloseButtonClick}
        >
          Menu
        </li>
        {navItems}
      </ul>
    );
  };

  private renderTopic = (topicDef: HeaderMegaMenuTopic): ReactNode => {
    const { isMobile } = this.state;

    const topicColumns: ReactNode[] = [];
    const topicSectionItems: ReactNode[] = [];

    let index = 0;
    for (const columnDef of topicDef.columns) {
      topicColumns.push(
        <div
          className="neo-megamenu-topic-column"
          key={`${topicDef.id}-column-${(index += 1)}`}
        >
          {columnDef.sections.map(i => this.renderTopicSection(topicDef, i))}
        </div>
      );

      for (const sectionDef of columnDef.sections) {
        const isSectionActive = this.state.activeSection === sectionDef.id;

        topicSectionItems.push(
          <li className="neo-nav-item" key={sectionDef.id}>
            <button
              onClick={e =>
                this.onSectionButtonClick(topicDef.id, sectionDef.id, e)
              }
              aria-expanded={isSectionActive ? 'true' : 'false'}
            >
              <span>{sectionDef.title}</span>
            </button>
          </li>
        );
      }
    }

    const isActive = this.state.activeTopic === topicDef.id;

    return (
      <li
        className="neo-nav-item"
        key={topicDef.id}
        data-topic-id={topicDef.id}
      >
        <button
          className={
            (topicDef.className || '') + (isActive ? ' is-active' : '')
          }
          aria-expanded={isActive ? 'true' : 'false'}
          onClick={e => this.onTopicButtonClick(topicDef.id, e)}
        >
          <span>{topicDef.title}</span>
        </button>
        <div
          className={`neo-megamenu-topic ${topicDef.topicClassName || ''}`}
          aria-hidden={isActive ? 'false' : 'true'}
        >
          <div
            className="neo-megamenu-topic-title"
            aria-hidden={isMobile ? 'false' : 'true'}
            tabIndex={isMobile && isActive ? 0 : -1}
            onClick={e => this.onTopicCollapseButtonClick(topicDef.id, e)}
          >
            {topicDef.title}
          </div>
          <ul
            className="neo-nav neo-megamenu-topic-sections-list"
            aria-hidden={isMobile ? 'false' : 'true'}
          >
            {topicSectionItems}
          </ul>
          {topicColumns}
        </div>
      </li>
    );
  };

  private renderTopicSection = (
    topicDef: HeaderMegaMenuTopic,
    sectionDef: HeaderMegaMenuSection
  ): ReactNode => {
    const { isMobile } = this.state;
    const isActive = isMobile && this.state.activeSection === sectionDef.id;

    const sectionItems: ReactNode[] = [];

    let index = 0;
    for (const itemDef of sectionDef.items) {
      index += 1;

      let expandButton: ReactNode = null;
      let subItemsList: ReactNode = null;

      if (itemDef.children.length) {
        const subSectionId = `${sectionDef.id}-${index}`;
        const isSubsectionExpanded =
          this.state.expandedThirdLevels.indexOf(subSectionId) !== -1;

        let subIndex = 0;
        const subItemsItems: ReactNode[] = [];

        for (const subItemDef of itemDef.children) {
          subItemsItems.push(
            this.renderLink(subItemDef, `${subSectionId}-${(subIndex += 1)}`)
          );
        }

        subItemsList = (
          <ul
            className={`neo-nav ${isSubsectionExpanded ? 'is-expanded' : ''}`}
          >
            {subItemsItems}
          </ul>
        );
        expandButton = (
          <button
            className={`neo-nav-subsection-expand ${
              isSubsectionExpanded ? 'is-active' : ''
            }`}
            onClick={e =>
              this.onSubSectionExpandButtonClick(
                topicDef.id,
                sectionDef.id,
                subSectionId,
                e
              )
            }
            aria-label="Expand"
            aria-expanded={isSubsectionExpanded ? 'true' : 'false'}
          >
            <span />
            <span />
          </button>
        );
      }

      const itemKey = `${sectionDef.id}-${index}`;
      const link = this.renderLink(itemDef.link, itemKey, true);

      sectionItems.push(
        <li
          className={`neo-nav-item ${itemDef.link.itemClassName || ''}`}
          key={itemKey}
        >
          <div className="neo-nav-button-group">
            {link}
            {expandButton}
          </div>
          {subItemsList}
        </li>
      );
    }

    return (
      <div
        className={`neo-megamenu-topic-section ${isActive ? 'is-active' : ''}`}
        key={sectionDef.id}
      >
        <div
          className="neo-megamenu-topic-section-title"
          tabIndex={isMobile && isActive ? 0 : -1}
          onClick={e =>
            this.onSectionCollapseButtonClick(topicDef.id, sectionDef.id, e)
          }
        >
          {sectionDef.title}
        </div>
        <ul className="neo-nav neo-megamenu-topic-section-items">
          {sectionItems}
        </ul>
      </div>
    );
  };

  private renderLink = (
    itemDef: HeaderLinkData,
    key: string,
    linkOnly: boolean = false
  ): ReactNode => {
    const { iconPrefixPriority } = this.props;

    let icon: ReactNode = null;
    const label: ReactNode = <span>{itemDef.title}</span>;

    if (itemDef.icon) {
      icon = (
        <PrismicFontAwesomeIcon
          icon={itemDef.icon}
          size={itemDef.iconSize}
          prefixPriority={iconPrefixPriority}
        />
      );
    }

    const linkEl = (
      <PrismicLink
        to={itemDef}
        className={itemDef.className}
        activeClassName={itemDef.activeClassName}
        partiallyActive={itemDef.partiallyActive}
      >
        {icon}
        {label}
      </PrismicLink>
    );

    if (linkOnly) {
      return linkEl;
    }

    return (
      <li className={`neo-nav-item ${itemDef.itemClassName || ''}`} key={key}>
        {linkEl}
      </li>
    );
  };
};
export default HeaderMegaMenu;

interface HeaderMegaMenuProps {
  className?: string;
  items: HeaderMegaMenuTopLevelItem[];
  iconPrefixPriority?: IconPrefix[];
  isOpened: boolean;
  onCloseButtonClick: (e: React.MouseEvent) => void;
}

interface HeaderMegaMenuState {
  activeTopic?: string;
  activeSection?: string;
  expandedThirdLevels: string[];
  isMobile: boolean;
}

export interface HeaderMegaMenuTopic {
  id: string;
  title?: string;
  columns: HeaderMegaMenuTopicColumn[];
  activeClassName?: string;
  className?: string;
  topicClassName?: string;
}

export interface HeaderMegaMenuTopicColumn {
  sections: HeaderMegaMenuSection[];
}

export interface HeaderMegaMenuSection {
  id: string;
  title?: string;
  items: HeaderMegaMenuSectionItem[];
}

export interface HeaderMegaMenuSectionItem {
  link: HeaderLinkData;
  children: HeaderLinkData[];
}

export type HeaderMegaMenuTopLevelItem = HeaderMegaMenuTopic | HeaderLinkData;
