import { WindowLocation } from '@reach/router';
import React, { Component, PropsWithChildren } from 'react';
import WithGlobalResources, {
  InjectedGlobalResourcesProps,
} from './higher-order/WithGlobalResources';
import Header from './Header';
import { RefreshBreakpointValue } from '../utils/BreakpointHelpers';
import Footer from './Footer';
import { RefreshViewportHeight } from '../utils/ViewportHeightHelpers';
const Layout = WithGlobalResources(
  class extends Component<
    PropsWithChildren<LayoutProps & InjectedGlobalResourcesProps>
  > {
    componentDidMount(): void {
      RefreshBreakpointValue();
      RefreshViewportHeight();
    }

    render() {
      const { children } = this.props;

      return (
        <div id="top" className={`neo-page ${this.props.classNames || ''}`}>
          <a className="neo-wcag-skip-to-content" href="#main">
            {this.props.globalResources.label_skip_to_content}
          </a>
          <Header />
          <main id="main">{children}</main>
          <Footer />
        </div>
      );
    }
  }
);
export default Layout;

export interface LayoutProps {
  location: WindowLocation;
  title?: string;
  classNames?: string;
}
