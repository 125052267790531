import React from 'react';
import { PrismicStructuredTextType } from '../../graphql';
import PrismicStructuredText from './PrismicStructuredText';

function SectionTitle(props: { component: PrismicStructuredTextType | null | undefined }) {
  /**
   This function displays or hides the section title depending on its content
   Hide the section title if section title is an empty HTML tag (ie: <h1></h1>)
   Display the section title otherwise

   @param {string} html - The title of the section calling this control
   @return {html}       - Return the section title or null if not matching the regex
   */

  const htmlTagsRegex = /(((<\w+>)+[ \n(<br>)]*(<\/\w+>)+)+)|<br>/;
  return props.component?.html?.match(htmlTagsRegex) ? null : (
    <div className="neo-section-title">
      <PrismicStructuredText text={props.component}></PrismicStructuredText>
    </div>
  );
}

export default SectionTitle;
