import { PrismicTravelTrip, PrismicTravelTripConnection } from '../../graphql';
import React, { Component, ComponentType } from 'react';
import { graphql, StaticQuery } from 'gatsby';
import { Subset } from '../../utils/UtilityTypes';
import { ToMoment } from '../../utils/PrismicHelpers';
import moment from 'moment-timezone';

const WithTravelTripData = function WithTravelTripDataFn<
  P extends InjectedTravelTripDataProps
>(
  ComposedComponent: ComponentType<P>
): ComponentType<Subset<P, InjectedTravelTripDataProps>> {
  return class extends Component<Subset<P, InjectedTravelTripDataProps>> {
    render() {
      return (
        <StaticQuery
          query={TravelTripDataQuery}
          render={queryResult => {
            const {
              allPrismicTravelTrip,
            } = queryResult as TravelTripDataQueryResults;

            return (
              <ComposedComponent
                {...(this.props as any)}
                travelTripData={
                  allPrismicTravelTrip?.nodes?.filter(node => {
                    if (!node.data?.precise_date_start) {
                      return true;
                    }

                    const startDate = ToMoment(node.data?.precise_date_start);
                    return !startDate?.isBefore(moment());
                  }) || []
                }
              />
            );
          }}
        />
      );
    }
  };
};
export default WithTravelTripData;

const TravelTripDataQuery = graphql`
  query TravelTripDataQuery {
    allPrismicTravelTrip(
      filter: { is_active: { eq: true } }
      sort: { fields: data___precise_date_start, order: ASC }
    ) {
      nodes {
        ...TravelTripFragment
      }
    }
  }
`;

interface TravelTripDataQueryResults {
  allPrismicTravelTrip: PrismicTravelTripConnection;
}

// noinspection JSUnusedGlobalSymbols
export const TravelTripFragment = graphql`
  fragment TravelTripFragment on PrismicTravelTrip {
    ...PrismicDocumentLinkFragment
    data {
      page_title
      precise_date_start
      precise_date_end
      date_display_override
      price
      cta_title
      cta_link_dest {
        ...PrismicLinkFragment
      }
      trip_image {
        alt
        url
        gatsbyImageData(width: 960, placeholder: NONE, layout: CONSTRAINED)
      }
      travel_types {
        travel_type {
          document {
            ... on PrismicTaxonomyTravelType {
              id
              data {
                name
                icon
              }
            }
          }
        }
      }
      travel_destinations {
        travel_destination {
          document {
            ... on PrismicTaxonomyTravelDestination {
              id
              data {
                name
              }
            }
          }
        }
      }
      travel_partner {
        ...PrismicLinkFragment
        document {
          ... on PrismicTravelPartner {
            id
          }
        }
      }
    }
  }
`;

export interface InjectedTravelTripDataProps {
  travelTripData: PrismicTravelTrip[];
}
