import React, { Component, ReactNode } from 'react';
import { IPlanData } from '../ComparativeTableSlice';
import PrismicLink from '../../controls/PrismicLink';
import PrismicImage from '../../controls/PrismicImage';

export default class ComparativeTablePlanHeader extends Component<IPlanHeader> {
  render() {
    const nodeArray: ReactNode[] = [];
    const index: number = this.props.index;
    const planData: IPlanData | undefined = this.props.planData || undefined;

    if (!planData) {
      return [];
    }

    const planImage: ReactNode = planData.image ? (
      <div key={`plan-image-${index}`} className={'plan-image-padding-box'}>
        <PrismicImage
          imgStyle={{ objectFit: 'contain' }}
          image={planData.image}
        />
      </div>
    ) : null;
    if (planData.best_value) {
      nodeArray.push(
        <div key={`best-value-${index}`} className={'best-value'}>
          {'BEST VALUE'}
        </div>
      );
    }

    if (planData.plan_name) {
      nodeArray.push(<h4 key={`plan-name-${index}`}>{planData.plan_name}</h4>);
    }

    if (planData.price_per_month) {
      nodeArray.push(
        <div key={`plan-price-per-month-${index}`} className={`main-price `}>
          <span>
            <strong
              className={`${
                planData.promo_price_per_month ? 'strike-through' : ''
              }`}
            >
              {planData.price_per_month}
            </strong>
          </span>
          {planData.promo_price_per_month && (
            <strong>{planData.promo_price_per_month}</strong>
          )}

          {planData.price_per_month_label
            ? planData.price_per_month_label
            : '/ month'}
        </div>
      );
    }
    if (planData.price_per_year) {
      nodeArray.push(
        <div
          key={`plan-price-per-year-${index}`}
        >
          <span
            className={`${
              planData.promo_price_per_month ? 'strike-through' : ''
            }`}
          >
            {planData.price_per_year}
          </span>
          {planData.promo_price_per_year && planData.promo_price_per_year}
        </div>
      );
    }

    let buttonLabel = 'Buy';
    if (planData.button_label) {
      buttonLabel = planData.button_label;
    }

    if (planData.button_link_dest) {
      nodeArray.push(
        <PrismicLink
          key={`button-${index}`}
          to={planData.button_link_dest}
          className="neo-red-btn"
        >
          {buttonLabel}
        </PrismicLink>
      );
    }

    const planHeaderContent: ReactNode = (
      <div
        key={`plan-header-content-${index}`}
        className={`planHeaderContent ${
          planData.best_value ? 'is-best-value' : ''
        }`}
      >
        {nodeArray}
      </div>
    );

    return [planImage, planHeaderContent];
  }
}

export interface IPlanHeader {
  index: number;
  planData?: IPlanData;
}
