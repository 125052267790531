import React, { Component, ReactNode } from 'react';
import { graphql, PageProps } from 'gatsby';
import { withPrismicPreview, WithPrismicPreviewProps } from 'gatsby-plugin-prismic-previews';

import Layout from '../components/Layout';
import {
  Maybe,
  PrismicPageDataBodySlicesType,
  PrismicSearchPage,
  PrismicSearchPageDataBodySlicesType,
  SiteSearchablePageConnection,
  SiteSearchIndex,
} from '../graphql';
import SEO from '../components/controls/SEO';
import PageSlicesRender from '../components/controls/PageSlicesRender';
import WithGlobalResources, { InjectedGlobalResourcesProps } from '../components/higher-order/WithGlobalResources';
import {
  PrismicSearchPageBodySearchEnginePlaceholderWithSearchData,
  SearchPageSearchEngineSliceKey,
} from '../components/slices/internal/SearchPageSearchEngineSlice';
import NotFoundPage from './NotFoundPage';

type SlicesTypes = Maybe<PrismicPageDataBodySlicesType | PrismicSearchPageDataBodySlicesType>;

type SearchPageTemplateProps = {
  data: {
    prismicSearchPage: PrismicSearchPage;
    siteSearchIndex: SiteSearchIndex;
    allSiteSearchablePage: SiteSearchablePageConnection;
  };
} & InjectedGlobalResourcesProps &
  WithPrismicPreviewProps<PrismicSearchPage> &
  PageProps<PrismicSearchPage>;

class SearchPageTemplate extends Component<SearchPageTemplateProps> {
  render() {
    const pageNode = this.props?.data?.prismicSearchPage;
    const pageComponents: ReactNode[] = [];

    if (!pageNode) {
      return <NotFoundPage location={this.props.location} />;
    }

    pageComponents.push(
      <SEO
        key="page-seo-item"
        title={pageNode.data?.page_title || ''}
        description={pageNode?.data?.share_description || undefined}
        fbImage={pageNode.data?.og_image?.localFile?.childImageSharp?.gatsbyImageData}
        slug={pageNode?.url || undefined}
        titleTemplate={pageNode?.data?.use_page_title_template === 'no' ? '{pageTitle}' : undefined}
        meta={
          pageNode?.data?.indexable === 'no'
            ? [
                {
                  name: `robots`,
                  content: `noindex`,
                },
              ]
            : undefined
        }
      />
    );

    const normalizedPageNodes: SlicesTypes[] = [];

    if (pageNode?.data?.body?.length) {
      for (const slice of pageNode.data.body) {
        if (slice?.slice_type === 'search_engine_placeholder') {
          normalizedPageNodes.push({
            ...slice,
            additionalQueryData: {
              siteSearchIndex: this.props.data.siteSearchIndex,
              allSiteSearchablePage: this.props.data.allSiteSearchablePage,
            },
            slice_type: SearchPageSearchEngineSliceKey,
          } as PrismicSearchPageBodySearchEnginePlaceholderWithSearchData);
          continue;
        }

        normalizedPageNodes.push(slice);
      }
    }

    pageComponents.push(<PageSlicesRender key="slices" body={normalizedPageNodes} pageNode={pageNode} />);

    return <Layout location={this.props.location}>{pageComponents}</Layout>;
  }
}

export default withPrismicPreview(WithGlobalResources(SearchPageTemplate));

export const pageQuery = graphql`
  query PrismicSearchPageQuery($nodeId: String!) {
    prismicSearchPage(id: { eq: $nodeId }) {
      _previewable
      id
      url
      data {
        page_title
        share_description
        share_title
        indexable
        og_image {
          alt
          url
          gatsbyImageData(
            width: 1200
            height: 630
            backgroundColor: "rgba(255,255,255,1)"
            placeholder: NONE
            layout: FIXED
          )
        }
        use_page_title_template
        body {
          ... on PrismicSearchPageDataBodyBreadcrumbs {
            ...SliceBreadcrumbsFragment
          }
          ... on PrismicSearchPageDataBodyCarousel {
            ...SliceCarouselFragment
          }
          ... on PrismicSearchPageDataBodyCollapsableContent {
            ...SliceCollapsableContentFragment
          }
          ... on PrismicSearchPageDataBodyHeroImage {
            ...SliceHeroImageFragment
          }
          ... on PrismicSearchPageDataBodyMarketingHero {
            ...SliceMarketingHeroFragment
          }
          ... on PrismicSearchPageDataBodyIconLinkList {
            ...SliceIconLinkListFragment
          }
          ... on PrismicSearchPageDataBodyImageLinkTiles {
            ...SliceImageLinkTilesFragment
          }
          ... on PrismicSearchPageDataBodyImageLinkCards {
            ...SliceImageLinkCardsFragment
          }
          ... on PrismicSearchPageDataBodyMultiColumnContent {
            ...SliceMultiColumnContentFragment
          }
          ... on PrismicSearchPageDataBodySearchEnginePlaceholder {
            id
            slice_type
            primary {
              section_title {
                html
              }
              next_page_label
              previous_page_label
              page_label
              plural_results_label
              singular_result_label
              search_field_label
              search_field_placeholder
              results_title
              no_results_found_content {
                html
              }
            }
          }
          ... on PrismicSearchPageDataBodySimpleLinkTiles {
            ...SliceSimpleLinkTilesFragment
          }
          ... on PrismicSearchPageDataBodyTextContent {
            ...SliceTextContentFragment
          }
        }
      }
    }
    siteSearchIndex {
      index
    }
    allSiteSearchablePage {
      nodes {
        id
        path
        uid
        title
        description
        parentType
        image {
          alt
          url
        }
        parent {
          ... on PrismicMerchandise {
            data {
              member_price
              non_member_price
              sale_price
              sale_expiry
            }
          }
          ... on PrismicTravelDeal {
            data {
              deal_expiry
            }
          }
          ... on PrismicTravelStore {
            data {
              address
              opening_hours {
                hours_label
                hours_value
              }
            }
          }
          ... on PrismicTravelTalk {
            data {
              event_start_time
              event_end_time
              caa_travel_store {
                document {
                  ... on PrismicTravelStore {
                    ...PrismicDocumentLinkFragment
                    data {
                      page_title
                      address
                    }
                  }
                }
              }
            }
          }
          ... on PrismicTravelTrip {
            data {
              precise_date_start
              precise_date_end
              date_display_override
              price
            }
          }
          ... on PrismicRewardsOffer {
            data {
              item_title
              offer_title_text
              offer_description {
                text
              }
              partner_portal_link {
                url
                target
                type
                document {
                  ...PrismicDocumentLinkFragment
                }
              }
              offer_image {
                alt
                url
                gatsbyImageData(width: 960, placeholder: NONE, layout: CONSTRAINED)
              }
            }
          }
          ... on PrismicRewardsPartner {
            data {
              partner_name
              partner_tag_line
              partner_logo {
                alt
                url
                gatsbyImageData(width: 960, placeholder: NONE, layout: CONSTRAINED)
              }
              partner_portal_link {
                url
                target
                type
                document {
                  ...PrismicDocumentLinkFragment
                }
              }
            }
          }
        }
      }
    }
  }
`;
