const { UidToSlug } = require('./src/utils/LinkResolver');

module.exports = {
  prismicLinkResolver(post) {
    if (post.type === 'error_page' && (post.data || {}).error_code) {
      return `/${post.data.error_code}.html`;
    }

    return UidToSlug(post.uid);
  },
};
