import React, { Component, ReactNode } from 'react';
import { graphql, PageProps } from 'gatsby';
import { withPrismicPreview, WithPrismicPreviewProps } from 'gatsby-plugin-prismic-previews';

import Layout from '../components/Layout';
import { PrismicPage, PrismicPageDataBodySlicesType } from '../graphql';
import SEO from '../components/controls/SEO';
import PageSlicesRender from '../components/controls/PageSlicesRender';
import { groupTables, TableDataBodyPseudoSlicesType } from '../utils/TableUtility';

type PageTemplateProps = {
  data: {
    prismicPage: PrismicPage;
  };
} & WithPrismicPreviewProps<PrismicPage> &
  PageProps<PrismicPage>;

export type PageBody = (PrismicPageDataBodySlicesType | TableDataBodyPseudoSlicesType)[];

class PageTemplate extends Component<PageTemplateProps> {
  render() {
    const pageNode = this.props.data?.prismicPage;
    const pageComponents: ReactNode[] = [];

    if (pageNode?.data?.body) {
      pageComponents.push(
        <SEO
          key="page-seo-item"
          title={pageNode.data.page_title ?? ''}
          description={pageNode.data.share_description ?? undefined}
          fbImage={pageNode.data.og_image?.localFile?.childImageSharp?.gatsbyImageData}
          slug={pageNode.url ?? undefined}
          canonical={pageNode.data.canonical?.url ?? undefined}
          titleTemplate={pageNode.data.use_page_title_template === 'no' ? '{pageTitle}' : undefined}
          meta={
            pageNode.data.indexable === 'no'
              ? [
                  {
                    name: `robots`,
                    content: `noindex`,
                  },
                ]
              : undefined
          }
        />
      );

      let body : PageBody = pageNode.data.body;

      if (pageNode.data) {
        body = groupTables(pageNode.data.body);
      }

      pageComponents.push(
        <PageSlicesRender
          key="slices"
          body={body}
          pageNode={pageNode}
        />
      );
    }

    return <Layout location={this.props.location}>{pageComponents}</Layout>;
  }
}

export default withPrismicPreview(PageTemplate);

export const pageQuery = graphql`
  query PrismicPageQuery($nodeId: String!) {
    prismicPage(id: { eq: $nodeId }) {
      _previewable
      id
      url
      data {
        body {
          ... on PrismicPageDataBodyBreadcrumbs {
            ...SliceBreadcrumbsFragment
          }
          ... on PrismicPageDataBodyCarousel {
            ...SliceCarouselFragment
          }
          ... on PrismicPageDataBodyCollapsableContent {
            ...SliceCollapsableContentFragment
          }
          ... on PrismicPageDataBodyComparativeTable {
            ...SliceComparativeTableFragment
          }
          ... on PrismicPageDataBodyContentListing {
            ...SliceContentListingFragment
          }
          ... on PrismicPageDataBodyDropdownGuidedNavigation {
            ...SliceDropdownGuidedNavigationFragment
          }
          ... on PrismicPageDataBodyHeroImage {
            ...SliceHeroImageFragment
          }
          ... on PrismicPageDataBodyIconLinkList {
            ...SliceIconLinkListFragment
          }
          ... on PrismicPageDataBodyImageLinkTiles {
            ...SliceImageLinkTilesFragment
          }
          ... on PrismicPageDataBodyImageLinkCards {
            ...SliceImageLinkCardsFragment
          }
          ... on PrismicPageDataBodyMap {
            ...SliceMapFragment
          }
          ... on PrismicPageDataBodyLogoLinkList {
            ...SliceLogoLinkListFragment
          }
          ... on PrismicPageDataBodyMarketingHero {
            ...SliceMarketingHeroFragment
          }
          ... on PrismicPageDataBodyMembershipTiles {
            ...SliceMembershipTilesFragment
          }
          ... on PrismicPageDataBodyMultiColumnContent {
            ...SliceMultiColumnContentFragment
          }
          ... on PrismicPageDataBodyRewardsOffers {
            ...SliceRewardsOffersFragment
          }
          ... on PrismicPageDataBodyRewardsPartners {
            ...SliceRewardsPartnersFragment
          }
          ... on PrismicPageDataBodyAgent {
            ...SliceAgentFragment
          }
          ... on PrismicPageDataBodySimpleLinkTiles {
            ...SliceSimpleLinkTilesFragment
          }
          ... on PrismicPageDataBodyTextContent {
            ...SliceTextContentFragment
          }
          ... on PrismicPageDataBodyTravelOffers {
            ...SliceTravelOffersFragment
          }
          ... on PrismicPageDataBodyTstWidget {
            ...SliceTstWidgetFragment
          }
          ... on PrismicPageDataBodyTwoColumnsTextContent {
            ...SliceTwoColumnsTextContentFragment
          }
          ... on PrismicPageDataBodyYoutubeVideo {
            ...SliceYoutubeVideoFragment
          }
          ... on PrismicPageDataBodyCustomScripts {
            ...SliceCustomScriptsFragment
          }
          ... on PrismicPageDataBodyTableColumn {
            ...SliceTableColumnFragment
          }          
          ... on PrismicPageDataBodyTableRow {
            ...SliceTableRowFragment
          }
        }
        page_title
        share_description
        share_title
        indexable
        canonical {
          ...PrismicLinkFragment
        }
        og_image {
          alt
          url
          gatsbyImageData(
            width: 1200
            height: 630
            backgroundColor: "rgba(255,255,255,1)"
            placeholder: NONE
            layout: FIXED
          )
        }
        use_page_title_template
      }
    }
  }
`;
