import React, { Component, ReactNode } from 'react';
import { graphql } from 'gatsby';
import { Subscription } from 'rxjs';
import classNames from 'classnames';

import { PrismicPage, PrismicPageDataBodyCarousel, PrismicPageDataBodyCarouselItem } from '../../graphql';
import { IsValidPrismicLink } from '../../utils/PrismicHelpers';
import { CurrentBreakpoint$, IsBreakpointAtLeast, Breakpoint } from '../../utils/BreakpointHelpers';

import WithGlobalResources, { InjectedGlobalResourcesProps } from '../higher-order/WithGlobalResources';
import PrismicImage from '../controls/PrismicImage';
import PrismicLink from '../controls/PrismicLink';
import PrismicFontAwesomeIcon from '../controls/PrismicFontAwesomeIcon';

export const CarouselSliceKey = 'carousel';

const CarouselSlice = WithGlobalResources(
  class extends Component<CarouselSliceProps & InjectedGlobalResourcesProps, CarouselSliceState> {
    private carouselId: number = Date.now();
    private timerId?: any;
    private timerDelay = 5000;
    private breakpointSubscription?: Subscription;

    constructor(props: Readonly<CarouselSliceProps & InjectedGlobalResourcesProps>) {
      super(props);

      this.state = {
        activeSlide: 0,
        isPaused: false,
        isUserPaused: false,
        isFocused: false,
        areCarouselImagesFixed: false,
      };

      this.prevSlide = this.prevSlide.bind(this);
      this.nextSlide = this.nextSlide.bind(this);
      this.startTimer = this.startTimer.bind(this);
      this.stopTimer = this.stopTimer.bind(this);
      this.pauseTimer = this.pauseTimer.bind(this);
      this.handleFocus = this.handleFocus.bind(this);
      this.handleBlur = this.handleBlur.bind(this);
      this.handleMouseEnter = this.handleMouseEnter.bind(this);
      this.handleMouseLeave = this.handleMouseLeave.bind(this);
    }

    componentDidMount(): void {
      this.startTimer();

      this.breakpointSubscription = CurrentBreakpoint$.subscribe((newBreakpoint) => {
        this.setState({
          areCarouselImagesFixed: IsBreakpointAtLeast(newBreakpoint.breakpoint, Breakpoint.Desktop),
        });
      });
    }

    componentWillUnmount(): void {
      this.stopTimer();
      this.breakpointSubscription?.unsubscribe();
    }

    stopTimer(): void {
      if (this.timerId) {
        clearTimeout(this.timerId);
        this.timerId = undefined;
      }
    }

    startTimer(): void {
      if (typeof this.timerId === 'undefined' && !this.state.isPaused && !this.state.isUserPaused) {
        this.timerId = setInterval(() => {
          this.nextSlide();
        }, this.timerDelay);
      }
    }

    pauseTimer(): void {
      this.setState(
        {
          isUserPaused: !this.state.isUserPaused,
        },
        () => {
          if (this.state.isUserPaused) {
            this.stopTimer();
          } else {
            this.startTimer();
          }
        }
      );
    }

    prevSlide(): void {
      const validItems = this.validSlides;

      const prevSlide = this.state?.activeSlide - 1 >= 0 ? this.state?.activeSlide - 1 : validItems.length - 1;

      this.goToSlide(prevSlide);
    }

    nextSlide(): void {
      const validItems = this.validSlides;

      const nextSlide = (this.state?.activeSlide + 1) % validItems.length;

      this.goToSlide(nextSlide);
    }

    goToSlide(slideIndex: number): void {
      this.setState({
        activeSlide: slideIndex,
      });
    }

    handleFocus(): void {
      this.setState(
        {
          isFocused: true,
          isPaused: true,
        },
        () => this.stopTimer()
      );
    }

    handleBlur(): void {
      this.setState(
        {
          isFocused: false,
          isPaused: false,
        },
        () => this.startTimer()
      );
    }

    handleMouseEnter(): void {
      this.setState(
        {
          isPaused: true,
        },
        () => this.stopTimer()
      );
    }

    handleMouseLeave(): void {
      if (!this.state.isFocused) {
        this.setState(
          {
            isPaused: false,
          },
          () => this.startTimer()
        );
      }
    }

    renderControls(dots: React.ReactNode[]) {
      return (
        <div className="neo-carousel-controls-container container">
          <div className="neo-carousel-controls">
            <div aria-label="Carousel navigation" tabIndex={0}>
              <button
                className="neo-carousel-prev-button"
                aria-label="Previous slide"
                aria-controls={`neo-carousel-${this.carouselId}`}
                tabIndex={-1}
                onClick={this.prevSlide}
              >
                <PrismicFontAwesomeIcon icon="fas chevron-left" />
              </button>
              <button
                className="neo-carousel-pause-button"
                aria-label="Pause"
                aria-controls={`neo-carousel-${this.carouselId}`}
                tabIndex={0}
                onClick={this.pauseTimer}
              >
                <PrismicFontAwesomeIcon icon={this.state.isUserPaused ? 'fas play' : 'fas pause'} />
              </button>
              <button
                className="neo-carousel-next-button"
                aria-label="Next slide"
                aria-controls={`neo-carousel-${this.carouselId}`}
                tabIndex={-1}
                onClick={this.nextSlide}
              >
                <PrismicFontAwesomeIcon icon="fas chevron-right" />
              </button>
            </div>
            <ol className="neo-carousel-dots" aria-label="Carousel pagination">
              {dots}
            </ol>
          </div>
        </div>
      );
    }

    render() {
      const { globalResources } = this.props;
      const { activeSlide = 0, areCarouselImagesFixed } = this.state;

      const validItems = this.validSlides;
      const slides: ReactNode[] = [];
      const dots: ReactNode[] = [];

      let slideIndex = 0;
      for (const item of validItems) {
        const isSlideActive = activeSlide === slideIndex;
        const slideClasses = classNames({
          'neo-carousel-slide': true,
          'is-active': slideIndex === activeSlide,
        });

        let titleNode: ReactNode = null;
        let descNode: ReactNode = null;
        let ctaNode: ReactNode = null;

        if (item.link_title) {
          titleNode = <div className="neo-carousel-slide-title">{item.link_title}</div>;
        }

        if (item.link_desc?.html) {
          descNode = (
            <div className="neo-carousel-slide-desc" dangerouslySetInnerHTML={{ __html: item.link_desc?.html || '' }} />
          );
        }

        if (IsValidPrismicLink(item.link_dest)) {
          ctaNode = (
            <div className="neo-carousel-slide-cta">
              <PrismicLink className="button has-text-weight-bold" to={item.link_dest}>
                {item.link_cta || globalResources.default_cta_text}
              </PrismicLink>
            </div>
          );
        }

        slides.push(
          <div
            id={`neo-carousel-slide-${slideIndex}`}
            className={slideClasses}
            key={slideIndex}
            aria-hidden={!isSlideActive}
            tabIndex={isSlideActive ? 0 : -1}
          >
            <PrismicImage
              className="has-neo-arc has-no-arc-mobile"
              image={item.image}
              imgStyle={{ height: '100%' }}
              srcIsFixed={areCarouselImagesFixed}
            />
            <div className="container">
              <div className="neo-carousel-slide-content">
                {titleNode}
                {descNode}
                {ctaNode}
              </div>
            </div>
          </div>
        );

        dots.push(
          <li key={slideIndex} className={isSlideActive ? 'neo-carousel-dot-active' : 'neo-carousel-dot'}>
            <button
              type="button"
              data-role="none"
              role="button"
              aria-selected={isSlideActive}
              aria-controls={`neo-carousel-slide-${slideIndex}`}
              aria-label={`Slide ${slideIndex + 1}`}
              onClick={this.goToSlide.bind(this, slideIndex)}
            >
              {slideIndex + 1}
            </button>
          </li>
        );

        slideIndex = slideIndex + 1;
      }

      return (
        <div
          id={`neo-carousel-${this.carouselId}`}
          className="container-limited neo-carousel is-caa-darkforestgreen has-slide-data-left"
          onMouseEnter={this.handleMouseEnter}
          onMouseLeave={this.handleMouseLeave}
          onFocus={this.handleFocus}
          onBlur={this.handleBlur}
        >
          {dots.length > 1 && this.renderControls(dots)}
          {slides}
          <div className="neo-carousel-pusher" />
        </div>
      );
    }

    get validSlides(): PrismicPageDataBodyCarouselItem[] {
      const { sliceData } = this.props;
      return (sliceData.items ?? []).filter(
        (item) => item?.link_title && IsValidPrismicLink(item?.link_dest) && item?.image
      ) as PrismicPageDataBodyCarouselItem[];
    }
  }
);
export default CarouselSlice;

export interface CarouselSliceProps {
  sliceData: PrismicPageDataBodyCarousel;
  pageData?: PrismicPage;
}

interface CarouselSliceState {
  isUserPaused: boolean;
  isPaused: boolean;
  activeSlide: number;
  isFocused: boolean;
  areCarouselImagesFixed: boolean;
}

// noinspection JSUnusedGlobalSymbols
export const SliceCarouselFragment = graphql`
  fragment SliceCarouselFragment on PrismicSlicesCarousel {
    ... on PrismicPageDataBodyCarousel {
      id
      slice_type
      items {
        link_cta
        link_desc {
          html
        }
        link_dest {
          ...PrismicLinkFragment
        }
        link_title
        image {
          alt
          url
          gatsbyImageData(layout: FULL_WIDTH)
        }
      }
    }
    ... on PrismicErrorPageDataBodyCarousel {
      id
      slice_type
      items {
        link_cta
        link_desc {
          html
        }
        link_dest {
          ...PrismicLinkFragment
        }
        link_title
        image {
          alt
          url
          gatsbyImageData(layout: FULL_WIDTH)
        }
      }
    }
    ... on PrismicEmailSubscriptionPageDataBodyCarousel {
      id
      slice_type
      items {
        link_cta
        link_desc {
          html
        }
        link_dest {
          ...PrismicLinkFragment
        }
        link_title
        image {
          alt
          url
          gatsbyImageData(layout: FULL_WIDTH)
        }
      }
    }
    ... on PrismicGaragesSearchPageDataBodyCarousel {
      id
      slice_type
      items {
        link_cta
        link_desc {
          html
        }
        link_dest {
          ...PrismicLinkFragment
        }
        link_title
        image {
          alt
          url
          gatsbyImageData(layout: FULL_WIDTH)
        }
      }
    }
    ... on PrismicMerchandiseSearchPageDataBodyCarousel {
      id
      slice_type
      items {
        link_cta
        link_desc {
          html
        }
        link_dest {
          ...PrismicLinkFragment
        }
        link_title
        image {
          alt
          url
          gatsbyImageData(layout: FULL_WIDTH)
        }
      }
    }
    ... on PrismicSearchPageDataBodyCarousel {
      id
      slice_type
      items {
        link_cta
        link_desc {
          html
        }
        link_dest {
          ...PrismicLinkFragment
        }
        link_title
        image {
          alt
          url
          gatsbyImageData(layout: FULL_WIDTH)
        }
      }
    }
    ... on PrismicTravelAgentsSearchPageDataBodyCarousel {
      id
      slice_type
      items {
        link_cta
        link_desc {
          html
        }
        link_dest {
          ...PrismicLinkFragment
        }
        link_title
        image {
          alt
          url
          gatsbyImageData(layout: FULL_WIDTH)
        }
      }
    }
    ... on PrismicTravelDealsSearchPageDataBodyCarousel {
      id
      slice_type
      items {
        link_cta
        link_desc {
          html
        }
        link_dest {
          ...PrismicLinkFragment
        }
        link_title
        image {
          alt
          url
          gatsbyImageData(layout: FULL_WIDTH)
        }
      }
    }
    ... on PrismicTravelPartnersSearchPageDataBodyCarousel {
      id
      slice_type
      items {
        link_cta
        link_desc {
          html
        }
        link_dest {
          ...PrismicLinkFragment
        }
        link_title
        image {
          alt
          url
          gatsbyImageData(layout: FULL_WIDTH)
        }
      }
    }
    ... on PrismicTravelStoreSearchPageDataBodyCarousel {
      id
      slice_type
      items {
        link_cta
        link_desc {
          html
        }
        link_dest {
          ...PrismicLinkFragment
        }
        link_title
        image {
          alt
          url
          gatsbyImageData(layout: FULL_WIDTH)
        }
      }
    }
    ... on PrismicTravelTalksSearchPageDataBodyCarousel {
      id
      slice_type
      items {
        link_cta
        link_desc {
          html
        }
        link_dest {
          ...PrismicLinkFragment
        }
        link_title
        image {
          alt
          url
          gatsbyImageData(layout: FULL_WIDTH)
        }
      }
    }
    ... on PrismicTravelTripsSearchPageDataBodyCarousel {
      id
      slice_type
      items {
        link_cta
        link_desc {
          html
        }
        link_dest {
          ...PrismicLinkFragment
        }
        link_title
        image {
          alt
          url
          gatsbyImageData(layout: FULL_WIDTH)
        }
      }
    }
    ... on PrismicTravelStoreDataBodyCarousel {
      id
      slice_type
      items {
        link_cta
        link_desc {
          html
        }
        link_dest {
          ...PrismicLinkFragment
        }
        link_title
        image {
          alt
          url
          gatsbyImageData(layout: FULL_WIDTH)
        }
      }
    }
    ... on PrismicTravelTalkDataBodyCarousel {
      id
      slice_type
      items {
        link_cta
        link_desc {
          html
        }
        link_dest {
          ...PrismicLinkFragment
        }
        link_title
        image {
          alt
          url
          gatsbyImageData(layout: FULL_WIDTH)
        }
      }
    }
    ... on PrismicTravelTripDataBodyCarousel {
      id
      slice_type
      items {
        link_cta
        link_desc {
          html
        }
        link_dest {
          ...PrismicLinkFragment
        }
        link_title
        image {
          alt
          url
          gatsbyImageData(layout: FULL_WIDTH)
        }
      }
    }
    ... on PrismicTravelDealDataBodyCarousel {
      id
      slice_type
      items {
        link_cta
        link_desc {
          html
        }
        link_dest {
          ...PrismicLinkFragment
        }
        link_title
        image {
          alt
          url
          gatsbyImageData(layout: FULL_WIDTH)
        }
      }
    }
    ... on PrismicMerchandiseDataBodyCarousel {
      id
      slice_type
      items {
        link_cta
        link_desc {
          html
        }
        link_dest {
          ...PrismicLinkFragment
        }
        link_title
        image {
          alt
          url
          gatsbyImageData(layout: FULL_WIDTH)
        }
      }
    }
    ... on PrismicTravelPartnerDataBodyCarousel {
      id
      slice_type
      items {
        link_cta
        link_desc {
          html
        }
        link_dest {
          ...PrismicLinkFragment
        }
        link_title
        image {
          alt
          url
          gatsbyImageData(layout: FULL_WIDTH)
        }
      }
    }
  }
`;
