import {
  PrismicTravelPartner,
  PrismicTravelPartnerConnection,
} from '../../graphql';
import React, { Component, ComponentType } from 'react';
import { graphql, StaticQuery } from 'gatsby';
import { Subset } from '../../utils/UtilityTypes';

const WithTravelPartnerData = function WithTravelPartnerDataFn<
  P extends InjectedTravelPartnerDataProps
>(
  ComposedComponent: ComponentType<P>
): ComponentType<Subset<P, InjectedTravelPartnerDataProps>> {
  return class extends Component<Subset<P, InjectedTravelPartnerDataProps>> {
    render() {
      return (
        <StaticQuery
          query={TravelPartnerDataQuery}
          render={queryResult => {
            const {
              allPrismicTravelPartner,
            } = queryResult as TravelPartnerDataQueryResults;

            return (
              <ComposedComponent
                {...(this.props as any)}
                travelPartnerData={allPrismicTravelPartner.nodes || []}
              />
            );
          }}
        />
      );
    }
  };
};
export default WithTravelPartnerData;

const TravelPartnerDataQuery = graphql`
  query TravelPartnerDataQuery {
    allPrismicTravelPartner(sort: { fields: data___page_title, order: ASC }) {
      nodes {
        ...TravelPartnerFragment
      }
    }
  }
`;

interface TravelPartnerDataQueryResults {
  allPrismicTravelPartner: PrismicTravelPartnerConnection;
}

// noinspection JSUnusedGlobalSymbols
export const TravelPartnerFragment = graphql`
  fragment TravelPartnerFragment on PrismicTravelPartner {
    id
    uid
    url
    data {
      page_title
      share_description
      og_image {
        alt
        url
        gatsbyImageData(width: 960, placeholder: NONE, layout: CONSTRAINED)
      }
      partner_logo {
        alt
        url
        gatsbyImageData(width: 960, placeholder: NONE, layout: CONSTRAINED)
      }
      travel_types {
        travel_type {
          document {
            ... on PrismicTaxonomyTravelType {
              id
            }
          }
        }
      }
    }
  }
`;

export interface InjectedTravelPartnerDataProps {
  travelPartnerData: PrismicTravelPartner[];
}
