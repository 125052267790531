import React, { ReactElement } from 'react';
import { PrismicStructuredTextType } from '../../graphql';
import ReactHtmlParser, { convertNodeToElement, Transform } from 'react-html-parser';
import { DomElement } from 'htmlparser2';

const notWhiteSpaceRegex = /\S/;
const nodeIs = (name: string, node?: DomElement) => node && node.type === 'tag' && node.name === name;
/**
 * Checks whether that the node is an anchor (tag "a") or a span (tag "span") containing an anchor
 * and that its parent is a paragraph (tag "p").
 * Emmet: p>a || p>span>a
 * @param node The node to check
 * @return "true" if node is <p> > <a> or <p> > <span> > <a>. "false" otherwise.
 */
const nodeIsCustomButton = (node: DomElement) =>
  nodeIs('a', node) && (nodeIs('p', node.parent) || nodeIs('span', node.parent));
const isParagraphEligible = (node?: DomElement) => {
  if (!node || !node.children?.length) {
    return false;
  }

  let hasOnlyAnchors = true;

  for (const childNode of node.children) {
    if (childNode.type === 'text') {
      const textData = (childNode.data as string | null | undefined) ?? '';

      if (textData.match(notWhiteSpaceRegex) === null) {
        continue;
      }
    }

    if (nodeIs('a', childNode)) {
      continue;
    }

    hasOnlyAnchors = false;
    break;
  }

  return hasOnlyAnchors;
};

export interface PrismicStructuredTextProps {
  text?: PrismicStructuredTextType | null;
  transform?: Transform;
  transformLoneLinksAsButton?: boolean;
  transformPreAsBlockquotes?: boolean;
  colorClass?: string;
}

const PrismicStructuredText = ({
  text,
  transform,
  transformLoneLinksAsButton = true,
  transformPreAsBlockquotes = true,
  colorClass = 'is-caa-forestgreen',
}: PrismicStructuredTextProps) => {
  if (!text?.html) {
    return <React.Fragment />;
  }

  const defaultTransform = (node: DomElement, index: number): ReactElement | void | null => {
    if (nodeIs('a', node) && node.parent.attribs?.class === 'nofollow') {
      node.attribs.rel = 'nofollow';
    }
    if (nodeIs('span', node) && node.attribs?.class === 'superscript') {
      node.name = 'sup';
      node.attribs = {};
    }
    if (nodeIsCustomButton(node) && transformLoneLinksAsButton) {
      if (!isParagraphEligible(node.parent)) {
        return undefined;
      }
      const firstAnchor: DomElement | undefined = node.parent?.children?.find(
        (n: DomElement) => nodeIs('a', n) || (nodeIs('span', n) && nodeIs('a', n.children[0]))
      );

      const buttonNode = nodeIs('a', node) ? node : node.children[0];
      if (buttonNode.attribs === undefined) {
        buttonNode.attribs = {};
      }

      buttonNode.attribs.class =
        firstAnchor === buttonNode
          ? `button ${colorClass} has-text-weight-bold is-medium`
          : 'button is-light has-text-weight-bold is-medium';

      return convertNodeToElement(node, index, defaultTransform);
    }

    if (nodeIs('span', node) && node.attribs.class === 'hr') {
      node.name = 'hr';
      node.attribs = {};
      node.children = [];

      return convertNodeToElement(node, index, defaultTransform);
    }

    if (nodeIs('p', node) && transformLoneLinksAsButton && isParagraphEligible(node)) {
      if (!node.attribs) {
        node.attribs = {};
      }

      const linkCount = node.children?.filter((n: DomElement) => nodeIs('a', n)).length ?? 0;
      node.attribs.class = `neo-button-row ${linkCount > 1 ? 'is-neo-multiple-button' : 'is-neo-single-button'}`;

      node.children
        ?.filter((c: DomElement) => c.attribs?.href.includes('.pdf') && !c.attribs.target)
        .forEach((child: DomElement) => {
          child.attribs = { target: '_blank', href: child.attribs?.href };
        });

      return convertNodeToElement(node, index, defaultTransform);
    }

    if (nodeIs('pre', node) && transformPreAsBlockquotes) {
      node.name = 'blockquote';

      return convertNodeToElement(node, index, defaultTransform);
    }
  };
  const nodes = ReactHtmlParser(text?.html, {
    transform: transform || defaultTransform,
  });

  return <>{nodes}</>;
};

export default PrismicStructuredText;
