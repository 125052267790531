import React, { Component, ReactNode } from 'react';
import shuffle from 'lodash/shuffle';
import Agent from './Agent';
import ImageLinkTileList from '../../controls/ImageLinkTileList';
import WithAgentData, { InjectedAgentDataProps } from '../../higher-order/WithAgentData';
import { FilterTravelDataBasedOnTags } from '../../../utils/AgentDataHelpers';
import { PrismicAgent } from '../../../graphql';

const RandomAgent = WithAgentData(
  class RandomAgentImpl extends Component<RandomAgentProps & InjectedAgentDataProps, RandomAgentState> {
    static defaultProps: Partial<RandomAgentProps & InjectedAgentDataProps> = {
      numOfResults: 1,
      travelStoreIds: [],
      travelDestinations: [],
      travelPartners: [],
      travelTypes: [],
    };

    constructor(props: Readonly<RandomAgentProps & InjectedAgentDataProps>) {
      super(props);

      this.state = {
        showRandomItems: false,
      };
    }

    componentDidMount(): void {
      this.setState({
        showRandomItems: true,
      });
    }

    render(): ReactNode {
      const {
        agentType,
        travelStoreId,
        travelStoreIds,
        travelDestinations,
        travelPartners,
        travelTypes,
        travelTypeId,
        returnEmptyIfNoMatch,
        numOfResults,
        render,
        agentData,
      } = this.props;
      if (!this.state.showRandomItems) {
        return null;
      }

      if (travelStoreId) {
        travelStoreIds.push(travelStoreId);
      }

      if (travelTypeId) {
        travelTypes.push(travelTypeId);
      }

      const eligibleAgents = FilterTravelDataBasedOnTags<PrismicAgent>(agentData, {
        travelStoreId: travelStoreIds,
        travelDestinationId: travelDestinations,
        travelPartnerId: travelPartners,
        travelTypeId: travelTypes,
        agentType,
      });

      if (eligibleAgents.length === 0 && returnEmptyIfNoMatch === true) {
        return null;
      }

      if (eligibleAgents.length === 0) {
        eligibleAgents.push(...(agentType ? agentData.filter((item) => item.data.type === agentType) : agentData));
      }

      const selectedAgents =
        numOfResults !== undefined && numOfResults < 0
          ? shuffle(eligibleAgents)
          : shuffle(eligibleAgents).slice(0, numOfResults || 1);

      if (selectedAgents.length === 0) {
        return null;
      }

      const outputNode: ReactNode =
        numOfResults === 1 ? (
          <Agent agent={selectedAgents[0]} />
        ) : (
          <ImageLinkTileList>
            {selectedAgents.map((agent, index) => (
              <Agent key={index} agent={agent} />
            ))}
          </ImageLinkTileList>
        );

      if (render) {
        return render(outputNode);
      }

      return outputNode;
    }
  }
);

export default RandomAgent;

export interface RandomAgentProps {
  agentType?: string | null;
  travelTypeId?: string | null;
  travelStoreId?: string | null;
  travelStoreIds: (string | undefined)[];
  travelDestinations: (string | undefined)[];
  travelPartners: (string | undefined)[];
  travelTypes: (string | undefined)[];
  returnEmptyIfNoMatch?: boolean;
  numOfResults?: number;
  render?: (node: ReactNode) => ReactNode;
}

export interface RandomAgentState {
  showRandomItems: boolean;
}
