import React, { Component, ReactNode } from 'react';
import {
  Maybe,
  PrismicEmailSubscriptionPageDataBodyFormPlaceholder,
  PrismicEmailSubscriptionPageDataBodySlicesType,
  PrismicErrorPageDataBodySearchEnginePlaceholder,
  PrismicErrorPageDataBodySlicesType,
  PrismicGaragesSearchPageDataBodySearchEnginePlaceholder,
  PrismicGaragesSearchPageDataBodySlicesType,
  PrismicMerchandiseDataBodySlicesType,
  PrismicMerchandiseSearchPageDataBodySearchEnginePlaceholder,
  PrismicMerchandiseSearchPageDataBodySlicesType,
  PrismicPageDataBodyBreadcrumbs,
  PrismicPageDataBodyCarousel,
  PrismicPageDataBodyCollapsableContent,
  PrismicPageDataBodyComparativeTable,
  PrismicPageDataBodyContentListing,
  PrismicPageDataBodyCustomScripts,
  PrismicPageDataBodyDropdownGuidedNavigation,
  PrismicPageDataBodyHeroImage,
  PrismicPageDataBodyIconLinkList,
  PrismicPageDataBodyImageLinkCards,
  PrismicPageDataBodyImageLinkTiles,
  PrismicPageDataBodyLogoLinkList,
  PrismicPageDataBodyMap,
  PrismicPageDataBodyMarketingHero,
  PrismicPageDataBodyMembershipTiles,
  PrismicPageDataBodyMultiColumnContent,
  PrismicPageDataBodyRewardsOffers,
  PrismicPageDataBodyRewardsPartners,
  PrismicPageDataBodySimpleLinkTiles,
  PrismicPageDataBodySlicesType,
  PrismicPageDataBodyTableColumn,
  PrismicPageDataBodyTableRow,
  PrismicPageDataBodyTextContent,
  PrismicPageDataBodyTravelOffers,
  PrismicPageDataBodyTstWidget,
  PrismicPageDataBodyTwoColumnsTextContent,
  PrismicPageDataBodyYoutubeVideo,
  PrismicSearchPageDataBodySlicesType,
  PrismicTravelAgentsSearchPageDataBodySearchEnginePlaceholder,
  PrismicTravelAgentsSearchPageDataBodySlicesType,
  PrismicTravelDealDataBodySlicesType,
  PrismicTravelDealsSearchPageDataBodySearchEnginePlaceholder,
  PrismicTravelDealsSearchPageDataBodySlicesType,
  PrismicTravelPartnerDataBodySlicesType,
  PrismicTravelPartnersSearchPageDataBodySearchEnginePlaceholder,
  PrismicTravelPartnersSearchPageDataBodySlicesType,
  PrismicTravelStoreDataBodySlicesType,
  PrismicTravelStoreSearchPageDataBodySearchEnginePlaceholder,
  PrismicTravelStoreSearchPageDataBodySlicesType,
  PrismicTravelTalkDataBodySlicesType,
  PrismicTravelTalksSearchPageDataBodySearchEnginePlaceholder,
  PrismicTravelTalksSearchPageDataBodySlicesType,
  PrismicTravelTripDataBodySlicesType,
  PrismicTravelTripsSearchPageDataBodySearchEnginePlaceholder,
  PrismicTravelTripsSearchPageDataBodySlicesType,
} from '../../graphql';
import HeroImageSlice, { HeroImageSliceKey } from '../slices/HeroImageSlice';
import TextContentSlice, { TextContentSliceKey } from '../slices/TextContentSlice';
import VideoEmbedSlice, { VideoEmbedSliceKey } from '../slices/VideoEmbedSlice';
import IconLinkListSlice, { IconLinkListSliceKey } from '../slices/IconLinkListSlice';
import ImageLinkTilesSlice, { ImageLinkTilesSliceKey } from '../slices/ImageLinkTilesSlice';
import ImageLinkCardsSlice, { ImageLinkCardsSliceKey } from '../slices/ImageLinkCardsSlice';
import TwoColumnsTextContentSlice, { TwoColumnsTextContentSliceKey } from '../slices/TwoColumnsTextContentSlice';
import MarketingHeroSlice, { MarketingHeroSliceKey } from '../slices/MarketingHeroSlice';
import DropdownGuidedNavigationSlice, {
  DropdownGuidedNavigationSliceKey,
} from '../slices/DropdownGuidedNavigationSlice';
import BreadcrumbsSlice, { BreadcrumbsSliceKey } from '../slices/BreadcrumbsSlice';
import SimpleLinkTilesSlice, { SimpleLinkTilesSliceKey } from '../slices/SimpleLinkTilesSlice';
import TSTWidgetSlice, { TSTWidgetSliceKey } from '../slices/TSTWidgetSlice';
import CollapsableContentSlice, { CollapsableContentSliceKey } from '../slices/CollapsableContentSlice';
import ContentListingSlice, { ContentListingSliceKey } from '../slices/ContentListingSlice';
import RewardsPartnersSlice, { RewardsPartnersSliceKey } from '../slices/RewardsPartnersSlice';
import UnknownSlicePlaceholder from '../slices/UnknownSlicePlaceholder';
import TravelPartnerContentSlice, { TravelPartnerContentSliceKey } from '../slices/internal/TravelPartnerContentSlice';
import MultiColumnContentSlice, {
  MultiColumnContentSliceKey,
  SpecsContentSliceKey,
  StoreCredentialsContentSliceKey,
} from '../slices/MultiColumnContentSlice';
import TravelStoreContentSlice, { TravelStoreContentSliceKey } from '../slices/internal/TravelStoreContentSlice';
import AgentsSlice, { AgentsSliceKey } from '../slices/internal/AgentsSlice';
import TravelStoreTalksSlice, { TravelStoreTalksSliceKey } from '../slices/internal/TravelStoreTalksSlice';
import { TableColumnSlice, TableColumnSliceKey } from '../slices/TableColumnSlice';
import { TableRowSlice, TableRowSliceKey } from '../slices/TableRowSlice';
import CarouselSlice, { CarouselSliceKey } from '../slices/CarouselSlice';
import TravelTalkContentSlice, { TravelTalkContentSliceKey } from '../slices/internal/TravelTalkContentSlice';
import TravelPartnerRelatedSlice, { TravelPartnerRelatedSliceKey } from '../slices/internal/TravelPartnerRelatedSlice';
import TravelDealContentSlice, { TravelDealContentSliceKey } from '../slices/internal/TravelDealContentSlice';
import ComparativeTableSlice, { ComparativeTableSliceKey } from '../slices/ComparativeTableSlice';
import MembershipTilesSlice, { MembershipTilesSliceKey } from '../slices/MembershipTilesSlice';
import TravelTripContentSlice, { TravelTripContentSliceKey } from '../slices/internal/TravelTripContentSlice';
import MapSlice, { MapSliceKey } from '../slices/MapSlice';
import RewardsOffersSlice, { RewardsOffersSliceKey } from '../slices/RewardsOffersSlice';
import TravelAgentsSearchSlice, { TravelAgentsSearchSliceKey } from '../slices/internal/TravelAgentsSearchSlice';
import TravelTalksSearchSlice, { TravelTalksSearchSliceKey } from '../slices/internal/TravelTalksSearchSlice';
import TravelTripsSearchSlice, { TravelTripsSearchSliceKey } from '../slices/internal/TravelTripsSearchSlice';
import TravelDealsSearchSlice, { TravelDealsSearchSliceKey } from '../slices/internal/TravelDealsSearchSlice';
import TravelPartnersSearchSlice, { TravelPartnersSearchSliceKey } from '../slices/internal/TravelPartnersSearchSlice';
import TravelStoreSearchSlice, { TravelStoreSearchSliceKey } from '../slices/internal/TravelStoreSearchSlice';
import SearchPageSearchEngineSlice, {
  PrismicSearchPageBodySearchEnginePlaceholderWithSearchData,
  SearchPageSearchEngineSliceKey,
} from '../slices/internal/SearchPageSearchEngineSlice';
import TravelOffersSlice, { TravelOffersSliceKey } from '../slices/TravelOffersSlice';
import EmailSubscriptionFormSlice, {
  EmailSubscriptionFormSliceKey,
} from '../slices/internal/EmailSubscriptionFormSlice';
import MerchandiseContentSlice, { MerchandiseContentSliceKey } from '../slices/internal/MerchandiseContentSlice';
import MerchandiseSearchSlice, { MerchandiseSearchSliceKey } from '../slices/internal/MerchandiseSearchSlice';
import ErrorPageSearchSlice, { ErrorPageSearchSliceKey } from '../slices/internal/ErrorPageSearchSlice';
import GaragesSearchSlice, { GaragesSearchSliceKey } from '../slices/internal/GaragesSearchSlice';
import LogoLinkListSlice, { LogoLinkListSliceKey } from '../slices/LogoLinkListSlice';
import CustomScriptsSlice, { CustomScriptsSliceKey } from '../slices/CustomScriptsSlice';
import { GroupedSlicePrefix, TableDataBodyPseudoSlicesType } from '../../utils/TableUtility';

export default class PageSlicesRender extends Component<PageSlicesRenderProps> {
  render(): ReactNode {
    const { body, pageNode } = this.props;

    const normBody = body || [];
    const pageComponents: ReactNode[] = [];

    for (const [index, bodyElement] of normBody.entries()) {
      if (!bodyElement) continue;

      const sliceKey = `slice-${index}`;

      switch (bodyElement.slice_type) {
        case BreadcrumbsSliceKey:
          pageComponents.push(
            <BreadcrumbsSlice
              sliceData={bodyElement as PrismicPageDataBodyBreadcrumbs}
              pageData={pageNode}
              key={sliceKey}
            />
          );
          break;

        case CarouselSliceKey:
          pageComponents.push(
            <CarouselSlice sliceData={bodyElement as PrismicPageDataBodyCarousel} pageData={pageNode} key={sliceKey} />
          );
          break;

        case CollapsableContentSliceKey:
          pageComponents.push(
            <CollapsableContentSlice
              sliceData={bodyElement as PrismicPageDataBodyCollapsableContent}
              pageData={pageNode}
              key={sliceKey}
            />
          );
          break;

        case ComparativeTableSliceKey:
          pageComponents.push(
            <ComparativeTableSlice
              sliceData={bodyElement as PrismicPageDataBodyComparativeTable}
              pageData={pageNode}
              key={sliceKey}
            />
          );
          break;

        case ContentListingSliceKey:
          pageComponents.push(
            <ContentListingSlice
              sliceData={bodyElement as PrismicPageDataBodyContentListing}
              pageData={pageNode}
              key={sliceKey}
            />
          );
          break;

        case DropdownGuidedNavigationSliceKey:
          pageComponents.push(
            <DropdownGuidedNavigationSlice
              sliceData={bodyElement as PrismicPageDataBodyDropdownGuidedNavigation}
              pageData={pageNode}
              key={sliceKey}
            />
          );
          break;

        case HeroImageSliceKey:
          pageComponents.push(
            <HeroImageSlice
              sliceData={bodyElement as PrismicPageDataBodyHeroImage}
              pageData={pageNode}
              key={sliceKey}
            />
          );
          break;

        case ImageLinkCardsSliceKey:
          pageComponents.push(
            <ImageLinkCardsSlice
              sliceData={bodyElement as PrismicPageDataBodyImageLinkCards}
              pageData={pageNode}
              key={sliceKey}
            />
          );
          break;

        case IconLinkListSliceKey:
          pageComponents.push(
            <IconLinkListSlice
              sliceData={bodyElement as PrismicPageDataBodyIconLinkList}
              pageData={pageNode}
              key={sliceKey}
            />
          );
          break;

        case ImageLinkTilesSliceKey:
          pageComponents.push(
            <ImageLinkTilesSlice
              sliceData={bodyElement as PrismicPageDataBodyImageLinkTiles}
              pageData={pageNode}
              key={sliceKey}
            />
          );
          break;

        case MapSliceKey:
          pageComponents.push(
            <MapSlice sliceData={bodyElement as PrismicPageDataBodyMap} pageData={pageNode} key={sliceKey} />
          );
          break;

        case MarketingHeroSliceKey:
          pageComponents.push(
            <MarketingHeroSlice
              sliceData={bodyElement as PrismicPageDataBodyMarketingHero}
              pageData={pageNode}
              key={sliceKey}
            />
          );
          break;

        case MembershipTilesSliceKey:
          pageComponents.push(
            <MembershipTilesSlice
              sliceData={bodyElement as PrismicPageDataBodyMembershipTiles}
              pageData={pageNode}
              key={sliceKey}
            />
          );
          break;

        case MultiColumnContentSliceKey:
        case SpecsContentSliceKey:
        case StoreCredentialsContentSliceKey:
          pageComponents.push(
            <MultiColumnContentSlice
              sliceData={bodyElement as PrismicPageDataBodyMultiColumnContent}
              pageData={pageNode}
              key={sliceKey}
            />
          );
          break;

        case `${GroupedSlicePrefix + TableColumnSliceKey}`:
          pageComponents.push(
            <TableColumnSlice
              sliceData={(bodyElement as TableDataBodyPseudoSlicesType).data as PrismicPageDataBodyTableColumn[]}
              key={sliceKey}
            />
          );
          break;

        case `${GroupedSlicePrefix + TableRowSliceKey}`:
          pageComponents.push(
            <TableRowSlice
              sliceData={(bodyElement as TableDataBodyPseudoSlicesType).data as PrismicPageDataBodyTableRow[]}
              key={sliceKey}
            />
          );
          break;

        case RewardsOffersSliceKey:
          pageComponents.push(
            <RewardsOffersSlice
              sliceData={bodyElement as PrismicPageDataBodyRewardsOffers}
              pageData={pageNode}
              key={sliceKey}
            />
          );
          break;

        case RewardsPartnersSliceKey:
          pageComponents.push(
            <RewardsPartnersSlice
              sliceData={bodyElement as PrismicPageDataBodyRewardsPartners}
              pageData={pageNode}
              key={sliceKey}
            />
          );
          break;

        case SimpleLinkTilesSliceKey:
          pageComponents.push(
            <SimpleLinkTilesSlice
              sliceData={bodyElement as PrismicPageDataBodySimpleLinkTiles}
              pageData={pageNode}
              key={sliceKey}
            />
          );
          break;

        case TextContentSliceKey:
          pageComponents.push(
            <TextContentSlice
              sliceData={bodyElement as PrismicPageDataBodyTextContent}
              pageData={pageNode}
              key={sliceKey}
            />
          );
          break;

        case TravelOffersSliceKey:
          pageComponents.push(
            <TravelOffersSlice
              sliceData={bodyElement as PrismicPageDataBodyTravelOffers}
              pageData={pageNode}
              key={sliceKey}
            />
          );
          break;

        case TSTWidgetSliceKey:
          pageComponents.push(
            <TSTWidgetSlice
              sliceData={bodyElement as PrismicPageDataBodyTstWidget}
              pageData={pageNode}
              key={sliceKey}
            />
          );
          break;

        case TwoColumnsTextContentSliceKey:
          pageComponents.push(
            <TwoColumnsTextContentSlice
              sliceData={bodyElement as PrismicPageDataBodyTwoColumnsTextContent}
              pageData={pageNode}
              key={sliceKey}
            />
          );
          break;

        case VideoEmbedSliceKey:
          pageComponents.push(
            <VideoEmbedSlice
              sliceData={bodyElement as PrismicPageDataBodyYoutubeVideo}
              pageData={pageNode}
              key={sliceKey}
            />
          );
          break;

        case CustomScriptsSliceKey:
          pageComponents.push(
            <CustomScriptsSlice
              sliceData={bodyElement as PrismicPageDataBodyCustomScripts}
              pageData={pageNode}
              key={sliceKey}
            />
          );
          break;

        case ErrorPageSearchSliceKey:
          pageComponents.push(
            <ErrorPageSearchSlice
              sliceData={bodyElement as PrismicErrorPageDataBodySearchEnginePlaceholder}
              pageData={pageNode}
              key={sliceKey}
            />
          );
          break;

        case EmailSubscriptionFormSliceKey:
          pageComponents.push(
            <EmailSubscriptionFormSlice
              sliceData={bodyElement as PrismicEmailSubscriptionPageDataBodyFormPlaceholder}
              pageData={pageNode}
              key={sliceKey}
            />
          );
          break;

        case GaragesSearchSliceKey:
          pageComponents.push(
            <GaragesSearchSlice
              sliceData={bodyElement as PrismicGaragesSearchPageDataBodySearchEnginePlaceholder}
              pageData={pageNode}
              key={sliceKey}
            />
          );
          break;

        case MerchandiseContentSliceKey:
          pageComponents.push(<MerchandiseContentSlice sliceData={bodyElement} pageData={pageNode} key={sliceKey} />);
          break;

        case MerchandiseSearchSliceKey:
          pageComponents.push(
            <MerchandiseSearchSlice
              sliceData={bodyElement as PrismicMerchandiseSearchPageDataBodySearchEnginePlaceholder}
              pageData={pageNode}
              key={sliceKey}
            />
          );
          break;

        case SearchPageSearchEngineSliceKey:
          pageComponents.push(
            <SearchPageSearchEngineSlice
              sliceData={bodyElement as PrismicSearchPageBodySearchEnginePlaceholderWithSearchData}
              pageData={pageNode}
              key={sliceKey}
            />
          );
          break;

        case TravelAgentsSearchSliceKey:
          pageComponents.push(
            <TravelAgentsSearchSlice
              sliceData={bodyElement as PrismicTravelAgentsSearchPageDataBodySearchEnginePlaceholder}
              pageData={pageNode}
              key={sliceKey}
            />
          );
          break;

        case TravelDealContentSliceKey:
          pageComponents.push(<TravelDealContentSlice sliceData={bodyElement} pageData={pageNode} key={sliceKey} />);
          break;

        case TravelDealsSearchSliceKey:
          pageComponents.push(
            <TravelDealsSearchSlice
              sliceData={bodyElement as PrismicTravelDealsSearchPageDataBodySearchEnginePlaceholder}
              pageData={pageNode}
              key={sliceKey}
            />
          );
          break;

        case TravelPartnerContentSliceKey:
          pageComponents.push(<TravelPartnerContentSlice sliceData={bodyElement} pageData={pageNode} key={sliceKey} />);
          break;

        case TravelPartnerRelatedSliceKey:
          pageComponents.push(<TravelPartnerRelatedSlice sliceData={bodyElement} pageData={pageNode} key={sliceKey} />);
          break;

        case TravelPartnersSearchSliceKey:
          pageComponents.push(
            <TravelPartnersSearchSlice
              sliceData={bodyElement as PrismicTravelPartnersSearchPageDataBodySearchEnginePlaceholder}
              pageData={pageNode}
              key={sliceKey}
            />
          );
          break;

        case TravelStoreContentSliceKey:
          pageComponents.push(<TravelStoreContentSlice sliceData={bodyElement} pageData={pageNode} key={sliceKey} />);
          break;

        case TravelStoreTalksSliceKey:
          pageComponents.push(<TravelStoreTalksSlice sliceData={bodyElement} pageData={pageNode} key={sliceKey} />);
          break;

        case AgentsSliceKey:
          pageComponents.push(<AgentsSlice sliceData={bodyElement} pageData={pageNode} key={sliceKey} />);
          break;

        case TravelStoreSearchSliceKey:
          pageComponents.push(
            <TravelStoreSearchSlice
              sliceData={bodyElement as PrismicTravelStoreSearchPageDataBodySearchEnginePlaceholder}
              pageData={pageNode}
              key={sliceKey}
            />
          );
          break;

        case TravelTalkContentSliceKey:
          pageComponents.push(<TravelTalkContentSlice sliceData={bodyElement} pageData={pageNode} key={sliceKey} />);
          break;

        case TravelTalksSearchSliceKey:
          pageComponents.push(
            <TravelTalksSearchSlice
              sliceData={bodyElement as PrismicTravelTalksSearchPageDataBodySearchEnginePlaceholder}
              pageData={pageNode}
              key={sliceKey}
            />
          );
          break;

        case TravelTripContentSliceKey:
          pageComponents.push(<TravelTripContentSlice sliceData={bodyElement} pageData={pageNode} key={sliceKey} />);
          break;

        case TravelTripsSearchSliceKey:
          pageComponents.push(
            <TravelTripsSearchSlice
              sliceData={bodyElement as PrismicTravelTripsSearchPageDataBodySearchEnginePlaceholder}
              pageData={pageNode}
              key={sliceKey}
            />
          );
          break;

        case LogoLinkListSliceKey:
          pageComponents.push(
            <LogoLinkListSlice
              sliceData={bodyElement as PrismicPageDataBodyLogoLinkList}
              pageData={pageNode}
              key={sliceKey}
            />
          );
          break;

        default:
          pageComponents.push(<UnknownSlicePlaceholder sliceData={bodyElement} pageData={pageNode} key={sliceKey} />);
          break;
      }
    }
    return <React.Fragment>{pageComponents}</React.Fragment>;
  }
}

type SlicesTypes = Maybe<
  | PrismicPageDataBodySlicesType
  | PrismicSearchPageDataBodySlicesType
  | PrismicTravelDealsSearchPageDataBodySlicesType
  | PrismicTravelAgentsSearchPageDataBodySlicesType
  | PrismicTravelDealDataBodySlicesType
  | PrismicTravelPartnerDataBodySlicesType
  | PrismicTravelPartnersSearchPageDataBodySlicesType
  | PrismicEmailSubscriptionPageDataBodySlicesType
  | PrismicTravelStoreDataBodySlicesType
  | PrismicErrorPageDataBodySlicesType
  | PrismicTravelTalkDataBodySlicesType
  | PrismicTravelStoreSearchPageDataBodySlicesType
  | PrismicTravelTripDataBodySlicesType
  | PrismicMerchandiseSearchPageDataBodySlicesType
  | PrismicGaragesSearchPageDataBodySlicesType
  | PrismicTravelTripsSearchPageDataBodySlicesType
  | PrismicMerchandiseDataBodySlicesType
  | PrismicTravelTalksSearchPageDataBodySlicesType
  | TableDataBodyPseudoSlicesType
  >;

export interface PageSlicesRenderProps {
  body: Maybe<SlicesTypes[]>;
  pageNode?: any;
}
