import React, { Component, ReactNode } from 'react';
import { graphql, PageProps } from 'gatsby';
import {
  withPrismicPreview,
  WithPrismicPreviewProps,
} from 'gatsby-plugin-prismic-previews';

import Layout from '../components/Layout';
import {
  Maybe,
  PrismicPageDataBodyBreadcrumbs,
  PrismicPageDataBodySlicesType,
  PrismicTravelDeal,
  PrismicTravelDealDataBodySlicesType,
} from '../graphql';
import SEO from '../components/controls/SEO';
import PageSlicesRender from '../components/controls/PageSlicesRender';
import { BreadcrumbsSliceKey } from '../components/slices/BreadcrumbsSlice';
import { TravelDealContentSliceKey } from '../components/slices/internal/TravelDealContentSlice';
import WithGlobalResources, {
  InjectedGlobalResourcesProps,
} from '../components/higher-order/WithGlobalResources';
import NotFoundPage from './NotFoundPage';

type SlicesTypes = Maybe<
  PrismicPageDataBodySlicesType | PrismicTravelDealDataBodySlicesType
>;

type TravelDealTemplateProps = {
  data: {
    prismicTravelDeal: PrismicTravelDeal;
  };
} & InjectedGlobalResourcesProps &
  WithPrismicPreviewProps<PrismicTravelDeal> &
  PageProps<PrismicTravelDeal>;

class TravelDealTemplate extends Component<TravelDealTemplateProps> {
  render() {
    const { globalResources } = this.props;
    const pageNode = this.props?.data?.prismicTravelDeal;

    const pageComponents: ReactNode[] = [];

    if (!pageNode) {
      return <NotFoundPage location={this.props.location} />;
    }

    pageComponents.push(
      <SEO
        key="page-seo-item"
        title={pageNode.data?.page_title || ''}
        description={pageNode.data?.share_description ?? undefined}
        fbImage={
          pageNode.data?.og_image?.localFile?.childImageSharp?.gatsbyImageData
        }
        slug={pageNode.url ?? undefined}
        titleTemplate={
          pageNode?.data?.use_page_title_template === 'no'
            ? '{pageTitle}'
            : undefined
        }
        meta={
          pageNode?.data?.indexable === 'no'
            ? [
                {
                  name: `robots`,
                  content: `noindex`,
                },
              ]
            : undefined
        }
      />
    );

    const normalizedPageNodes: SlicesTypes[] = [];

    normalizedPageNodes.push({
      slice_type: BreadcrumbsSliceKey,
      primary: {
        include_home: 'yes',
        include_self: 'yes',
      },
      items: globalResources?.travel_deal_page_breadcrumb || [],
    } as PrismicPageDataBodyBreadcrumbs);

    normalizedPageNodes.push({
      slice_type: TravelDealContentSliceKey,
    } as PrismicPageDataBodySlicesType);

    if (pageNode?.data?.body?.length) {
      for (const slice of pageNode.data.body) {
        normalizedPageNodes.push(slice);
      }
    }
    pageComponents.push(
      <PageSlicesRender
        key="slices"
        body={normalizedPageNodes}
        pageNode={pageNode}
      />
    );

    return <Layout location={this.props.location}>{pageComponents}</Layout>;
  }
}

export default withPrismicPreview(WithGlobalResources(TravelDealTemplate));

export const pageQuery = graphql`
  query PrismicTravelDealQuery($nodeId: String!) {
    prismicTravelDeal(id: { eq: $nodeId }) {
      _previewable
      id
      url
      data {
        deal_image {
          alt
          url
          gatsbyImageData(width: 960, layout: CONSTRAINED)
        }
        page_title
        share_description
        share_title
        indexable
        og_image {
          alt
          url
          gatsbyImageData(
            width: 1200
            height: 630
            backgroundColor: "rgba(255,255,255,1)"
            placeholder: NONE
            layout: FIXED
          )
        }
        use_page_title_template
        deal_expiry
        cta_title
        cta_link_dest {
          ...PrismicLinkFragment
        }
        description {
          html
        }
        member_benefits {
          html
        }
        legal_text {
          html
        }
        travel_types {
          travel_type {
            document {
              ... on PrismicTaxonomyTravelType {
                id
                data {
                  name
                }
              }
            }
          }
        }
        travel_partner {
          ...PrismicLinkFragment
          document {
            ...PrismicDocumentLinkFragment
            ... on PrismicTravelPartner {
              data {
                page_title
                partner_logo {
                  alt
                  url
                  gatsbyImageData(width: 960, layout: CONSTRAINED)
                }
              }
            }
          }
        }
        body {
          ... on PrismicTravelDealDataBodyHeroImage {
            ...SliceHeroImageFragment
          }
          ... on PrismicTravelDealDataBodyCollapsableContent {
            ...SliceCollapsableContentFragment
          }
          ... on PrismicTravelDealDataBodyIconLinkList {
            ...SliceIconLinkListFragment
          }
          ... on PrismicTravelDealDataBodyImageLinkCards {
            ...SliceImageLinkCardsFragment
          }
          ... on PrismicTravelDealDataBodyImageLinkTiles {
            ...SliceImageLinkTilesFragment
          }
          ... on PrismicTravelDealDataBodyMarketingHero {
            ...SliceMarketingHeroFragment
          }
          ... on PrismicTravelDealDataBodyMultiColumnContent {
            ...SliceMultiColumnContentFragment
          }
          ... on PrismicTravelDealDataBodyTextContent {
            ...SliceTextContentFragment
          }
          ... on PrismicTravelDealDataBodyCarousel {
            ...SliceCarouselFragment
          }
          ... on PrismicTravelDealDataBodySimpleLinkTiles {
            ...SliceSimpleLinkTilesFragment
          }
          ... on PrismicTravelDealDataBodyYoutubeVideo {
            ...SliceYoutubeVideoFragment
          }
          ... on PrismicTravelDealDataBodyTravelOffers {
            ...SliceTravelOffersFragment
          }
          ... on PrismicTravelDealDataBodyContentListing {
            ...SliceContentListingFragment
          }
        }
      }
    }
  }
`;
