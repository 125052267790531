import React, { Component, ReactNode } from 'react';
import ImageLinkCompactTile, {
  IImageLinkCompactTileData,
} from '../controls/ImageLinkCompactTile';

class ImageLinkCardList extends Component<
  IImageLinkCompactTileData & IImageLinkCardListProps
> {
  render() {
    const imageLinkCardListData: IImageLinkCompactTileData[] = this.props
      .imageLinkCardListData;

    const itemsClassName = this.props.itemsClassName || undefined;

    return imageLinkCardListData.map((itemData, index) => {
      const linkKey = index.toString();
      let columnKey = 'tile';
      columnKey = columnKey.concat(index.toString());
      const linkRendering: ReactNode = (
        <div key={columnKey} className={itemsClassName}>
          <ImageLinkCompactTile
            key={linkKey}
            link_dest={itemData.link_dest}
            image={itemData.image}
            text={itemData.text}
          />
        </div>
      );
      return linkRendering;
    });
  }
}
export default ImageLinkCardList;

export interface IImageLinkCardListProps {
  imageLinkCardListData: IImageLinkCompactTileData[];
  itemsClassName?: string;
}
