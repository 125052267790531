import {
  PrismicTravelStore,
  PrismicTravelStoreConnection,
} from '../../graphql';
import React, { Component, ComponentType } from 'react';
import { graphql, StaticQuery } from 'gatsby';
import { Subset } from '../../utils/UtilityTypes';

const WithTravelStoreData = function WithTravelStoreDataFn<
  P extends InjectedTravelStoreDataProps
>(
  ComposedComponent: ComponentType<P>
): ComponentType<Subset<P, InjectedTravelStoreDataProps>> {
  return class extends Component<Subset<P, InjectedTravelStoreDataProps>> {
    render() {
      return (
        <StaticQuery
          query={TravelStoreDataQuery}
          render={queryResult => {
            const {
              allPrismicTravelStore,
            } = queryResult as TravelStoreDataQueryResults;

            return (
              <ComposedComponent
                {...(this.props as any)}
                travelStoreData={allPrismicTravelStore.nodes || []}
              />
            );
          }}
        />
      );
    }
  };
};
export default WithTravelStoreData;

const TravelStoreDataQuery = graphql`
  query TravelStoreDataQuery {
    allPrismicTravelStore(sort: { fields: data___page_title, order: ASC }) {
      nodes {
        ...TravelStoreFragment
      }
    }
  }
`;

interface TravelStoreDataQueryResults {
  allPrismicTravelStore: PrismicTravelStoreConnection;
}

// noinspection JSUnusedGlobalSymbols
export const TravelStoreFragment = graphql`
  fragment TravelStoreFragment on PrismicTravelStore {
    id
    uid
    url
    data {
      page_title
      location_type
      visibility
      store_image {
        alt
        url
        gatsbyImageData(width: 960, placeholder: NONE, layout: CONSTRAINED)
      }
    }
  }
`;

export interface InjectedTravelStoreDataProps {
  travelStoreData: PrismicTravelStore[];
}
