import React, { Component } from 'react';
import {
  IconPrefix,
  IconName,
  findIconDefinition,
  IconDefinition,
} from '@fortawesome/fontawesome-svg-core';
import {
  FontAwesomeIcon,
  FontAwesomeIconProps,
} from '@fortawesome/react-fontawesome';
import { IsBrowserEnvironment } from '../../utils/BrowserHelpers';

const FontAwesomeReadyEvent = 'neoFontAwesomeReady';

/**
 * A PrismicFontAwesomeIcon is a wrapper for a FontAwesomeIcon.
 * It is the component that should be used when adding an icon to a component.
 * It should not be used for user inputted icons. When a user can change an
 * icon through the CMS, use the PrismicIcon component instead.
 */
const PrismicFontAwesomeIcon = class extends Component<
  PrismicFontAwesomeIconProps
> {
  static defaultProps: Partial<PrismicFontAwesomeIconProps> = {
    prefixPriority: ['fab', 'fad', 'fas', 'fal'],
  };

  private extractPrefixRegex = /^\s*(?:(fa[sbrld])(?:\s+|-))?(?:fa-)?(.+)\s*/;

  componentDidMount(): void {
    if (IsBrowserEnvironment) {
      window.addEventListener(FontAwesomeReadyEvent, this.onFontAwesomeReady);
    }
  }

  componentWillUnmount(): void {
    if (IsBrowserEnvironment) {
      window.removeEventListener(
        FontAwesomeReadyEvent,
        this.onFontAwesomeReady
      );
    }
  }

  render() {
    const { icon, mask, prefixPriority, ...faProps } = this.props;

    if (!icon) {
      return <React.Fragment />;
    }

    const resolvedIcon = this.findIconFromString(icon, prefixPriority!);

    if (!resolvedIcon) {
      return <React.Fragment />;
    }

    const resolvedMask = this.findIconFromString(mask, prefixPriority!);

    return (
      <FontAwesomeIcon icon={resolvedIcon} mask={resolvedMask} {...faProps} />
    );
  }

  findIconFromString(
    iconName: string | undefined,
    prefixPriority: IconPrefix[]
  ): IconDefinition | undefined {
    if (!iconName) {
      return undefined;
    }

    const matches = this.extractPrefixRegex.exec(iconName);

    if (!matches) {
      return undefined;
    }

    if (IsBrowserEnvironment) {
      if ((window as any).___CAANEO___.isFontAwesomeReady !== true) {
        return undefined;
      }
    }

    let prefixSearches = prefixPriority;

    if (matches[1]) {
      prefixSearches = [matches[1] as IconPrefix];
    }

    for (const prefix of prefixSearches) {
      try {
        const iconDefinition = findIconDefinition({
          prefix,
          iconName: matches[2] as IconName,
        });

        if (iconDefinition) {
          return iconDefinition;
        }
      } catch (e) {
        // Continue to next item
      }
    }

    return undefined;
  }

  onFontAwesomeReady = () => {
    this.forceUpdate();
  };
};

export interface PrismicFontAwesomeIconProps
  extends Omit<FontAwesomeIconProps, 'icon' | 'mask'> {
  icon?: string;
  mask?: string;
  prefixPriority?: IconPrefix[];
}

export default PrismicFontAwesomeIcon;
