import React, { Component, ReactNode } from 'react';
import { graphql, PageProps } from 'gatsby';
import {
  withPrismicPreview,
  WithPrismicPreviewProps,
} from 'gatsby-plugin-prismic-previews';

import Layout from '../components/Layout';
import {
  Maybe,
  PrismicPageDataBodyBreadcrumbs,
  PrismicPageDataBodyHeroImage,
  PrismicPageDataBodySlicesType,
  PrismicTravelPartner,
  PrismicTravelPartnerDataBodySlicesType,
} from '../graphql';
import SEO from '../components/controls/SEO';
import PageSlicesRender from '../components/controls/PageSlicesRender';
import { BreadcrumbsSliceKey } from '../components/slices/BreadcrumbsSlice';
import { HeroImageSliceKey } from '../components/slices/HeroImageSlice';
import { TravelPartnerContentSliceKey } from '../components/slices/internal/TravelPartnerContentSlice';
import WithGlobalResources, {
  InjectedGlobalResourcesProps,
} from '../components/higher-order/WithGlobalResources';
import { TravelPartnerRelatedSliceKey } from '../components/slices/internal/TravelPartnerRelatedSlice';
import NotFoundPage from './NotFoundPage';

type SlicesTypes = Maybe<
  PrismicPageDataBodySlicesType | PrismicTravelPartnerDataBodySlicesType
>;

type TravelPartnerTemplateProps = {
  data: {
    prismicTravelPartner: PrismicTravelPartner;
  };
} & InjectedGlobalResourcesProps &
  WithPrismicPreviewProps<PrismicTravelPartner> &
  PageProps<PrismicTravelPartner>;

class TravelPartnerTemplate extends Component<TravelPartnerTemplateProps> {
  render() {
    const { globalResources } = this.props;
    const pageNode: PrismicTravelPartner | undefined =
      this?.props?.data?.prismicTravelPartner || undefined;
    const pageComponents: ReactNode[] = [];

    if (!pageNode) {
      return <NotFoundPage location={this.props.location} />;
    }

    pageComponents.push(
      <SEO
        key="page-seo-item"
        title={pageNode.data?.page_title || ''}
        description={pageNode.data?.share_description ?? undefined}
        fbImage={
          pageNode.data?.og_image?.localFile?.childImageSharp?.gatsbyImageData
        }
        slug={pageNode.url ?? undefined}
        titleTemplate={
          pageNode?.data?.use_page_title_template === 'no'
            ? '{pageTitle}'
            : undefined
        }
        meta={
          pageNode?.data?.indexable === 'no'
            ? [
                {
                  name: `robots`,
                  content: `noindex`,
                },
              ]
            : undefined
        }
      />
    );

    const normalizedPageNodes: SlicesTypes[] = [];

    if (pageNode.data?.hero_image) {
      normalizedPageNodes.push({
        slice_type: HeroImageSliceKey,
        primary: {
          image: pageNode.data?.hero_image,
          use_curve: 'yes',
        },
      } as PrismicPageDataBodyHeroImage);
    }

    normalizedPageNodes.push({
      slice_type: BreadcrumbsSliceKey,
      primary: {
        include_home: 'yes',
        include_self: 'yes',
      },
      items: globalResources?.travel_partner_page_breadcrumb || [],
    } as PrismicPageDataBodyBreadcrumbs);

    normalizedPageNodes.push({
      slice_type: TravelPartnerContentSliceKey,
    } as PrismicTravelPartnerDataBodySlicesType);

    if (pageNode?.data?.body?.length) {
      for (const slice of pageNode.data.body) {
        normalizedPageNodes.push(slice);
      }
    }

    normalizedPageNodes.push({
      slice_type: TravelPartnerRelatedSliceKey,
    } as PrismicTravelPartnerDataBodySlicesType);

    pageComponents.push(
      <PageSlicesRender
        key="slices"
        body={normalizedPageNodes}
        pageNode={pageNode}
      />
    );

    return <Layout location={this.props.location}>{pageComponents}</Layout>;
  }
}

export default withPrismicPreview(WithGlobalResources(TravelPartnerTemplate));

export const pageQuery = graphql`
  query PrismicTravelPartnerQuery($nodeId: String!) {
    prismicTravelPartner(id: { eq: $nodeId }) {
      _previewable
      id
      url
      data {
        hero_image {
          alt
          url
          gatsbyImageData(layout: FULL_WIDTH)
        }
        partner_description {
          html
        }
        partner_showcase {
          html
        }
        member_benefits {
          html
        }
        partner_logo {
          alt
          url
          gatsbyImageData(width: 960, layout: CONSTRAINED)
        }
        partner_phone
        page_title
        share_description
        share_title
        indexable
        og_image {
          alt
          url
          gatsbyImageData(
            width: 1200
            height: 630
            backgroundColor: "rgba(255,255,255,1)"
            placeholder: NONE
            layout: FIXED
          )
        }
        use_page_title_template
        body {
          ... on PrismicTravelPartnerDataBodySpecs {
            ...SliceMultiColumnContentFragment
          }
          ... on PrismicTravelPartnerDataBodyCarousel {
            ...SliceCarouselFragment
          }
          ... on PrismicTravelPartnerDataBodyCollapsableContent {
            ...SliceCollapsableContentFragment
          }
          ... on PrismicTravelPartnerDataBodyIconLinkList {
            ...SliceIconLinkListFragment
          }
          ... on PrismicTravelPartnerDataBodyImageLinkCards {
            ...SliceImageLinkCardsFragment
          }
          ... on PrismicTravelPartnerDataBodyImageLinkTiles {
            ...SliceImageLinkTilesFragment
          }
          ... on PrismicTravelPartnerDataBodyMarketingHero {
            ...SliceMarketingHeroFragment
          }
          ... on PrismicTravelPartnerDataBodyMultiColumnContent {
            ...SliceMultiColumnContentFragment
          }
          ... on PrismicTravelPartnerDataBodySimpleLinkTiles {
            ...SliceSimpleLinkTilesFragment
          }
          ... on PrismicTravelPartnerDataBodyTextContent {
            ...SliceTextContentFragment
          }
          ... on PrismicTravelPartnerDataBodyContentListing {
            ...SliceContentListingFragment
          }
          ... on PrismicTravelPartnerDataBodyTravelOffers {
            ...SliceTravelOffersFragment
          }
          ... on PrismicTravelPartnerDataBodyYoutubeVideo {
            ...SliceYoutubeVideoFragment
          }
        }
      }
    }
  }
`;
