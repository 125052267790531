import React, { Component, ReactNode } from 'react';
import {
  Maybe,
  PrismicLinkType,
  PrismicPage,
  PrismicPageDataBodyComparativeTable,
  PrismicPageDataBodyComparativeTableItem,
  PrismicPlan,
  PrismicPlanDataImageImageType,
  PrismicPlanDataPlanImageImageType,
  PrismicStructuredTextType,
  PrismicTaxonomyPlanSpecType,
} from '../../graphql';
import { Subscription } from 'rxjs';
import { Breakpoint, CurrentBreakpoint$, IsBreakpointAtMost } from '../../utils/BreakpointHelpers';
import DesktopComparativeTable from './comparative-table-layouts/DesktopComparativeTable';
import MobileComparativeTable from './comparative-table-layouts/MobileComparativeTable';
import { graphql } from 'gatsby';
import { MakeRandomId } from '../../utils/RandomId';
import SectionTitle from '../controls/SectionTitle';

export const ComparativeTableSliceKey = 'comparative_table';
export default class ComparativeTableSlice extends Component<ComparativeTableSliceProps, ComparativeTableSliceState> {
  private breakpointSubscription?: Subscription;
  private allPlansSpecs: IPlanSpecification[] = [];

  constructor(props: Readonly<ComparativeTableSliceProps>) {
    super(props);

    this.state = {
      isMobile: false,
      activeItemsIndex: [],
      elementIdPrefix: MakeRandomId('ComparativeTableSlice', 10),
    };
  }

  componentDidMount(): void {
    this.breakpointSubscription = CurrentBreakpoint$.subscribe((newBreakpoint) => {
      this.setState({
        isMobile: IsBreakpointAtMost(newBreakpoint.breakpoint, Breakpoint.Mobile),
      });
    });
  }

  componentWillUnmount(): void {
    if (this.breakpointSubscription) {
      this.breakpointSubscription.unsubscribe();
    }
  }

  render(): ReactNode {
    const { sliceData } = this.props;
    const { isMobile } = this.state;
    const items = Array.isArray(sliceData.items) ? sliceData.items : [];

    if (!items.length) {
      return <React.Fragment />;
    }
    const plansData: IPlanData[] = this.formatPlansData(items) || [];
    const uniqueSpecs = this.allPlansSpecs.filter((spec, index) => {
      const _spec = JSON.stringify(spec);
      return (
        index ===
        this.allPlansSpecs.findIndex((obj) => {
          return JSON.stringify(obj) === _spec;
        })
      );
    });

    const setActiveItem = (index: number, event: React.MouseEvent<HTMLAnchorElement | HTMLButtonElement>) => {
      event.preventDefault();

      let newActiveItems: number[] = this.state.activeItemsIndex;

      if (this.state.activeItemsIndex.includes(index)) {
        newActiveItems = newActiveItems.filter((item) => {
          return item !== index;
        });
      } else {
        newActiveItems.push(index);
      }
      this.setState({
        activeItemsIndex: newActiveItems,
      });
    };

    let notes: ReactNode = null;

    if (sliceData.primary?.notes?.html) {
      notes = (
        <div
          className="section-notes"
          dangerouslySetInnerHTML={{
            __html: sliceData.primary.notes.html || '',
          }}
        />
      );
    }

    const plansRendering = isMobile ? (
      <MobileComparativeTable
        plansData={plansData}
        uniqueSpecs={uniqueSpecs}
        activeItemsIndex={this.state.activeItemsIndex}
        elementIdPrefix={this.state.elementIdPrefix}
        setActiveItem={setActiveItem}
        {...this.props}
      />
    ) : (
      <DesktopComparativeTable
        plansData={plansData}
        uniqueSpecs={uniqueSpecs}
        activeItemsIndex={this.state.activeItemsIndex}
        elementIdPrefix={this.state.elementIdPrefix}
        setActiveItem={setActiveItem}
        {...this.props}
      />
    );

    return (
      <section className="container neo-comparative-table-section">
        <SectionTitle component={sliceData.primary?.section_title} />
        {plansRendering}
        {notes}
      </section>
    );
  }

  private formatPlansData = (items: Maybe<PrismicPageDataBodyComparativeTableItem>[]): IPlanData[] => {
    const plansData: IPlanData[] = items.map((value) => {
      let planData: IPlanData = {};
      if (value?.plan?.document) {
        const doc = value.plan.document as PrismicPlan;
        const planImage = doc.data.plan_image ?? doc.data.image;
        let planSpecs: IPlanSpecificationData[] = [];
        let isBestValue = false;

        if (doc.data.specs) {
          planSpecs = doc.data.specs.map((spec) => {
            let bgColor = 'white';
            let specName: string | undefined;
            let specValue: PrismicStructuredTextType | undefined;
            let specDescription: PrismicStructuredTextType | undefined;
            isBestValue = doc.data.best_value === 'Yes';

            if (spec?.spec_type?.document) {
              const specDoc = spec.spec_type.document as PrismicTaxonomyPlanSpecType;
              specName = specDoc.data.name ?? undefined;

              switch (specDoc.data.add_on_background_color) {
                case 'white':
                default:
                  bgColor = 'white';
                  break;

                case 'color1 (lighter blue)':
                  bgColor = 'caa-lightgray';
                  break;

                case 'color2 (medium blue)':
                  bgColor = 'caa-washoutblue';
                  break;

                case 'color3 (dark blue)':
                  bgColor = 'caa-medblue';
                  break;

                case 'color4 (darker blue)':
                  bgColor = 'caa-blue';
                  break;
              }

              if (specDoc.data?.description) {
                specDescription = specDoc.data.description;
              }
              if (specDoc.data.name) {
                this.allPlansSpecs.push({
                  name: specName,
                  add_on_bg_color: bgColor,
                  description: specDescription,
                });
              }
            }

            if (spec?.value) {
              specValue = spec.value;
            }

            return {
              name: specName,
              add_on_bg_color: bgColor,
              value: specValue,
              description: specDescription,
            };
          });
        }

        planData = {
          image: planImage,
          plan_name: doc.data.plan_name,
          best_value: isBestValue,
          price_per_month: doc.data.price_per_month,
          promo_price_per_month: doc.data.promo_price_per_month,
          price_per_month_label: doc.data.price_per_month_label,
          price_per_year: doc.data.price_per_year,
          promo_price_per_year: doc.data.promo_price_per_year,
          button_label: doc.data.button_label,
          button_link_dest: doc.data.button_link_dest,
          specs: planSpecs,
        };
      }

      return planData;
    });
    return plansData;
  };
}

export interface ComparativeTableSliceProps {
  sliceData: PrismicPageDataBodyComparativeTable;
  pageData?: PrismicPage;
  activeItemsIndex?: number[];
  elementIdPrefix?: string;
  setActiveItem?: (currentIndex: number, event: React.MouseEvent<HTMLAnchorElement | HTMLButtonElement>) => void;
}

interface ComparativeTableSliceState {
  isMobile: boolean;
  activeItemsIndex: number[];
  elementIdPrefix: string;
}

export interface IPlanData {
  index?: number;
  image?: PrismicPlanDataImageImageType | PrismicPlanDataPlanImageImageType | null;
  best_value?: boolean;
  plan_name?: string | null;
  price_per_month?: string | null;
  promo_price_per_month?: string | null;
  price_per_month_label?: string | null;
  price_per_year?: string | null;
  promo_price_per_year?: string | null;
  button_label?: string | null;
  button_link_dest?: PrismicLinkType | null;
  specs?: IPlanSpecificationData[] | null;
}

export interface IPlanSpecificationData extends IPlanSpecification {
  value?: PrismicStructuredTextType | null;
}

export interface IPlanSpecification {
  name?: string | null;
  add_on_bg_color: string;
  description?: PrismicStructuredTextType | null;
}

export interface IComparativeTable {
  plansData?: IPlanData[];
  uniqueSpecs?: IPlanSpecification[];
}

// noinspection JSUnusedGlobalSymbols
export const SliceComparativeTableFragment = graphql`
  fragment SliceComparativeTableFragment on PrismicPageDataBodyComparativeTable {
    id
    slice_type
    primary {
      section_title {
        html
      }
      table_instruction
      notes {
        html
      }
    }
    items {
      plan {
        document {
          ... on PrismicPlan {
            id
            data {
              plan_image {
                alt
                url
                gatsbyImageData
              }
              image {
                alt
                url
                gatsbyImageData
              }
              plan_name
              best_value
              price_per_month
              promo_price_per_month
              price_per_month_label
              price_per_year
              promo_price_per_year
              button_label
              button_link_dest {
                url
                target
                link_type
              }
              specs {
                spec_type {
                  id
                  document {
                    ... on PrismicTaxonomyPlanSpecType {
                      id
                      data {
                        name
                        add_on_background_color
                        description {
                          html
                          text
                          richText
                        }
                      }
                    }
                  }
                }
                value {
                  html
                }
              }
            }
          }
        }
      }
    }
  }
`;
