import React, { Component, ReactNode } from 'react';
import { PrismicPage, PrismicPageDataBodyHeroImage } from '../../graphql';
import PrismicImage from '../controls/PrismicImage';
import { graphql } from 'gatsby';

export const HeroImageSliceKey = 'hero_image';
export default class HeroImageSlice extends Component<HeroImageSliceProps> {
  render(): ReactNode {
    const { sliceData } = this.props;

    return (
      <div
        className={`container-limited neo-hero-image ${
          sliceData?.primary?.use_curve === 'yes' ? 'has-neo-arc has-no-arc-mobile' : ''
        }`}
      >
        <PrismicImage image={sliceData.primary!.image!} style={{ position: 'absolute' }} srcIsFixed />
      </div>
    );
  }
}

export interface HeroImageSliceProps {
  sliceData: PrismicPageDataBodyHeroImage;
  pageData?: PrismicPage;
}

// noinspection JSUnusedGlobalSymbols
export const SliceHeroImageFragment = graphql`
  fragment SliceHeroImageFragment on PrismicSlicesHeroImage {
    ... on PrismicPageDataBodyHeroImage {
      id
      slice_type
      primary {
        use_curve
        image {
          alt
          url
          gatsbyImageData(layout: FULL_WIDTH)
        }
      }
    }
    ... on PrismicErrorPageDataBodyHeroImage {
      id
      slice_type
      primary {
        use_curve
        image {
          alt
          url
          gatsbyImageData(layout: FULL_WIDTH)
        }
      }
    }
    ... on PrismicEmailSubscriptionPageDataBodyHeroImage {
      id
      slice_type
      primary {
        use_curve
        image {
          alt
          url
          gatsbyImageData(layout: FULL_WIDTH)
        }
      }
    }
    ... on PrismicGaragesSearchPageDataBodyHeroImage {
      id
      slice_type
      primary {
        use_curve
        image {
          alt
          url
          gatsbyImageData(layout: FULL_WIDTH)
        }
      }
    }
    ... on PrismicMerchandiseSearchPageDataBodyHeroImage {
      id
      slice_type
      primary {
        use_curve
        image {
          alt
          url
          gatsbyImageData(layout: FULL_WIDTH)
        }
      }
    }
    ... on PrismicSearchPageDataBodyHeroImage {
      id
      slice_type
      primary {
        use_curve
        image {
          alt
          url
          gatsbyImageData(layout: FULL_WIDTH)
        }
      }
    }
    ... on PrismicTravelAgentsSearchPageDataBodyHeroImage {
      id
      slice_type
      primary {
        use_curve
        image {
          alt
          url
          gatsbyImageData(layout: FULL_WIDTH)
        }
      }
    }
    ... on PrismicTravelDealsSearchPageDataBodyHeroImage {
      id
      slice_type
      primary {
        use_curve
        image {
          alt
          url
          gatsbyImageData(layout: FULL_WIDTH)
        }
      }
    }
    ... on PrismicTravelPartnersSearchPageDataBodyHeroImage {
      id
      slice_type
      primary {
        use_curve
        image {
          alt
          url
          gatsbyImageData(layout: FULL_WIDTH)
        }
      }
    }
    ... on PrismicTravelStoreSearchPageDataBodyHeroImage {
      id
      slice_type
      primary {
        use_curve
        image {
          alt
          url
          gatsbyImageData(layout: FULL_WIDTH)
        }
      }
    }
    ... on PrismicTravelTalksSearchPageDataBodyHeroImage {
      id
      slice_type
      primary {
        use_curve
        image {
          alt
          url
          gatsbyImageData(layout: FULL_WIDTH)
        }
      }
    }
    ... on PrismicTravelTripsSearchPageDataBodyHeroImage {
      id
      slice_type
      primary {
        use_curve
        image {
          alt
          url
          gatsbyImageData(layout: FULL_WIDTH)
        }
      }
    }
    ... on PrismicTravelStoreDataBodyHeroImage {
      id
      slice_type
      primary {
        use_curve
        image {
          alt
          url
          gatsbyImageData(layout: FULL_WIDTH)
        }
      }
    }
    ... on PrismicTravelTalkDataBodyHeroImage {
      id
      slice_type
      primary {
        use_curve
        image {
          alt
          url
          gatsbyImageData(layout: FULL_WIDTH)
        }
      }
    }
    ... on PrismicTravelTripDataBodyHeroImage {
      id
      slice_type
      primary {
        use_curve
        image {
          alt
          url
          gatsbyImageData(layout: FULL_WIDTH)
        }
      }
    }
    ... on PrismicTravelDealDataBodyHeroImage {
      id
      slice_type
      primary {
        use_curve
        image {
          alt
          url
          gatsbyImageData(layout: FULL_WIDTH)
        }
      }
    }
    ... on PrismicMerchandiseDataBodyHeroImage {
      id
      slice_type
      primary {
        use_curve
        image {
          alt
          url
          gatsbyImageData(layout: FULL_WIDTH)
        }
      }
    }
  }
`;
