import {
  PrismicMerchandise,
  PrismicRewardsOffer,
  PrismicRewardsPartner,
  PrismicSearchPage,
  PrismicSearchPageDataBodySearchEnginePlaceholder,
  PrismicTravelDeal,
  PrismicTravelStore,
  PrismicTravelTalk,
  PrismicTravelTrip,
  SiteSearchablePage,
  SiteSearchablePageConnection,
  SiteSearchIndex,
} from '../../../graphql';
import React, { Component, ReactNode } from 'react';
import WithGlobalResources, { InjectedGlobalResourcesProps } from '../../higher-order/WithGlobalResources';
import { parse as parseQueryString, stringify as toQueryString } from 'querystring';
import { debounce } from '@github/mini-throttle';
import { MakeRandomId } from '../../../utils/RandomId';
import PrismicStructuredText from '../../controls/PrismicStructuredText';
import ContentListing, { ContentListingItem } from '../../controls/ContentListing';
import { ToMoment } from '../../../utils/PrismicHelpers';
import moment from 'moment-timezone';
import PrismicLink from '../../controls/PrismicLink';
import KeyValueList from '../../controls/KeyValueList';
import { StoreHours } from './TravelStoreContentSlice';
import { Moment } from 'moment-timezone/moment-timezone';
import { Index, SearchResults as LunrSearchResults } from 'elasticlunr';
import { IsBrowserEnvironment } from '../../../utils/BrowserHelpers';
import SectionTitle from '../../controls/SectionTitle';

export const SearchPageSearchEngineSliceKey = '!internal_search_page_search_engine_slice';

const SearchPageSearchEngineSlice = WithGlobalResources(
  class SearchPageSearchEngineSliceImpl extends Component<
    SearchPageSearchEngineSliceProps & InjectedGlobalResourcesProps,
    SearchPageSearchEngineSliceState
  > {
    private readonly inputRef: React.RefObject<HTMLInputElement> = React.createRef();

    private readonly resultsRef: React.RefObject<HTMLDivElement> = React.createRef();

    private animationFrameCancelToken: number | undefined;
    private searchFn?: SearchFn;
    private readonly maxResultsPerPage: number = 20;
    private readonly paginationSpread: number = 2;

    constructor(props: Readonly<SearchPageSearchEngineSliceProps & InjectedGlobalResourcesProps>) {
      super(props);

      this.state = {
        elementIdPrefix: MakeRandomId('SearchPageSearchEngine', 10),
        query: '',
        executedQuery: '',
        results: [],
        page: 1,
      };
    }

    componentDidMount(): void {
      if (IsBrowserEnvironment && window.location.hash) {
        const hashQuery = parseQueryString(window.location.hash.replace(/^[?]/, ''));
        if (typeof hashQuery.q === 'string') {
          this.executeSearchQuery(hashQuery.q);
        }
      }

      if (this.inputRef.current) {
        this.inputRef.current.focus();
      }
    }

    componentDidUpdate(
      _prevProps: Readonly<SearchPageSearchEngineSliceProps & InjectedGlobalResourcesProps>,
      prevState: Readonly<SearchPageSearchEngineSliceState>,
      _snapshot?: any
    ): void {
      if (prevState.query !== this.state.query) {
        this.notifyNewSearchQuery();
      }

      if (prevState.executedQuery !== this.state.executedQuery) {
        this.updateUrlHashQuery();
      }
    }

    render(): ReactNode {
      const { sliceData } = this.props;
      const { elementIdPrefix } = this.state;

      return (
        <div className="container neo-site-search">
          <SectionTitle component={sliceData.primary?.section_title} />

          <div className="columns is-variable is-4 neo-site-search-controls">
            <div className="column">
              <div className="field">
                <label
                  className="label"
                  htmlFor={`${elementIdPrefix}__search__input`}
                  id={`${elementIdPrefix}__search__label`}
                >
                  {sliceData?.primary?.search_field_label}
                </label>
                <div className="control">
                  <input
                    className="input"
                    type="text"
                    id={`${elementIdPrefix}__search__input`}
                    aria-labelledby={`${elementIdPrefix}__search__label`}
                    placeholder={sliceData?.primary?.search_field_placeholder || undefined}
                    ref={this.inputRef}
                    value={this.state.query}
                    onChange={this.onSearchInputChange}
                  />
                </div>
              </div>
            </div>
          </div>

          {this.renderResults()}
        </div>
      );
    }

    renderResults(): ReactNode {
      const { sliceData, globalResources } = this.props;
      const { elementIdPrefix, results, page, executedQuery } = this.state;

      if (!results?.length) {
        if (executedQuery.trim() === '') {
          return null;
        }

        return (
          <div className="content has-text-centered">
            <PrismicStructuredText text={sliceData?.primary?.no_results_found_content} />
          </div>
        );
      }

      const pagedResults = results.slice(this.maxResultsPerPage * (page - 1), this.maxResultsPerPage * page);

      const contentListingItems = pagedResults.map((item) => {
        const clItem: ContentListingItem = {
          link: {
            url: item.document?.path,
            uid: item.document?.uid,
            link_type: 'Document',
          },
          title: item.document.title,
          description: item.document.description,
          image: item.document.image,
          keyValuePairs: [],
          dataAttributes: {
            score: item.score,
          },
        };

        switch (item.document.parentType) {
          case 'PrismicRewardsOffer':
            {
              const rewardsOffers = (item.document.parent || undefined) as PrismicRewardsOffer;
              clItem.title = rewardsOffers?.data?.item_title;
              clItem.description = rewardsOffers?.data?.offer_description?.text || '';
              clItem.image = rewardsOffers?.data?.offer_image;
              clItem.link = {
                url: rewardsOffers?.data?.partner_portal_link?.url,
                link_type: 'Web',
                target: rewardsOffers?.data?.partner_portal_link?.target,
              };
            }
            break;

          case 'PrismicRewardsPartner':
            {
              const rewardsPartner = (item.document.parent || undefined) as PrismicRewardsPartner;
              clItem.title = rewardsPartner?.data?.partner_name;
              clItem.description = rewardsPartner?.data?.partner_tag_line || '';
              clItem.image = rewardsPartner?.data?.partner_logo;
              clItem.link = {
                url: rewardsPartner?.data?.partner_portal_link?.url,
                link_type: 'Web',
                target: rewardsPartner?.data?.partner_portal_link?.target,
              };
            }
            break;

          case 'PrismicMerchandise':
            {
              const merchandise = (item.document.parent || undefined) as PrismicMerchandise;

              let merchHasSale = false;
              let merchSaleDate: Moment | undefined;

              if (merchandise?.data?.member_price) {
                if (merchandise?.data?.sale_price) {
                  merchSaleDate = ToMoment(merchandise?.data?.sale_expiry);
                  merchHasSale = !!(!merchSaleDate || merchSaleDate?.isAfter(moment(), 'day'));
                }

                if (merchHasSale) {
                  clItem.keyValuePairs!.push({
                    key: globalResources.merchandise_page_sale_price_header || '',
                    keyClassNames: 'is-narrow has-text-weight-semibold has-text-caa-red',
                    value: (
                      <React.Fragment>
                        <span className={'has-text-caa-red'}>{merchandise?.data?.sale_price}</span>
                        {' ' /* en space */}
                        <span
                          className={'has-text-caa-gray is-size-7 has-text-strikethrough'}
                          aria-label={globalResources.merchandise_page_sale_regular_price_sr_hint || undefined}
                        >
                          {merchandise?.data?.member_price}
                        </span>
                      </React.Fragment>
                    ),
                  });
                } else {
                  clItem.keyValuePairs!.push({
                    key: globalResources.merchandise_page_member_price_header || '',
                    value: merchandise?.data?.member_price,
                  });
                }
              }

              if (merchandise?.data?.non_member_price) {
                clItem.keyValuePairs!.push({
                  key: globalResources.merchandise_page_non_member_price_header || '',
                  value: merchandise?.data?.non_member_price,
                });
              }
            }
            break;

          case 'PrismicTravelDeal':
            {
              const travelDeal = (item.document.parent || undefined) as PrismicTravelDeal;

              if (travelDeal?.data?.deal_expiry) {
                const expiryTime = ToMoment(travelDeal?.data?.deal_expiry);

                if (expiryTime) {
                  const formattedDate = expiryTime.format('MMMM Do, YYYY');

                  clItem.keyValuePairs!.push({
                    key: globalResources.travel_deal_page_expiry_header || '',
                    value: formattedDate,
                  });
                }
              }
            }
            break;

          case 'PrismicTravelStore':
            {
              const travelStore = (item.document.parent || undefined) as PrismicTravelStore;

              if (travelStore?.data?.address) {
                clItem.keyValuePairs!.push({
                  key: globalResources.travel_store_location_header || '',
                  value: travelStore?.data?.address,
                });
              }

              const validOpeningHours = (travelStore?.data?.opening_hours || []).filter(
                (o) => o?.hours_label
              ) as StoreHours[];

              if (validOpeningHours.length) {
                clItem.keyValuePairs!.push({
                  key: globalResources.travel_store_store_hours_header || '',
                  value: (
                    <KeyValueList
                      keyColumnClassNames={'is-narrow is-italic'}
                      keyValues={validOpeningHours.map((hour) => ({
                        key: hour.hours_label || '',
                        value: hour.hours_value,
                      }))}
                    />
                  ),
                });
              }
            }
            break;

          case 'PrismicTravelTalk':
            {
              const travelTalk = (item.document.parent || undefined) as PrismicTravelTalk;
              const talkTravelStore = travelTalk?.data?.caa_travel_store?.document as
                | PrismicTravelStore
                | undefined
                | null;

              if (talkTravelStore?.id) {
                clItem.keyValuePairs!.push({
                  key: globalResources.travel_talk_page_location_header || '',
                  value: (
                    <React.Fragment>
                      <PrismicLink to={talkTravelStore}>{talkTravelStore.data?.page_title}</PrismicLink>
                      {talkTravelStore.data?.address ? ` – ${talkTravelStore.data?.address}` : ''}
                    </React.Fragment>
                  ),
                });
              }

              if (travelTalk?.data?.event_start_time) {
                const startTime = ToMoment(travelTalk?.data?.event_start_time);

                if (startTime) {
                  let endTime: moment.Moment | undefined = undefined;
                  let requireSeparateEndDateEntry = false;

                  let formattedDate = startTime.format('dddd, MMMM Do, YYYY - h:mm A');

                  if (travelTalk?.data?.event_end_time) {
                    endTime = ToMoment(travelTalk?.data?.event_end_time);

                    if (endTime) {
                      if (startTime.format('YYYY-MM-DD') === endTime.format('YYYY-MM-DD')) {
                        formattedDate += ` to ${endTime.format('h:mm A')}`;
                      } else {
                        requireSeparateEndDateEntry = true;
                      }
                    }
                  }

                  clItem.keyValuePairs!.push({
                    key: globalResources.travel_talk_page_start_date_header || '',
                    value: formattedDate,
                  });
                  if (requireSeparateEndDateEntry && endTime) {
                    clItem.keyValuePairs!.push({
                      key: globalResources.travel_talk_page_end_date_header || '',
                      value: endTime.format('dddd, MMMM Do, YYYY - h:mm A'),
                    });
                  }
                }
              }
            }
            break;

          case 'PrismicTravelTrip':
            {
              const travelTrip = (item.document.parent || undefined) as PrismicTravelTrip;
              if (travelTrip?.data?.date_display_override) {
                clItem.keyValuePairs!.push({
                  key: globalResources.travel_trip_page_date_header || '',
                  value: travelTrip?.data?.date_display_override,
                });
              } else if (travelTrip?.data?.precise_date_start) {
                const startDate = ToMoment(travelTrip?.data?.precise_date_start);
                const endDate = ToMoment(travelTrip?.data?.precise_date_end);

                if (startDate) {
                  let formattedDate = startDate.format('MMMM Do, YYYY');

                  if (endDate) {
                    if (endDate.format('YYYY-MM-DD') === startDate.format('YYYY-MM-DD')) {
                      // Nothing to do
                    } else if (endDate.format('YYYY-MM') === startDate.format('YYYY-MM')) {
                      formattedDate = `${startDate.format('MMMM Do')} – ${endDate.format('Do, YYYY')}`;
                    } else if (endDate.format('YYYY') === startDate.format('YYYY')) {
                      formattedDate = `${startDate.format('MMMM Do')} – ${endDate.format('MMMM Do, YYYY')}`;
                    } else {
                      formattedDate = `${startDate.format('MMMM Do, YYYY')} – ${endDate.format('MMMM Do, YYYY')}`;
                    }
                  }

                  clItem.keyValuePairs!.push({
                    key: globalResources.travel_trip_page_date_header || '',
                    value: formattedDate,
                  });
                }
              }
              if (travelTrip?.data?.price) {
                clItem.keyValuePairs!.push({
                  key: globalResources.travel_trip_page_price_header || '',
                  value: travelTrip?.data?.price,
                });
              }
            }
            break;

          default:
            break;
        }

        return clItem;
      });

      return (
        <div className="neo-site-search-results" id={`${elementIdPrefix}__results`} ref={this.resultsRef}>
          <h2>{sliceData?.primary?.results_title}</h2>
          <p>
            {(
              (results.length === 1
                ? sliceData?.primary?.singular_result_label
                : sliceData?.primary?.plural_results_label) || ''
            ).replace('{num}', `${results.length}`)}
          </p>

          <ContentListing items={contentListingItems} keyColumnClassNames="is-narrow has-text-weight-semibold" />

          {this.renderPagination()}
        </div>
      );
    }

    renderPagination(): ReactNode {
      const { sliceData } = this.props;
      const { results, page } = this.state;

      if (results.length <= this.maxResultsPerPage) {
        return null;
      }

      const maxPages = Math.ceil(results.length / this.maxResultsPerPage);

      let spreadMinPage = page - this.paginationSpread;
      let spreadMaxPage = page + this.paginationSpread;

      if (spreadMaxPage > maxPages) {
        spreadMaxPage = maxPages;
        spreadMinPage = spreadMaxPage - this.paginationSpread * 2;
      }

      if (spreadMinPage < 1) {
        spreadMinPage = 1;

        if (spreadMaxPage !== maxPages) {
          spreadMaxPage = Math.min(maxPages, 1 + this.paginationSpread * 2);
        }
      }

      const MakePageButton = (pageNum: number) => {
        return (
          <button
            className={`pagination-link${pageNum === page ? ' is-current' : ''}`}
            key={pageNum}
            aria-label={sliceData?.primary?.page_label?.replace('{pageNum}', `${pageNum}`)}
            onClick={() => this.navigateToPage(pageNum)}
          >
            {pageNum}
          </button>
        );
      };

      const paginationListItems: ReactNode[] = [];

      if (spreadMinPage !== 1) {
        paginationListItems.push(MakePageButton(1));
        paginationListItems.push(
          <li key={'first-ellip'}>
            <span className="pagination-ellipsis">&hellip;</span>
          </li>
        );
      }

      for (let i = spreadMinPage; i <= spreadMaxPage; i += 1) {
        paginationListItems.push(MakePageButton(i));
      }

      if (spreadMaxPage !== maxPages) {
        paginationListItems.push(
          <li key={'last-ellip'}>
            <span className="pagination-ellipsis">&hellip;</span>
          </li>
        );
        paginationListItems.push(MakePageButton(maxPages));
      }

      return (
        <nav className="pagination is-right" role="navigation" aria-label="pagination">
          <button className="pagination-previous" disabled={page === 1} onClick={() => this.navigateToPage(page - 1)}>
            {sliceData.primary?.previous_page_label}
          </button>
          <button
            className="pagination-next"
            disabled={page === maxPages}
            onClick={() => this.navigateToPage(page + 1)}
          >
            {sliceData.primary?.next_page_label}
          </button>
          <ul className="pagination-list">{paginationListItems}</ul>
        </nav>
      );
    }

    onSearchInputChange = () => {
      if (!this.inputRef.current) {
        return;
      }

      this.setState({
        query: this.inputRef.current.value,
      });
    };

    navigateToPage = (page: number) => {
      const { results } = this.state;

      if (results.length === 0 || page < 1) {
        return;
      }

      const maxPages = Math.ceil(results.length / this.maxResultsPerPage);

      if (page > maxPages) {
        return;
      }

      this.setState({
        page,
      });

      if (this.resultsRef.current?.scrollIntoView) {
        this.resultsRef.current.scrollIntoView(true);
      }
    };

    updateUrlHashQuery = () => {
      const { executedQuery } = this.state;

      if (!executedQuery || executedQuery.trim() === '') {
        window.history.replaceState(null, '', window.location.pathname);
        return;
      }

      const fragmentString = `?${toQueryString({
        q: executedQuery,
      })}`;

      window.history.replaceState(null, '', window.location.pathname + fragmentString);
    };

    executeSearchQuery = (forceQuery: string | undefined = undefined) => {
      const { query, executedQuery } = this.state;

      if (!forceQuery && query === executedQuery) {
        return;
      }

      if (this.animationFrameCancelToken !== undefined) {
        window.cancelAnimationFrame(this.animationFrameCancelToken);
      }

      this.animationFrameCancelToken = window.requestAnimationFrame(() => {
        if (!this.searchFn) {
          this.prepareSearchFunction();
        }

        const searchResults = this.searchFn!(forceQuery ? forceQuery : query);

        if (forceQuery) {
          this.setState({
            query: forceQuery,
            executedQuery: forceQuery,
            results: searchResults,
            page: 1,
          });
        } else {
          this.setState({
            executedQuery: query,
            results: searchResults,
            page: 1,
          });
        }
      });
    };

    prepareSearchFunction = () => {
      if (!this.props.sliceData.additionalQueryData?.siteSearchIndex?.index) {
        this.searchFn = () => [];
        return;
      }

      const index = Index.load<SiteSearchIndexDocument>(
        this.props.sliceData.additionalQueryData?.siteSearchIndex?.index
      );

      const searchablePageMap: SiteSearchablePageMap = {};

      (this.props.sliceData.additionalQueryData?.allSiteSearchablePage?.nodes || []).forEach((value) => {
        if (value.id) {
          searchablePageMap[value.id!] = value;
        }
      });

      this.searchFn = (query: string) => {
        const normalizedQuery = query.replace(/([\s]+)/g, ' ').trim();

        if (normalizedQuery === '') {
          return [];
        }

        return index
          .search(query, {
            fields: {
              title: { boost: 4, expand: true, bool: 'AND' },
              body: { boost: 2, expand: true, bool: 'AND' },
              description: { boost: 1, expand: true, bool: 'AND' },
            },
          })
          .map((result) => {
            return {
              ...result,
              document: searchablePageMap[result.ref],
            } as SearchResult;
          })
          .filter((result) => result.document);
      };
    };

    // tslint:disable-next-line:member-ordering
    notifyNewSearchQuery = debounce(() => {
      this.executeSearchQuery();
    }, 250);
  }
);
export default SearchPageSearchEngineSlice;

export interface SearchPageSearchEngineSliceProps {
  sliceData: PrismicSearchPageBodySearchEnginePlaceholderWithSearchData;
  pageData?: PrismicSearchPage;
}

export interface PrismicSearchPageBodySearchEnginePlaceholderWithSearchData
  extends PrismicSearchPageDataBodySearchEnginePlaceholder {
  additionalQueryData?: {
    siteSearchIndex?: SiteSearchIndex;
    allSiteSearchablePage?: SiteSearchablePageConnection;
  };
}

interface SearchPageSearchEngineSliceState {
  elementIdPrefix: string;
  query: string;
  executedQuery: string;
  results: SearchResult[];
  page: number;
}

interface SearchResult extends LunrSearchResults {
  document: SiteSearchablePage;
}

interface SiteSearchablePageMap {
  [key: string]: SiteSearchablePage;
}

type SiteSearchIndexDocument = Pick<SiteSearchablePage, 'id' | 'title' | 'body' | 'description'>;

type SearchFn = (query: string) => SearchResult[];
