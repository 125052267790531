import React, { Component, ReactNode } from 'react';
import PrismicLink from '../controls/PrismicLink';
import PrismicImage from '../controls/PrismicImage';
import WithGlobalResources, {
  InjectedGlobalResourcesProps,
} from '../higher-order/WithGlobalResources';
import {
  PrismicAllDocumentTypes,
  PrismicLinkType,
} from '../../graphql';

const ImageLinkCompactTile = WithGlobalResources(
  class ImageLinkTilesImpl extends Component<
    InjectedGlobalResourcesProps & IImageLinkCompactTileData
  > {
    render() {
      let image: ReactNode = undefined;
      let text: ReactNode = undefined;

      if (this.props.image) {
        image = <PrismicImage image={this.props.image} />;
      }
      if (this.props.text) {
        text = (
          <div className="tile-text">
            <p>{this.props.text}</p>
          </div>
        );
      }
      return (
        <PrismicLink to={this.props.link_dest!} className="image-link-compact">
          {image}
          <div className="tile-content">{text}</div>
        </PrismicLink>
      );
    }
  }
);
export default ImageLinkCompactTile;

export interface IImageLinkCompactTileData {
  image?: any;
  text?: string | null;
  link_dest?: PrismicLinkType | PrismicAllDocumentTypes | null;
}
