import React, { Component, ComponentProps, ReactNode } from 'react';
import WithGlobalResources, {
  InjectedGlobalResourcesProps,
} from '../higher-order/WithGlobalResources';
import PrismicImage from './PrismicImage';
import PrismicLink, { PrismicLinkFragment } from './PrismicLink';
import { PrismicStructuredTextType } from '../../graphql';

const MembershipTile = WithGlobalResources(
  class MembershipTilesImpl extends Component<
    InjectedGlobalResourcesProps & IMembershipTileData
  > {
    render() {
      const itemData = this.props;
      const content: ReactNode[] = [];
      let image: ReactNode = undefined;
      let isClickable = false;

      if (itemData.image) {
        image = (
          <div className={'membership-image-padding-box'}>
            <PrismicImage
              imgStyle={{ objectFit: 'contain' }}
              image={itemData.image}
            />
          </div>
        );
      }
      if (itemData.membership_title?.html) {
        content.push(
          <div
            key="tile-title"
            className="tile-title"
            dangerouslySetInnerHTML={{
              __html: itemData.membership_title.html,
            }}
          />
        );
      }

      if (itemData.feature_desc) {
        let featureTitle: ReactNode = (
          <div className="feature-title">Towing up to</div>
        );
        if (itemData.feature_title) {
          featureTitle = (
            <div className="feature-title">{itemData.feature_title}</div>
          );
        }

        content.push(
          <div key="feature" className={'feature'}>
            {featureTitle}
            <div className="feature-desc">{itemData.feature_desc}</div>
          </div>
        );
      }

      if (itemData.membership_short_desc) {
        content.push(
          <div key="short-desc" className="short-desc">
            {itemData.membership_short_desc}
          </div>
        );
      }

      if (itemData.button_link_dest?.url || itemData.button_link_dest?.uid) {
        let buttonLabel = 'Buy Now';
        if (itemData.button_label) {
          buttonLabel = itemData.button_label;
        }
        content.push(
          <PrismicLink
            key="button"
            to={itemData.button_link_dest}
            className="neo-red-btn"
          >
            {buttonLabel}
          </PrismicLink>
        );
      }

      if (itemData.membership_specs?.html && itemData.showSpecs) {
        content.push(
          <div
            key="tile-text"
            className="tile-text content"
            dangerouslySetInnerHTML={{
              __html: itemData.membership_specs.html,
            }}
          />
        );
      }

      if (
        (itemData.link_dest?.url || itemData.link_dest?.uid) &&
        itemData.showTileCTA
      ) {
        isClickable = true;
        content.push(
          <PrismicLink key="cta" to={itemData.link_dest} className={'cta'}>
            {itemData.link_cta || itemData.globalResources.default_cta_text}
          </PrismicLink>
        );
      }

      const tileClasses = isClickable
        ? 'image-link-tile membership-tile clickable'
        : 'image-link-tile membership-tile';

      return (
        <div key="membership-tile" className={tileClasses}>
          <div className={'image-link-tile-interior'}>
            {image}
            <div className="tile-content">{content}</div>
          </div>
        </div>
      );
    }
  }
);
export default MembershipTile;

export interface IMembershipTileData {
  index?: number;
  image?: ComponentProps<typeof PrismicImage>['image'];
  membership_title?: PrismicStructuredTextType | null;
  feature_title?: string | null;
  feature_desc?: string | null;
  membership_short_desc?: string | null;
  button_label?: string | null;
  button_link_dest?: PrismicLinkFragment | null;
  membership_specs?: PrismicStructuredTextType | null;
  link_cta?: string | null;
  link_dest?: PrismicLinkFragment | null;
  showSpecs?: boolean;
  showTileCTA?: boolean;
}
