import {
  Maybe,
  PrismicLinkType,
  Scalars,
} from '../graphql';
import moment from 'moment-timezone';

export const IsValidPrismicLink = (
  data: PrismicLinkType | undefined | null
): data is PrismicLinkType => {
  if (data?.link_type === `Document` && !data?.uid) {
    return false;
  }

  return !!(data?.url || data?.uid);
};

export const ToMoment = (
  date: Maybe<Scalars['Date']> | undefined
): moment.Moment | undefined => {
  if (!date) {
    return undefined;
  }

  return moment(date).tz(SiteTz);
};

export const SiteTz = 'America/Toronto';
