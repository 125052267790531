import React, { Component, ReactNode } from 'react';
import { graphql, PageProps } from 'gatsby';
import {
  withPrismicPreview,
  WithPrismicPreviewProps,
} from 'gatsby-plugin-prismic-previews';

import Layout from '../components/Layout';
import {
  Maybe,
  PrismicMerchandiseSearchPage,
  PrismicMerchandiseSearchPageDataBodySlicesType,
  PrismicPageDataBodySlicesType,
} from '../graphql';
import SEO from '../components/controls/SEO';
import PageSlicesRender from '../components/controls/PageSlicesRender';
import WithGlobalResources, {
  InjectedGlobalResourcesProps,
} from '../components/higher-order/WithGlobalResources';
import { MerchandiseSearchSliceKey } from '../components/slices/internal/MerchandiseSearchSlice';
import NotFoundPage from './NotFoundPage';

type SlicesTypes = Maybe<
  PrismicPageDataBodySlicesType | PrismicMerchandiseSearchPageDataBodySlicesType
>;

type MerchandiseSearchPageTemplateProps = {
  data: {
    prismicMerchandiseSearchPage: PrismicMerchandiseSearchPage;
  };
} & InjectedGlobalResourcesProps &
  WithPrismicPreviewProps<PrismicMerchandiseSearchPage> &
  PageProps<PrismicMerchandiseSearchPage>;

class MerchandiseSearchPageTemplate extends Component<MerchandiseSearchPageTemplateProps> {
  render() {
    const pageNode = this.props?.data?.prismicMerchandiseSearchPage;
    const pageComponents: ReactNode[] = [];

    if (!pageNode) {
      return <NotFoundPage location={this.props.location} />;
    }

    pageComponents.push(
      <SEO
        key="page-seo-item"
        title={pageNode.data?.page_title ?? ''}
        description={pageNode.data?.share_description ?? undefined}
        fbImage={
          pageNode.data?.og_image?.localFile?.childImageSharp?.gatsbyImageData
        }
        slug={pageNode.url ?? undefined}
        titleTemplate={
          pageNode.data?.use_page_title_template === 'no'
            ? '{pageTitle}'
            : undefined
        }
        meta={
          pageNode.data?.indexable === 'no'
            ? [
                {
                  name: `robots`,
                  content: `noindex`,
                },
              ]
            : undefined
        }
      />
    );

    const normalizedPageNodes: SlicesTypes[] = [];

    if (pageNode?.data?.body?.length) {
      for (const slice of pageNode.data.body) {
        if (slice?.slice_type === 'search_engine_placeholder') {
          normalizedPageNodes.push({
            ...slice,
            slice_type: MerchandiseSearchSliceKey,
          } as PrismicPageDataBodySlicesType);
          continue;
        }

        normalizedPageNodes.push(slice);
      }
    }

    pageComponents.push(
      <PageSlicesRender
        key="slices"
        body={normalizedPageNodes}
        pageNode={pageNode}
      />
    );

    return <Layout location={this.props.location}>{pageComponents}</Layout>;
  }
}

export default withPrismicPreview(
  WithGlobalResources(MerchandiseSearchPageTemplate)
);

export const pageQuery = graphql`
  query PrismicMerchandiseSearchPageQuery($nodeId: String!) {
    prismicMerchandiseSearchPage(id: { eq: $nodeId }) {
      _previewable
      id
      url
      data {
        page_title
        share_description
        share_title
        indexable
        og_image {
          alt
          url
          gatsbyImageData(
            width: 1200
            height: 630
            backgroundColor: "rgba(255,255,255,1)"
            placeholder: NONE
            layout: FIXED
          )
        }
        use_page_title_template
        body {
          ... on PrismicMerchandiseSearchPageDataBodyBreadcrumbs {
            ...SliceBreadcrumbsFragment
          }
          ... on PrismicMerchandiseSearchPageDataBodyCarousel {
            ...SliceCarouselFragment
          }
          ... on PrismicMerchandiseSearchPageDataBodyCollapsableContent {
            ...SliceCollapsableContentFragment
          }
          ... on PrismicMerchandiseSearchPageDataBodyHeroImage {
            ...SliceHeroImageFragment
          }
          ... on PrismicMerchandiseSearchPageDataBodyIconLinkList {
            ...SliceIconLinkListFragment
          }
          ... on PrismicMerchandiseSearchPageDataBodyImageLinkTiles {
            ...SliceImageLinkTilesFragment
          }
          ... on PrismicMerchandiseSearchPageDataBodyImageLinkCards {
            ...SliceImageLinkCardsFragment
          }
          ... on PrismicMerchandiseSearchPageDataBodyMarketingHero {
            ...SliceMarketingHeroFragment
          }
          ... on PrismicMerchandiseSearchPageDataBodyMultiColumnContent {
            ...SliceMultiColumnContentFragment
          }
          ... on PrismicMerchandiseSearchPageDataBodySearchEnginePlaceholder {
            id
            slice_type
            primary {
              section_title {
                html
              }
              travel_type_field_label
              merch_category_field_label
              sale_status_field_label
              sale_status_show_all_option
              sale_status_show_sales_only_options
              sort_price_field_label
              sort_price_asc_option
              sort_price_desc_option
              no_results_found_content {
                html
              }
            }
          }
          ... on PrismicMerchandiseSearchPageDataBodySimpleLinkTiles {
            ...SliceSimpleLinkTilesFragment
          }
          ... on PrismicMerchandiseSearchPageDataBodyTextContent {
            ...SliceTextContentFragment
          }
        }
      }
    }
  }
`;
