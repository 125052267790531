import React, { Component, ReactNode } from 'react';
import { graphql, PageProps } from 'gatsby';
import {
  withPrismicPreview,
  WithPrismicPreviewProps,
} from 'gatsby-plugin-prismic-previews';

import Layout from '../components/Layout';
import {
  Maybe,
  PrismicPageDataBodyBreadcrumbs,
  PrismicPageDataBodySlicesType,
  PrismicTravelTalk,
  PrismicTravelTalkDataBodySlicesType,
} from '../graphql';
import SEO from '../components/controls/SEO';
import PageSlicesRender from '../components/controls/PageSlicesRender';
import { BreadcrumbsSliceKey } from '../components/slices/BreadcrumbsSlice';
import { TravelTalkContentSliceKey } from '../components/slices/internal/TravelTalkContentSlice';
import WithGlobalResources, {
  InjectedGlobalResourcesProps,
} from '../components/higher-order/WithGlobalResources';
import NotFoundPage from './NotFoundPage';

type SlicesTypes = Maybe<
  PrismicPageDataBodySlicesType | PrismicTravelTalkDataBodySlicesType
>;

type TravelTalkTemplateProps = {
  data: {
    prismicTravelTalk: PrismicTravelTalk;
  };
} & InjectedGlobalResourcesProps &
  WithPrismicPreviewProps<PrismicTravelTalk> &
  PageProps<PrismicTravelTalk>;

class TravelTalkTemplate extends Component<TravelTalkTemplateProps> {
  render() {
    const { globalResources } = this.props;
    const pageNode = this.props?.data?.prismicTravelTalk;
    const pageComponents: ReactNode[] = [];

    if (!pageNode) {
      return <NotFoundPage location={this.props.location} />;
    }
    pageComponents.push(
      <SEO
        key="page-seo-item"
        title={pageNode.data?.page_title ?? ''}
        description={pageNode.data?.share_description ?? undefined}
        fbImage={
          pageNode?.data?.og_image?.localFile?.childImageSharp?.gatsbyImageData
        }
        slug={pageNode.url ?? undefined}
        titleTemplate={
          pageNode.data?.use_page_title_template === 'no'
            ? '{pageTitle}'
            : undefined
        }
        meta={
          pageNode.data?.indexable === 'no'
            ? [
                {
                  name: `robots`,
                  content: `noindex`,
                },
              ]
            : undefined
        }
      />
    );

    const normalizedPageNodes: SlicesTypes[] = [];

    normalizedPageNodes.push({
      slice_type: BreadcrumbsSliceKey,
      primary: {
        include_home: 'yes',
        include_self: 'yes',
      },
      items: globalResources?.travel_talk_page_breadcrumb || [],
    } as PrismicPageDataBodyBreadcrumbs);

    normalizedPageNodes.push({
      slice_type: TravelTalkContentSliceKey,
    } as PrismicPageDataBodySlicesType);

    if (pageNode?.data?.body?.length) {
      for (const slice of pageNode.data.body) {
        normalizedPageNodes.push(slice);
      }
    }

    pageComponents.push(
      <PageSlicesRender
        key="slices"
        body={normalizedPageNodes}
        pageNode={pageNode}
      />
    );

    return <Layout location={this.props.location}>{pageComponents}</Layout>;
  }
}

export default withPrismicPreview(WithGlobalResources(TravelTalkTemplate));

export const pageQuery = graphql`
  query PrismicTravelTalkQuery($nodeId: String!) {
    prismicTravelTalk(id: { eq: $nodeId }) {
      _previewable
      id
      url
      data {
        event_image {
          alt
          url
          gatsbyImageData(width: 960, layout: CONSTRAINED)
        }
        page_title
        share_description
        share_title
        indexable
        og_image {
          alt
          url
          gatsbyImageData(
            width: 1200
            height: 630
            backgroundColor: "rgba(255,255,255,1)"
            placeholder: NONE
            layout: FIXED
          )
        }
        use_page_title_template
        event_start_time
        event_end_time
        rsvp_link_dest {
          ...PrismicLinkFragment
        }
        event_description {
          html
        }
        caa_travel_store {
          document {
            ... on PrismicTravelStore {
              ...PrismicDocumentLinkFragment
              data {
                page_title
                address
                store_image {
                  alt
                  url
                  gatsbyImageData(width: 960, layout: CONSTRAINED)
                }
              }
            }
          }
          link_type
          target
          url
          uid
        }
        travel_types {
          travel_type {
            document {
              ... on PrismicTaxonomyTravelType {
                id
                data {
                  name
                }
              }
            }
          }
        }
        travel_partners {
          travel_partner {
            ...PrismicLinkFragment
            document {
              ...PrismicDocumentLinkFragment
              ... on PrismicTravelPartner {
                data {
                  page_title
                  partner_logo {
                    alt
                    url
                    gatsbyImageData(width: 960, layout: CONSTRAINED)
                  }
                }
              }
            }
          }
        }
        body {
          ... on PrismicTravelTalkDataBodyHeroImage {
            ...SliceHeroImageFragment
          }
          ... on PrismicTravelTalkDataBodyCollapsableContent {
            ...SliceCollapsableContentFragment
          }
          ... on PrismicTravelTalkDataBodyIconLinkList {
            ...SliceIconLinkListFragment
          }
          ... on PrismicTravelTalkDataBodyImageLinkCards {
            ...SliceImageLinkCardsFragment
          }
          ... on PrismicTravelTalkDataBodyImageLinkTiles {
            ...SliceImageLinkTilesFragment
          }
          ... on PrismicTravelTalkDataBodyMarketingHero {
            ...SliceMarketingHeroFragment
          }
          ... on PrismicTravelTalkDataBodyTextContent {
            ...SliceTextContentFragment
          }
          ... on PrismicTravelTalkDataBodyCarousel {
            ...SliceCarouselFragment
          }
          ... on PrismicTravelTalkDataBodySimpleLinkTiles {
            ...SliceSimpleLinkTilesFragment
          }
          ... on PrismicTravelTalkDataBodyMultiColumnContent {
            ...SliceMultiColumnContentFragment
          }
          ... on PrismicTravelTalkDataBodyYoutubeVideo {
            ...SliceYoutubeVideoFragment
          }
          ... on PrismicTravelTalkDataBodyTravelOffers {
            ...SliceTravelOffersFragment
          }
          ... on PrismicTravelTalkDataBodyContentListing {
            ...SliceContentListingFragment
          }
        }
      }
    }
  }
`;
