const { trim } = require('lodash');

exports.UidToSlug = uid => {
  if (uid === null || uid === undefined || uid === 'home') {
    return '/';
  }

  return `/${trim(
    uid.replace(/[^a-zA-Z0-9_-]/g, '').replace(/-{2,}/g, '/'),
    '-_/'
  )}/`;
};
