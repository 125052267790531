import {
  PrismicTravelPartner,
  PrismicTravelPartnersSearchPage,
  PrismicTravelPartnersSearchPageDataBodySearchEnginePlaceholder,
  PrismicTravelPartnerConnection,
} from '../../../graphql';
import React, { Component, ReactNode } from 'react';
import { graphql, StaticQuery } from 'gatsby';
import WithGlobalResources, { InjectedGlobalResourcesProps } from '../../higher-order/WithGlobalResources';
import TagBasedSearch, { NodeListToOptions, TagBasedSearchFieldsConfig } from '../../controls/TagBasedSearch';
import WithTravelPartnerData, { InjectedTravelPartnerDataProps } from '../../higher-order/WithTravelPartnerData';
import TravelPartner from '../../fragments/travel-partner/TravelPartner';
import ImageLinkTileList from '../../controls/ImageLinkTileList';
import PrismicStructuredText from '../../controls/PrismicStructuredText';
import SectionTitle from '../../controls/SectionTitle';

export const TravelPartnersSearchSliceKey = '!internal_travel_partners_search_slice';

const TravelPartnersSearchSlice = WithGlobalResources(
  WithTravelPartnerData(
    class TravelPartnersSearchSliceImpl extends Component<
      TravelPartnersSearchSliceProps & InjectedGlobalResourcesProps & InjectedTravelPartnerDataProps
    > {
      render(): ReactNode {
        return (
          <StaticQuery query={TravelPartnersSearchSliceQuery} render={(results) => this.renderWithData(results)} />
        );
      }

      renderWithData(data: TravelPartnersSearchSliceQueryResults): ReactNode {
        const { sliceData } = this.props;

        const fieldConfig: TagBasedSearchFieldsConfig<TravelPartnersSearchParams> = {
          travelPartner: {
            label: sliceData?.primary?.travel_partner_field_label || '',
            options: NodeListToOptions(data?.allPrismicTravelPartner?.nodes, (node) => node?.data?.page_title),
            allowMultiple: false,
          },
        };

        return (
          <div className="container neo-tag-search-page">
            <SectionTitle component={sliceData.primary?.section_title} />

            <TagBasedSearch
              fields={fieldConfig}
              search={this.executeSearch}
              render={this.renderSearchResults}
              maxPerPage={6}
            />
          </div>
        );
      }

      executeSearch = (params: TravelPartnersSearchParams): PrismicTravelPartner[] => {
        const { travelPartnerData } = this.props;

        if (params.travelPartner.length) {
          return travelPartnerData.filter((p) => p.id === params.travelPartner[0]);
        }
        return travelPartnerData.sort(() => Math.random() - 0.5);
      };
      renderSearchResults = (results: PrismicTravelPartner[]): ReactNode => {
        const { sliceData } = this.props;

        if (results.length === 0) {
          return (
            <div className="content has-text-centered">
              <PrismicStructuredText text={sliceData?.primary?.no_results_found_content} />
            </div>
          );
        }

        return (
          <ImageLinkTileList forceLayout="thirds">
            {results.map((partner, index) => (
              <TravelPartner key={index} partner={partner} />
            ))}
          </ImageLinkTileList>
        );
      };
    }
  )
);
export default TravelPartnersSearchSlice;

const TravelPartnersSearchSliceQuery = graphql`
  query TravelPartnersSearchSliceQuery {
    allPrismicTravelPartner(sort: { fields: data___page_title }) {
      nodes {
        id
        data {
          page_title
        }
      }
    }
  }
`;

interface TravelPartnersSearchParams {
  travelPartner: string[];
}

interface TravelPartnersSearchSliceQueryResults {
  allPrismicTravelPartner?: PrismicTravelPartnerConnection;
}

export interface TravelPartnersSearchSliceProps {
  sliceData: PrismicTravelPartnersSearchPageDataBodySearchEnginePlaceholder;
  pageData?: PrismicTravelPartnersSearchPage;
}
