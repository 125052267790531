import React, { ReactNode } from 'react';
import { graphql, PageProps } from 'gatsby';
import {
  withPrismicPreview,
  WithPrismicPreviewProps,
} from 'gatsby-plugin-prismic-previews';

import Layout from '../components/Layout';
import {
  Maybe,
  PrismicEmailSubscriptionPage,
  PrismicEmailSubscriptionPageDataBodySlicesType,
  PrismicPageDataBodySlicesType,
} from '../graphql';
import SEO from '../components/controls/SEO';
import PageSlicesRender from '../components/controls/PageSlicesRender';
import WithGlobalResources, {
  InjectedGlobalResourcesProps,
} from '../components/higher-order/WithGlobalResources';
import { EmailSubscriptionFormSliceKey } from '../components/slices/internal/EmailSubscriptionFormSlice';
import NotFoundPage from './NotFoundPage';

type SlicesTypes = Maybe<
  PrismicPageDataBodySlicesType | PrismicEmailSubscriptionPageDataBodySlicesType
>;

type EmailSubscriptionPageTemplateProps = {
  data: {
    prismicEmailSubscriptionPage: PrismicEmailSubscriptionPage;
  };
} & InjectedGlobalResourcesProps &
  WithPrismicPreviewProps<PrismicEmailSubscriptionPage> &
  PageProps<PrismicEmailSubscriptionPage>;

const EmailSubscriptionPageTemplate = ({
  data,
  location,
}: EmailSubscriptionPageTemplateProps) => {
  const pageNode = data?.prismicEmailSubscriptionPage;
  const pageComponents: ReactNode[] = [];

  if (!pageNode) {
    return <NotFoundPage location={location} />;
  }

  pageComponents.push(
    <SEO
      key="page-seo-item"
      title={pageNode.data?.page_title ?? ''}
      description={pageNode.data?.share_description ?? undefined}
      fbImage={
        pageNode.data?.og_image?.localFile?.childImageSharp?.gatsbyImageData
      }
      slug={pageNode.url ?? undefined}
      titleTemplate={
        pageNode.data?.use_page_title_template === 'no'
          ? '{pageTitle}'
          : undefined
      }
      meta={
        pageNode.data?.indexable === 'no'
          ? [
              {
                name: `robots`,
                content: `noindex`,
              },
            ]
          : undefined
      }
    />
  );

  const normalizedPageNodes: SlicesTypes[] = [];

  if (pageNode?.data?.body?.length) {
    for (const slice of pageNode.data.body) {
      if (slice?.slice_type === 'form_placeholder') {
        normalizedPageNodes.push({
          ...slice,
          slice_type: EmailSubscriptionFormSliceKey,
        } as PrismicPageDataBodySlicesType);
        continue;
      }

      normalizedPageNodes.push(slice);
    }
  }

  pageComponents.push(
    <PageSlicesRender
      key="slices"
      body={normalizedPageNodes}
      pageNode={pageNode}
    />
  );

  return <Layout location={location}>{pageComponents}</Layout>;
};

export default withPrismicPreview(
  WithGlobalResources(EmailSubscriptionPageTemplate)
);

export const pageQuery = graphql`
  query PrismicEmailSubscriptionPageQuery($nodeId: String!) {
    prismicEmailSubscriptionPage(id: { eq: $nodeId }) {
      _previewable
      id
      url
      data {
        page_title
        share_description
        share_title
        indexable
        og_image {
          alt
          url
          gatsbyImageData(
            width: 1200
            height: 630
            backgroundColor: "rgba(255,255,255,1)"
            placeholder: NONE
            layout: FIXED
          )
        }
        use_page_title_template
        body {
          ... on PrismicEmailSubscriptionPageDataBodyBreadcrumbs {
            ...SliceBreadcrumbsFragment
          }
          ... on PrismicEmailSubscriptionPageDataBodyCarousel {
            ...SliceCarouselFragment
          }
          ... on PrismicEmailSubscriptionPageDataBodyCollapsableContent {
            ...SliceCollapsableContentFragment
          }
          ... on PrismicEmailSubscriptionPageDataBodyFormPlaceholder {
            id
            slice_type
            primary {
              section_title {
                html
              }
              membership_number_field_label
              email_field_label
              newsletters_list_intro {
                html
              }
              member_bulletin_title
              member_bulletin_description {
                html
              }
              travel_title
              travel_description {
                html
              }
              solo_travel_title
              solo_travel_description {
                html
              }
              magazine_title
              magazine_description {
                html
              }
              unsubscribe_label
              unsubscribe_sublabel
              submit_button_label
              success_message {
                html
              }
              error_message {
                html
              }
            }
          }
          ... on PrismicEmailSubscriptionPageDataBodyHeroImage {
            ...SliceHeroImageFragment
          }
          ... on PrismicEmailSubscriptionPageDataBodyIconLinkList {
            ...SliceIconLinkListFragment
          }
          ... on PrismicEmailSubscriptionPageDataBodyImageLinkTiles {
            ...SliceImageLinkTilesFragment
          }
          ... on PrismicEmailSubscriptionPageDataBodyImageLinkCards {
            ...SliceImageLinkCardsFragment
          }
          ... on PrismicEmailSubscriptionPageDataBodyMarketingHero {
            ...SliceMarketingHeroFragment
          }
          ... on PrismicEmailSubscriptionPageDataBodyMultiColumnContent {
            ...SliceMultiColumnContentFragment
          }
          ... on PrismicEmailSubscriptionPageDataBodySimpleLinkTiles {
            ...SliceSimpleLinkTilesFragment
          }
          ... on PrismicEmailSubscriptionPageDataBodyTextContent {
            ...SliceTextContentFragment
          }
        }
      }
    }
  }
`;
