import React from 'react';
import {SizeProp} from '@fortawesome/fontawesome-svg-core';

import WithGlobalResources, {InjectedGlobalResourcesProps,} from '../../higher-order/WithGlobalResources';
import IconLink from '../../controls/IconLink';
import ImageLinkCardList from '../ImageLinkCardList';
import {Maybe, PrismicLinkType, PrismicPageDataBodyMembershipTilesItemsImageImageType} from '../../../graphql';

export interface ILinkTileData {
  index?: number | null;
  link_dest?: PrismicLinkType | null;
  text?: string | null;
  icon?: string | null;
  iconSize?: SizeProp;
  image?: Maybe<PrismicPageDataBodyMembershipTilesItemsImageImageType>;
}

export interface ILinkTilesProps {
  linkTilesData?: ILinkTileData[];
}

type AllLinkTilesProps = InjectedGlobalResourcesProps & ILinkTileData & ILinkTilesProps;

const SideLinkTileList = WithGlobalResources(({linkTilesData = []}: AllLinkTilesProps) => {
  const tilesClasses = ['tiles'];
  const iconLinks = linkTilesData
    .filter(linkData => linkData.icon)
    .map((linkData, index) =>
      <div key={index.toString()} className="tile">
        <IconLink
          link_dest={linkData.link_dest}
          icon={linkData.icon}
          text={linkData.text}
        />
      </div>
    );
  const imageLinks = linkTilesData.filter(linkData => linkData.image?.url || linkData.image?.gatsbyImageData);

  return (
    <section className="neo-side-links">
      {iconLinks.length > 0 && (
        <div className={tilesClasses.concat('icon-links').join(' ')}>{iconLinks}</div>
      )}
      {imageLinks.length > 0 && (
        <div className={tilesClasses.concat('image-links').join(' ')}>
          <ImageLinkCardList
            imageLinkCardListData={imageLinks}
            itemsClassName="tile"
          />
        </div>
      )}
    </section>
  );
});

export default SideLinkTileList;
