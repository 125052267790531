import React, { Component } from 'react';
import IconLink, { IIconLinkData } from '../controls/IconLink';

class IconLinkList extends Component<IIconLinkListData> {
  render() {
    const iconLinksData: IIconLinkData[] = this.props.iconLinkListData;

    const iconLinkColumnClasses =
      iconLinksData.length % 3 === 0 || iconLinksData.length % 2 === 0
        ? 'column is-half-mobile is-one-third-tablet is-one-quarter-desktop is-2-fullhd'
        : iconLinksData.length % 7 === 0
        ? 'column is-half-mobile is-one-third-tablet is-one-quarter-desktop is-one-seventh-fullhd'
        : 'column is-half-mobile is-one-third-tablet is-one-quarter-desktop';

    const iconLinks = iconLinksData.map((qld, index) => {
      return (
        <div key={index} className={iconLinkColumnClasses}>
          <IconLink link_dest={qld.link_dest} icon={qld.icon} text={qld.text} />
        </div>
      );
    });

    return (
      <section className="neo-icon-links">
        <div className="columns is-mobile is-multiline is-centered">
          {iconLinks}
        </div>
      </section>
    );
  }
}
export default IconLinkList;

export interface IIconLinkListData {
  iconLinkListData: IIconLinkData[];
}
