import { PrismicPage, PrismicPageDataBodyTwoColumnsTextContent } from '../../graphql';
import React, { Component, ReactNode } from 'react';
import SideLinkTileList, { ILinkTileData } from '../fragments/twoColumns/SideLinkTileList';
import PrismicStructuredText from '../controls/PrismicStructuredText';
import PrismicMarkdownText from '../controls/PrismicMarkdownText';
import { graphql } from 'gatsby';
import SectionTitle from '../controls/SectionTitle';

export const TwoColumnsTextContentSliceKey = 'two_columns_text_content';
export default class TwoColumnsTextContentSlice extends Component<TwoColumnsTextContentSliceProps> {
  render(): ReactNode {
    const { sliceData } = this.props;

    const isMarkdown = sliceData.primary?.rendering_mode === 'Markdown';

    if (
      !sliceData.primary?.section_title?.html &&
      ((!isMarkdown && !sliceData.primary?.rich_text?.html) || (isMarkdown && !sliceData.primary?.markdown?.text))
    ) {
      return <React.Fragment />;
    }

    let contentElement: ReactNode = null;
    if (isMarkdown) {
      contentElement = <PrismicMarkdownText text={sliceData.primary?.markdown?.text} />;
    } else {
      contentElement = <PrismicStructuredText text={sliceData.primary?.rich_text} />;
    }

    return (
      <div className="container neo-two-column-content">
        <SectionTitle component={sliceData.primary?.section_title} />

        <div className="columns is-variable is-8">
          <div className="column is-three-quarters content">{contentElement}</div>
          <div className="column has-border-left-caa-lightgray has-no-border-mobile">
            <SideLinkTileList
              linkTilesData={sliceData.items?.map((item, i) => {
                const linkData: ILinkTileData = {
                  index: i,
                  image: item?.link_image,
                  icon: item?.link_icon,
                  text: item?.link_title,
                  link_dest: item?.link_dest,
                };
                return linkData;
              })}
            />
          </div>
        </div>
      </div>
    );
  }
}

export interface TwoColumnsTextContentSliceProps {
  sliceData: PrismicPageDataBodyTwoColumnsTextContent;
  pageData?: PrismicPage;
}

// noinspection JSUnusedGlobalSymbols
export const SliceTwoColumnsTextContentFragment = graphql`
  fragment SliceTwoColumnsTextContentFragment on PrismicPageDataBodyTwoColumnsTextContent {
    id
    slice_type
    primary {
      section_title {
        html
      }
      rich_text {
        html
      }
      rendering_mode
      markdown {
        text
      }
    }
    items {
      link_dest {
        ...PrismicLinkFragment
      }
      link_title
      link_icon
      link_image {
        alt
        url
        gatsbyImageData(width: 960, layout: CONSTRAINED)
      }
    }
  }
`;
