import React, { Component, ReactNode } from 'react';
import PrismicLink from '../controls/PrismicLink';
import { SizeProp } from '@fortawesome/fontawesome-svg-core';
import { PrismicLinkType } from '../../graphql';
import PrismicIcon from './PrismicIcon';

export default class IconLink extends Component<IIconLinkData> {
  render() {
    let icon: ReactNode = null;
    const label: ReactNode = <div>{this.props.text}</div>;

    if (this.props.icon) {
      icon = <PrismicIcon icon={this.props.icon} size="3x" />;
    }

    return (
      <PrismicLink to={this.props.link_dest!} className="icon-link">
        {icon}
        {label}
      </PrismicLink>
    );
  }
}

export interface IIconLinkData {
  text?: string | null;
  icon?: string | null;
  iconSize?: SizeProp;
  link_dest?: PrismicLinkType | null;
}
