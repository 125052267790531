import {
  PrismicMerchandise,
  PrismicMerchandiseDataProductImagesImageImageType,
  PrismicMerchandiseSearchPage,
  PrismicTaxonomyMerchandiseCategory,
  PrismicTravelStoreSearchPage,
} from '../../../graphql';
import React, { Component, ReactNode } from 'react';
import PrismicStructuredText from '../../controls/PrismicStructuredText';
import WithGlobalResources, {
  InjectedGlobalResourcesProps,
} from '../../higher-order/WithGlobalResources';
import PrismicLink from '../../controls/PrismicLink';
import { graphql, StaticQuery } from 'gatsby';
import KeyValueList, { KeyValuePair } from '../../controls/KeyValueList';
import { ToMoment } from '../../../utils/PrismicHelpers';
import PrismicImage from '../../controls/PrismicImage';
import { Swipeable } from 'react-swipeable';
import moment from 'moment-timezone';
import { Moment } from 'moment-timezone/moment-timezone';
import PrismicFontAwesomeIcon from '../../controls/PrismicFontAwesomeIcon';

const MerchandiseImageCarousel = class MerchandiseImageCarouselImpl extends Component<
  MerchandiseImageCarouselProps,
  MerchandiseImageCarouselState
> {
  constructor(props: Readonly<MerchandiseImageCarouselProps>) {
    super(props);

    this.state = {
      activeImage: 0,
    };
  }

  render(): ReactNode {
    const { images } = this.props;
    const { activeImage } = this.state;

    if (!images?.length) {
      return null;
    }

    return (
      <div className="neo-merchandise-carousel">
        <div className="neo-merchandise-carousel-inner">
          <div className="neo-merchandise-carousel-slides">
            {images?.length > 1 ? (
              <div className="neo-merchandise-carousel-controls">
                <button
                  className="neo-merchandise-carousel-prev-button"
                  aria-label="Previous image"
                  onClick={this.prevImage}
                >
                  <PrismicFontAwesomeIcon icon="fas chevron-left" />
                </button>
                <button
                  className="neo-merchandise-carousel-next-button"
                  aria-label="Next image"
                  onClick={this.nextImage}
                >
                  <PrismicFontAwesomeIcon icon="fas chevron-right" />
                </button>
              </div>
            ) : null}
            {images?.map((image, index) => {
              return (
                <Swipeable
                  className={`neo-merchandise-carousel-slide ${
                    index === activeImage ? 'is-active' : ''
                  }`}
                  key={index}
                  aria-hidden={index !== activeImage}
                  onSwipedLeft={this.prevImage}
                  onSwipedRight={this.nextImage}
                >
                  <PrismicImage image={image} />
                </Swipeable>
              );
            })}
          </div>
        </div>
      </div>
    );
  }

  prevImage = () => {
    const { images } = this.props;

    if (!images) {
      return;
    }

    const prevImage =
      this.state.activeImage - 1 >= 0
        ? this.state.activeImage - 1
        : images!.length - 1;

    this.setState({
      activeImage: prevImage,
    });
  };

  nextImage = () => {
    const { images } = this.props;

    if (!images) {
      return;
    }

    const nextImage = (this.state.activeImage + 1) % images!.length;

    this.setState({
      activeImage: nextImage,
    });
  };
};

export const MerchandiseContentSliceKey = '!internal_merchandise_content_slice';
const MerchandiseContentSlice = WithGlobalResources(
  class MerchandiseContentSliceImpl extends Component<
    MerchandiseContentSliceProps & InjectedGlobalResourcesProps
  > {
    render(): ReactNode {
      return (
        <StaticQuery
          query={MerchandiseContentSliceQuery}
          render={(results) => this.renderWithData(results)}
        />
      );
    }

    renderWithData(data: MerchandiseContentSliceQueryResults): ReactNode {
      const { pageData, globalResources } = this.props;

      let titleElement: ReactNode = null;

      if (pageData?.data?.page_title) {
        titleElement = (
          <div className="neo-section-title">
            <h1>{pageData?.data?.page_title}</h1>
          </div>
        );
      }

      const actionButtons: ReactNode[] = [];
      const keyValuePairs: KeyValuePair[] = [];

      let hasSale = false;
      let saleDate: Moment | undefined;

      if (pageData?.data?.member_price) {
        if (pageData?.data?.sale_price) {
          saleDate = ToMoment(pageData?.data?.sale_expiry);
          hasSale = !!(!saleDate || saleDate?.isAfter(moment(), 'day'));
        }

        if (hasSale) {
          keyValuePairs.push({
            key: globalResources.merchandise_page_sale_price_header || '',
            keyClassNames:
              'is-narrow has-text-weight-semibold has-text-caa-red is-size-4',
            value: (
              <React.Fragment>
                <span className={'has-text-caa-red'}>
                  {pageData?.data?.sale_price}
                </span>
                {' ' /* en space */}
                <span
                  className={
                    'has-text-caa-gray is-size-6 has-text-strikethrough'
                  }
                  aria-label={
                    globalResources.merchandise_page_sale_regular_price_sr_hint ||
                    undefined
                  }
                >
                  {pageData?.data?.member_price}
                </span>
              </React.Fragment>
            ),
            valueClassNames: 'is-size-4',
          });
        } else {
          keyValuePairs.push({
            key: globalResources.merchandise_page_member_price_header || '',
            value: pageData?.data?.member_price,
          });
        }
      }

      if (hasSale && saleDate) {
        keyValuePairs.push({
          key: globalResources.merchandise_page_sale_expiry_header || '',
          keyClassNames: 'is-narrow has-text-weight-semibold has-text-caa-red',
          value: saleDate?.format('MMMM Do, YYYY'),
          valueClassNames: 'has-text-caa-red',
        });
      }

      if (pageData?.data?.non_member_price) {
        keyValuePairs.push({
          key: globalResources.merchandise_page_non_member_price_header || '',
          value: pageData?.data?.non_member_price,
        });
      }

      const contentElement: ReactNode = (
        <PrismicStructuredText text={pageData?.data?.product_description} />
      );

      if (data?.prismicTravelStoreSearchPage?.id) {
        actionButtons.push(
          <PrismicLink
            to={data?.prismicTravelStoreSearchPage}
            className="button is-medium is-caa-forestgreen has-text-weight-bold"
          >
            {globalResources.merchandise_page_find_store_cta}
          </PrismicLink>
        );
      }

      let legalElement: ReactNode = null;
      if (pageData?.data?.legal_text?.html) {
        legalElement = (
          <div className="is-size-7">
            <PrismicStructuredText text={pageData?.data?.legal_text} />
          </div>
        );
      }

      const actionButtonsNode = actionButtons.length ? (
        <div className="field is-grouped is-grouped-centered is-grouped-multiline">
          {actionButtons.map((node, index) => (
            <div key={index} className="control">
              {node}
            </div>
          ))}
        </div>
      ) : null;

      let typeTagsNode: ReactNode = null;

      if (pageData?.data?.merchandise_categories?.length) {
        typeTagsNode = (
          <div className="neo-merchandise-tags">
            <p className="is-size-6 has-text-weight-bold">
              {globalResources.merchandise_page_tags_listing_header}
            </p>
            <div className="columns is-variable is-2 is-mobile">
              {pageData?.data?.merchandise_categories
                ?.filter((d) => d?.merchandise_category?.document?.id)
                .map((item, index) => {
                  const merchandiseCategory = item?.merchandise_category?.document as PrismicTaxonomyMerchandiseCategory;

                  return (
                    <div className="column is-narrow" key={index}>
                      <PrismicLink
                        to={data.prismicMerchandiseSearchPage}
                        fragmentQuery={{
                          merchCat: merchandiseCategory?.id,
                        }}
                        className="button is-small has-text-weight-semibold"
                      >
                        {merchandiseCategory?.data?.name}
                      </PrismicLink>
                    </div>
                  );
                })}
            </div>
          </div>
        );
      }

      return (
        <div className="container neo-merchandise">
          {titleElement}

          <div className="columns is-variable is-8">
            <div className="column is-two-thirds content">
              <KeyValueList keyValues={keyValuePairs} />
              {contentElement}
              {legalElement}
              {actionButtonsNode}
            </div>
            <div className="column has-border-left-caa-lightgray has-no-border-mobile">
              <div className="neo-merchandise-images-carousel">
                <MerchandiseImageCarousel
                  images={pageData?.data?.product_images
                    ?.filter((i) => i?.image)
                    .map((i) => i!.image!)}
                />
              </div>
            </div>
          </div>

          {typeTagsNode}
        </div>
      );
    }
  }
);
export default MerchandiseContentSlice;

const MerchandiseContentSliceQuery = graphql`
  query MerchandiseContentSliceQuery {
    prismicMerchandiseSearchPage {
      id
      url
      uid
    }
    prismicTravelStoreSearchPage {
      id
      url
      uid
    }
  }
`;

interface MerchandiseContentSliceQueryResults {
  prismicMerchandiseSearchPage?: PrismicMerchandiseSearchPage;
  prismicTravelStoreSearchPage?: PrismicTravelStoreSearchPage;
}

export interface MerchandiseContentSliceProps {
  sliceData: any;
  pageData?: PrismicMerchandise;
}

interface MerchandiseImageCarouselProps {
  images?: PrismicMerchandiseDataProductImagesImageImageType[];
}

interface MerchandiseImageCarouselState {
  activeImage: number;
}
