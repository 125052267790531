import React, { Component, ReactNode } from 'react';
import {
  PrismicPage,
  PrismicPageDataBodyMap,
  PrismicPageDataBodyMapItem,
  PrismicStructuredTextType,
} from '../../graphql';
import PrismicStructuredText from '../controls/PrismicStructuredText';
import { graphql } from 'gatsby';
import { LeafletMap } from '../controls/LeafletMap';
import { LatLngLiteral } from 'leaflet';
import { Marker, Popup } from 'react-leaflet';
import SectionTitle from '../controls/SectionTitle';

export const MapSliceKey = 'map';
export default class MapSlice extends Component<MapSliceProps> {
  render(): ReactNode {
    const { sliceData } = this.props;

    const validMarkers = this.validMarkers;

    if (validMarkers.length === 0) {
      return null;
    }

    return (
      <div className="limited-container neo-map-slice">
        <div className="container">
          <SectionTitle component={sliceData.primary?.section_title} />
          <div className="neo-map-slice-container">
            <div className="neo-map-slice-container-inner">
              <LeafletMap
                boundsOptions={{ maxZoom: 16, padding: [20, 20] }}
                bounds={validMarkers.map((marker) => [marker.lat, marker.lng])}
                style={{ height: '100%' }}
              >
                {validMarkers.map((marker, index) => (
                  <Marker position={marker} key={index}>
                    {marker.desc?.html ? (
                      <Popup>
                        <PrismicStructuredText text={marker.desc} />
                      </Popup>
                    ) : null}
                  </Marker>
                ))}
              </LeafletMap>
            </div>
          </div>
        </div>
      </div>
    );
  }

  get validMarkers(): MarkerData[] {
    const { sliceData } = this.props;
    return (
      (sliceData?.items || []).filter(
        (item) => item?.marker_location?.latitude || item?.marker_location?.longitude
      ) as PrismicPageDataBodyMapItem[]
    ).map((item) => {
      return {
        lat: item?.marker_location?.latitude || 0,
        lng: item?.marker_location?.longitude || 0,
        desc: item?.marker_description || undefined,
      } as MarkerData;
    });
  }
}

export interface MapSliceProps {
  sliceData: PrismicPageDataBodyMap;
  pageData?: PrismicPage;
}

// noinspection JSUnusedGlobalSymbols
export const SliceMapFragment = graphql`
  fragment SliceMapFragment on PrismicSlicesMap {
    ... on PrismicPageDataBodyMap {
      id
      slice_type
      primary {
        section_title {
          html
        }
      }
      items {
        marker_location {
          latitude
          longitude
        }
        marker_description {
          html
        }
      }
    }
    ... on PrismicTravelTripDataBodyMap {
      id
      slice_type
      primary {
        section_title {
          html
        }
      }
      items {
        marker_location {
          latitude
          longitude
        }
        marker_description {
          html
        }
      }
    }
  }
`;

interface MarkerData extends LatLngLiteral {
  desc?: PrismicStructuredTextType;
}
