import {
  PrismicRewardsOffer,
  PrismicRewardsOfferConnection,
} from '../../graphql';
import React, { Component, ComponentType } from 'react';
import { graphql, StaticQuery } from 'gatsby';
import { Subset } from '../../utils/UtilityTypes';

const WithRewardsOfferData = function WithRewardsOfferDataFn<
  P extends InjectedRewardsOfferDataProps
>(
  ComposedComponent: ComponentType<P>
): ComponentType<Subset<P, InjectedRewardsOfferDataProps>> {
  return class extends Component<Subset<P, InjectedRewardsOfferDataProps>> {
    render() {
      return (
        <StaticQuery
          query={RewardsOfferDataQuery}
          render={queryResult => {
            const {
              allPrismicRewardsOffer,
            } = queryResult as RewardsOfferDataQueryResults;

            return (
              <ComposedComponent
                {...(this.props as any)}
                rewardsOfferData={allPrismicRewardsOffer.nodes || []}
              />
            );
          }}
        />
      );
    }
  };
};
export default WithRewardsOfferData;

const RewardsOfferDataQuery = graphql`
  query RewardsOfferDataQuery {
    allPrismicRewardsOffer {
      nodes {
        ...RewardsOfferFragment
      }
    }
  }
`;

interface RewardsOfferDataQueryResults {
  allPrismicRewardsOffer: PrismicRewardsOfferConnection;
}

// noinspection JSUnusedGlobalSymbols
export const RewardsOfferFragment = graphql`
  fragment RewardsOfferFragment on PrismicRewardsOffer {
    id
    data {
      offer_title_text
      offer_description {
        html
      }
      legal_text {
        html
      }
      partner_portal_link {
        ...PrismicLinkFragment
      }
      partner {
        document {
          ...RewardsPartnerFragment
        }
      }
      offer_image {
        alt
        url
        gatsbyImageData(width: 960, placeholder: NONE, layout: CONSTRAINED)
      }
      tags {
        tag {
          document {
            ... on PrismicTag {
              id
            }
          }
        }
      }
    }
  }
`;

export interface InjectedRewardsOfferDataProps {
  rewardsOfferData: PrismicRewardsOffer[];
}
