import { PrismicAgent, PrismicAgentConnection } from '../../graphql';
import React, { Component, ComponentType } from 'react';
import { graphql, StaticQuery } from 'gatsby';
import { Subset } from '../../utils/UtilityTypes';

const WithAgentData = function WithAgentDataFn<P extends InjectedAgentDataProps>(
  ComposedComponent: ComponentType<P>
): ComponentType<Subset<P, InjectedAgentDataProps>> {
  return class extends Component<Subset<P, InjectedAgentDataProps>> {
    render() {
      return (
        <StaticQuery
          query={AgentDataQuery}
          render={(queryResult) => {
            const { allPrismicAgent } = queryResult as AgentDataQueryResults;

            return <ComposedComponent {...(this.props as any)} agentData={allPrismicAgent.nodes || []} />;
          }}
        />
      );
    }
  };
};
export default WithAgentData;

const AgentDataQuery = graphql`
  query AgentDataQuery {
    allPrismicAgent(sort: { fields: data___name, order: ASC }) {
      nodes {
        ...AgentFragment
      }
    }
  }
`;

interface AgentDataQueryResults {
  allPrismicAgent: PrismicAgentConnection;
}

// noinspection JSUnusedGlobalSymbols
export const AgentFragment = graphql`
  fragment AgentFragment on PrismicAgent {
    id
    data {
      name
      title
      type
      agent_phone
      agent_phone_extension
      agent_photo {
        alt
        url
        gatsbyImageData(width: 960, placeholder: NONE, layout: CONSTRAINED)
      }
      caa_travel_store_list {
        section {
          document {
            ... on PrismicTravelStore {
              id
              data {
                page_title
                visibility
                location_type
              }
            }
          }
        }
      }
      book_an_appointment_url {
        ...PrismicLinkFragment
      }
      agent_contact_form_url {
        ...PrismicLinkFragment
      }
      travel_types {
        travel_type {
          document {
            ... on PrismicTaxonomyTravelType {
              id
            }
          }
        }
      }
      travel_partners {
        travel_partner {
          document {
            ... on PrismicTravelPartner {
              id
            }
          }
        }
      }
      travel_destinations {
        travel_destinatioon {
          document {
            ... on PrismicTaxonomyTravelDestination {
              id
            }
          }
        }
      }
    }
  }
`;

export interface InjectedAgentDataProps {
  agentData: PrismicAgent[];
}
