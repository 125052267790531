import React, { Component, ReactNode } from 'react';
import {
  AttributionControl,
  ContextProps,
  Map,
  MapProps,
  TileLayer,
  withLeaflet,
} from 'react-leaflet';
import Control from 'react-leaflet-control';
import PrismicFontAwesomeIcon from './PrismicFontAwesomeIcon';
import { IsBrowserEnvironment } from '../../utils/BrowserHelpers';

// React Leaflet doesn't work with Server-Side Rendering. This is a workaround.

const ZoomControl = IsBrowserEnvironment
  ? withLeaflet(
      class ZoomControlImpl extends Component<ContextProps> {
        render() {
          const { leaflet } = this.props;

          return (
            <div className="neo-map-zoom-control">
              <button
                className="button is-white is-small is-narrow has-border-caa-gray"
                aria-label="Zoom in"
                onClick={_event => leaflet?.map?.zoomIn()}
              >
                <PrismicFontAwesomeIcon icon={'fas-plus'} />
              </button>
              <button
                className="button is-white is-small is-narrow has-border-caa-gray"
                aria-label="Zoom out"
                onClick={_event => leaflet?.map?.zoomOut()}
              >
                <PrismicFontAwesomeIcon icon={'fas-minus'} />
              </button>
            </div>
          );
        }
      }
    )
  : class NullZoomControl extends Component<ContextProps> {};

export class LeafletMap extends Component<MapProps, LeafletMapState> {
  constructor(props: Readonly<MapProps>) {
    super(props);

    this.state = {
      isMounted: false,
    };
  }

  componentDidMount(): void {
    this.setState({
      isMounted: true,
    });
  }

  render(): ReactNode {
    const { children, ...otherProps } = this.props;
    const { isMounted } = this.state;

    if (!isMounted) {
      return null;
    }

    return (
      <Map attributionControl={false} zoomControl={false} {...otherProps}>
        <TileLayer
          attribution='&amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        />
        <AttributionControl prefix={''} />
        <Control position={'topleft'}>
          <ZoomControl />
        </Control>
        {children}
      </Map>
    );
  }
}

interface LeafletMapState {
  isMounted: boolean;
}
