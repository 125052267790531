import React, { Component, ReactNode } from 'react';
import { IImageLinkCompactTileData } from '../controls/ImageLinkCompactTile';
import PrismicLink from '../controls/PrismicLink';
import ImageLinkCardList from '../fragments/ImageLinkCardList';
import { PrismicPage, PrismicPageDataBodyImageLinkCards } from '../../graphql';
import { IsValidPrismicLink } from '../../utils/PrismicHelpers';
import { graphql } from 'gatsby';
import SectionTitle from '../controls/SectionTitle';

export const ImageLinkCardsSliceKey = 'image_link_cards';
export default class ImageLinkCardsSlice extends Component<ImageLinkCardsSliceProps> {
  getCardsWidth = (numberOfItems: number): string => {
    return numberOfItems < 3 || (numberOfItems % 3 === 0 && numberOfItems % 4 !== 0) ? 'third' : 'quarter';
  };

  render(): ReactNode {
    const { sliceData } = this.props;
    const hasHero = sliceData.primary?.has_hero === 'Yes';

    let items = Array.isArray(sliceData.resolved_items)
      ? sliceData.resolved_items
      : Array.isArray(sliceData.items)
      ? sliceData.items
      : [];
    items = items.filter((item) => item?.link_title && IsValidPrismicLink(item?.link_dest) && item?.image);

    if (!items.length) {
      return <React.Fragment />;
    }

    let sectionCTA: ReactNode = null;

    if (sliceData.primary?.cta_title && sliceData.primary?.cta_dest) {
      sectionCTA = (
        <div className="neo-section-cta">
          <PrismicLink to={sliceData.primary.cta_dest}>{sliceData.primary.cta_title}</PrismicLink>
        </div>
      );
    }

    const imageLinkCardsData: IImageLinkCompactTileData[] = items.map((value) => {
      return {
        image: value?.image,
        text: value?.link_title,
        link_dest: value?.link_dest,
      };
    });

    const imageLinkCardsRendering: ReactNode[] = [];
    const imageLinksRendering: ReactNode[] = [];

    if (imageLinkCardsData.length) {
      if (hasHero) {
        imageLinksRendering.push(
          <div key={'neo-image-link-cards-0'} className={'neo-image-link-cards'}>
            <ImageLinkCardList
              imageLinkCardListData={imageLinkCardsData.slice(0, 1)}
              itemsClassName={'image-link-card'}
            />
          </div>
        );
        if (imageLinkCardsData.length > 1) {
          imageLinksRendering.push(
            <div key={'neo-image-link-cards-1'} className={'neo-image-link-cards'}>
              <ImageLinkCardList
                imageLinkCardListData={imageLinkCardsData.slice(1, 5)}
                itemsClassName={'image-link-card'}
              />
            </div>
          );
        }

        imageLinkCardsRendering.push(
          <div key={'wrapper-element'} className={'neo-image-link-cards-container has-hero'}>
            {imageLinksRendering}
          </div>
        );
      }
      if ((hasHero && imageLinkCardsData.length > 5) || !hasHero) {
        const cardsData: IImageLinkCompactTileData[] = hasHero ? imageLinkCardsData.slice(5) : imageLinkCardsData;

        const cardsWidth = this.getCardsWidth(cardsData.length);
        imageLinkCardsRendering.push(
          <div key={'neo-image-link-cards'} className={`neo-image-link-cards ${cardsWidth}`}>
            <ImageLinkCardList imageLinkCardListData={cardsData} itemsClassName={'image-link-card'} />
          </div>
        );
      }
    }

    return (
      <div className="container neo-image-link-card-slice">
        <SectionTitle component={sliceData.primary?.section_title} />
        {sectionCTA}
        {imageLinkCardsRendering}
      </div>
    );
  }
}

export interface ImageLinkCardsSliceProps {
  sliceData: PrismicPageDataBodyImageLinkCards;
  pageData?: PrismicPage;
}

// noinspection JSUnusedGlobalSymbols
export const SliceImageLinkCardsFragment = graphql`
  fragment SliceImageLinkCardsFragment on PrismicSlicesImageLinkCards {
    ... on PrismicPageDataBodyImageLinkCards {
      id
      slice_type
      primary {
        cta_title
        cta_dest {
          ...PrismicLinkFragment
        }
        section_title {
          html
        }
        has_hero
      }
      resolved_items {
        link_dest {
          ...PrismicLinkFragment
        }
        link_title
        image {
          alt
          url
          gatsbyImageData(width: 960, layout: CONSTRAINED)
        }
      }
    }
    ... on PrismicEmailSubscriptionPageDataBodyImageLinkCards {
      id
      slice_type
      primary {
        cta_title
        cta_dest {
          ...PrismicLinkFragment
        }
        section_title {
          html
        }
        has_hero
      }
      resolved_items {
        link_dest {
          ...PrismicLinkFragment
        }
        link_title
        image {
          alt
          url
          gatsbyImageData(width: 960, layout: CONSTRAINED)
        }
      }
    }
    ... on PrismicErrorPageDataBodyImageLinkCards {
      id
      slice_type
      primary {
        cta_title
        cta_dest {
          ...PrismicLinkFragment
        }
        section_title {
          html
        }
        has_hero
      }
      resolved_items {
        link_dest {
          ...PrismicLinkFragment
        }
        link_title
        image {
          alt
          url
          gatsbyImageData(width: 960, layout: CONSTRAINED)
        }
      }
    }
    ... on PrismicGaragesSearchPageDataBodyImageLinkCards {
      id
      slice_type
      primary {
        cta_title
        cta_dest {
          ...PrismicLinkFragment
        }
        section_title {
          html
        }
        has_hero
      }
      resolved_items {
        link_dest {
          ...PrismicLinkFragment
        }
        link_title
        image {
          alt
          url
          gatsbyImageData(width: 960, layout: CONSTRAINED)
        }
      }
    }
    ... on PrismicMerchandiseSearchPageDataBodyImageLinkCards {
      id
      slice_type
      primary {
        cta_title
        cta_dest {
          ...PrismicLinkFragment
        }
        section_title {
          html
        }
        has_hero
      }
      resolved_items {
        link_dest {
          ...PrismicLinkFragment
        }
        link_title
        image {
          alt
          url
          gatsbyImageData(width: 960, layout: CONSTRAINED)
        }
      }
    }
    ... on PrismicSearchPageDataBodyImageLinkCards {
      id
      slice_type
      primary {
        cta_title
        cta_dest {
          ...PrismicLinkFragment
        }
        section_title {
          html
        }
        has_hero
      }
      resolved_items {
        link_dest {
          ...PrismicLinkFragment
        }
        link_title
        image {
          alt
          url
          gatsbyImageData(width: 960, layout: CONSTRAINED)
        }
      }
    }
    ... on PrismicTravelAgentsSearchPageDataBodyImageLinkCards {
      id
      slice_type
      primary {
        cta_title
        cta_dest {
          ...PrismicLinkFragment
        }
        section_title {
          html
        }
        has_hero
      }
      resolved_items {
        link_dest {
          ...PrismicLinkFragment
        }
        link_title
        image {
          alt
          url
          gatsbyImageData(width: 960, layout: CONSTRAINED)
        }
      }
    }
    ... on PrismicTravelDealsSearchPageDataBodyImageLinkCards {
      id
      slice_type
      primary {
        cta_title
        cta_dest {
          ...PrismicLinkFragment
        }
        section_title {
          html
        }
        has_hero
      }
      resolved_items {
        link_dest {
          ...PrismicLinkFragment
        }
        link_title
        image {
          alt
          url
          gatsbyImageData(width: 960, layout: CONSTRAINED)
        }
      }
    }
    ... on PrismicTravelPartnersSearchPageDataBodyImageLinkCards {
      id
      slice_type
      primary {
        cta_title
        cta_dest {
          ...PrismicLinkFragment
        }
        section_title {
          html
        }
        has_hero
      }
      resolved_items {
        link_dest {
          ...PrismicLinkFragment
        }
        link_title
        image {
          alt
          url
          gatsbyImageData(width: 960, layout: CONSTRAINED)
        }
      }
    }
    ... on PrismicTravelStoreSearchPageDataBodyImageLinkCards {
      id
      slice_type
      primary {
        cta_title
        cta_dest {
          ...PrismicLinkFragment
        }
        section_title {
          html
        }
        has_hero
      }
      resolved_items {
        link_dest {
          ...PrismicLinkFragment
        }
        link_title
        image {
          alt
          url
          gatsbyImageData(width: 960, layout: CONSTRAINED)
        }
      }
    }
    ... on PrismicTravelTalksSearchPageDataBodyImageLinkCards {
      id
      slice_type
      primary {
        cta_title
        cta_dest {
          ...PrismicLinkFragment
        }
        section_title {
          html
        }
        has_hero
      }
      resolved_items {
        link_dest {
          ...PrismicLinkFragment
        }
        link_title
        image {
          alt
          url
          gatsbyImageData(width: 960, layout: CONSTRAINED)
        }
      }
    }
    ... on PrismicTravelTripsSearchPageDataBodyImageLinkCards {
      id
      slice_type
      primary {
        cta_title
        cta_dest {
          ...PrismicLinkFragment
        }
        section_title {
          html
        }
        has_hero
      }
      resolved_items {
        link_dest {
          ...PrismicLinkFragment
        }
        link_title
        image {
          alt
          url
          gatsbyImageData(width: 960, layout: CONSTRAINED)
        }
      }
    }
    ... on PrismicTravelStoreDataBodyImageLinkCards {
      id
      slice_type
      primary {
        cta_title
        cta_dest {
          ...PrismicLinkFragment
        }
        section_title {
          html
        }
        has_hero
      }
      resolved_items {
        link_dest {
          ...PrismicLinkFragment
        }
        link_title
        image {
          alt
          url
          gatsbyImageData(width: 960, layout: CONSTRAINED)
        }
      }
    }
    ... on PrismicTravelTalkDataBodyImageLinkCards {
      id
      slice_type
      primary {
        cta_title
        cta_dest {
          ...PrismicLinkFragment
        }
        section_title {
          html
        }
        has_hero
      }
      resolved_items {
        link_dest {
          ...PrismicLinkFragment
        }
        link_title
        image {
          alt
          url
          gatsbyImageData(width: 960, layout: CONSTRAINED)
        }
      }
    }
    ... on PrismicTravelTripDataBodyImageLinkCards {
      id
      slice_type
      primary {
        cta_title
        cta_dest {
          ...PrismicLinkFragment
        }
        section_title {
          html
        }
        has_hero
      }
      resolved_items {
        link_dest {
          ...PrismicLinkFragment
        }
        link_title
        image {
          alt
          url
          gatsbyImageData(width: 960, layout: CONSTRAINED)
        }
      }
    }
    ... on PrismicTravelDealDataBodyImageLinkCards {
      id
      slice_type
      primary {
        cta_title
        cta_dest {
          ...PrismicLinkFragment
        }
        section_title {
          html
        }
        has_hero
      }
      resolved_items {
        link_dest {
          ...PrismicLinkFragment
        }
        link_title
        image {
          alt
          url
          gatsbyImageData(width: 960, layout: CONSTRAINED)
        }
      }
    }
    ... on PrismicMerchandiseDataBodyImageLinkCards {
      id
      slice_type
      primary {
        cta_title
        cta_dest {
          ...PrismicLinkFragment
        }
        section_title {
          html
        }
        has_hero
      }
      resolved_items {
        link_dest {
          ...PrismicLinkFragment
        }
        link_title
        image {
          alt
          url
          gatsbyImageData(width: 960, layout: CONSTRAINED)
        }
      }
    }
    ... on PrismicTravelPartnerDataBodyImageLinkCards {
      id
      slice_type
      primary {
        cta_title
        cta_dest {
          ...PrismicLinkFragment
        }
        section_title {
          html
        }
        has_hero
      }
      resolved_items {
        link_dest {
          ...PrismicLinkFragment
        }
        link_title
        image {
          alt
          url
          gatsbyImageData(width: 960, layout: CONSTRAINED)
        }
      }
    }
  }
`;
