import { PrismicTravelDeal, PrismicTravelDealConnection } from '../../graphql';
import React, { Component, ComponentType } from 'react';
import { graphql, StaticQuery } from 'gatsby';
import { Subset } from '../../utils/UtilityTypes';
import { ToMoment } from '../../utils/PrismicHelpers';
import moment from 'moment-timezone';

const WithTravelDealData = function WithTravelDealDataFn<
  P extends InjectedTravelDealDataProps
>(
  ComposedComponent: ComponentType<P>
): ComponentType<Subset<P, InjectedTravelDealDataProps>> {
  return class extends Component<Subset<P, InjectedTravelDealDataProps>> {
    render() {
      return (
        <StaticQuery
          query={TravelDealDataQuery}
          render={queryResult => {
            const {
              allPrismicTravelDeal,
            } = queryResult as TravelDealDataQueryResults;

            return (
              <ComposedComponent
                {...(this.props as any)}
                travelDealData={
                  allPrismicTravelDeal?.nodes?.filter(node => {
                    return !(
                      node.data?.deal_expiry &&
                      ToMoment(node.data?.deal_expiry)?.isBefore(
                        moment(),
                        'day'
                      )
                    );
                  }) || []
                }
              />
            );
          }}
        />
      );
    }
  };
};
export default WithTravelDealData;

const TravelDealDataQuery = graphql`
  query TravelDealDataQuery {
    allPrismicTravelDeal(
      filter: { is_active: { eq: true } }
      sort: { fields: data___deal_expiry, order: ASC }
    ) {
      nodes {
        ...TravelDealFragment
      }
    }
  }
`;

interface TravelDealDataQueryResults {
  allPrismicTravelDeal: PrismicTravelDealConnection;
}

// noinspection JSUnusedGlobalSymbols
export const TravelDealFragment = graphql`
  fragment TravelDealFragment on PrismicTravelDeal {
    ...PrismicDocumentLinkFragment
    data {
      page_title
      deal_expiry
      cta_title
      cta_link_dest {
        ...PrismicLinkFragment
      }
      deal_image {
        alt
        url
        gatsbyImageData(width: 960, placeholder: NONE, layout: CONSTRAINED)
      }
      travel_types {
        travel_type {
          document {
            ... on PrismicTaxonomyTravelType {
              id
            }
          }
        }
      }
      travel_destinations {
        travel_destination {
          document {
            ... on PrismicTaxonomyTravelDestination {
              id
            }
          }
        }
      }
      travel_partner {
        ...PrismicLinkFragment
        document {
          ... on PrismicTravelPartner {
            id
            data {
              page_title
              partner_logo {
                alt
                url
                gatsbyImageData(width: 960, placeholder: NONE, layout: CONSTRAINED)
              }
            }
          }
        }
      }
    }
  }
`;

export interface InjectedTravelDealDataProps {
  travelDealData: PrismicTravelDeal[];
}
