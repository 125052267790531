import {
  PrismicTravelStore,
  PrismicTravelTalksSearchPage,
} from '../../../graphql';
import React, { Component, ReactNode } from 'react';
import WithGlobalResources, {
  InjectedGlobalResourcesProps,
} from '../../higher-order/WithGlobalResources';
import { graphql, StaticQuery } from 'gatsby';
import UpcomingTravelTalk from '../../fragments/travel-talk/UpcomingTravelTalk';
import PrismicImage from '../../controls/PrismicImage';
import PrismicLink from '../../controls/PrismicLink';

export const TravelStoreTalksSliceKey = '!internal_travel_store_talks_slice';

const TravelStoreTalksSlice = WithGlobalResources(
  class TravelStoreTalksSliceImpl extends Component<
    TravelStoreTalksSliceProps & InjectedGlobalResourcesProps
  > {
    render(): ReactNode {
      return (
        <StaticQuery
          query={TravelStoreTalksSliceQuery}
          render={results => this.renderWithData(results)}
        />
      );
    }

    renderWithData(results: TravelStoreTalksSliceQueryResults): ReactNode {
      const { pageData, globalResources } = this.props;

      const titleElement: ReactNode = (
        <div className="neo-section-title">
          <h1>
            {globalResources.travel_store_talks_section_header?.replace(
              '{storeName}',
              pageData?.data?.page_title || ''
            )}
          </h1>
        </div>
      );

      const ctaElement: ReactNode = (
        <div className="neo-section-cta">
          <PrismicLink
            to={results.prismicTravelTalksSearchPage}
            className="button is-caa-forestgreen is-inverted"
          >
            {globalResources.travel_store_talks_view_more_cta}
          </PrismicLink>
        </div>
      );

      return (
        <UpcomingTravelTalk
          travelStoreId={pageData?.id}
          numOfResults={3}
          returnEmptyIfNoMatch={true}
          render={resultNode => (
            <div className="container-limited has-container-background is-background-overlay-caa-darkforestgreen">
              <div className="container neo-travel-store-agents">
                {titleElement}
                {ctaElement}
                {resultNode}
              </div>
              <PrismicImage
                className={'container-background'}
                image={
                  globalResources.travel_store_talks_section_background ??
                  null
                }
              />
            </div>
          )}
        />
      );
    }
  }
);
export default TravelStoreTalksSlice;

const TravelStoreTalksSliceQuery = graphql`
  query TravelStoreTalksSliceQuery {
    prismicTravelTalksSearchPage {
      id
      url
      uid
    }
  }
`;

interface TravelStoreTalksSliceQueryResults {
  prismicTravelTalksSearchPage?: PrismicTravelTalksSearchPage;
}

export interface TravelStoreTalksSliceProps {
  sliceData: any;
  pageData?: PrismicTravelStore;
}
