import React, { Component, ReactNode } from 'react';
import PrismicFontAwesomeIcon from '../controls/PrismicFontAwesomeIcon';
import PrismicLink from '../controls/PrismicLink';
import { SizeProp } from '@fortawesome/fontawesome-svg-core';
import { PrismicLinkType } from '../../graphql';

export default class BreadcrumbLink extends Component<IBreadcrumbData> {
  render() {
    let icon: ReactNode = undefined;
    let label: ReactNode = undefined;
    let ariaLabel = undefined;
    let iconSize: SizeProp = '2x';

    if (this.props.iconSize) {
      iconSize = this.props.iconSize;
    }
    if (this.props.icon) {
      icon = (
        <PrismicFontAwesomeIcon
          className={'has-text-caa-forestgreen'}
          icon={this.props.icon}
          size={iconSize}
        />
      );
    }
    if (this.props.text) {
      label = <div>{this.props.text}</div>;
    }
    if (this.props.ariaLabel) {
      ariaLabel = this.props.ariaLabel;
    }

    return (
      <PrismicLink
        to={this.props.link_dest!}
        className="breadcrumb-link"
        aria-label={ariaLabel}
      >
        {icon}
        {label}
      </PrismicLink>
    );
  }
}

export interface IBreadcrumbData {
  text?: string;
  ariaLabel?: string;
  icon?: string;
  iconSize?: SizeProp;
  link_dest?: PrismicLinkType | null;
}
