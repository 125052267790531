const unified = require('unified');
const markdown = require('remark-parse');
const remarkSubSuper = require('remark-sub-super');
const remark2rehype = require('remark-rehype');
const rehypeAddClasses = require('rehype-add-classes').default;
const raw = require('rehype-raw');
const html = require('rehype-stringify');

const visit = require('unist-util-visit');
const select = require('hast-util-select');
const parseSelector = require('hast-util-parse-selector');

const rehypeWrap = options => {
  options = options || {};

  /*
   * Transformer
   */
  return tree => {
    Object.keys(options).forEach(selector => {
      const selected = select.selectAll(selector, tree);
      const wrap = parseSelector(options[selector]);

      if (selected && selected.length) {
        selected.forEach(selectedNode => {
          visit(tree, selectedNode, (node, i, parent) => {
            if (node.tagName === 'body') {
              wrap.children = node.children;
              node.children = [wrap];
            } else {
              wrap.children = [selectedNode];
              parent.children[i] = wrap;
            }
          });
        });
      }
    });
  };
};

const buildPipeline = allowDangerousHtml => {
  return unified()
    .use(markdown)
    .use(remarkSubSuper)
    .use(remark2rehype, { allowDangerousHTML: allowDangerousHtml })
    .use(raw)
    .use(rehypeAddClasses, { table: 'table' })
    .use(rehypeWrap, { table: 'div.table-container' })
    .use(html);
};

/**
 * @return {Promise<string>}
 */
exports.ParseMarkdown = function(markdownString, allowDangerousHtml) {
  return new Promise((resolve, reject) => {
    if (typeof markdownString !== 'string') {
      resolve(markdownString);
      return;
    }

    buildPipeline(allowDangerousHtml).process(markdownString, function(
      err,
      file
    ) {
      const result = String(file);

      if (!result && err) {
        reject(err);
        return;
      }

      resolve(result);
    });
  });
};

/**
 * @return {string}
 */
exports.ParseMarkdownSync = function(markdownString, allowDangerousHtml) {
  let vfile = buildPipeline(allowDangerousHtml).processSync(markdownString);

  return String(vfile);
};
