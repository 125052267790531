import React, { Component, ReactNode } from 'react';
import { graphql, PageProps } from 'gatsby';
import { withPrismicPreview, WithPrismicPreviewProps } from 'gatsby-plugin-prismic-previews';

import Layout from '../components/Layout';
import {
  Maybe,
  PrismicPageDataBodyBreadcrumbs,
  PrismicPageDataBodySlicesType,
  PrismicTravelStore,
  PrismicTravelStoreDataBodySlicesType,
} from '../graphql';
import SEO from '../components/controls/SEO';
import PageSlicesRender from '../components/controls/PageSlicesRender';
import { BreadcrumbsSliceKey } from '../components/slices/BreadcrumbsSlice';
import WithGlobalResources, { InjectedGlobalResourcesProps } from '../components/higher-order/WithGlobalResources';
import {
  StoreOpeningHoursSliceKey,
  TravelStoreContentSliceKey,
} from '../components/slices/internal/TravelStoreContentSlice';
import { TravelStoreTalksSliceKey } from '../components/slices/internal/TravelStoreTalksSlice';
import NotFoundPage from './NotFoundPage';

type SlicesTypes = Maybe<PrismicPageDataBodySlicesType | PrismicTravelStoreDataBodySlicesType>;

type TravelStoreTemplateProps = {
  data: {
    prismicTravelStore: PrismicTravelStore;
  };
} & InjectedGlobalResourcesProps &
  WithPrismicPreviewProps<PrismicTravelStore> &
  PageProps<PrismicTravelStore>;

class TravelStoreTemplate extends Component<TravelStoreTemplateProps> {
  render() {
    const { globalResources } = this.props;
    const pageNode = this.props?.data?.prismicTravelStore;
    const pageComponents: ReactNode[] = [];

    if (!pageNode) {
      return <NotFoundPage location={this.props.location} />;
    }
    pageComponents.push(
      <SEO
        key="page-seo-item"
        title={pageNode.data?.page_title ?? ''}
        description={pageNode.data?.share_description ?? undefined}
        fbImage={pageNode.data?.og_image?.localFile?.childImageSharp?.gatsbyImageData}
        slug={pageNode.url ?? undefined}
        titleTemplate={pageNode.data?.use_page_title_template === 'no' ? '{pageTitle}' : undefined}
        meta={
          pageNode.data?.indexable === 'no'
            ? [
                {
                  name: `robots`,
                  content: `noindex`,
                },
              ]
            : undefined
        }
      />
    );

    const normalizedPageNodes: SlicesTypes[] = [
      {
        slice_type: BreadcrumbsSliceKey,
        primary: {
          include_home: 'yes',
          include_self: 'yes',
        },
        items: globalResources?.travel_store_page_breadcrumb || [],
      } as PrismicPageDataBodyBreadcrumbs,
      {
        slice_type: TravelStoreContentSliceKey,
      } as PrismicTravelStoreDataBodySlicesType,
    ];

    if (pageNode?.data?.body?.length) {
      for (const slice of pageNode.data.body) {
        if (slice?.slice_type === StoreOpeningHoursSliceKey) {
          continue;
        }

        normalizedPageNodes.push(slice);
      }
    }

    normalizedPageNodes.push({
      slice_type: TravelStoreTalksSliceKey,
    } as PrismicTravelStoreDataBodySlicesType);
    pageComponents.push(<PageSlicesRender key="slices" body={normalizedPageNodes} pageNode={pageNode} />);

    return <Layout location={this.props.location}>{pageComponents}</Layout>;
  }
}

export default withPrismicPreview(WithGlobalResources(TravelStoreTemplate));

export const pageQuery = graphql`
  query PrismicTravelStoreQuery($nodeId: String!) {
    prismicTravelStore(id: { eq: $nodeId }) {
      _previewable
      id
      url
      data {
        store_image {
          alt
          url
          gatsbyImageData(width: 960, layout: CONSTRAINED)
        }
        location {
          latitude
          longitude
        }
        address
        opening_hours {
          hours_label
          hours_value
        }
        contact_information {
          item_title
          link_text
          link_dest {
            ...PrismicLinkFragment
          }
        }
        page_title
        share_description
        share_title
        indexable
        og_image {
          alt
          url
          gatsbyImageData(
            width: 1200
            height: 630
            backgroundColor: "rgba(255,255,255,1)"
            placeholder: NONE
            layout: FIXED
          )
        }
        use_page_title_template
        body {
          ... on PrismicTravelStoreDataBodyOpeningHours {
            id
            slice_type
            primary {
              title {
                text
              }
            }
            items {
              hours_label
              hours_value
            }
          }
          ... on PrismicTravelStoreDataBodyAgent {
            ...SliceAgentFragment
          }
          ... on PrismicTravelStoreDataBodyStoreCredentials {
            ...SliceMultiColumnContentFragment
          }
          ... on PrismicTravelStoreDataBodyMultiColumnContent {
            ...SliceMultiColumnContentFragment
          }
          ... on PrismicTravelStoreDataBodyHeroImage {
            ...SliceHeroImageFragment
          }
          ... on PrismicTravelStoreDataBodyCollapsableContent {
            ...SliceCollapsableContentFragment
          }
          ... on PrismicTravelStoreDataBodyIconLinkList {
            ...SliceIconLinkListFragment
          }
          ... on PrismicTravelStoreDataBodyImageLinkCards {
            ...SliceImageLinkCardsFragment
          }
          ... on PrismicTravelStoreDataBodyImageLinkTiles {
            ...SliceImageLinkTilesFragment
          }
          ... on PrismicTravelStoreDataBodyMarketingHero {
            ...SliceMarketingHeroFragment
          }
          ... on PrismicTravelStoreDataBodyCarousel {
            ...SliceCarouselFragment
          }
          ... on PrismicTravelStoreDataBodySimpleLinkTiles {
            ...SliceSimpleLinkTilesFragment
          }
          ... on PrismicTravelStoreDataBodyTextContent {
            ...SliceTextContentFragment
          }
          ... on PrismicTravelStoreDataBodyYoutubeVideo {
            ...SliceYoutubeVideoFragment
          }
          ... on PrismicTravelStoreDataBodyTravelOffers {
            ...SliceTravelOffersFragment
          }
          ... on PrismicTravelStoreDataBodyContentListing {
            ...SliceContentListingFragment
          }
        }
      }
    }
  }
`;
