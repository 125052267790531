import React, { Component, ReactNode } from 'react';
import { PrismicPage, PrismicPageDataBodySimpleLinkTiles } from '../../graphql';
import SimpleLinkTile from '../controls/SimpleLinkTile';
import { IsValidPrismicLink } from '../../utils/PrismicHelpers';
import { graphql } from 'gatsby';
import SectionTitle from '../controls/SectionTitle';

export const SimpleLinkTilesSliceKey = 'simple_link_tiles';
export default class SimpleLinkTilesSlice extends Component<SimpleLinkTilesSliceProps> {
  render(): ReactNode {
    const { sliceData } = this.props;

    let items = Array.isArray(sliceData.resolved_items)
      ? sliceData.resolved_items
      : Array.isArray(sliceData.items)
      ? sliceData.items
      : [];
    items = items.filter((item) => item?.link_title && IsValidPrismicLink(item?.link_dest));

    if (!items.length) {
      return <React.Fragment />;
    }

    const numberOfItems = items.length;
    const tilesWidth = numberOfItems < 3 || (numberOfItems % 3 === 0 && numberOfItems % 4 !== 0) ? 'third' : 'quarter';

    const linkItems: ReactNode[] = items.map((value, index) => {
      return (
        <div key={index} className="tile">
          <SimpleLinkTile
            text={value?.link_title || ''}
            link_dest={value!.link_dest}
            ctaLabel={value?.link_cta || undefined}
            mainColor="green"
          />
        </div>
      );
    });

    return (
      <div className="container">
        <SectionTitle component={sliceData.primary?.section_title} />
        <section className="neo-simple-link-tiles">
          <div className={`tiles ${tilesWidth}`}>{linkItems}</div>
        </section>
      </div>
    );
  }
}

export interface SimpleLinkTilesSliceProps {
  sliceData: PrismicPageDataBodySimpleLinkTiles;
  pageData?: PrismicPage;
}

// noinspection JSUnusedGlobalSymbols
export const SliceSimpleLinkTilesFragment = graphql`
  fragment SliceSimpleLinkTilesFragment on PrismicSlicesSimpleLinkTiles {
    ... on PrismicPageDataBodySimpleLinkTiles {
      id
      slice_type
      primary {
        section_title {
          html
        }
      }
      resolved_items {
        link_cta
        link_dest {
          ...PrismicLinkFragment
        }
        link_title
      }
    }
    ... on PrismicEmailSubscriptionPageDataBodySimpleLinkTiles {
      id
      slice_type
      primary {
        section_title {
          html
        }
      }
      resolved_items {
        link_cta
        link_dest {
          ...PrismicLinkFragment
        }
        link_title
      }
    }
    ... on PrismicErrorPageDataBodySimpleLinkTiles {
      id
      slice_type
      primary {
        section_title {
          html
        }
      }
      resolved_items {
        link_cta
        link_dest {
          ...PrismicLinkFragment
        }
        link_title
      }
    }
    ... on PrismicGaragesSearchPageDataBodySimpleLinkTiles {
      id
      slice_type
      primary {
        section_title {
          html
        }
      }
      resolved_items {
        link_cta
        link_dest {
          ...PrismicLinkFragment
        }
        link_title
      }
    }
    ... on PrismicMerchandiseSearchPageDataBodySimpleLinkTiles {
      id
      slice_type
      primary {
        section_title {
          html
        }
      }
      resolved_items {
        link_cta
        link_dest {
          ...PrismicLinkFragment
        }
        link_title
      }
    }
    ... on PrismicSearchPageDataBodySimpleLinkTiles {
      id
      slice_type
      primary {
        section_title {
          html
        }
      }
      resolved_items {
        link_cta
        link_dest {
          ...PrismicLinkFragment
        }
        link_title
      }
    }
    ... on PrismicTravelAgentsSearchPageDataBodySimpleLinkTiles {
      id
      slice_type
      primary {
        section_title {
          html
        }
      }
      resolved_items {
        link_cta
        link_dest {
          ...PrismicLinkFragment
        }
        link_title
      }
    }
    ... on PrismicTravelDealsSearchPageDataBodySimpleLinkTiles {
      id
      slice_type
      primary {
        section_title {
          html
        }
      }
      resolved_items {
        link_cta
        link_dest {
          ...PrismicLinkFragment
        }
        link_title
      }
    }
    ... on PrismicTravelPartnersSearchPageDataBodySimpleLinkTiles {
      id
      slice_type
      primary {
        section_title {
          html
        }
      }
      resolved_items {
        link_cta
        link_dest {
          ...PrismicLinkFragment
        }
        link_title
      }
    }
    ... on PrismicTravelStoreSearchPageDataBodySimpleLinkTiles {
      id
      slice_type
      primary {
        section_title {
          html
        }
      }
      resolved_items {
        link_cta
        link_dest {
          ...PrismicLinkFragment
        }
        link_title
      }
    }
    ... on PrismicTravelTalksSearchPageDataBodySimpleLinkTiles {
      id
      slice_type
      primary {
        section_title {
          html
        }
      }
      resolved_items {
        link_cta
        link_dest {
          ...PrismicLinkFragment
        }
        link_title
      }
    }
    ... on PrismicTravelTripsSearchPageDataBodySimpleLinkTiles {
      id
      slice_type
      primary {
        section_title {
          html
        }
      }
      resolved_items {
        link_cta
        link_dest {
          ...PrismicLinkFragment
        }
        link_title
      }
    }
    ... on PrismicTravelStoreDataBodySimpleLinkTiles {
      id
      slice_type
      primary {
        section_title {
          html
        }
      }
      resolved_items {
        link_cta
        link_dest {
          ...PrismicLinkFragment
        }
        link_title
      }
    }
    ... on PrismicTravelTalkDataBodySimpleLinkTiles {
      id
      slice_type
      primary {
        section_title {
          html
        }
      }
      resolved_items {
        link_cta
        link_dest {
          ...PrismicLinkFragment
        }
        link_title
      }
    }
    ... on PrismicTravelDealDataBodySimpleLinkTiles {
      id
      slice_type
      primary {
        section_title {
          html
        }
      }
      resolved_items {
        link_cta
        link_dest {
          ...PrismicLinkFragment
        }
        link_title
      }
    }
    ... on PrismicMerchandiseDataBodySimpleLinkTiles {
      id
      slice_type
      primary {
        section_title {
          html
        }
      }
      resolved_items {
        link_cta
        link_dest {
          ...PrismicLinkFragment
        }
        link_title
      }
    }
    ... on PrismicTravelTripDataBodySimpleLinkTiles {
      id
      slice_type
      primary {
        section_title {
          html
        }
      }
      resolved_items {
        link_cta
        link_dest {
          ...PrismicLinkFragment
        }
        link_title
      }
    }
    ... on PrismicTravelPartnerDataBodySimpleLinkTiles {
      id
      slice_type
      primary {
        section_title {
          html
        }
      }
      resolved_items {
        link_cta
        link_dest {
          ...PrismicLinkFragment
        }
        link_title
      }
    }
  }
`;
