import React, { ReactNode } from 'react';
import { RowContent, RowContentType } from './RowContent';
import { getColorClassName } from '../../../utils/TableUtility';
import { TableData, TableHeader } from './TableContainer';

export interface ColumnTableProps {
  headers: TableHeader[];
  columns: TableData[];
  numberOfColumns: number;
}

/**
 * Builds a table column by column.
 * @param props : ColumnTableProps The data necessary to build the table.
 *
 * @TechDebt The design of this component could be better by using an iterator.
 *           If work needs to be done in this class, it could be a good start.
 */
export const ColumnTable = ({ headers, columns, numberOfColumns }: ColumnTableProps) => {
  const colGroupContent: ReactNode[] = [];
  const headerRowsExists = headers.length > 0;
  const headerContent: RowContentType[] = [];

  // We go through each column to generate the colgroup
  for (let colIndex = 0; colIndex < numberOfColumns; colIndex++) {
    const column = columns.find((column) => column.index === colIndex);
    const colorClassName = column?.color ? getColorClassName(column.color) : '';
    colGroupContent.push(colorClassName ? <col className={colorClassName} /> : <col />);

    // Header content has to be done first because it will be used as a label
    // for the row
    if (headerRowsExists) {
      const column = headers.find((header) => header.index === colIndex);
      headerContent.push(column?.header);
    }
  }

  const tableContent: ReactNode[] = [];
  const numberOfRows = Math.max(...columns.map((a) => a.content.length));

  // Html tables are built by row, we need to go through each row
  for (let rowIndex = 0; rowIndex < numberOfRows; rowIndex++) {
    const rowContent: RowContentType[] = [];
    let rowHeader: RowContentType;

    // then for each column, we add the cell to the corresponding row
    for (let colIndex = 0; colIndex < numberOfColumns; colIndex++) {
      // a column might be empty, we need to use the index
      const column = columns.find((column) => column.index === colIndex);

      // if the column is a header and is the first column, than it is
      // the header for the row
      if (column?.isHeader && colIndex === 0) {
        rowHeader = column.content[rowIndex];
        continue;
      }

      rowContent.push(column?.content[rowIndex]);
    }

    // The row has been built, it can be added to the  table
    tableContent.push(
      <RowContent
        header={rowHeader}
        labels={
          columns[0].isHeader
            ? headerContent.map((content) => content?.text as string).slice(1)
            : headerContent.map((content) => content?.text as string)
        }
        numberOfCells={columns[0].isHeader ? numberOfColumns - 1 : numberOfColumns}
        rowCells={rowContent}
      />
    );
  }

  return (
    <>
      <colgroup>{colGroupContent}</colgroup>
      { headerRowsExists && (
        <thead>
        <RowContent isHeader rowCells={headerContent ?? []} numberOfCells={numberOfColumns} />
        </thead>
      )}
      <tbody>{tableContent}</tbody>
    </>
  );
};
