import React, { Component, ReactNode } from 'react';
import {
  IPlanData,
  IPlanSpecification,
  IPlanSpecificationData,
} from '../ComparativeTableSlice';
import ComparativeTablePlanHeader from './ComparativeTablePlanHeader';

export default class ComparativeDiv extends Component<IComparativeDiv> {
  render(): ReactNode {
    const index: number = this.props.index;
    const planData: IPlanData | undefined = this.props.planData || undefined;
    const uniqueSpecs: IPlanSpecification[] = this.props.uniqueSpecs;

    if (!planData) {
      return <React.Fragment />;
    }

    let specsNodeArray: ReactNode[] = [];
    const planSpecs = planData.specs || [];
    if (uniqueSpecs.length) {
      specsNodeArray = uniqueSpecs.map((spec, specIndex) => {
        let divClasses = 'plan-spec';
        let value: ReactNode = <div>&mdash;</div>;
        let description: ReactNode = <div />;
        const isActive = this.props.activeItemsIndex.includes(specIndex);
        const titleNode: ReactNode = (
          <span className={'spec-name'}>{spec.name}</span>
        );
        if (spec.add_on_bg_color) {
          divClasses = divClasses.concat(' is-', spec.add_on_bg_color);
        }

        // if actual plan has the actual spec defined, get value.
        const specData = planSpecs.find(
          (planSpec: IPlanSpecificationData) => planSpec.name === spec.name
        );
        if (specData) {
          if (specData.value?.html) {
            value = (
              <div
                className={'spec-value'}
                dangerouslySetInnerHTML={{
                  __html: specData.value.html,
                }}
              />
            );
          }
          if (specData.description?.text) {
            description = (
              <div>
                <a
                  className={'spec-name is-accordion-item-button'}
                  aria-controls={`${this.props.elementIdPrefix}__${specIndex}__Content`}
                  aria-expanded={isActive ? 'true' : 'false'}
                  onClick={_event =>
                    this.props.setActiveItem(specIndex, _event)
                  }
                >
                  {spec.name}
                </a>

                <div
                  key={`specName ${index}`}
                  id={`${this.props.elementIdPrefix}__${index}__Content`}
                  className={`spec-value neo-collapsable-region ${
                    isActive ? 'is-active' : ''
                  }`}
                  aria-labelledby={`${this.props.elementIdPrefix}__${index}__Toggle`}
                >
                  {specData.description.text}
                </div>
              </div>
            );
          }
        }
        return (
          <div key={`spec-${index}-${specIndex}`} className={divClasses}>
            {spec.description?.text ? description : titleNode}
            {value}
          </div>
        );
      });
    }

    return (
      <div
        key={`comparative-div-${index}`}
        className={`comparative-div comparative-div-${index}`}
      >
        <div
          key={`comparative-div-content-${index}`}
          className={'comparative-div-content'}
        >
          <div
            key={`comparative-div-header-${index}`}
            className={'comparative-div-header'}
          >
            <ComparativeTablePlanHeader
              key={`comparative-div-plan-header-${index}`}
              planData={planData}
              index={index}
            />
          </div>
          {specsNodeArray}
        </div>
      </div>
    );
  }
}

export interface IComparativeDiv {
  index: number;
  planData?: IPlanData;
  uniqueSpecs: IPlanSpecification[];
  activeItemsIndex: number[];
  elementIdPrefix: string;
  setActiveItem: (
    currentIndex: number,
    event: React.MouseEvent<HTMLAnchorElement | HTMLButtonElement>
  ) => void;
}
