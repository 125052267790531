import React from 'react';
import { graphql } from 'gatsby';
import { PrismicPageDataBodyTableRow, PrismicStructuredTextType } from '../../graphql';
import { TableColorOptions } from '../../utils/TableUtility';
import { TableContainer, TableData, TableHeader } from '../controls/tables/TableContainer';

export const TableRowSliceKey = 'table_row';

export interface TableRowSliceProps {
  sliceData: PrismicPageDataBodyTableRow[];
}

/**
 * This component extracts the data from prismic. It represent one or a group
 * of table rows slice.
 * @param props : TableRowSliceProps The data from prismic
 * @returns A TableContainer component that can render the data
 */
export const TableRowSlice = ({ sliceData }: TableRowSliceProps) => {
  const headers: TableHeader[] = [];
  const tableData: TableData[] = [];

  for (const [index, slice] of sliceData.entries()) {
    if (slice.primary?.row_header?.html) {
      headers.push({
        index,
        header: slice.primary.row_header,
        color: (slice.primary.row_color as TableColorOptions) ?? TableColorOptions.Default,
      });
    }

    tableData.push({
      index,
      isHeader: slice.primary.is_header_row ?? false,
      content: slice.items.map((item) => item.column_content as PrismicStructuredTextType),
      color: (slice.primary.row_color as TableColorOptions) ?? TableColorOptions.Default,
    });
  }

  return <TableContainer isByRow headers={headers} tableData={tableData} />;
};

// noinspection JSUnusedGlobalSymbols
export const SliceTableRowFragment = graphql`
  fragment SliceTableRowFragment on PrismicSlicesTableRow {
    ... on PrismicPageDataBodyTableRow {
      id
      slice_label
      slice_type
      primary {
        row_header {
          html
          text
        }
        is_header_row
        row_color
      }
      items {
        column_content {
          html
          text
        }
      }
    }
  }
`;
