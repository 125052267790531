import React, { ReactNode } from 'react';
import { graphql, PageProps } from 'gatsby';
import {
  withPrismicPreview,
  WithPrismicPreviewProps,
} from 'gatsby-plugin-prismic-previews';

import Layout from '../components/Layout';
import {
  Maybe,
  PrismicErrorPage,
  PrismicErrorPageDataBodySlicesType,
  PrismicPageDataBodySlicesType,
} from '../graphql';
import SEO from '../components/controls/SEO';
import PageSlicesRender from '../components/controls/PageSlicesRender';
import WithGlobalResources, {
  InjectedGlobalResourcesProps,
} from '../components/higher-order/WithGlobalResources';
import { ErrorPageSearchSliceKey } from '../components/slices/internal/ErrorPageSearchSlice';

type SlicesTypes = Maybe<
  PrismicPageDataBodySlicesType | PrismicErrorPageDataBodySlicesType
>;

type ErrorPageTemplateProps = {
  data: {
    prismicErrorPage: PrismicErrorPage;
  };
} & InjectedGlobalResourcesProps &
  WithPrismicPreviewProps<PrismicErrorPage> &
  PageProps<PrismicErrorPage>;

const ErrorPageTemplate = ({ data, location }: ErrorPageTemplateProps) => {
  const pageNode = data?.prismicErrorPage;
  const pageComponents: ReactNode[] = [];

  if (!pageNode) {
    return <Layout location={location} />;
  }

  pageComponents.push(
    <SEO
      key="page-seo-item"
      title={pageNode.data?.page_title ?? ''}
      description={pageNode.data?.share_description ?? undefined}
      fbImage={
        pageNode.data?.og_image?.localFile?.childImageSharp?.gatsbyImageData
      }
      slug={pageNode.url ?? undefined}
      titleTemplate={
        pageNode.data?.use_page_title_template === 'no'
          ? '{pageTitle}'
          : undefined
      }
      meta={
        pageNode.data?.indexable !== 'yes'
          ? [
              {
                name: `robots`,
                content: `noindex`,
              },
            ]
          : undefined
      }
    />
  );

  const normalizedPageNodes: SlicesTypes[] = [];

  if (pageNode?.data?.body?.length) {
    for (const slice of pageNode.data.body) {
      if (slice?.slice_type === 'search_engine_placeholder') {
        normalizedPageNodes.push({
          ...slice,
          slice_type: ErrorPageSearchSliceKey,
        });
        continue;
      }

      normalizedPageNodes.push(slice);
    }
  }

  pageComponents.push(
    <PageSlicesRender
      key="slices"
      body={normalizedPageNodes}
      pageNode={pageNode}
    />
  );

  return <Layout location={location}>{pageComponents}</Layout>;
};

export default withPrismicPreview(WithGlobalResources(ErrorPageTemplate));

export const ErrorPageQueryFragment = graphql`
  fragment ErrorPageQueryFragment on PrismicErrorPage {
    _previewable
    id
    url
    data {
      page_title
      share_description
      share_title
      indexable
      og_image {
        alt
        url
        gatsbyImageData(
          width: 1200
          height: 630
          backgroundColor: "rgba(255,255,255,1)"
          placeholder: NONE
          layout: FIXED
        )
      }
      use_page_title_template
      body {
        ... on PrismicErrorPageDataBodyBreadcrumbs {
          ...SliceBreadcrumbsFragment
        }
        ... on PrismicErrorPageDataBodyCarousel {
          ...SliceCarouselFragment
        }
        ... on PrismicErrorPageDataBodyCollapsableContent {
          ...SliceCollapsableContentFragment
        }
        ... on PrismicErrorPageDataBodyHeroImage {
          ...SliceHeroImageFragment
        }
        ... on PrismicErrorPageDataBodyIconLinkList {
          ...SliceIconLinkListFragment
        }
        ... on PrismicErrorPageDataBodyImageLinkTiles {
          ...SliceImageLinkTilesFragment
        }
        ... on PrismicErrorPageDataBodyImageLinkCards {
          ...SliceImageLinkCardsFragment
        }
        ... on PrismicErrorPageDataBodyMarketingHero {
          ...SliceMarketingHeroFragment
        }
        ... on PrismicErrorPageDataBodyMultiColumnContent {
          ...SliceMultiColumnContentFragment
        }
        ... on PrismicErrorPageDataBodySearchEnginePlaceholder {
          id
          slice_type
          primary {
            section_title {
              html
            }
            search_button_label
            search_field_label
            search_field_placeholder
          }
        }
        ... on PrismicErrorPageDataBodySimpleLinkTiles {
          ...SliceSimpleLinkTilesFragment
        }
        ... on PrismicErrorPageDataBodyTextContent {
          ...SliceTextContentFragment
        }
      }
    }
  }
`;

export const pageQuery = graphql`
  query PrismicErrorPageQuery($nodeId: String!) {
    prismicErrorPage(id: { eq: $nodeId }) {
      ...ErrorPageQueryFragment
    }
  }
`;
