import { BehaviorSubject } from 'rxjs';
import { distinctUntilChanged } from 'rxjs/operators';
import { IsBrowserEnvironment } from './BrowserHelpers';

interface DeviceInfo {
  breakpoint: Breakpoint;
  pointerType: PointerType;
}

export enum Breakpoint {
  Mobile = 'mobile',
  Tablet = 'tablet',
  Desktop = 'desktop',
  Widescreen = 'widescreen',
  FullHD = 'fullhd',
}

export enum PointerType {
  None = 'none',
  Coarse = 'Coarse',
  Fine = 'fine',
}

const BreakpointSubject = new BehaviorSubject<DeviceInfo>({
  breakpoint: Breakpoint.FullHD,
  pointerType: PointerType.Fine,
});

export const CurrentBreakpoint$ = BreakpointSubject.pipe(
  distinctUntilChanged()
);

export const RefreshBreakpointValue = IsBrowserEnvironment
  ? () => {
      const breakpointValue = window
        .getComputedStyle(document.body, ':before')
        .getPropertyValue('content')
        .replace(/["']/g, '')
        .split('|');

      BreakpointSubject.next({
        breakpoint: (breakpointValue[0] || 'fullhd') as Breakpoint,
        pointerType: (breakpointValue[1] || 'fine') as PointerType,
      });
    }
  : () => {
      /* NOOP */
    };

if (IsBrowserEnvironment) {
  let cancelToken: number | undefined = undefined;

  window.addEventListener('resize', _ev => {
    if (cancelToken !== undefined) {
      window.cancelAnimationFrame(cancelToken);
    }

    cancelToken = window.requestAnimationFrame(() => {
      RefreshBreakpointValue();
    });
  });
}

const BreakpointToInt = (point: Breakpoint): number => {
  switch (point) {
    default:
    case Breakpoint.Mobile:
      return 0;

    case Breakpoint.Tablet:
      return 1;

    case Breakpoint.Desktop:
      return 2;

    case Breakpoint.Widescreen:
      return 3;

    case Breakpoint.FullHD:
      return 4;
  }
};

export const IsBreakpointAtLeast = (
  currentBreakpoint: Breakpoint,
  compareToBreakpoint: Breakpoint
): boolean => {
  const currentPointInt = BreakpointToInt(currentBreakpoint);
  const compareToPointInt = BreakpointToInt(compareToBreakpoint);

  return currentPointInt >= compareToPointInt;
};

export const IsBreakpointAtMost = (
  currentBreakpoint: Breakpoint,
  compareToBreakpoint: Breakpoint
): boolean => {
  const currentPointInt = BreakpointToInt(currentBreakpoint);
  const compareToPointInt = BreakpointToInt(compareToBreakpoint);

  return currentPointInt <= compareToPointInt;
};
