import React, { ReactNode } from 'react';
import PrismicStructuredText from '../PrismicStructuredText';
import { PrismicStructuredTextType } from '../../../graphql';

export interface RowContentProps {
  header?: RowContentType;
  isHeader?: boolean;
  addEmptyHeader?: boolean;
  rowCells: RowContentType[];
  labels?: (string | null | undefined)[];
  className?: string;
  numberOfCells: number;
}

export type RowContentType = PrismicStructuredTextType | undefined;

/**
 * Builds a table row.
 * @param props The data necessary to build the row.
 */
export const RowContent = ({
  header,
  isHeader = false,
  addEmptyHeader = false,
  rowCells,
  labels,
  className,
  numberOfCells,
}: RowContentProps) => {
  const rowContent: ReactNode[] = [];

  if (header || addEmptyHeader) {
    rowContent.push(
      <th scope={'row'}>
        {!addEmptyHeader && <PrismicStructuredText text={header} transformLoneLinksAsButton={false} />}
      </th>
    );
  }

  // We iterate over all the cells. We use a basic for loop since there might be
  // empty cells not in the array
  for (let index = 0; index < numberOfCells; index++) {
    const cell = rowCells[index];

    if (isHeader) {
      rowContent.push(
        <th scope={'col'}>
          <PrismicStructuredText text={cell} transformLoneLinksAsButton={false} />
        </th>
      );
      continue;
    }

    rowContent.push(
      <td data-label={labels?.at(index)}>
        <PrismicStructuredText text={cell} transformLoneLinksAsButton={false} />
      </td>
    );
  }

  return className ? <tr className={className}>{rowContent}</tr> : <tr>{rowContent}</tr>;
};
