import { BehaviorSubject } from 'rxjs';
import { distinctUntilChanged } from 'rxjs/operators';
import { IsBrowserEnvironment } from './BrowserHelpers';

const ViewportHeightSubject = new BehaviorSubject<number>(0);

export const CurrentViewportHeight$ = ViewportHeightSubject.pipe(
  distinctUntilChanged()
);

export const RefreshViewportHeight = IsBrowserEnvironment
  ? () => {
      const vh = window.innerHeight * 0.01;
      ViewportHeightSubject.next(vh);
    }
  : () => {
      /* NOOP */
    };

if (IsBrowserEnvironment) {
  let cancelToken: number | undefined = undefined;

  window.addEventListener('resize', _ev => {
    if (cancelToken !== undefined) {
      window.cancelAnimationFrame(cancelToken);
    }

    cancelToken = window.requestAnimationFrame(() => {
      RefreshViewportHeight();
    });
  });

  CurrentViewportHeight$.subscribe(vh => {
    document.documentElement.style.setProperty('--vh-unit', `${vh}px`);
  });
}
