import {
  Maybe,
  PrismicTaxonomyTravelType,
  PrismicTravelPartner,
  PrismicTravelTrip,
  PrismicTravelTripsSearchPage,
} from '../../../graphql';
import React, { Component, ReactNode } from 'react';
import PrismicStructuredText from '../../controls/PrismicStructuredText';
import PrismicImage from '../../controls/PrismicImage';
import WithGlobalResources, { InjectedGlobalResourcesProps } from '../../higher-order/WithGlobalResources';
import PrismicLink from '../../controls/PrismicLink';
import { IsValidPrismicLink, ToMoment } from '../../../utils/PrismicHelpers';
import { graphql, StaticQuery } from 'gatsby';
import RandomAgent from '../../fragments/travel-agent/RandomAgent';
import KeyValueList, { KeyValuePair } from '../../controls/KeyValueList';
import { ResolveTravelLinkItems } from '../../../utils/DynamicLinksHelper';

export const TravelTripContentSliceKey = '!internal_travel_trip_content_slice';

const TravelTripContentSlice = WithGlobalResources(
  class TravelTripContentSliceImpl extends Component<TravelTripContentSliceProps & InjectedGlobalResourcesProps> {
    render(): ReactNode {
      return <StaticQuery query={TravelTripContentSliceQuery} render={(results) => this.renderWithData(results)} />;
    }

    renderWithData(data: TravelTripContentSliceQueryResults): ReactNode {
      const { pageData, globalResources } = this.props;

      const partner = (pageData?.data?.travel_partner?.document || undefined) as PrismicTravelPartner | undefined;

      let titleElement: ReactNode = null;

      if (pageData?.data?.page_title) {
        titleElement = (
          <div className="neo-section-title">
            <h1>{pageData?.data?.page_title}</h1>
          </div>
        );
      }

      const actionButtons: ReactNode[] = [];
      const keyValuePairs: KeyValuePair[] = [];

      const contentElement: ReactNode = <PrismicStructuredText text={pageData?.data?.description} />;

      if (pageData?.data?.date_display_override) {
        keyValuePairs.push({
          key: globalResources.travel_trip_page_date_header || '',
          value: pageData?.data?.date_display_override,
        });
      } else if (pageData?.data?.precise_date_start) {
        const startDate = ToMoment(pageData?.data?.precise_date_start);
        const endDate = ToMoment(pageData?.data?.precise_date_end);

        if (startDate) {
          let formattedDate = startDate.format('MMMM Do, YYYY');

          if (endDate) {
            if (endDate.format('YYYY-MM-DD') === startDate.format('YYYY-MM-DD')) {
              // Nothing to do
            } else if (endDate.format('YYYY-MM') === startDate.format('YYYY-MM')) {
              formattedDate = `${startDate.format('MMMM Do')} – ${endDate.format('Do, YYYY')}`;
            } else if (endDate.format('YYYY') === startDate.format('YYYY')) {
              formattedDate = `${startDate.format('MMMM Do')} – ${endDate.format('MMMM Do, YYYY')}`;
            } else {
              formattedDate = `${startDate.format('MMMM Do, YYYY')} – ${endDate.format('MMMM Do, YYYY')}`;
            }
          }

          keyValuePairs.push({
            key: globalResources.travel_trip_page_date_header || '',
            value: formattedDate,
          });
        }
      }

      if (pageData?.data?.price) {
        keyValuePairs.push({
          key: globalResources.travel_trip_page_price_header || '',
          value: pageData?.data?.price,
        });
      }

      let partnersNode: ReactNode = null;
      if (IsValidPrismicLink(pageData?.data?.travel_partner)) {
        partnersNode = (
          <div className="columns is-variable is-centered is-2 multiline">
            <div className="column is-half has-text-centered">
              <PrismicLink to={pageData?.data?.travel_partner} title={partner?.data?.page_title || undefined}>
                <PrismicImage
                  image={partner?.data?.partner_logo ?? null}
                  className="has-max-height-180"
                  imgStyle={{ objectFit: 'contain' }}
                  aria-label={partner?.data?.page_title ?? undefined}
                />
              </PrismicLink>
            </div>
          </div>
        );
      }

      ResolveTravelLinkItems(globalResources.travel_trip_page_additional_cta, {
        travelPartner: partner,
      }).forEach((item) => {
        actionButtons.push(
          <PrismicLink to={item.link_dest} className="button is-medium is-caa-lightgray has-text-weight-bold">
            {item.link_text}
          </PrismicLink>
        );
      });

      if (IsValidPrismicLink(pageData?.data?.cta_link_dest) && pageData?.data?.cta_title) {
        actionButtons.push(
          <PrismicLink
            to={pageData?.data?.cta_link_dest}
            className="button is-medium is-caa-forestgreen has-text-weight-bold"
          >
            {pageData?.data?.cta_title}
          </PrismicLink>
        );
      }

      const actionButtonsNode = actionButtons.length ? (
        <div className="field is-grouped is-grouped-centered is-grouped-multiline">
          {actionButtons.map((node, index) => (
            <div key={index} className="control">
              {node}
            </div>
          ))}
        </div>
      ) : null;

      let typeTagsNode: ReactNode = null;

      if (pageData?.data?.travel_types?.length) {
        typeTagsNode = (
          <div className="neo-travel-trip-tags">
            <p className="is-size-6 has-text-weight-bold">{globalResources.travel_trip_page_tags_listing_header}</p>
            <div className="columns is-variable is-2 is-mobile">
              {pageData?.data?.travel_types
                ?.filter((d) => d?.travel_type?.document?.id)
                .map((item, index) => {
                  const travelType = item?.travel_type?.document as Maybe<PrismicTaxonomyTravelType>;

                  return (
                    <div className="column is-narrow" key={index}>
                      <PrismicLink
                        to={data.prismicTravelTripsSearchPage}
                        fragmentQuery={{
                          travelType: travelType?.id,
                        }}
                        className="button is-small has-text-weight-semibold"
                      >
                        {travelType?.data?.name}
                      </PrismicLink>
                    </div>
                  );
                })}
            </div>
          </div>
        );
      }

      return (
        <div className="container neo-travel-trip">
          {titleElement}

          <div className="columns is-variable is-8">
            <div className="column is-two-thirds content">
              <KeyValueList keyValues={keyValuePairs} />
              {contentElement}
              {partnersNode}
              {actionButtonsNode}
            </div>
            <div className="column has-border-left-caa-lightgray has-no-border-mobile">
              <div className="neo-travel-trip-image">
                <PrismicImage image={pageData?.data?.trip_image ?? null} />
              </div>
              <RandomAgent
                agentType="Travel"
                travelDestinations={[]}
                travelStoreIds={[]}
                travelTypes={[]}
                travelPartners={[partner?.id]}
              />
            </div>
          </div>

          {typeTagsNode}
        </div>
      );
    }
  }
);
export default TravelTripContentSlice;

const TravelTripContentSliceQuery = graphql`
  query TravelTripContentSliceQuery {
    prismicTravelTripsSearchPage {
      id
      url
      uid
    }
  }
`;

interface TravelTripContentSliceQueryResults {
  prismicTravelTripsSearchPage?: PrismicTravelTripsSearchPage;
}

export interface TravelTripContentSliceProps {
  sliceData: any;
  pageData?: PrismicTravelTrip;
}
