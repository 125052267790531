import React, { Component, ReactNode } from 'react';
import { graphql, PageProps } from 'gatsby';
import {
  withPrismicPreview,
  WithPrismicPreviewProps,
} from 'gatsby-plugin-prismic-previews';

import Layout from '../components/Layout';
import {
  Maybe,
  PrismicMerchandise,
  PrismicMerchandiseDataBodySlicesType,
  PrismicPageDataBodyBreadcrumbs,
  PrismicPageDataBodySlicesType,
} from '../graphql';
import SEO from '../components/controls/SEO';
import PageSlicesRender from '../components/controls/PageSlicesRender';
import { BreadcrumbsSliceKey } from '../components/slices/BreadcrumbsSlice';
import { MerchandiseContentSliceKey } from '../components/slices/internal/MerchandiseContentSlice';
import WithGlobalResources, {
  InjectedGlobalResourcesProps,
} from '../components/higher-order/WithGlobalResources';
import NotFoundPage from './NotFoundPage';
import { StoreOpeningHoursSliceKey } from '../components/slices/internal/TravelStoreContentSlice';

type SlicesTypes = Maybe<
  PrismicPageDataBodySlicesType | PrismicMerchandiseDataBodySlicesType
>;

type MerchandiseTemplateProps = {
  data: {
    prismicMerchandise: PrismicMerchandise;
  };
} & InjectedGlobalResourcesProps &
  WithPrismicPreviewProps<PrismicMerchandise> &
  PageProps<PrismicMerchandise>;

class MerchandiseTemplate extends Component<MerchandiseTemplateProps> {
  render() {
    const { globalResources } = this.props;
    const pageNode = this.props.data?.prismicMerchandise;
    const pageComponents: ReactNode[] = [];

    if (!pageNode) {
      return <NotFoundPage location={this.props.location} />;
    }

    pageComponents.push(
      <SEO
        key="page-seo-item"
        title={pageNode.data?.page_title ?? ''}
        description={pageNode.data?.share_description ?? undefined}
        fbImage={
          pageNode.data?.og_image?.localFile?.childImageSharp?.gatsbyImageData
        }
        slug={pageNode.url ?? undefined}
        titleTemplate={
          pageNode.data?.use_page_title_template === 'no'
            ? '{pageTitle}'
            : undefined
        }
        meta={
          pageNode.data?.indexable === 'no'
            ? [
                {
                  name: `robots`,
                  content: `noindex`,
                },
              ]
            : undefined
        }
      />
    );

    const normalizedPageNodes: SlicesTypes[] = [];

    normalizedPageNodes.push({
      slice_type: BreadcrumbsSliceKey,
      primary: {
        include_home: 'yes',
        include_self: 'yes',
      },
      items: globalResources?.merchandise_page_breadcrumb || [],
    } as PrismicPageDataBodyBreadcrumbs);

    if (pageNode?.data?.body?.length) {
      for (const slice of pageNode.data.body) {
        if (slice?.slice_type === StoreOpeningHoursSliceKey) {
          continue;
        }
        normalizedPageNodes.push(slice);
      }
    }

    normalizedPageNodes.push({
      slice_type: MerchandiseContentSliceKey,
    } as PrismicPageDataBodySlicesType);

    pageComponents.push(
      <PageSlicesRender
        key="slices"
        body={normalizedPageNodes}
        pageNode={pageNode}
      />
    );

    return <Layout location={this.props.location}>{pageComponents}</Layout>;
  }
}

export default withPrismicPreview(WithGlobalResources(MerchandiseTemplate));

export const pageQuery = graphql`
  query PrismicMerchandiseQuery($nodeId: String!) {
    prismicMerchandise(id: { eq: $nodeId }) {
      _previewable
      id
      url
      data {
        page_title
        share_description
        share_title
        indexable
        og_image {
          alt
          url
          gatsbyImageData(
            width: 1200
            height: 630
            backgroundColor: "rgba(255,255,255,1)"
            placeholder: NONE
            layout: FIXED
          )
        }
        use_page_title_template
        member_price
        non_member_price
        sale_price
        sale_expiry
        product_description {
          html
        }
        legal_text {
          html
        }
        product_images {
          image {
            alt
            url
            gatsbyImageData(width: 960, layout: CONSTRAINED)
          }
        }
        merchandise_categories {
          merchandise_category {
            document {
              ... on PrismicTaxonomyMerchandiseCategory {
                id
                data {
                  name
                }
              }
            }
          }
        }
        body {
          ... on PrismicMerchandiseDataBodyHeroImage {
            ...SliceHeroImageFragment
          }
          ... on PrismicMerchandiseDataBodyCollapsableContent {
            ...SliceCollapsableContentFragment
          }
          ... on PrismicMerchandiseDataBodyIconLinkList {
            ...SliceIconLinkListFragment
          }
          ... on PrismicMerchandiseDataBodyImageLinkCards {
            ...SliceImageLinkCardsFragment
          }
          ... on PrismicMerchandiseDataBodyImageLinkTiles {
            ...SliceImageLinkTilesFragment
          }
          ... on PrismicMerchandiseDataBodyMarketingHero {
            ...SliceMarketingHeroFragment
          }
          ... on PrismicMerchandiseDataBodyTextContent {
            ...SliceTextContentFragment
          }
          ... on PrismicMerchandiseDataBodyCarousel {
            ...SliceCarouselFragment
          }
          ... on PrismicMerchandiseDataBodyMultiColumnContent {
            ...SliceMultiColumnContentFragment
          }
          ... on PrismicMerchandiseDataBodySimpleLinkTiles {
            ...SliceSimpleLinkTilesFragment
          }
          ... on PrismicMerchandiseDataBodyYoutubeVideo {
            ...SliceYoutubeVideoFragment
          }
          ... on PrismicMerchandiseDataBodyContentListing {
            ...SliceContentListingFragment
          }
          ... on PrismicMerchandiseDataBodyTravelOffers {
            ...SliceTravelOffersFragment
          }
        }
      }
    }
  }
`;
