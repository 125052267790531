import React, { Component, ReactNode } from 'react';
import { PrismicTravelStore } from '../../../graphql';
import WithGlobalResources, {
  InjectedGlobalResourcesProps,
} from '../../higher-order/WithGlobalResources';
import ImageLinkCompactTile from '../../controls/ImageLinkCompactTile';

const TravelStore = WithGlobalResources(
  class TravelStoreImpl extends Component<
    TravelStoreProps & InjectedGlobalResourcesProps
  > {
    render(): ReactNode {
      const { store } = this.props;

      if (!store) {
        return null;
      }

      return (
        <ImageLinkCompactTile
          link_dest={store}
          image={store?.data?.store_image}
          text={store?.data?.page_title}
        />
      );
    }
  }
);
export default TravelStore;

export interface TravelStoreProps {
  store?: PrismicTravelStore | null;
}
