import {
  PrismicMerchandise,
  PrismicMerchandiseConnection,
} from '../../graphql';
import React, { Component, ComponentType } from 'react';
import { graphql, StaticQuery } from 'gatsby';
import { Subset } from '../../utils/UtilityTypes';
import moment from 'moment-timezone';
import { ToMoment } from '../../utils/PrismicHelpers';

const regexPriceDetect = /(?:\$\s*((?:[0-9]+[ ,]?)*[0-9]+(?:\.[0-9]{2})?)(?:$|[^0-9., ]|\.$|[., ][^0-9])|(?:^|[^0-9., ]|[^0-9][., ])((?:[0-9]+[ ,]?)*[0-9]+(?:\.[0-9]{2})?)\s*\$)/;

const WithMerchandiseData = function WithMerchandiseDataFn<
  P extends InjectedMerchandiseDataProps
>(
  ComposedComponent: ComponentType<P>
): ComponentType<Subset<P, InjectedMerchandiseDataProps>> {
  return class extends Component<Subset<P, InjectedMerchandiseDataProps>> {
    render() {
      return (
        <StaticQuery
          query={MerchandiseDataQuery}
          render={queryResult => {
            const {
              allPrismicMerchandise,
            } = queryResult as MerchandiseDataQueryResults;

            const detectPriceFromString = (str: string | null | undefined) => {
              if (!str) {
                return undefined;
              }

              const matches = str.match(regexPriceDetect);

              if (!matches) {
                return undefined;
              }

              if (matches[1]) {
                return parseFloat(matches[1].replace(/[^0-9.]/g, ''));
              }

              if (matches[2]) {
                return parseFloat(matches[2].replace(/[^0-9.]/g, ''));
              }

              return undefined;
            };

            const merchandiseData = (allPrismicMerchandise.nodes || []).map(
              node => {
                const hasSaleActive =
                  !!node?.data?.sale_price &&
                  !!(
                    !node?.data?.sale_expiry ||
                    ToMoment(node?.data?.sale_expiry)?.isAfter(moment(), 'day')
                  );

                let numericPrice: number | undefined;

                if (hasSaleActive) {
                  numericPrice = detectPriceFromString(node?.data?.sale_price);
                } else {
                  numericPrice = detectPriceFromString(
                    node?.data?.member_price
                  );
                }

                return {
                  ...node,
                  hasSaleActive,
                  numericPrice,
                } as ExtendedPrismicMerchandise;
              }
            );

            return (
              <ComposedComponent
                {...(this.props as any)}
                merchandiseData={merchandiseData}
              />
            );
          }}
        />
      );
    }
  };
};
export default WithMerchandiseData;

const MerchandiseDataQuery = graphql`
  query MerchandiseDataQuery {
    allPrismicMerchandise(sort: { fields: data___page_title, order: ASC }) {
      nodes {
        ...MerchandiseFragment
      }
    }
  }
`;

interface MerchandiseDataQueryResults {
  allPrismicMerchandise: PrismicMerchandiseConnection;
}

// noinspection JSUnusedGlobalSymbols
export const MerchandiseFragment = graphql`
  fragment MerchandiseFragment on PrismicMerchandise {
    ...PrismicDocumentLinkFragment
    data {
      page_title
      member_price
      non_member_price
      sale_price
      sale_expiry
      product_short_description {
        html
      }
      product_images {
        image {
          alt
          url
          gatsbyImageData(width: 960, placeholder: NONE, layout: CONSTRAINED)
        }
      }
      travel_types {
        travel_type {
          document {
            ... on PrismicTaxonomyTravelType {
              id
            }
          }
        }
      }
      merchandise_categories {
        merchandise_category {
          document {
            ... on PrismicTaxonomyMerchandiseCategory {
              id
              data {
                name
              }
            }
          }
        }
      }
    }
  }
`;

export interface InjectedMerchandiseDataProps {
  merchandiseData: ExtendedPrismicMerchandise[];
}

export interface ExtendedPrismicMerchandise extends PrismicMerchandise {
  numericPrice?: number;
  hasSaleActive: boolean;
}
