import React, {Component, ReactNode} from 'react';
import {Maybe, PrismicTravelStore, PrismicTravelTalk} from '../../../graphql';
import ImageLinkTile from '../../controls/ImageLinkTile';
import WithGlobalResources, {InjectedGlobalResourcesProps,} from '../../higher-order/WithGlobalResources';
import PrismicStructuredText from '../../controls/PrismicStructuredText';
import PrismicLink from '../../controls/PrismicLink';
import {IsValidPrismicLink, ToMoment} from '../../../utils/PrismicHelpers';
import {ResolveTravelLinkItems} from '../../../utils/DynamicLinksHelper';

const TravelTalk = WithGlobalResources(
  class TravelTalkImpl extends Component<TravelTalkProps & InjectedGlobalResourcesProps> {
    render(): ReactNode {
      const {talk, globalResources} = this.props;

      if (!talk) {
        return null;
      }

      let talkNameNode: ReactNode = null;
      if (talk?.data?.page_title) {
        talkNameNode = (
          <div className="image-link-tile-talk-name">
            {talk?.data?.page_title}
          </div>
        );
      }

      let talkDescriptionNode: ReactNode = null;
      if (talk?.data?.event_short_description?.html) {
        talkDescriptionNode = (
          <div className="image-link-tile-talk-description">
            <PrismicStructuredText text={talk?.data?.event_short_description}/>
          </div>
        );
      }

      let talkDateNode: ReactNode = null;
      if (talk?.data?.event_start_time) {
        const startDate = ToMoment(talk?.data?.event_start_time);

        if (startDate) {
          talkDateNode = (
            <React.Fragment>
              <strong>{startDate.format('MMM Do')}</strong> at{' '}
              {startDate.format('h:mm A')}
            </React.Fragment>
          );
        }
      }

      const storeLocation = (talk?.data?.caa_travel_store?.document as Maybe<PrismicTravelStore>)?.data?.page_title;

      let rsvpButton: ReactNode = null;
      if (IsValidPrismicLink(talk?.data?.rsvp_link_dest)) {
        rsvpButton = (
          <PrismicLink
            to={talk?.data?.rsvp_link_dest}
            className="button has-text-weight-bold is-caa-redorange"
          >
            {globalResources.travel_talk_card_rsvp_cta}
          </PrismicLink>
        );
      }

      const bottomNavigationItems = ResolveTravelLinkItems(
        globalResources.travel_talk_card_nav_items,
        {
          store: talk?.data?.caa_travel_store?.document as PrismicTravelStore,
        }
      ).map((item, index) => (
        <li key={index}>
          <PrismicLink to={item.link_dest}>{item.link_text}</PrismicLink>
        </li>
      ));

      bottomNavigationItems.push(
        <li key={'learn-more'}>
          <PrismicLink to={talk}>
            {globalResources.travel_talk_card_learn_more_cta}
          </PrismicLink>
        </li>
      );

      let bottomNavigation = null;
      if (bottomNavigationItems) {
        bottomNavigation = (
          <ul className={'image-link-tile-bottom-links'}>
            {bottomNavigationItems}
          </ul>
        );
      }

      return (
        <ImageLinkTile
          className={'is-travel-talk-tile'}
          image={talk?.data?.event_image}
          tag_icon={'map-marker-alt'}
          tag_label={storeLocation}
          crown_label={globalResources.travel_talk_card_header_text}
          crown_icon={'comment-lines'}
          image_bottom_content={talkDateNode}
          image_bottom_icon={'calendar-alt'}
          custom_content={
            <React.Fragment>
              <div className="tile-content-main">
                {talkNameNode}
                {talkDescriptionNode}
              </div>
              {rsvpButton}
              {bottomNavigation}
            </React.Fragment>
          }
        />
      );
    }
  }
);
export default TravelTalk;

export interface TravelTalkProps {
  talk?: PrismicTravelTalk | null;
}
