import React, {
  Component,
  ComponentClass,
  ReactNode,
} from 'react';
import Helmet from 'react-helmet';
import { graphql, StaticQuery } from 'gatsby';
import WithGlobalResources, {
  InjectedGlobalResourcesProps,
} from '../higher-order/WithGlobalResources';

const SEOWithoutResources = class extends Component<
  SEOProps & SEODefaultProps & InjectedGlobalResourcesProps
> {
  static defaultProps: Partial<
    SEOProps & SEODefaultProps & InjectedGlobalResourcesProps
  > = {
    link: [],
    meta: [],
    keywords: [],
    invertDefaultTitleTemplate: false,
  };

  render(): ReactNode {
    const {
      description,
      link,
      meta,
      keywords,
      title,
      titleTemplate,
      globalResources,
      fbImage,
      twitterImage,
      twitterCardType = 'summary_large_image',
      slug,
      canonical,
    } = this.props;

    const properTitleTemplate = titleTemplate
      ? titleTemplate
      : globalResources.page_title_template || '{pageTitle}';

    return (
      <StaticQuery
        query={query}
        render={(data) => {
          const resolvedLang = 'en';
          const { site } = data;
          const siteUrl = site.siteMetadata.siteUrl;
          const metaDescription =
            description ?? globalResources.default_share_description;
          const resolvedTitle = properTitleTemplate.replace(
            '{pageTitle}',
            title
          );
          let ogUrl: string | undefined;

          if (canonical) {
            ogUrl = canonical;
          } else if (slug) {
            ogUrl = site.siteMetadata.siteUrl + slug;
          }

          const resolvedFbImage =
            fbImage ??
            (globalResources.default_og_image?.localFile?.childImageSharp
              ?.gatsbyImageData as Record<string, unknown>);
          const resolvedTwitterImage = twitterImage ?? resolvedFbImage;
          const defaultLinkTags: LinkProps[] = [];
          const defaultMetaTags = [
            {
              name: `description`,
              content: metaDescription,
            },
            {
              property: `og:title`,
              content: resolvedTitle,
            },
            {
              property: `og:description`,
              content: metaDescription,
            },
            {
              property: `og:type`,
              content: `website`,
            },
            {
              name: `twitter:card`,
              content: twitterCardType,
            },
            {
              name: `twitter:creator`,
              content: globalResources.twitter_site,
            },
            {
              name: `twitter:site`,
              content: globalResources.twitter_site,
            },
            {
              name: `twitter:title`,
              content: resolvedTitle,
            },
            {
              name: `twitter:description`,
              content: metaDescription,
            },
            {
              name: `ci:deployed-version`,
              content: site.siteMetadata!.commitSha,
            },
            {
              name: `ci:deployed-env`,
              content: site.siteMetadata!.siteEnv,
            },
            {
              name: `facebook-domain-verification`,
              content: `ai4ehv7yi7x0iwbu7gpysudevn4st1`,
            },
          ] as MetaProps[];
          const asStringOrGetSrc = (
            val: string | Record<string, unknown>
          ): string => {
            if (typeof val === 'string') {
              return val;
            }

            return val.src as string;
          };

          if (ogUrl) {
            defaultMetaTags.push({
              name: `og:url`,
              content: ogUrl,
            });
          }

          if (canonical) {
            defaultLinkTags.push({
              rel: 'canonical',
              key: canonical,
              href: canonical,
            });
          }

          if (resolvedFbImage) {
            const fbImageUrl = asStringOrGetSrc(resolvedFbImage);

            defaultMetaTags.push({
              property: `og:image`,
              content: siteUrl + fbImageUrl,
            });

            if (typeof resolvedFbImage !== 'string') {
              if (resolvedFbImage.width) {
                defaultMetaTags.push({
                  name: `og:image:width`,
                  content: `${resolvedFbImage.width}`,
                });
              }

              if (resolvedFbImage.height) {
                defaultMetaTags.push({
                  name: `og:image:height`,
                  content: `${resolvedFbImage.height}`,
                });
              }
            }
          }

          if (resolvedTwitterImage) {
            const twitterImageUrl = asStringOrGetSrc(resolvedTwitterImage);

            defaultMetaTags.push({
              name: `twitter:image`,
              content: siteUrl + twitterImageUrl,
            });
          }

          if (keywords.length > 0) {
            defaultMetaTags.push({
              name: `keywords`,
              content: keywords.join(`, `),
            });
          }

          return (
            <Helmet
              htmlAttributes={{
                lang: resolvedLang,
              }}
              title={resolvedTitle}
              link={defaultLinkTags.concat(link)}
              meta={defaultMetaTags.concat(meta)}
            />
          );
        }}
      />
    );
  }
};

const query = graphql`
  query {
    site {
      siteMetadata {
        siteUrl
        siteEnv
        commitSha
      }
    }
  }
`;

const SEO = WithGlobalResources(
  SEOWithoutResources
) as ComponentClass<SEOProps>;
export default SEO;

type LinkProps = JSX.IntrinsicElements['link'];

type MetaProps = JSX.IntrinsicElements['meta'];

export interface SEOProps extends Partial<SEODefaultProps> {
  description?: string;
  title: string;
  slug?: string;
  canonical?: string;
}

interface SEODefaultProps {
  lang?: string;
  link: LinkProps[];
  meta: MetaProps[];
  keywords: string[];
  titleTemplate?: string;
  fbImage?: string | Record<string, unknown>;
  twitterCardType: 'summary' | 'summary_large_image';
  twitterImage?: string | Record<string, unknown>;
  invertDefaultTitleTemplate: boolean;
}
