import React, { Component, ReactNode } from 'react';
import { PrismicPage, PrismicPageDataBodyIconLinkList } from '../../graphql';
import { IIconLinkData } from '../controls/IconLink';
import IconLinkList from '../fragments/IconLinkList';
import { graphql } from 'gatsby';
import SectionTitle from '../controls/SectionTitle';

export const IconLinkListSliceKey = 'icon_link_list';
export default class IconLinkListSlice extends Component<IconLinkListSliceProps> {
  render(): ReactNode {
    const { sliceData } = this.props;

    if (!sliceData.items?.length) {
      return <React.Fragment />;
    }

    const linkData: IIconLinkData[] = [];

    for (const item of sliceData.items) {
      if (!item) continue;

      linkData.push({
        text: item.link_title,
        icon: item.link_icon,
        iconSize: '3x',
        link_dest: item.link_dest,
      });
    }

    return (
      <div className="container neo-icon-list">
        <SectionTitle component={sliceData.primary?.section_title} />
        <IconLinkList iconLinkListData={linkData} />
      </div>
    );
  }
}

export interface IconLinkListSliceProps {
  sliceData: PrismicPageDataBodyIconLinkList;
  pageData?: PrismicPage;
}

// noinspection JSUnusedGlobalSymbols
export const SliceIconLinkListFragment = graphql`
  fragment SliceIconLinkListFragment on PrismicSlicesIconLinkList {
    ... on PrismicPageDataBodyIconLinkList {
      id
      slice_type
      primary {
        section_title {
          html
        }
      }
      items {
        link_icon
        link_title
        link_dest {
          ...PrismicLinkFragment
        }
      }
    }
    ... on PrismicEmailSubscriptionPageDataBodyIconLinkList {
      id
      slice_type
      primary {
        section_title {
          html
        }
      }
      items {
        link_icon
        link_title
        link_dest {
          ...PrismicLinkFragment
        }
      }
    }
    ... on PrismicErrorPageDataBodyIconLinkList {
      id
      slice_type
      primary {
        section_title {
          html
        }
      }
      items {
        link_icon
        link_title
        link_dest {
          ...PrismicLinkFragment
        }
      }
    }
    ... on PrismicGaragesSearchPageDataBodyIconLinkList {
      id
      slice_type
      primary {
        section_title {
          html
        }
      }
      items {
        link_icon
        link_title
        link_dest {
          ...PrismicLinkFragment
        }
      }
    }
    ... on PrismicMerchandiseSearchPageDataBodyIconLinkList {
      id
      slice_type
      primary {
        section_title {
          html
        }
      }
      items {
        link_icon
        link_title
        link_dest {
          ...PrismicLinkFragment
        }
      }
    }
    ... on PrismicSearchPageDataBodyIconLinkList {
      id
      slice_type
      primary {
        section_title {
          html
        }
      }
      items {
        link_icon
        link_title
        link_dest {
          ...PrismicLinkFragment
        }
      }
    }
    ... on PrismicTravelAgentsSearchPageDataBodyIconLinkList {
      id
      slice_type
      primary {
        section_title {
          html
        }
      }
      items {
        link_icon
        link_title
        link_dest {
          ...PrismicLinkFragment
        }
      }
    }
    ... on PrismicTravelDealsSearchPageDataBodyIconLinkList {
      id
      slice_type
      primary {
        section_title {
          html
        }
      }
      items {
        link_icon
        link_title
        link_dest {
          ...PrismicLinkFragment
        }
      }
    }
    ... on PrismicTravelPartnersSearchPageDataBodyIconLinkList {
      id
      slice_type
      primary {
        section_title {
          html
        }
      }
      items {
        link_icon
        link_title
        link_dest {
          ...PrismicLinkFragment
        }
      }
    }
    ... on PrismicTravelStoreSearchPageDataBodyIconLinkList {
      id
      slice_type
      primary {
        section_title {
          html
        }
      }
      items {
        link_icon
        link_title
        link_dest {
          ...PrismicLinkFragment
        }
      }
    }
    ... on PrismicTravelTalksSearchPageDataBodyIconLinkList {
      id
      slice_type
      primary {
        section_title {
          html
        }
      }
      items {
        link_icon
        link_title
        link_dest {
          ...PrismicLinkFragment
        }
      }
    }
    ... on PrismicTravelTripsSearchPageDataBodyIconLinkList {
      id
      slice_type
      primary {
        section_title {
          html
        }
      }
      items {
        link_icon
        link_title
        link_dest {
          ...PrismicLinkFragment
        }
      }
    }
    ... on PrismicTravelStoreDataBodyIconLinkList {
      id
      slice_type
      primary {
        section_title {
          html
        }
      }
      items {
        link_icon
        link_title
        link_dest {
          ...PrismicLinkFragment
        }
      }
    }
    ... on PrismicTravelTalkDataBodyIconLinkList {
      id
      slice_type
      primary {
        section_title {
          html
        }
      }
      items {
        link_icon
        link_title
        link_dest {
          ...PrismicLinkFragment
        }
      }
    }
    ... on PrismicTravelTripDataBodyIconLinkList {
      id
      slice_type
      primary {
        section_title {
          html
        }
      }
      items {
        link_icon
        link_title
        link_dest {
          ...PrismicLinkFragment
        }
      }
    }
    ... on PrismicTravelDealDataBodyIconLinkList {
      id
      slice_type
      primary {
        section_title {
          html
        }
      }
      items {
        link_icon
        link_title
        link_dest {
          ...PrismicLinkFragment
        }
      }
    }
    ... on PrismicMerchandiseDataBodyIconLinkList {
      id
      slice_type
      primary {
        section_title {
          html
        }
      }
      items {
        link_icon
        link_title
        link_dest {
          ...PrismicLinkFragment
        }
      }
    }
    ... on PrismicTravelPartnerDataBodyIconLinkList {
      id
      slice_type
      primary {
        section_title {
          html
        }
      }
      items {
        link_icon
        link_title
        link_dest {
          ...PrismicLinkFragment
        }
      }
    }
  }
`;
