import { PrismicTravelStore, PrismicTravelStoreDataBodyOpeningHours } from '../../../graphql';
import React, { Component, ReactNode } from 'react';
import PrismicImage from '../../controls/PrismicImage';
import WithGlobalResources, { InjectedGlobalResourcesProps } from '../../higher-order/WithGlobalResources';
import { Marker } from 'react-leaflet';
import { LeafletMap } from '../../controls/LeafletMap';
import { LatLngLiteral } from 'leaflet';
import Control from 'react-leaflet-control';
import PrismicLink from '../../controls/PrismicLink';
import { IsValidPrismicLink } from '../../../utils/PrismicHelpers';

export const TravelStoreContentSliceKey = '!internal_travel_store_content_slice';
export const StoreOpeningHoursSliceKey = 'opening_hours';

const TravelStoreContentSlice = WithGlobalResources(
  class TravelStoreContentSliceImpl extends Component<TravelStoreContentSliceProps & InjectedGlobalResourcesProps> {
    render(): ReactNode {
      const { pageData, globalResources } = this.props;

      let titleElement: ReactNode = null;

      if (pageData?.data?.page_title) {
        titleElement = (
          <div className="neo-section-title">
            <h1>{pageData?.data?.page_title}</h1>
          </div>
        );
      }

      let addressElement: ReactNode = null;
      if (pageData?.data?.address) {
        addressElement = (
          <React.Fragment>
            <h3>{globalResources.travel_store_location_header}</h3>
            <address>{pageData?.data?.address}</address>
          </React.Fragment>
        );
      }

      let mapElement: ReactNode = null;
      if (pageData?.data?.location?.latitude && pageData?.data?.location?.longitude) {
        const position = {
          lat: pageData.data.location.latitude,
          lng: pageData.data.location.longitude,
        };

        mapElement = (
          <div className="column neo-travel-store-map-column">
            <LeafletMap center={position} zoom={16} style={{ height: '100%' }}>
              <Marker position={position} />
              <Control position={'topright'}>
                <button
                  className="button is-small is-white has-border-caa-gray has-text-weight-bold"
                  onClick={(_event) => this.openMap(position, pageData?.data?.address)}
                >
                  {globalResources.travel_store_view_google_map_button}
                </button>
              </Control>
            </LeafletMap>
          </div>
        );
      }

      let storeHoursElement: ReactNode = null;
      const validOpeningHours = (pageData?.data?.opening_hours || []).filter((o) => o?.hours_label) as StoreHours[];
      const openingHoursSlices = (pageData?.data?.body || []).filter(
        (s) => s?.slice_type === StoreOpeningHoursSliceKey
      ) as PrismicTravelStoreDataBodyOpeningHours[];

      if (validOpeningHours.length || openingHoursSlices.length) {
        const storeHours: StoreHoursGroup[] = [];

        if (validOpeningHours.length) {
          storeHours.push({
            groupName: globalResources.travel_store_store_hours_header || '',
            hours: validOpeningHours,
          });
        }

        for (const slice of openingHoursSlices) {
          const validSliceOpeningHours = (slice?.items || []).filter((o) => o?.hours_label) as StoreHours[];

          if (!validSliceOpeningHours.length) {
            continue;
          }

          storeHours.push({
            groupName: slice.primary?.title?.text || '',
            hours: validSliceOpeningHours,
          });
        }

        storeHoursElement = storeHours.map((group, index) => {
          return (
            <React.Fragment key={index}>
              <h3>{group.groupName}</h3>
              {group.hours.map((hours, hindex) => {
                return (
                  <div key={hindex} className="columns is-mobile is-gapless">
                    <div className="column is-half has-text-weight-semibold">{hours.hours_label}</div>
                    <div className="column">{hours.hours_value}</div>
                  </div>
                );
              })}
            </React.Fragment>
          );
        });
      }

      let contactInfoElement: ReactNode = null;
      const validContactInfo = (pageData?.data?.contact_information || []).filter((c) => c?.item_title);

      if (validContactInfo.length) {
        contactInfoElement = (
          <React.Fragment>
            <h3>{globalResources.travel_store_contact_information_header}</h3>
            {validContactInfo.map((contactInfo, index) => {
              return (
                <div key={index} className="columns is-mobile is-gapless">
                  <div className="column is-half has-text-weight-semibold">{contactInfo?.item_title}</div>
                  <div className="column">
                    {IsValidPrismicLink(contactInfo?.link_dest) ? (
                      <PrismicLink to={contactInfo?.link_dest}>{contactInfo?.link_text}</PrismicLink>
                    ) : (
                      contactInfo?.link_text
                    )}
                  </div>
                </div>
              );
            })}
          </React.Fragment>
        );
      }

      return (
        <div className="container neo-travel-store">
          {titleElement}

          <div className="columns is-variable is-8">
            <div className="column is-three-quarters neo-travel-store-main-content">
              <div className="columns is-variable is-4">
                <div className="column content">
                  {addressElement}
                  {storeHoursElement}
                  {contactInfoElement}
                </div>
                {mapElement}
              </div>
            </div>
            <div className="column has-border-left-caa-lightgray has-no-border-mobile">
              <div className="neo-travel-store-image">
                <PrismicImage image={pageData?.data?.store_image ?? null} />
              </div>
            </div>
          </div>
        </div>
      );
    }

    openMap = (position: LatLngLiteral, address: string | null | undefined) => {
      const query = address ? address : `${position.lat},${position.lng}`;
      window.open(`https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(query)}`, '_blank');
    };
  }
);
export default TravelStoreContentSlice;

export interface TravelStoreContentSliceProps {
  sliceData: any;
  pageData?: PrismicTravelStore;
}

interface StoreHoursGroup {
  groupName?: string | null;
  hours: StoreHours[];
}

export interface StoreHours {
  hours_label?: string | null;
  hours_value?: string | null;
}
