import React, { Component, ReactNode } from 'react';
import { Node } from '../../graphql';
import { MakeRandomId } from '../../utils/RandomId';
import Select, { ValueType } from 'react-select';
import { parse as parseQueryString, stringify as toQueryString } from 'querystring';
import { GlobalResourcesProvider } from '../higher-order/WithGlobalResources';
import { Subscription } from 'rxjs';
import { Breakpoint, CurrentBreakpoint$, IsBreakpointAtMost, PointerType } from '../../utils/BreakpointHelpers';
import { IsBrowserEnvironment } from '../../utils/BrowserHelpers';

export default class TagBasedSearch<TSearchParams, TResult> extends Component<
  TagBasedSearchProps<TSearchParams, TResult>,
  TagBasedSearchState<TSearchParams, TResult>
> {
  private breakpointSubscription?: Subscription;

  constructor(props: Readonly<TagBasedSearchProps<TSearchParams, TResult>>) {
    super(props);

    const { fields } = this.props;

    const emptyParams: TagBasedSearchFieldsValues<TSearchParams> = {} as any;
    Object.keys(fields).forEach((key) => {
      const field = key as keyof TSearchParams;
      emptyParams[field] = fields[field].defaultValue || [];
    });

    this.state = {
      fields,
      elementIdPrefix: MakeRandomId('TagBasedSearch', 10),
      params: emptyParams,
      results: [],
      page: 1,
      enableSearch: true,
    };
  }

  componentDidMount(): void {
    const { fields } = this.props;

    this.breakpointSubscription = CurrentBreakpoint$.subscribe(
      newBreakpoint => {
        this.setState({
          enableSearch: !(
            IsBreakpointAtMost(newBreakpoint.breakpoint, Breakpoint.Tablet) &&
            newBreakpoint.pointerType !== PointerType.Fine
          ),
        });
      }
    );

    let executeSearch = true;

    if (IsBrowserEnvironment && window.location.hash) {
      if (window.location.hash.match(/^[?]/)) {
        const hashQuery = parseQueryString(
          window.location.hash.replace(/^[?]/, '')
        );

        const paramsFromQuery: TagBasedSearchFieldsValues<TSearchParams> = {} as any;
        Object.keys(fields).forEach(keyName => {
          const field = keyName as keyof TSearchParams;
          const config = fields[field];
          if (hashQuery[keyName]) {
            if (typeof hashQuery[keyName] === 'string') {
              hashQuery[keyName] = [hashQuery[keyName] as string];
            }

            paramsFromQuery[field] = (hashQuery[
              keyName
            ] as string[]).filter(id =>
              config.options.find(o => o.value === id)
            );
          } else {
            paramsFromQuery[field] = [];
          }
        });

        if (this.isParamsChanged(paramsFromQuery, this.state.params)) {
          this.setState({
            params: paramsFromQuery,
          });

          executeSearch = false;
        }
      }
    }

    if (executeSearch) {
      this.executeSearch();
    }
  }

  componentDidUpdate(
    _prevProps: Readonly<TagBasedSearchProps<TSearchParams, TResult>>,
    prevState: Readonly<TagBasedSearchState<TSearchParams, TResult>>,
    _snapshot?: any
  ): void {
    if (this.isParamsChanged(prevState.params, this.state.params)) {
      if (IsBrowserEnvironment) {
        const fragmentString = this.paramsToFragmentString(this.state.params);
        window.history.replaceState(
          null,
          '',
          window.location.pathname + fragmentString
        );
      }

      this.executeSearch();
    }
  }

  componentWillUnmount(): void {
    if (this.breakpointSubscription) {
      this.breakpointSubscription.unsubscribe();
    }
  }

  render() {
    const {
      render,
      renderAboveFields,
      renderBelowResults,
      maxPerPage,
    } = this.props;
    const {
      elementIdPrefix,
      params,
      results,
      page,
      enableSearch,
      fields,
    } = this.state;

    const filterFieldNodes: ReactNode[] = [];

    let pagedResults = results;
    if (typeof maxPerPage !== 'undefined') {
      pagedResults = pagedResults.slice(0, maxPerPage * page);
    }

    Object.keys(fields).forEach(fieldName => {
      const field = fieldName as keyof TSearchParams;
      const config = fields[field];

      if (!config.isVisible){
        return;
      }

      let currentValue: ValueType<TagBasedSearchOption> = params[field]
        .map(id => config.options.find(o => o.value === id))
        .filter(o => o !== undefined) as TagBasedSearchOption[];

      if (!config.allowMultiple) {
        currentValue = currentValue[0];
      }

      filterFieldNodes.push(
        <div className="column" key={fieldName}>
          <div className="field">
            <label
              className="label"
              id={`${elementIdPrefix}__${fieldName}__label`}
            >
              {config.label}
            </label>
            <div className="control">
              <Select
                aria-label={config.label}
                id={`${elementIdPrefix}__${fieldName}__select`}
                isMulti={config.allowMultiple}
                isClearable={config.requireValue !== true}
                isSearchable={enableSearch && config.isSearchable !== false}
                options={config.options}
                value={currentValue}
                onChange={value => this.onSelectValueChange(field, value)}
                styles={{
                  placeholder: provided => ({
                    ...provided,
                    color: '#1f1f1f',
                  }),
                  // Fixes the overlapping problem of the component
                  menu: provided => ({
                    ...provided,
                    zIndex: 9999,
                  }),
                }}
              />
            </div>
          </div>
        </div>
      );
    });

    let nextPageButtonNode: ReactNode = null;
    if (results.length !== pagedResults.length) {
      nextPageButtonNode = (
        <div className="field is-grouped is-grouped-centered is-grouped-multiline">
          <div className="control">
            <GlobalResourcesProvider
              render={globalResources => (
                <button
                  className="button is-medium is-caa-forestgreen has-text-weight-bold"
                  onClick={this.onNextPageButtonClick}
                >
                  {globalResources.load_more_cta_text}
                </button>
              )}
            />
          </div>
        </div>
      );
    }

    return (
      <div className="neo-tag-based-search">
        {renderAboveFields ? (
          <div className="neo-tag-based-search-above-fields">
            {renderAboveFields(pagedResults, results, params)}
          </div>
        ) : null}
        <div className="columns is-variable is-4 neo-tag-based-search-controls">
          {filterFieldNodes}
        </div>
        <div className="neo-tag-based-search-results">
          {render(pagedResults, results, params)}
        </div>
        {nextPageButtonNode}
        {renderBelowResults ? (
          <div className="neo-tag-based-search-below-results">
            {renderBelowResults(pagedResults, results, params)}
          </div>
        ) : null}
      </div>
    );
  }

  onSelectValueChange = (
    field: keyof TSearchParams,
    value: ValueType<TagBasedSearchOption>
  ) => {
    const { params, fields } = this.state;

    const config = fields[field];

    let setAsValue: string[] = [];

    if (config.allowMultiple) {
      setAsValue = Array.isArray(value) ? value.map(v => v.value) : [];
    } else if (
      !Array.isArray(value) &&
      (value as TagBasedSearchOption)?.value
    ) {
      setAsValue = [(value as TagBasedSearchOption)?.value];
    }

    const newParams: TagBasedSearchFieldsValues<TSearchParams> = {
      ...params,
    };
    newParams[field] = setAsValue;

    this.setState({
      params: newParams,
    });
  };

  onNextPageButtonClick = () => {
    const { page } = this.state;

    this.setState({
      page: page + 1,
    });
  };

  executeSearch = () => {
    const { search, filter } = this.props;
    const { params, fields } = this.state;
    const results = search(params);
    let newFields = fields;
    if (filter !== undefined) {
      newFields = filter(fields, results);
    }

    this.setState({
      results,
      fields: newFields,
      page: 1,
    });
  };

  isParamsChanged(
    paramsBase: TagBasedSearchFieldsValues<TSearchParams>,
    paramsCompare: TagBasedSearchFieldsValues<TSearchParams>
  ): boolean {
    const { fields } = this.state;

    const keys = Object.keys(fields);
    for (const fieldName of keys) {
      const field = fieldName as keyof TSearchParams;

      if (paramsBase[field].length !== paramsCompare[field].length) {
        return true;
      }

      if (
        paramsBase[field].some(
          id => paramsCompare[field].find(i => i === id) === undefined
        )
      ) {
        return true;
      }
    }

    return false;
  }

  paramsToFragmentString(params: TagBasedSearchFieldsValues<TSearchParams>) {
    const { fields } = this.state;

    const paramsForQs: Partial<Record<keyof TSearchParams, string[]>> = {};

    let generateQs = false;
    const keys = Object.keys(fields);
    for (const fieldName of keys) {
      const field = fieldName as keyof TSearchParams;

      if (!params[field].length) {
        continue;
      }

      if (
        fields[field].requireValue &&
        fields[field].defaultValue &&
        fields[field].defaultValue?.length === params[field].length &&
        params[field].every(v =>
          fields[field].defaultValue?.find(a => v === a)
        )
      ) {
        continue;
      }

      paramsForQs[field] = params[field];
      generateQs = true;
    }

    if (generateQs) {
      return `?${toQueryString(paramsForQs)}`;
    }

    return '';
  }
}

export interface TagBasedSearchProps<TSearchParams, TResult> {
  fields: TagBasedSearchFieldsConfig<TSearchParams>;
  search: (params: TagBasedSearchFieldsValues<TSearchParams>) => TResult[];
  render: (
    results: TResult[],
    allResults: TResult[],
    params: TagBasedSearchFieldsValues<TSearchParams>
  ) => ReactNode;
  renderAboveFields?: (
    results: TResult[],
    allResults: TResult[],
    params: TagBasedSearchFieldsValues<TSearchParams>
  ) => ReactNode;
  renderBelowResults?: (
    results: TResult[],
    allResults: TResult[],
    params: TagBasedSearchFieldsValues<TSearchParams>
  ) => ReactNode;
  maxPerPage?: number;
  filter?: (
    fields: TagBasedSearchFieldsConfig<TSearchParams>,
    results: TResult[]
  ) => TagBasedSearchFieldsConfig<TSearchParams>;
}

interface TagBasedSearchState<TSearchParams, TResult> {
  elementIdPrefix: string;
  params: TagBasedSearchFieldsValues<TSearchParams>;
  results: TResult[];
  page: number;
  enableSearch: boolean;
  fields: TagBasedSearchFieldsConfig<TSearchParams>;
}

export type TagBasedSearchFieldsConfig<TSearchParams> = Required<
  Record<keyof TSearchParams, TagBasedSearchFieldDefinition>
>;

type TagBasedSearchFieldsValues<TSearchParams> = Required<
  Record<keyof TSearchParams, string[]>
>;

interface TagBasedSearchFieldDefinition {
  label: string;
  options: ReadonlyArray<TagBasedSearchOption>;
  allowMultiple?: boolean;
  isSearchable?: boolean;
  requireValue?: boolean;
  defaultValue?: string[];
  isVisible?: boolean;
}

export interface TagBasedSearchOption {
  label: string;
  value: string;
}

export const NodeListToOptions = function<TNode extends Node>(
  nodes: TNode[] | undefined | null,
  mapFn: (node: TNode) => string | undefined | null
): ReadonlyArray<TagBasedSearchOption> {
  if (!nodes) {
    return [];
  }

  const options: TagBasedSearchOption[] = [];

  nodes.forEach(node => {
    if (!node?.id) {
      return;
    }

    options.push({
      value: node?.id ?? '',
      label: mapFn(node) || (node?.id ?? ''),
    });
  });

  return options;
};
