import React, { ReactNode } from 'react';
import { RowContent, RowContentType } from './RowContent';
import { TableData, TableHeader } from './TableContainer';
import { getColorClassName } from '../../../utils/TableUtility';

export interface RowTableProps {
  headers: TableHeader[];
  rows: TableData[];
  numberOfRows: number;
}

/**
 * Builds a table row by row.
 * @param props : RowTableProps The data necessary to build the table.
 *
 * @TechDebt The design of this component could be better by using an iterator.
 *           If work needs to be done in this class, it could be a good start.
 */
export const RowTable = ({ headers, rows, numberOfRows }: RowTableProps) => {
  const tableContent: ReactNode[] = [];
  let tableHeader: ReactNode | undefined;
  const numberOfColumns = Math.max(...rows.map((r) => r.content.length));
  const headerColumnExists = headers.length > 0;

  // We iterate through all rows (there could be more rows than exists in `rows`)
  for (let index = 0; index < numberOfRows; index++) {
    // We use the index, because there could be empty rows
    const row = rows.find((row) => row.index === index);
    const colorClassName = row?.color ? getColorClassName(row.color) : '';
    const rowContent = row?.content ?? [];
    let rowHeader: RowContentType;


    if (headerColumnExists) {
      rowHeader = headers.find((header) => header.index === index)?.header;
    }

    // We add the row in a thead tag if its a header and the first row
    if (row?.isHeader && index === 0) {
      tableHeader = (
        <thead>
          <RowContent
            header={rowHeader}
            addEmptyHeader={headerColumnExists && !rowHeader}
            isHeader={!!row?.isHeader}
            rowCells={rowContent}
            className={colorClassName}
            numberOfCells={numberOfColumns}
          />
        </thead>
      );
      continue;
    }

    tableContent.push(
      <RowContent
        header={rowHeader}
        addEmptyHeader={headerColumnExists && !rowHeader}
        labels={rows.find((row) => row.isHeader)?.content.map((content) => content?.text)}
        isHeader={row?.isHeader ?? false}
        rowCells={rowContent}
        className={colorClassName}
        numberOfCells={numberOfColumns}
      />
    );
  }

  return (
    <>
      {tableHeader}
      <tbody>{tableContent}</tbody>
    </>
  );
};
