import {
  Maybe,
  PrismicTaxonomyTravelType,
  PrismicTravelPartner,
  PrismicTravelStore,
  PrismicTravelTalk,
  PrismicTravelTalksSearchPage,
} from '../../../graphql';
import React, { Component, ReactNode } from 'react';
import PrismicStructuredText from '../../controls/PrismicStructuredText';
import PrismicImage from '../../controls/PrismicImage';
import WithGlobalResources, { InjectedGlobalResourcesProps } from '../../higher-order/WithGlobalResources';
import PrismicLink from '../../controls/PrismicLink';
import moment from 'moment-timezone';
import { IsValidPrismicLink, ToMoment } from '../../../utils/PrismicHelpers';
import { graphql, StaticQuery } from 'gatsby';
import KeyValueList, { KeyValuePair } from '../../controls/KeyValueList';
import { ResolveTravelLinkItems } from '../../../utils/DynamicLinksHelper';

export const TravelTalkContentSliceKey = '!internal_travel_talk_content_slice';

const TravelTalkContentSlice = WithGlobalResources(
  class TravelTalkContentSliceImpl extends Component<TravelTalkContentSliceProps & InjectedGlobalResourcesProps> {
    render(): ReactNode {
      return <StaticQuery query={TravelTalkContentSliceQuery} render={(results) => this.renderWithData(results)} />;
    }

    renderWithData(data: TravelTalkContentSliceQueryResults): ReactNode {
      const { pageData, globalResources } = this.props;

      const travelStore = pageData?.data?.caa_travel_store?.document as PrismicTravelStore | undefined | null;

      let titleElement: ReactNode = null;

      if (pageData?.data?.page_title) {
        titleElement = (
          <div className="neo-section-title">
            <h1>{pageData?.data?.page_title}</h1>
          </div>
        );
      }

      const actionButtons: ReactNode[] = [];
      const keyValuePairs: KeyValuePair[] = [];

      if (travelStore?.id && travelStore?.data?.location_type !== 'Virtual') {
        keyValuePairs.push({
          key: globalResources.travel_talk_page_location_header || '',
          value: (
            <React.Fragment>
              <PrismicLink to={travelStore}>{travelStore.data?.page_title}</PrismicLink>
              {travelStore.data?.address ? ` – ${travelStore.data?.address}` : ''}
            </React.Fragment>
          ),
        });
      }

      ResolveTravelLinkItems(globalResources.travel_talk_page_additional_cta, {
        store: travelStore,
      }).forEach((item) => {
        actionButtons.push(
          <PrismicLink to={item.link_dest} className="button is-medium is-caa-lightgray has-text-weight-bold">
            {item.link_text}
          </PrismicLink>
        );
      });

      let requireSeparateEndDateEntry = false;

      if (pageData?.data?.event_start_time) {
        const startTime = ToMoment(pageData?.data?.event_start_time);

        if (startTime) {
          let endTime: moment.Moment | undefined = undefined;

          let formattedDate = startTime.format('dddd, MMMM Do, YYYY - h:mm A');

          if (pageData?.data?.event_end_time) {
            endTime = ToMoment(pageData?.data?.event_end_time);

            if (endTime) {
              if (startTime.format('YYYY-MM-DD') === endTime.format('YYYY-MM-DD')) {
                formattedDate += ` to ${endTime.format('h:mm A')}`;
              } else {
                requireSeparateEndDateEntry = true;
              }
            }
          }

          keyValuePairs.push({
            key: globalResources.travel_talk_page_start_date_header || '',
            value: formattedDate,
          });

          if (requireSeparateEndDateEntry && endTime) {
            keyValuePairs.push({
              key: globalResources.travel_talk_page_end_date_header || '',
              value: endTime.format('dddd, MMMM Do, YYYY - h:mm A'),
            });
          }
        }
      }

      const contentElement: ReactNode = <PrismicStructuredText text={pageData?.data?.event_description} />;

      let partnersNode: ReactNode = null;
      if (pageData?.data?.travel_partners?.length) {
        partnersNode = (
          <div className="columns is-variable is-centered is-2 multiline">
            {pageData?.data?.travel_partners
              ?.filter((p) => p?.travel_partner?.document?.id)
              .map((item, index) => {
                const partner = item?.travel_partner?.document as Maybe<PrismicTravelPartner>;

                return (
                  <div className="column is-half has-text-centered" key={index}>
                    <PrismicLink to={partner} title={partner?.data?.page_title || undefined}>
                      <PrismicImage
                        image={partner?.data?.partner_logo ?? null}
                        className="has-max-height-180"
                        imgStyle={{ objectFit: 'contain' }}
                        aria-label={partner?.data?.page_title ?? undefined}
                      />
                    </PrismicLink>
                  </div>
                );
              })}
          </div>
        );
      }

      if (IsValidPrismicLink(pageData?.data?.rsvp_link_dest)) {
        actionButtons.push(
          <PrismicLink
            to={pageData?.data?.rsvp_link_dest}
            className="button is-medium is-caa-forestgreen has-text-weight-bold"
          >
            {globalResources.travel_talk_page_rsvp_cta}
          </PrismicLink>
        );
      }

      const actionButtonsNode = actionButtons.length ? (
        <div className="field is-grouped is-grouped-centered is-grouped-multiline">
          {actionButtons.map((node, index) => (
            <div key={index} className="control">
              {node}
            </div>
          ))}
        </div>
      ) : null;

      let typeTagsNode: ReactNode = null;

      if (pageData?.data?.travel_types?.length) {
        typeTagsNode = (
          <div className="neo-travel-talk-tags">
            <p className="is-size-6 has-text-weight-bold">{globalResources.travel_talk_page_tags_listing_header}</p>
            <div className="columns is-variable is-2 is-mobile">
              {pageData?.data?.travel_types
                ?.filter((d) => d?.travel_type?.document?.id)
                .map((item, index) => {
                  const travelType = item?.travel_type?.document as Maybe<PrismicTaxonomyTravelType>;

                  return (
                    <div className="column is-narrow" key={index}>
                      <PrismicLink
                        to={data.prismicTravelTalksSearchPage}
                        fragmentQuery={{
                          travelType: travelType?.id,
                        }}
                        className="button is-small has-text-weight-semibold"
                      >
                        {travelType?.data?.name}
                      </PrismicLink>
                    </div>
                  );
                })}
            </div>
          </div>
        );
      }

      return (
        <div className="container neo-travel-talk">
          {titleElement}

          <div className="columns is-variable is-8">
            <div className="column is-two-thirds content">
              <KeyValueList keyValues={keyValuePairs} />
              {contentElement}
              {partnersNode}
              {actionButtonsNode}
            </div>
            <div className="column has-border-left-caa-lightgray has-no-border-mobile">
              <div className="neo-travel-talk-image">
                <PrismicImage image={pageData?.data?.event_image ?? null} />
              </div>
            </div>
          </div>

          {typeTagsNode}
        </div>
      );
    }
  }
);
export default TravelTalkContentSlice;

const TravelTalkContentSliceQuery = graphql`
  query TravelTalkContentSliceQuery {
    prismicTravelTalksSearchPage {
      ...PrismicDocumentLinkFragment
    }
  }
`;

interface TravelTalkContentSliceQueryResults {
  prismicTravelTalksSearchPage?: PrismicTravelTalksSearchPage;
}

export interface TravelTalkContentSliceProps {
  sliceData: any;
  pageData?: PrismicTravelTalk;
}
