import React, { Component, ReactNode } from 'react';
import { PrismicAllDocumentTypes, PrismicLinkType } from '../../graphql';
import KeyValueList, { KeyValuePair } from './KeyValueList';
import PrismicLink from './PrismicLink';
import PrismicImage from './PrismicImage';
import { HashMap } from '../../utils/UtilityTypes';

export default class ContentListing extends Component<ContentListingProps> {
  render(): ReactNode {
    const {
      items,
      classNames,
      columnsClassNames,
      itemColumnClassNames,
      imageColumnClassNames,
      keyColumnClassNames,
      valueColumnClassNames,
      noImageColumn,
    } = this.props;

    if (!items?.length) {
      return null;
    }

    const resolvedColumnsClassNames = columnsClassNames ? columnsClassNames : 'is-variable is-4';
    const resolvedImageColumnClassNames = imageColumnClassNames
      ? imageColumnClassNames
      : 'is-one-fifth neo-content-listing-item-image';
    const resolvedItemColumnClassNames = itemColumnClassNames
      ? itemColumnClassNames
      : 'neo-content-listing-item-content';

    return (
      <div className={`neo-content-listing ${classNames || ''}`}>
        {items.map((item, index) => {
          let imageColumn: ReactNode = null;

          if (noImageColumn !== true) {
            if (item.image) {
              imageColumn = (
                <div
                  className={`column ${
                    item.imageColumnClassNames ? item.imageColumnClassNames : resolvedImageColumnClassNames
                  }`}
                >
                  <PrismicLink to={item.link}>
                    <PrismicImage image={item.image} />
                  </PrismicLink>
                </div>
              );
            } else {
              imageColumn = (
                <div
                  className={`column ${
                    item.imageColumnClassNames ? item.imageColumnClassNames : resolvedImageColumnClassNames
                  }`}
                />
              );
            }
          }

          let extraInfoNode: ReactNode = null;
          if (item.extraInfo) {
            extraInfoNode = <div className="neo-content-listing-item-extra-info">{item.extraInfo}</div>;
          }

          let descriptionNode: ReactNode = null;
          if (item.description) {
            descriptionNode = <div className="neo-content-listing-item-desc">{item.description}</div>;
          }

          const contentColumn = (
            <div
              className={`column ${
                item.itemColumnClassNames ? item.itemColumnClassNames : resolvedItemColumnClassNames
              }`}
            >
              <PrismicLink to={item.link} className="neo-content-listing-item-title">
                {item.title}
              </PrismicLink>
              {extraInfoNode}
              <KeyValueList
                keyColumnClassNames={keyColumnClassNames}
                valueColumnClassNames={valueColumnClassNames}
                keyValues={item.keyValuePairs}
              />
              {descriptionNode}
            </div>
          );

          const dataHTMLAttributes: HashMap<string | string[] | number> = {};

          if (item.dataAttributes) {
            for (const attrName in item.dataAttributes) {
              if (!Object.prototype.hasOwnProperty.call(item.dataAttributes, attrName)) {
                continue;
              }

              dataHTMLAttributes[`data-${attrName}`] = item.dataAttributes[attrName];
            }
          }

          return (
            <div
              className={`columns ${item.classNames ? item.classNames : resolvedColumnsClassNames}`}
              key={index}
              {...dataHTMLAttributes}
            >
              {imageColumn}
              {contentColumn}
            </div>
          );
        })}
      </div>
    );
  }
}

export interface ContentListingProps {
  items: ContentListingItem[] | undefined;
  columnsClassNames?: string;
  imageColumnClassNames?: string;
  itemColumnClassNames?: string;
  classNames?: string;
  noImageColumn?: boolean;
  keyColumnClassNames?: string;
  valueColumnClassNames?: string;
}

export interface ContentListingItem {
  link?: PrismicLinkType | PrismicAllDocumentTypes | null;
  title?: string | null;
  /*
   * TODO: Fix image types by aggregating all image types in one.
   * The following are some of the image types as an example.
   **/
  // image?: PrismicPageDataOgImageImageType | PrismicPageDataLinkImageImageType | SiteSearchablePageImage | null;
  image?: any;
  keyValuePairs?: KeyValuePair[];
  extraInfo?: ReactNode;
  description?: ReactNode;
  imageColumnClassNames?: string;
  itemColumnClassNames?: string;
  classNames?: string;
  dataAttributes?: HashMap<string | string[] | number>;
}
