import {
  File,
  PrismicLinkType,
  PrismicPage,
  PrismicPageDataBodyDropdownGuidedNavigation,
  PrismicPageDataBodyDropdownGuidedNavigationItem,
} from '../../graphql';
import React, { Component, FormEvent, ReactNode } from 'react';
import PrismicLink from '../controls/PrismicLink';
import { graphql, StaticQuery } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';
import SectionTitle from '../controls/SectionTitle';

export const DropdownGuidedNavigationSliceKey = 'dropdown_guided_navigation';
export default class DropdownGuidedNavigationSlice extends Component<
  DropdownGuidedNavigationSliceProps,
  DropdownGuidedNavigationSliceState
> {
  constructor(props: Readonly<DropdownGuidedNavigationSliceProps>) {
    super(props);

    this.state = {
      selectedTopIndex: 0,
      selectedSecondIndex: 0,
    };

    this.renderWithData = this.renderWithData.bind(this);
    this.renderDropdown = this.renderDropdown.bind(this);
  }

  componentDidUpdate(
    _prevProps: Readonly<DropdownGuidedNavigationSliceProps>,
    prevState: Readonly<DropdownGuidedNavigationSliceState>,
    _snapshot?: any
  ): void {
    if (!this.state) {
      return;
    }

    if (this.state.selectedTopIndex !== prevState.selectedTopIndex) {
      // Reset index of second dropdown to the first value
      this.setState({
        selectedSecondIndex: 0,
      });
    }

    const numItems = this.props.sliceData?.items?.length || 0;
    if (this.state.selectedTopIndex >= numItems) {
      this.setState({
        selectedTopIndex: 0,
        selectedSecondIndex: 0,
      });
    }
  }

  render(): ReactNode {
    return <StaticQuery query={DropdownGuidedNavigationQuery} render={this.renderWithData} />;
  }

  renderWithData(queryResults: DropdownGuidedNavigationQueryResults): ReactNode {
    const { sliceData } = this.props;

    if (!sliceData.items?.length) {
      return <React.Fragment />;
    }

    const tree = this.buildDropdownTree(
      (sliceData?.items || []).filter((i) => i !== null) as PrismicPageDataBodyDropdownGuidedNavigationItem[]
    );

    let firstDropdownColumn: ReactNode = null;
    let secondDropdownColumn: ReactNode = null;
    let ctaColumn: ReactNode = null;

    const firstDropdownlabel = sliceData.primary?.label_first_dropdown || '';
    const secondDropdownLabel = sliceData.primary?.label_second_dropdown || '';

    firstDropdownColumn = (
      <div className={'column'}>
        <label>{firstDropdownlabel}</label>
        {this.renderDropdown(firstDropdownlabel, tree, true)}
      </div>
    );

    const selectedTopLevelItem = tree[this.state.selectedTopIndex];

    let dest: PrismicLinkType | undefined = undefined;

    if (selectedTopLevelItem && selectedTopLevelItem.hasChildren) {
      secondDropdownColumn = (
        <div className={'column'}>
          <label>{secondDropdownLabel}</label>
          {this.renderDropdown(secondDropdownLabel, selectedTopLevelItem.children, false)}
        </div>
      );

      const selectedSecondLevelItem = selectedTopLevelItem.children[this.state.selectedSecondIndex];

      if (selectedSecondLevelItem) {
        dest = selectedSecondLevelItem.dest;
      }
    } else if (selectedTopLevelItem && !selectedTopLevelItem.hasChildren) {
      dest = selectedTopLevelItem.dest;
    }

    if (dest) {
      const caaRedOrange = 'rgb(175, 19, 13)';
      ctaColumn = (
        <div className="column">
          <PrismicLink
            to={dest}
            style={{
              // This is a workaround bulma that darkens automatically any active button
              backgroundColor: caaRedOrange,
              borderColor: caaRedOrange,
            }}
            className="button is-caa-redorange has-text-weight-bold"
          >
            {sliceData.primary?.cta_label}
          </PrismicLink>
        </div>
      );
    }

    return (
      <div className="container-limited neo-dropdown-guided-navigation has-neo-arc">
        <div className="container">
          <SectionTitle component={sliceData.primary?.section_title} />
          <div className="columns">
            {firstDropdownColumn}
            {secondDropdownColumn}
            {ctaColumn}
          </div>
        </div>
        <GatsbyImage
          alt=""
          image={queryResults.backgroundImage!.childImageSharp!.gatsbyImageData}
          className="neo-dropdown-guided-navigation-hand"
        />
      </div>
    );
  }

  renderDropdown(label: string, items: DropDownItemWithLabel[], isPrimary: boolean): ReactNode {
    const options: ReactNode[] = [];

    let index = 0;
    for (const item of items) {
      options.push(
        <option key={index} value={index}>
          {item.label}
        </option>
      );
      index = index + 1;
    }

    const selectedIndex = isPrimary ? this.state.selectedTopIndex : this.state.selectedSecondIndex;

    return (
      <select
        id={`${this.props.sliceData.id}_${isPrimary ? 'P' : 'S'}`}
        onChange={(event) => this.onDropdownChange(isPrimary, event)}
        value={selectedIndex}
        aria-label={label}
      >
        {options}
      </select>
    );
  }

  onDropdownChange = (isPrimary: boolean, event: FormEvent<HTMLSelectElement>) => {
    const selectedItem = parseInt((event.target as HTMLSelectElement).value, 10);

    if (isPrimary) {
      this.setState({
        selectedTopIndex: selectedItem,
      });
    } else {
      this.setState({
        selectedSecondIndex: selectedItem,
      });
    }
  };

  buildDropdownTree(items: PrismicPageDataBodyDropdownGuidedNavigationItem[]): TopDropDownItem[] {
    const tree: { [key: string]: TopDropDownItem } = {};

    for (const item of items) {
      const isFlatFirstLevel =
        item.second_level_label === null || item.second_level_label === undefined || item.second_level_label === '';
      const normalizedFirstLevelLabel = item.top_level_label || '';

      if (isFlatFirstLevel) {
        tree[normalizedFirstLevelLabel] = {
          label: normalizedFirstLevelLabel,
          dest: item.link_dest || undefined,
          hasChildren: false,
        };
        continue;
      }

      let existingItem = tree[item.top_level_label || ''];

      if (existingItem === undefined) {
        existingItem = {
          label: normalizedFirstLevelLabel,
          children: [],
          hasChildren: true,
        };

        tree[normalizedFirstLevelLabel] = existingItem;
      } else if (!existingItem.hasChildren) {
        continue;
      }

      existingItem.children.push({
        label: item.second_level_label || '',
        dest: item.link_dest || undefined,
      });
    }

    return Object.values(tree);
  }
}

const DropdownGuidedNavigationQuery = graphql`
  query DropdownGuidedNavigationQuery {
    backgroundImage: file(relativePath: { eq: "images/caa-card-hand.png" }) {
      childImageSharp {
        gatsbyImageData(placeholder: NONE, layout: FIXED)
      }
    }
  }
`;

interface DropdownGuidedNavigationQueryResults {
  backgroundImage?: File;
}

export interface DropdownGuidedNavigationSliceProps {
  sliceData: PrismicPageDataBodyDropdownGuidedNavigation;
  pageData?: PrismicPage;
}

interface DropdownGuidedNavigationSliceState {
  selectedTopIndex: number;
  selectedSecondIndex: number;
}

interface DropDownItemWithLabel {
  label: string;
}

interface DropdownItemWithDest extends DropDownItemWithLabel {
  dest?: PrismicLinkType;
}

interface TopDropDownItemWithChildren extends DropDownItemWithLabel {
  children: DropdownItemWithDest[];
  hasChildren: true;
}

interface TopDropDownItemWithoutChildren extends DropdownItemWithDest {
  hasChildren: false;
}

type TopDropDownItem = TopDropDownItemWithChildren | TopDropDownItemWithoutChildren;

// noinspection JSUnusedGlobalSymbols
export const SliceDropdownGuidedNavigationFragment = graphql`
  fragment SliceDropdownGuidedNavigationFragment on PrismicPageDataBodyDropdownGuidedNavigation {
    id
    slice_type
    primary {
      section_title {
        html
      }
      label_first_dropdown
      label_second_dropdown
      cta_label
    }
    items {
      link_dest {
        ...PrismicLinkFragment
      }
      top_level_label
      second_level_label
    }
  }
`;
