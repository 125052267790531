import { PrismicPageDataBodySlicesType, Scalars } from '../graphql';
import { TableRowSliceKey } from '../components/slices/TableRowSlice';
import { TableColumnSliceKey } from '../components/slices/TableColumnSlice';
import { PageBody } from '../templates/Page';

/**
 * A pseudo slice is a type that has some properties of a slice but
 * does not exist in prismic. This table pseudo slice can help some data
 * travel through the page renderer.
 */
export type TableDataBodyPseudoSlicesType = {
  slice_type: Scalars['String'];
  data: PrismicPageDataBodySlicesType[];
};

/**
 * Prefix used for grouped slices.
 */
export const GroupedSlicePrefix = 'grouped_';

/**
 * This function will group together the table slices. A table is a collection
 * of table_rows or table_columns. When there are more than one without another slice
 * between, they are merged. The original slice are added to an array
 * and inserted at the index of the first row or column.
 * @param currentSlices The slices that might contain tables to group.
 */
export const groupTables = (currentSlices: PageBody) => {
  const resultingBody = [] as PageBody;
  const currentTable = [] as PageBody;
  let lastSliceType: string | undefined = undefined;

  for (const [index, slice] of currentSlices.entries()) {
    const isTablePart = slice.slice_type === TableRowSliceKey || slice.slice_type === TableColumnSliceKey;
    const isSameTypeAsLastSlice = slice.slice_type === lastSliceType;
    const isLast = index === currentSlices.length - 1;
    const previousWasTable = currentTable.length > 0;

    // If the previous slice was a table and the current slice is not of the same type
    // as the last one, we can add the table to the body and reset the current table.
    if (previousWasTable && !isSameTypeAsLastSlice) {
      const table: TableDataBodyPseudoSlicesType = {
        slice_type: `grouped_${lastSliceType}`,
        data: [...currentTable] as PrismicPageDataBodySlicesType[],
      };
      resultingBody.push(table);
      currentTable.length = 0;
    }

    // If the current slice is part of a table, we add it to the current table.
    if (isTablePart) {
      currentTable.push(slice);

      // If the current table part is the last slice, we add it to the body.
      if (isLast) {
        const table: TableDataBodyPseudoSlicesType = {
          slice_type: `grouped_${slice.slice_type}`,
          data: [...currentTable] as PrismicPageDataBodySlicesType[],
        };
        resultingBody.push(table);
      }

    // If its not part of a table, we simply add it to the body.
    } else{
      resultingBody.push(slice);
    }
    lastSliceType = slice.slice_type;
  }

  return resultingBody;
};

/**
 * This enums contains all the color options for a column or a row.
 */
export const enum TableColorOptions {
  Default = 'Default',
  AlternatingColors = 'Alternating colors',
  None = 'None',
}

/**
 * This function returns the class name corresponding to a color option.
 * @param colorOption The color option that will be translated to a class name.
 */
export const getColorClassName = (colorOption: TableColorOptions) => {
  switch (colorOption) {
    case TableColorOptions.AlternatingColors:
      return 'is-color';
    case TableColorOptions.None:
      return 'is-no-color';
    default:
      return '';
  }
};
