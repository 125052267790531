import { PrismicPage, Site } from '../../graphql';
import React, { Component, ReactNode } from 'react';
import { graphql, StaticQuery } from 'gatsby';
import PrismicFontAwesomeIcon from '../controls/PrismicFontAwesomeIcon';
import JsonData from '../controls/JsonData';

export default class UnknownSlicePlaceholder extends Component<
  UnknownSlicePlaceholderProps,
  UnknownSlicePlaceholderState
> {
  constructor(props: Readonly<UnknownSlicePlaceholderProps>) {
    super(props);

    this.state = {
      showData: false,
    };
  }

  render(): ReactNode {
    const { sliceData } = this.props;

    return (
      <StaticQuery
        query={IsPlaceholderEnabledQuery}
        render={queryResult => {
          const { site } = queryResult as IsPlaceholderEnabledQueryResult;

          if (!site?.siteMetadata?.isSlicePlaceholdersEnabled) {
            return <React.Fragment />;
          }

          let dataInformationNode: ReactNode = null;

          if (this.state.showData) {
            dataInformationNode = (
              <React.Fragment>
                <JsonData data={sliceData} />

                <button
                  className="button is-caa-forestgreen has-text-weight-bold"
                  onClick={event => this.toggleData(event)}
                >
                  Hide Slice Data
                </button>
              </React.Fragment>
            );
          } else {
            dataInformationNode = (
              <button
                className="button is-caa-forestgreen has-text-weight-bold"
                onClick={event => this.toggleData(event)}
              >
                Show Slice Data
              </button>
            );
          }

          return (
            <div className="container-fullwidth neo-unknown-slice">
              <div className="container">
                <div className="neo-unknown-slice-content">
                  <h3>
                    <strong>Unimplemented Slice:</strong>{' '}
                    {sliceData?.__typename || sliceData?.slice_type}
                  </h3>

                  {dataInformationNode}
                </div>
              </div>

              <PrismicFontAwesomeIcon
                className="neo-unknown-slice-bg-icon"
                icon="fad-exclamation-triangle"
              />
            </div>
          );
        }}
      />
    );
  }

  toggleData = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();

    this.setState({
      showData: !this.state.showData,
    });
  };
}

export interface UnknownSlicePlaceholderProps {
  sliceData: any;
  pageData?: PrismicPage;
}

interface UnknownSlicePlaceholderState {
  showData: boolean;
}

interface IsPlaceholderEnabledQueryResult {
  site: Site;
}

const IsPlaceholderEnabledQuery = graphql`
  query IsPlaceholderEnabledQuery {
    site {
      siteMetadata {
        isSlicePlaceholdersEnabled
      }
    }
  }
`;
