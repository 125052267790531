import React, { Component, ReactNode } from 'react';
import {
  ComparativeTableSliceProps,
  IComparativeTable,
  IPlanData,
  IPlanSpecification,
} from '../ComparativeTableSlice';
import ComparativeDiv from './ComparativeDiv';
import PrismicFontAwesomeIcon from '../../controls/PrismicFontAwesomeIcon';

class PlanDivs extends Component<IPlanDivs> {
  render() {
    let nodeArray: ReactNode[] = [];
    const plansData: IPlanData[] = this.props.plansData || [];
    const uniqueSpecs: IPlanSpecification[] = this.props.uniqueSpecs;

    if (plansData.length) {
      nodeArray = plansData.map((planData, index) => {
        return (
          <ComparativeDiv
            key={`comparative-div-${index}`}
            planData={planData}
            uniqueSpecs={uniqueSpecs}
            index={index}
            activeItemsIndex={this.props.activeItemsIndex}
            elementIdPrefix={this.props.elementIdPrefix}
            setActiveItem={this.props.setActiveItem}
          />
        );
      });
    }
    return nodeArray;
  }
}

export default class MobileComparativeTable extends Component<
  ComparativeTableSliceProps & IComparativeTable,
  MobileComparativeTableState
> {
  private tableId: number = Date.now();
  private divPositions: number[] = [];
  constructor(props: Readonly<ComparativeTableSliceProps & IComparativeTable>) {
    super(props);

    this.state = {
      activeDivIndex: 0,
      showNavBtns: false,
    };

    this.prevDiv = this.prevDiv.bind(this);
    this.nextDiv = this.nextDiv.bind(this);
    this.goToDiv = this.goToDiv.bind(this);
  }

  componentDidMount(): void {
    window.addEventListener('scroll', this.handleScroll, true);
    this.setDivPositions();
  }

  componentWillUnmount(): void {
    window.removeEventListener('scroll', this.handleScroll);
  }

  handleScroll = () => {
    const container = document.querySelector(
      `#neo-comparative-divs-container-${this.tableId}`
    );

    let isVisible: boolean;
    if (!container) {
      isVisible = false;
    } else {
      const rect = container.getBoundingClientRect();
      const elemTop = rect.top;
      const elemBottom = rect.bottom;
      isVisible = elemTop < window.innerHeight / 2 && elemBottom >= 500;
    }

    this.setState({
      showNavBtns: isVisible,
    });
  };

  setDivPositions(): void {
    const plansData: IPlanData[] = this.props.plansData || [];
    for (let i = 0; i < plansData.length; i += 1) {
      const div = document.querySelector(
        `#neo-comparative-divs-container-${this.tableId} .comparative-div-${i}`
      );

      const divX: number = div?.getBoundingClientRect().x
        ? div?.getBoundingClientRect().x
        : 0;

      this.divPositions.push(divX);
    }
  }

  prevDiv(): void {
    const plansData: IPlanData[] = this.props.plansData || [];
    const prevDivIndex =
      this.state?.activeDivIndex - 1 >= 0
        ? this.state?.activeDivIndex - 1
        : plansData.length - 1;

    this.goToDiv(prevDivIndex);
  }

  nextDiv(): void {
    const plansData: IPlanData[] = this.props.plansData || [];
    const nextDivIndex = (this.state?.activeDivIndex + 1) % plansData.length;

    this.goToDiv(nextDivIndex);
  }

  goToDiv(divIndex = 0): void {
    this.setState(
      {
        activeDivIndex: divIndex,
      },
      () => {
        const targetDivParent = document.querySelector(
          `#neo-comparative-divs-container-${this.tableId} .neo-comparative-divs`
        );
        const targetDivX = this.divPositions[divIndex]
          ? this.divPositions[divIndex]
          : 0;

        if (targetDivParent) {
          targetDivParent.scroll({ left: targetDivX, behavior: 'smooth' });
        }
      }
    );
  }

  render() {
    const { sliceData } = this.props;
    const plansData: IPlanData[] = this.props.plansData || [];
    const uniqueSpecs: IPlanSpecification[] = this.props.uniqueSpecs || [];

    let tableInstructions: ReactNode = null;

    if (sliceData.primary?.table_instruction) {
      tableInstructions = (
        <div className={'instructions'}>
          <span>{sliceData.primary.table_instruction}</span>
        </div>
      );
    }

    return (
      <div
        className={'neo-comparative-divs-container'}
        id={`neo-comparative-divs-container-${this.tableId}`}
      >
        {tableInstructions}
        <div
          className={'neo-comparative-divs'}
          ref={() => 'neo-comparative-divs'}
        >
          <PlanDivs
            key="plan-divs"
            uniqueSpecs={uniqueSpecs}
            plansData={plansData}
            activeItemsIndex={this.props.activeItemsIndex ?? []}
            elementIdPrefix={this.props.elementIdPrefix ?? ''}
            setActiveItem={this.props.setActiveItem ?? (() => {})}
          />
        </div>
        <button
          className={`neo-comparative-divs-prev-button is-hidden-desktop ${!this
            .state.showNavBtns && 'hidden'}`}
          disabled={!this.state.showNavBtns}
          aria-label="Previous slide"
          onClick={this.prevDiv}
        >
          <PrismicFontAwesomeIcon icon="fas chevron-left" />
        </button>
        <button
          className={`neo-comparative-divs-next-button is-hidden-desktop ${!this
            .state.showNavBtns && 'hidden'}`}
          disabled={!this.state.showNavBtns}
          aria-label="Next slide"
          onClick={this.nextDiv}
        >
          <PrismicFontAwesomeIcon icon="fas chevron-right" />
        </button>
      </div>
    );
  }
}

export interface IPlanDivs {
  plansData?: IPlanData[];
  uniqueSpecs: IPlanSpecification[];
  activeItemsIndex: number[];
  elementIdPrefix: string;
  setActiveItem: (
    currentIndex: number,
    event: React.MouseEvent<HTMLAnchorElement | HTMLButtonElement>
  ) => void;
}

interface MobileComparativeTableState {
  activeDivIndex: number;
  showNavBtns: boolean;
}
