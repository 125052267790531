import {
  PrismicStructuredTextType,
  PrismicTravelDeal,
  PrismicTravelPartner,
  PrismicTravelTalk,
  PrismicTravelTrip,
} from '../../graphql';
import React, { Component, ReactNode } from 'react';
import WithGlobalResources, { InjectedGlobalResourcesProps } from '../higher-order/WithGlobalResources';
import WithTravelTripData, { InjectedTravelTripDataProps } from '../higher-order/WithTravelTripData';
import WithTravelTalkData, { InjectedTravelTalkDataProps } from '../higher-order/WithTravelTalkData';
import WithTravelDealData, { InjectedTravelDealDataProps } from '../higher-order/WithTravelDealData';
import { FilterString, FilterTravelDataBasedOnTags, NormalizeFilterString } from '../../utils/AgentDataHelpers';
import shuffle from 'lodash/shuffle';
import PrismicImage from '../controls/PrismicImage';
import TravelTalk from '../fragments/travel-talk/TravelTalk';
import ImageLinkTileList from '../controls/ImageLinkTileList';
import TravelTrip from '../fragments/travel-trip/TravelTrip';
import TravelDeal from '../fragments/travel-deal/TravelDeal';
import PrismicStructuredText from './PrismicStructuredText';
import WithTravelPartnerData, { InjectedTravelPartnerDataProps } from '../higher-order/WithTravelPartnerData';
import TravelPartner from '../fragments/travel-partner/TravelPartner';
import PrismicFontAwesomeIcon from './PrismicFontAwesomeIcon';

const TravelOffersControl = WithGlobalResources(
  WithTravelTripData(
    WithTravelTalkData(
      WithTravelDealData(
        WithTravelPartnerData(
          class TravelOffersControlImpl extends Component<
            TravelOffersControlProps &
              InjectedGlobalResourcesProps &
              InjectedTravelTripDataProps &
              InjectedTravelTalkDataProps &
              InjectedTravelDealDataProps &
              InjectedTravelPartnerDataProps,
            TravelOffersControlState
          > {
            constructor(
              props: Readonly<
                TravelOffersControlProps &
                  InjectedGlobalResourcesProps &
                  InjectedTravelTripDataProps &
                  InjectedTravelTalkDataProps &
                  InjectedTravelDealDataProps &
                  InjectedTravelPartnerDataProps
              >
            ) {
              super(props);

              this.state = {
                showRandomItems: false,
              };
            }

            componentDidMount(): void {
              this.setState({
                showRandomItems: true,
              });
            }

            render(): ReactNode {
              const {
                displayPartners,
                displayTrips,
                displayTalks,
                displayDeals,
                sectionTitle,
                sectionTitleIcon,
                numberOfItems,
                backgroundImage,
                backgroundImageOverlayColor,
                travelPartnerData,
                travelTripData,
                travelTalkData,
                travelDealData,
              } = this.props;

              if (!this.state.showRandomItems) {
                return null;
              }

              const maxCount = numberOfItems || 3;

              const travelFilters = {
                travelDestinationId: this.props.travelDestinationId,
                travelPartnerId: this.props.travelPartnerId,
                travelTypeId: this.props.travelTypeId,
                travelStoreId: this.props.travelStoreId,
              };

              let eligiblePartners: PrismicTravelPartner[] = [];
              let eligibleTrips: PrismicTravelTrip[] = [];
              let eligibleTalks: PrismicTravelTalk[] = [];
              let eligibleDeals: PrismicTravelDeal[] = [];

              if (displayPartners) {
                const normPartnerIdFilter = NormalizeFilterString(this.props.travelPartnerId);

                eligiblePartners = shuffle(FilterTravelDataBasedOnTags(travelPartnerData, travelFilters));

                if (normPartnerIdFilter?.length) {
                  eligiblePartners = eligiblePartners.filter((node) =>
                    normPartnerIdFilter.some((id) => node?.id === id)
                  );
                }
              }

              if (displayTrips) {
                eligibleTrips = shuffle(FilterTravelDataBasedOnTags(travelTripData, travelFilters));
              }

              if (displayTalks) {
                eligibleTalks = shuffle(FilterTravelDataBasedOnTags(travelTalkData, travelFilters));
              }

              if (displayDeals) {
                eligibleDeals = shuffle(FilterTravelDataBasedOnTags(travelDealData, travelFilters));
              }

              let currentCycle = 0;

              const selectedPartners: PrismicTravelPartner[] = [];
              const selectedTrips: PrismicTravelTrip[] = [];
              const selectedTalks: PrismicTravelTalk[] = [];
              const selectedDeals: PrismicTravelDeal[] = [];

              if (maxCount > 0) {
                // TODO: Replace with real condition
                // eslint-disable-next-line no-constant-condition
                while (true) {
                  if (currentCycle === 0 && eligiblePartners.length) {
                    selectedPartners.push(eligiblePartners.pop()!);
                  } else if (currentCycle === 1 && eligibleTrips.length) {
                    selectedTrips.push(eligibleTrips.pop()!);
                  } else if (currentCycle === 2 && eligibleTalks.length) {
                    selectedTalks.push(eligibleTalks.pop()!);
                  } else if (currentCycle === 3 && eligibleDeals.length) {
                    selectedDeals.push(eligibleDeals.pop()!);
                  }

                  if (
                    eligiblePartners.length + eligibleTrips.length + eligibleTalks.length + eligibleDeals.length ===
                      0 ||
                    selectedPartners.length + selectedTrips.length + selectedTalks.length + selectedDeals.length ===
                      maxCount
                  ) {
                    break;
                  }

                  currentCycle = (currentCycle + 1) % 4;
                }
              }

              const listNodes = ([] as ReactNode[])
                .concat(
                  selectedPartners.map((partner, index) => <TravelPartner key={`partner-${index}`} partner={partner} />)
                )
                .concat(selectedTrips.map((trip, index) => <TravelTrip key={`trip-${index}`} trip={trip} />))
                .concat(selectedTalks.map((talk, index) => <TravelTalk key={`talk-${index}`} talk={talk} />))
                .concat(selectedDeals.map((deal, index) => <TravelDeal key={`deal-${index}`} deal={deal} />));

              if (!listNodes.length) {
                return null;
              }

              let titleElement: ReactNode = null;
              if (sectionTitle) {
                let iconElement: ReactNode = null;

                if (sectionTitleIcon) {
                  iconElement = (
                    <div className="neo-section-title-icon">
                      <PrismicFontAwesomeIcon size="4x" icon={sectionTitleIcon} />
                    </div>
                  );
                }

                titleElement = (
                  <div className="neo-section-title">
                    {iconElement}
                    <PrismicStructuredText text={sectionTitle} />
                  </div>
                );
              }

              let containerBackgroundNode: ReactNode = <div className="container-background" />;

              if (backgroundImage) {
                containerBackgroundNode = <PrismicImage className={'container-background'} image={backgroundImage} />;
              }

              return (
                <div
                  className={`container-limited has-container-background is-background-overlay-${
                    backgroundImageOverlayColor || 'caa-darkforestgreen'
                  }`}
                >
                  <div className="container neo-travel-related">
                    {titleElement}
                    <ImageLinkTileList children={listNodes} />
                  </div>
                  {containerBackgroundNode}
                </div>
              );
            }
          }
        )
      )
    )
  )
);
export default TravelOffersControl;

export interface TravelOffersControlProps extends TravelOffersControlFilterProps {
  sectionTitle?: PrismicStructuredTextType | null;
  sectionTitleIcon?: string | null;
  numberOfItems?: number;
  displayPartners?: boolean;
  displayDeals?: boolean;
  displayTrips?: boolean;
  displayTalks?: boolean;
  // TODO: Use the required image types instead of any
  backgroundImage?: any;
  backgroundImageOverlayColor?: string | null;
}

export interface TravelOffersControlFilterProps {
  travelDestinationId?: FilterString;
  travelPartnerId?: FilterString;
  travelTypeId?: FilterString;
  travelStoreId?: FilterString;
}

export interface TravelOffersControlState {
  showRandomItems: boolean;
}
