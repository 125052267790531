import React from 'react';
import PrismicFontAwesomeIcon, { PrismicFontAwesomeIconProps } from "./PrismicFontAwesomeIcon";

/**
 * A PrismicIcon is a wrapper for a PrismicFontAwesomeIcon.
 * It should be used for user inputted icons. There is a PrismicFontAwesomeIcon
 * for the rest of the icons. This wrapper makes it possible to modify all the
 * user inputted icons in one place.
 *
 * @param props The props to pass to the PrismicFontAwesomeIcon.
 */
export default function PrismicIcon({ className, ...otherProps }: PrismicFontAwesomeIconProps) {

  const faIconClassName = `user-icon is-caa-forestgreen ${className ? className : ''}`.trimEnd();

  return <PrismicFontAwesomeIcon className={faIconClassName} {...otherProps} />;
}
