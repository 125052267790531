import React from 'react';
import { graphql } from 'gatsby';
import { PrismicPageDataBodyTableColumn, PrismicStructuredTextType } from '../../graphql';
import { TableColorOptions } from '../../utils/TableUtility';
import { TableContainer, TableData, TableHeader } from '../controls/tables/TableContainer';

export const TableColumnSliceKey = 'table_column';

export interface TableColumnSliceProps {
  sliceData: PrismicPageDataBodyTableColumn[];
}

/**
 * This component extracts the data from prismic. It represent one or a group
 * of table column slice.
 * @param props : TableColumnSliceProps The data from prismic
 * @returns A TableContainer component that can render the data
 */
export const TableColumnSlice = ({ sliceData }: TableColumnSliceProps) => {
  const headers: TableHeader[] = [];
  const tableData: TableData[] = [];

  for (const [index, slice] of sliceData.entries()) {
    if (slice.primary?.column_header?.html) {
      headers.push({
        index,
        header: slice.primary.column_header,
        color: (slice.primary.column_color as TableColorOptions) ?? TableColorOptions.Default,
      });
    }

    tableData.push({
      index,
      isHeader: slice.primary.is_header_column ?? false,
      content: slice.items.map((item) => item.row_content as PrismicStructuredTextType),
      color: (slice.primary.column_color as TableColorOptions) ?? TableColorOptions.Default,
    });
  }

  return <TableContainer isByRow={false} headers={headers} tableData={tableData} />;
};

// noinspection JSUnusedGlobalSymbols
export const SliceTableColumnFragment = graphql`
  fragment SliceTableColumnFragment on PrismicSlicesTableColumn {
    ... on PrismicPageDataBodyTableColumn {
      id
      slice_label
      slice_type
      primary {
        column_header {
          html
          text
        }
        is_header_column
        column_color
      }
      items {
        row_content {
          html
          text
        }
      }
    }
  }
`;
