import React, { Component, ReactNode } from 'react';
import { PrismicPage, PrismicPageDataBodyMarketingHero } from '../../graphql';
import PrismicLink from '../controls/PrismicLink';
import PropValidators from '../../utils/PropValidators';
import PrismicImage from '../controls/PrismicImage';
import PrismicStructuredText from '../controls/PrismicStructuredText';
import { graphql } from 'gatsby';

export const MarketingHeroSliceKey = 'marketing_hero';
export default class MarketingHeroSlice extends Component<
  MarketingHeroSliceProps
> {
  render(): ReactNode {
    const { sliceData } = this.props;

    let bgColor = 'green';
    let bgImage: ReactNode = undefined;
    let cta: ReactNode = undefined;
    let text: ReactNode = undefined;

    try {
      PropValidators.isValidEnumValue(
        sliceData.primary?.bg_color?.toLowerCase() || undefined,
        {
          blue: 'blue',
          lightBlue: 'light-blue',
          green: 'green',
          lightGreen: 'light-green',
          gray: 'gray',
          whiteGradient: 'white-gradient',
        },
        'MarketingHeroSlice',
        'bg_color'
      );

      bgColor = sliceData.primary?.bg_color?.toLowerCase() || 'green';
    } catch (ex) {
      // noop
    }

    if (sliceData.primary?.bg_image) {
      bgImage = (
        <PrismicImage
          image={sliceData.primary.bg_image}
          style={{
            position: 'absolute',
          }}
          imgStyle={{
            right: 'auto',
            height: '100%',
            left: '40%',
            minWidth: '60%',
            width: 'auto',
          }}
          srcIsFixed
        />
      );
    }
    if (sliceData.primary?.text?.html) {
      text = (
        <div className="marketing-hero-text">
          <PrismicStructuredText text={sliceData.primary.text} />
        </div>
      );
    }
    if (sliceData.primary?.btn_text) {
      if (sliceData.primary?.link_dest) {
        cta = (
          <PrismicLink className={'cta'} to={sliceData.primary.link_dest}>
            {sliceData.primary?.btn_text}
          </PrismicLink>
        );
      } else {
        cta = <div className={'cta'}>{sliceData.primary?.btn_text}</div>;
      }
    }

    let content: ReactNode = (
      <div className="container">
        <div className="marketing-hero-content">
          {text}
          {cta}
        </div>
      </div>
    );

    content = <div className={`wrapper ${bgColor}`}>{content}</div>;

    return (
      <div className={`container-limited neo-marketing-hero`}>
        {bgImage}
        {content}
      </div>
    );
  }
}

export interface MarketingHeroSliceProps {
  sliceData: PrismicPageDataBodyMarketingHero;
  pageData?: PrismicPage;
}

// noinspection JSUnusedGlobalSymbols
export const SliceMarketingHeroFragment = graphql`
  fragment SliceMarketingHeroFragment on PrismicSlicesMarketingHero {
    ... on PrismicPageDataBodyMarketingHero {
      id
      slice_type
      primary {
        bg_color
        btn_text
        link_dest {
          ...PrismicLinkFragment
        }
        bg_image {
          alt
          url
          gatsbyImageData(layout: FULL_WIDTH)
        }
        text {
          html
        }
      }
    }
    ... on PrismicErrorPageDataBodyMarketingHero {
      id
      slice_type
      primary {
        bg_color
        btn_text
        link_dest {
          ...PrismicLinkFragment
        }
        bg_image {
          alt
          url
          gatsbyImageData(layout: FULL_WIDTH)
        }
        text {
          html
        }
      }
    }
    ... on PrismicEmailSubscriptionPageDataBodyMarketingHero {
      id
      slice_type
      primary {
        bg_color
        btn_text
        link_dest {
          ...PrismicLinkFragment
        }
        bg_image {
          alt
          url
          gatsbyImageData(layout: FULL_WIDTH)
        }
        text {
          html
        }
      }
    }
    ... on PrismicGaragesSearchPageDataBodyMarketingHero {
      id
      slice_type
      primary {
        bg_color
        btn_text
        link_dest {
          ...PrismicLinkFragment
        }
        bg_image {
          alt
          url
          gatsbyImageData(layout: FULL_WIDTH)
        }
        text {
          html
        }
      }
    }
    ... on PrismicMerchandiseSearchPageDataBodyMarketingHero {
      id
      slice_type
      primary {
        bg_color
        btn_text
        link_dest {
          ...PrismicLinkFragment
        }
        bg_image {
          alt
          url
          gatsbyImageData(layout: FULL_WIDTH)
        }
        text {
          html
        }
      }
    }
    ... on PrismicTravelAgentsSearchPageDataBodyMarketingHero {
      id
      slice_type
      primary {
        bg_color
        btn_text
        link_dest {
          ...PrismicLinkFragment
        }
        bg_image {
          alt
          url
          gatsbyImageData(layout: FULL_WIDTH)
        }
        text {
          html
        }
      }
    }
    ... on PrismicTravelDealsSearchPageDataBodyMarketingHero {
      id
      slice_type
      primary {
        bg_color
        btn_text
        link_dest {
          ...PrismicLinkFragment
        }
        bg_image {
          alt
          url
          gatsbyImageData(layout: FULL_WIDTH)
        }
        text {
          html
        }
      }
    }
    ... on PrismicTravelPartnersSearchPageDataBodyMarketingHero {
      id
      slice_type
      primary {
        bg_color
        btn_text
        link_dest {
          ...PrismicLinkFragment
        }
        bg_image {
          alt
          url
          gatsbyImageData(layout: FULL_WIDTH)
        }
        text {
          html
        }
      }
    }
    ... on PrismicTravelStoreSearchPageDataBodyMarketingHero {
      id
      slice_type
      primary {
        bg_color
        btn_text
        link_dest {
          ...PrismicLinkFragment
        }
        bg_image {
          alt
          url
          gatsbyImageData(layout: FULL_WIDTH)
        }
        text {
          html
        }
      }
    }
    ... on PrismicTravelTalksSearchPageDataBodyMarketingHero {
      id
      slice_type
      primary {
        bg_color
        btn_text
        link_dest {
          ...PrismicLinkFragment
        }
        bg_image {
          alt
          url
          gatsbyImageData(layout: FULL_WIDTH)
        }
        text {
          html
        }
      }
    }
    ... on PrismicTravelTripsSearchPageDataBodyMarketingHero {
      id
      slice_type
      primary {
        bg_color
        btn_text
        link_dest {
          ...PrismicLinkFragment
        }
        bg_image {
          alt
          url
          gatsbyImageData(layout: FULL_WIDTH)
        }
        text {
          html
        }
      }
    }
    ... on PrismicTravelStoreDataBodyMarketingHero {
      id
      slice_type
      primary {
        bg_color
        btn_text
        link_dest {
          ...PrismicLinkFragment
        }
        bg_image {
          alt
          url
          gatsbyImageData(layout: FULL_WIDTH)
        }
        text {
          html
        }
      }
    }
    ... on PrismicTravelTalkDataBodyMarketingHero {
      id
      slice_type
      primary {
        bg_color
        btn_text
        link_dest {
          ...PrismicLinkFragment
        }
        bg_image {
          alt
          url
          gatsbyImageData(layout: FULL_WIDTH)
        }
        text {
          html
        }
      }
    }
    ... on PrismicTravelTripDataBodyMarketingHero {
      id
      slice_type
      primary {
        bg_color
        btn_text
        link_dest {
          ...PrismicLinkFragment
        }
        bg_image {
          alt
          url
          gatsbyImageData(layout: FULL_WIDTH)
        }
        text {
          html
        }
      }
    }
    ... on PrismicTravelDealDataBodyMarketingHero {
      id
      slice_type
      primary {
        bg_color
        btn_text
        link_dest {
          ...PrismicLinkFragment
        }
        bg_image {
          alt
          url
          gatsbyImageData(layout: FULL_WIDTH)
        }
        text {
          html
        }
      }
    }
    ... on PrismicMerchandiseDataBodyMarketingHero {
      id
      slice_type
      primary {
        bg_color
        btn_text
        link_dest {
          ...PrismicLinkFragment
        }
        bg_image {
          alt
          url
          gatsbyImageData(layout: FULL_WIDTH)
        }
        text {
          html
        }
      }
    }
    ... on PrismicTravelPartnerDataBodyMarketingHero {
      id
      slice_type
      primary {
        bg_color
        btn_text
        link_dest {
          ...PrismicLinkFragment
        }
        bg_image {
          alt
          url
          gatsbyImageData(layout: FULL_WIDTH)
        }
        text {
          html
        }
      }
    }
  }
`;
