import { PrismicPage, PrismicPageDataBodyTravelOffers } from '../../graphql';
import React, { Component, ReactNode } from 'react';
import { graphql } from 'gatsby';
import TravelOffersControl from '../controls/TravelOffersControl';

export const TravelOffersSliceKey = 'travel_offers';
export default class TravelOffersSlice extends Component<
  TravelOffersSliceProps
> {
  render(): ReactNode {
    const { sliceData } = this.props;

    const filters: Filters = {
      travelDestinationId: [],
      travelPartnerId: [],
      travelTypeId: [],
    };
    sliceData?.items?.forEach(node => {
      if (node?.travel_tag?.document?.id) {
        switch (node?.travel_tag?.document?.type) {
          case 'travel_partner':
            filters.travelPartnerId.push(node!.travel_tag!.document!.id!);
            break;

          case 'taxonomy_travel_destination':
            filters.travelDestinationId.push(node!.travel_tag!.document!.id!);
            break;

          case 'taxonomy_travel_type':
            filters.travelTypeId.push(node!.travel_tag!.document!.id!);
            break;

          default:
            break;
        }
      }
    });

    return (
      <TravelOffersControl
        sectionTitle={sliceData?.primary?.section_title}
        sectionTitleIcon={sliceData?.primary?.section_title_icon}
        backgroundImage={sliceData?.primary?.background_image}
        backgroundImageOverlayColor={
          sliceData?.primary?.background_overlay_color || 'caa-darkforestgreen'
        }
        numberOfItems={sliceData?.primary?.populate_max || 3}
        displayPartners={
          (sliceData?.primary?.display_partners || 'no') === 'yes'
        }
        displayTrips={(sliceData?.primary?.display_trips || 'yes') === 'yes'}
        displayTalks={(sliceData?.primary?.display_talks || 'yes') === 'yes'}
        displayDeals={(sliceData?.primary?.display_deals || 'yes') === 'yes'}
        {...filters}
      />
    );
  }
}

interface Filters {
  travelDestinationId: string[];
  travelPartnerId: string[];
  travelTypeId: string[];
}

export interface TravelOffersSliceProps {
  sliceData: PrismicPageDataBodyTravelOffers;
  pageData?: PrismicPage;
}

// noinspection JSUnusedGlobalSymbols
export const SliceTravelOffersFragment = graphql`
  fragment SliceTravelOffersFragment on PrismicSlicesTravelOffers {
    ... on PrismicPageDataBodyTravelOffers {
      id
      slice_type
      primary {
        section_title {
          html
        }
        section_title_icon
        background_image {
          alt
          url
          gatsbyImageData(layout: FULL_WIDTH)
        }
        background_overlay_color
        display_partners
        display_trips
        display_talks
        display_deals
        populate_max
      }
      items {
        travel_tag {
          document {
            ... on PrismicTravelPartner {
              id
              type
            }
            ... on PrismicTaxonomyTravelDestination {
              id
              type
            }
            ... on PrismicTaxonomyTravelType {
              id
              type
            }
          }
        }
      }
    }
    ... on PrismicTravelPartnerDataBodyTravelOffers {
      id
      slice_type
      primary {
        section_title {
          html
        }
        section_title_icon
        background_image {
          alt
          url
          gatsbyImageData(layout: FULL_WIDTH)
        }
        background_overlay_color
        display_partners
        display_trips
        display_talks
        display_deals
        populate_max
      }
      items {
        travel_tag {
          document {
            ... on PrismicTravelPartner {
              id
              type
            }
            ... on PrismicTaxonomyTravelDestination {
              id
              type
            }
            ... on PrismicTaxonomyTravelType {
              id
              type
            }
          }
        }
      }
    }
    ... on PrismicTravelTalkDataBodyTravelOffers {
      id
      slice_type
      primary {
        section_title {
          html
        }
        section_title_icon
        background_image {
          alt
          url
          gatsbyImageData(layout: FULL_WIDTH)
        }
        background_overlay_color
        display_partners
        display_trips
        display_talks
        display_deals
        populate_max
      }
      items {
        travel_tag {
          document {
            ... on PrismicTravelPartner {
              id
              type
            }
            ... on PrismicTaxonomyTravelDestination {
              id
              type
            }
            ... on PrismicTaxonomyTravelType {
              id
              type
            }
          }
        }
      }
    }
    ... on PrismicTravelTripDataBodyTravelOffers {
      id
      slice_type
      primary {
        section_title {
          html
        }
        section_title_icon
        background_image {
          alt
          url
          gatsbyImageData(layout: FULL_WIDTH)
        }
        background_overlay_color
        display_partners
        display_trips
        display_talks
        display_deals
        populate_max
      }
      items {
        travel_tag {
          document {
            ... on PrismicTravelPartner {
              id
              type
            }
            ... on PrismicTaxonomyTravelDestination {
              id
              type
            }
            ... on PrismicTaxonomyTravelType {
              id
              type
            }
          }
        }
      }
    }
    ... on PrismicTravelStoreDataBodyTravelOffers {
      id
      slice_type
      primary {
        section_title {
          html
        }
        section_title_icon
        background_image {
          alt
          url
          gatsbyImageData(layout: FULL_WIDTH)
        }
        background_overlay_color
        display_partners
        display_trips
        display_talks
        display_deals
        populate_max
      }
      items {
        travel_tag {
          document {
            ... on PrismicTravelPartner {
              id
              type
            }
            ... on PrismicTaxonomyTravelDestination {
              id
              type
            }
            ... on PrismicTaxonomyTravelType {
              id
              type
            }
          }
        }
      }
    }
    ... on PrismicMerchandiseDataBodyTravelOffers {
      id
      slice_type
      primary {
        section_title {
          html
        }
        section_title_icon
        background_image {
          alt
          url
          gatsbyImageData(layout: FULL_WIDTH)
        }
        background_overlay_color
        display_partners
        display_trips
        display_talks
        display_deals
        populate_max
      }
      items {
        travel_tag {
          document {
            ... on PrismicTravelPartner {
              id
              type
            }
            ... on PrismicTaxonomyTravelDestination {
              id
              type
            }
            ... on PrismicTaxonomyTravelType {
              id
              type
            }
          }
        }
      }
    }
    ... on PrismicTravelDealDataBodyTravelOffers {
      id
      slice_type
      primary {
        section_title {
          html
        }
        section_title_icon
        background_image {
          alt
          url
          gatsbyImageData(layout: FULL_WIDTH)
        }
        background_overlay_color
        display_partners
        display_trips
        display_talks
        display_deals
        populate_max
      }
      items {
        travel_tag {
          document {
            ... on PrismicTravelPartner {
              id
              type
            }
            ... on PrismicTaxonomyTravelDestination {
              id
              type
            }
            ... on PrismicTaxonomyTravelType {
              id
              type
            }
          }
        }
      }
    }
  }
`;
