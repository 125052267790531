export default class PropValidators {
  static isFiniteNumberBetween(
    value: number | undefined,
    min: number,
    max: number,
    componentName: string,
    propName: string
  ): void {
    if (value === undefined) {
      return;
    }

    if (!Number.isFinite(value) || value < min || value > max) {
      throw new Error(
        `Invalid prop \`${propName}\` supplied to \`${componentName}\`. Must be a number between [${min},${max}].`
      );
    }
  }

  static isFiniteNumberAtLeast(
    value: number | undefined,
    min: number,
    componentName: string,
    propName: string
  ): void {
    if (value === undefined) {
      return;
    }

    if (!Number.isFinite(value) || value < min) {
      throw new Error(
        `Invalid prop \`${propName}\` supplied to \`${componentName}\`. Must be a number greater or equal to ${min}.`
      );
    }
  }

  static isFiniteNumberAtMost(
    value: number | undefined,
    max: number,
    componentName: string,
    propName: string
  ): void {
    if (value === undefined) {
      return;
    }

    if (!Number.isFinite(value) || value > max) {
      throw new Error(
        `Invalid prop \`${propName}\` supplied to \`${componentName}\`. Must be a number smaller or equal to ${max}.`
      );
    }
  }

  static isValidCSSPosition(
    value: string | undefined,
    componentName: string,
    propName: string
  ): void {
    if (value === undefined) {
      return;
    }

    if (!CSSPositionRegex.test(value)) {
      throw new Error(
        `Invalid prop \`${propName}\` supplied to \`${componentName}\`. Must be a valid CSS position string.`
      );
    }
  }

  static isValidTagName(
    value: string | undefined,
    componentName: string,
    propName: string
  ): void {
    if (value === undefined) {
      return;
    }

    if (!HTMLTagNameRegex.test(value)) {
      throw new Error(
        `Invalid prop \`${propName}\` supplied to \`${componentName}\`. Must be a valid HTML Tag Name.`
      );
    }
  }

  static isValidEnumValue(
    value: string | undefined,
    enumType: object,
    componentName: string,
    propName: string
  ): void {
    if (value === undefined) {
      return;
    }

    if (!Object.values(enumType).includes(value)) {
      throw new Error(
        `Invalid prop \`${propName}\` supplied to \`${componentName}\`. Invalid enum value.`
      );
    }
  }
}

const HTMLTagNameRegex = /^[A-Za-z]([A-Za-z0-9-]*[A-Za-z0-9])?$/;
const CSSPositionRegex = /^\s*((left|center|right|top|bottom|(0|([0-9]+|[0-9]*\.[0-9]+)(cap|ch|em|ex|ic|lh|rem|rlh|vh|vw|vi|vb|vmin|vmax|px|cm|mm|Q|in|pc|pt|%)))\s+)?(left|center|right|top|bottom|(0|([0-9]+|[0-9]*\.[0-9]+)(cap|ch|em|ex|ic|lh|rem|rlh|vh|vw|vi|vb|vmin|vmax|px|cm|mm|Q|in|pc|pt|%)))\s*$/;
