import { PrismicPage, PrismicPageDataBodyCustomScripts } from '../../graphql';
import React, { useEffect } from 'react';
import { graphql } from 'gatsby';

export const CustomScriptsSliceKey = 'custom_scripts';

function createScriptElement(
  src: string,
  isDefered: boolean,
  isAsync: boolean,
  loadCallback: (this: HTMLScriptElement, ev: Event) => any
): HTMLScriptElement | undefined {
  // SSR check
  if (typeof document === undefined) {
    return;
  }

  const script = document.createElement('script');

  script.src = src;
  script.defer = isDefered;
  script.async = isAsync;
  script.addEventListener('load', loadCallback);
  script.setAttribute('data-to-remove', 'true');

  return script;
}

const CustomScriptsSlice = ({
  sliceData: { items },
}: CustomScriptsSliceProps) => {
  const scriptElements = items
    ?.filter(item => item?.type === 'Inline' && item?.content?.text)
    ?.map((item, index) => {
      let scriptElementType: string;

      if (item?.inline_module && !item?.content_type) {
        scriptElementType =
          item?.inline_module === 'Yes' ? 'module' : 'text/javascript';
      } else {
        scriptElementType = item?.content_type ?? 'text/javascript';
      }

      return (
        <script
          key={index}
          type={scriptElementType}
          data-to-remove="true"
          dangerouslySetInnerHTML={{ __html: item?.content?.text ?? '' }}
        />
      );
    });

  useEffect(() => {
    items
      ?.filter(item => item?.type === 'External' && item?.external_script)
      ?.map(item =>
        // executing the inlineJavascript whenever the external script has finisned loading
        createScriptElement(
          item?.external_script ?? '',
          item?.sync_option === 'defer',
          item?.sync_option === 'async',
          () => {
            if (item?.content?.text) {
              // tslint:disable-next-line: no-eval
              eval(item.content.text);
            }
          }
        )
      )
      ?.forEach(element => {
        if (!element) {
          return;
        }

        document.body.appendChild(element);
      });
  }, [items]);
  useEffect(
    () => () => {
      // NOTE: The code in this function cannot be put outside because SSR won't allow it.
      // SSR check
      if (typeof document === undefined) {
        return;
      }

      // removing any script tags injected by the CustomScriptsSlice
      // we've added a data-to-remove attribute in those scripts so it's gonna be easy-peasy
      const allScripts = document.getElementsByTagName('script');

      for (let i = 0; i < allScripts.length; i += 1) {
        const singleScript = allScripts[i];

        if (
          singleScript &&
          singleScript.getAttribute('data-to-remove') !== null
        ) {
          singleScript.parentNode?.removeChild(singleScript);
        }
      }

      const inContactChatButtonElement = document.getElementById(
        'icChatButton'
      );
      const inContactChatElements = document.getElementsByClassName(
        'ie-div-position-customer-chat'
      );

      if (inContactChatButtonElement) {
        inContactChatButtonElement.parentNode?.removeChild(
          inContactChatButtonElement
        );
      }

      if (inContactChatElements) {
        for (let index = 0; index < inContactChatElements.length; index += 1) {
          const element = inContactChatElements[index];
          element.parentNode?.removeChild(element);
        }
      }
    },
    []
  );

  return <>{scriptElements}</>;
};

export default CustomScriptsSlice;

export interface CustomScriptsSliceProps {
  sliceData: PrismicPageDataBodyCustomScripts;
  pageData?: PrismicPage;
}

// noinspection JSUnusedGlobalSymbols
export const SliceCustomScriptsFragment = graphql`
  fragment SliceCustomScriptsFragment on PrismicSlicesCustomScripts {
    ... on PrismicPageDataBodyCustomScripts {
      id
      slice_type
      items {
        type
        sync_option
        inline_module
        content_type
        content {
          text
        }
        external_script
      }
    }
    ... on PrismicGaragesSearchPageDataBodyCustomScripts {
      id
      slice_type
      items {
        type
        sync_option
        inline_module
        content_type
        content {
          text
        }
        external_script
      }
    }
  }
`;
