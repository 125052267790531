import {
  PrismicTaxonomyTravelDestinationConnection,
  PrismicTaxonomyTravelTypeConnection,
  PrismicTravelDeal,
  PrismicTravelDealsSearchPage,
  PrismicTravelDealsSearchPageDataBodySearchEnginePlaceholder,
  PrismicTravelPartnerConnection,
} from '../../../graphql';
import React, { Component, ReactNode } from 'react';
import { graphql, StaticQuery } from 'gatsby';
import WithGlobalResources, { InjectedGlobalResourcesProps } from '../../higher-order/WithGlobalResources';
import TagBasedSearch, { NodeListToOptions, TagBasedSearchFieldsConfig } from '../../controls/TagBasedSearch';
import WithTravelDealData, { InjectedTravelDealDataProps } from '../../higher-order/WithTravelDealData';
import { FilterTravelDataBasedOnTags } from '../../../utils/AgentDataHelpers';
import TravelDeal from '../../fragments/travel-deal/TravelDeal';
import ImageLinkTileList from '../../controls/ImageLinkTileList';
import PrismicStructuredText from '../../controls/PrismicStructuredText';
import { cloneDeep } from 'lodash';
import SectionTitle from '../../controls/SectionTitle';

export const TravelDealsSearchSliceKey = '!internal_travel_deals_search_slice';

const TravelDealsSearchSlice = WithGlobalResources(
  WithTravelDealData(
    class TravelDealsSearchSliceImpl extends Component<
      TravelDealsSearchSliceProps & InjectedGlobalResourcesProps & InjectedTravelDealDataProps
    > {
      private fieldConfig?: TagBasedSearchFieldsConfig<TravelDealsSearchParams>;

      constructor(props: TravelDealsSearchSliceProps & InjectedGlobalResourcesProps & InjectedTravelDealDataProps) {
        super(props);

        this.renderWithData = this.renderWithData.bind(this);
        this.executeSearch = this.executeSearch.bind(this);
        this.renderSearchResults = this.renderSearchResults.bind(this);
        this.filterFields = this.filterFields.bind(this);
      }

      render(): ReactNode {
        return <StaticQuery query={TravelDealsSearchSliceQuery} render={this.renderWithData} />;
      }

      renderWithData(data: TravelDealsSearchSliceQueryResults): ReactNode {
        const { sliceData, travelDealData } = this.props;

        let fieldConfig;
        if (!this.fieldConfig) {
          fieldConfig = {
            travelType: {
              label: sliceData?.primary?.travel_type_field_label || '',
              options: NodeListToOptions(data?.allPrismicTaxonomyTravelType?.nodes, (node) => node.data?.name).filter(
                (option) =>
                  travelDealData.some((ta) =>
                    ta.data?.travel_types?.some((tt) => tt?.travel_type?.document?.id === option.value)
                  )
              ),
              allowMultiple: true,
            },
            destination: {
              label: sliceData?.primary?.destination_field_label || '',
              options: NodeListToOptions(
                data?.allPrismicTaxonomyTravelDestination?.nodes,
                (node) => node?.data?.name
              ).filter((option) =>
                travelDealData.some((ta) =>
                  ta.data?.travel_destinations?.some((tt) => tt?.travel_destination?.document?.id === option.value)
                )
              ),
              allowMultiple: true,
            },
            travelPartner: {
              label: sliceData?.primary?.travel_partner_field_label || '',
              options: NodeListToOptions(data?.allPrismicTravelPartner?.nodes, (node) => node?.data?.page_title).filter(
                (option) => travelDealData.some((ta) => ta.data?.travel_partner?.document?.id === option.value)
              ),
              allowMultiple: true,
            },
            sortExpiry: {
              label: sliceData?.primary?.sort_expiry_field_label || '',
              options: [
                {
                  label: sliceData?.primary?.sort_expiry_asc_option || '',
                  value: 'ASC',
                },
                {
                  label: sliceData?.primary?.sort_expiry_desc_option || '',
                  value: 'DESC',
                },
              ],
              allowMultiple: false,
              isSearchable: false,
              defaultValue: ['ASC'],
              requireValue: true,
            },
          };
          this.fieldConfig = cloneDeep(fieldConfig);
        } else {
          fieldConfig = cloneDeep(this.fieldConfig);
        }

        return (
          <div className="container neo-tag-search-page">
            <SectionTitle component={sliceData.primary?.section_title} />

            <TagBasedSearch
              fields={fieldConfig}
              search={this.executeSearch}
              render={this.renderSearchResults}
              filter={this.filterFields}
              maxPerPage={6}
            />
          </div>
        );
      }

      executeSearch = (params: TravelDealsSearchParams): PrismicTravelDeal[] => {
        const { travelDealData } = this.props;

        const results = FilterTravelDataBasedOnTags(travelDealData, {
          travelPartnerId: params.travelPartner,
          travelDestinationId: params.destination,
          travelTypeId: params.travelType,
        });

        const isAsc = params.sortExpiry[0] !== 'DESC';

        results.sort((a, b) => {
          if (a?.data?.deal_expiry === b?.data?.deal_expiry) {
            return 0;
          }

          if (!a?.data?.deal_expiry) {
            return isAsc ? 1 : -1;
          }

          if (!b?.data?.deal_expiry) {
            return isAsc ? -1 : 1;
          }

          if (a?.data?.deal_expiry < b?.data?.deal_expiry) {
            return isAsc ? -1 : 1;
          }

          if (a?.data?.deal_expiry > b?.data?.deal_expiry) {
            return isAsc ? 1 : -1;
          }

          return 0;
        });

        return results;
      };
      renderSearchResults = (results: PrismicTravelDeal[]): ReactNode => {
        const { sliceData } = this.props;

        if (results.length === 0) {
          return (
            <div className="content has-text-centered">
              <PrismicStructuredText text={sliceData?.primary?.no_results_found_content} />
            </div>
          );
        }

        return (
          <ImageLinkTileList forceLayout="thirds">
            {results.map((deal, index) => (
              <TravelDeal key={index} deal={deal} />
            ))}
          </ImageLinkTileList>
        );
      };

      filterFields = (
        fields: TagBasedSearchFieldsConfig<TravelDealsSearchParams>,
        results: PrismicTravelDeal[]
      ): TagBasedSearchFieldsConfig<TravelDealsSearchParams> => {
        const fieldConfig = cloneDeep(this.fieldConfig);

        fields.destination.options =
          fieldConfig?.destination?.options.filter((td) => {
            return results.some((result) => {
              return result.data?.travel_destinations?.some((tds) => {
                return tds?.travel_destination?.document?.id === td.value;
              });
            });
          }) ?? [];
        fields.travelPartner.options =
          fieldConfig?.travelPartner?.options.filter((tp) => {
            return results.some((result) => {
              return result?.data?.travel_partner?.document?.id === tp.value;
            });
          }) ?? [];
        fields.travelType.options =
          fieldConfig?.travelType?.options.filter((tt) => {
            return results.some((result) => {
              return result.data?.travel_types?.some((tts) => {
                return tts?.travel_type?.document?.id === tt.value;
              });
            });
          }) ?? [];
        return fields;
      };
    }
  )
);
export default TravelDealsSearchSlice;

const TravelDealsSearchSliceQuery = graphql`
  query TravelDealsSearchSliceQuery {
    allPrismicTaxonomyTravelDestination(sort: { fields: data___name, order: ASC }) {
      nodes {
        id
        data {
          name
        }
      }
    }
    allPrismicTaxonomyTravelType(sort: { fields: data___name, order: ASC }) {
      nodes {
        id
        data {
          name
        }
      }
    }
    allPrismicTravelPartner(sort: { fields: data___page_title }) {
      nodes {
        id
        data {
          page_title
        }
      }
    }
  }
`;

interface TravelDealsSearchParams {
  travelType: string[];
  destination: string[];
  travelPartner: string[];
  sortExpiry: string[];
}

interface TravelDealsSearchSliceQueryResults {
  allPrismicTaxonomyTravelDestination?: PrismicTaxonomyTravelDestinationConnection;
  allPrismicTaxonomyTravelType?: PrismicTaxonomyTravelTypeConnection;
  allPrismicTravelPartner?: PrismicTravelPartnerConnection;
}

export interface TravelDealsSearchSliceProps {
  sliceData: PrismicTravelDealsSearchPageDataBodySearchEnginePlaceholder;
  pageData?: PrismicTravelDealsSearchPage;
}
