import React, { Component, RefObject } from 'react';
import { Transform } from 'react-html-parser';
import { ParseMarkdownSync } from '../../utils/MarkdownProcessor';

export default class PrismicMarkdownText extends Component<
  PrismicMarkdownTextProps
> {
  private readonly mdContent: RefObject<HTMLDivElement> = React.createRef();

  render() {
    const { text } = this.props;

    if (!text) {
      return <React.Fragment />;
    }

    const htmlString = ParseMarkdownSync(text, true) || '';

    return (
      <div
        ref={this.mdContent}
        className={'markdown-content'}
        dangerouslySetInnerHTML={{ __html: htmlString }}
      />
    );
  }
}

export interface PrismicMarkdownTextProps {
  text?: string | null;
  transform?: Transform;
}
