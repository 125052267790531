import React, { Component, ReactNode } from 'react';
import { PrismicTravelDeal, PrismicTravelPartner } from '../../../graphql';
import ImageLinkTile from '../../controls/ImageLinkTile';
import WithGlobalResources, {
  InjectedGlobalResourcesProps,
} from '../../higher-order/WithGlobalResources';
import PrismicLink from '../../controls/PrismicLink';
import { ToMoment } from '../../../utils/PrismicHelpers';
import PrismicImage from '../../controls/PrismicImage';
import { ResolveTravelLinkItems } from '../../../utils/DynamicLinksHelper';

const TravelDeal = WithGlobalResources(
  class TravelDealImpl extends Component<
    TravelDealProps & InjectedGlobalResourcesProps
  > {
    render(): ReactNode {
      const { deal, globalResources } = this.props;

      if (!deal) {
        return null;
      }

      let dealNameNode: ReactNode = null;
      if (deal?.data?.page_title) {
        dealNameNode = (
          <div className="image-link-tile-deal-name">
            {deal?.data?.page_title}
          </div>
        );
      }

      const partner =
        (deal?.data?.travel_partner?.document as
          | PrismicTravelPartner
          | undefined) || undefined;

      let dealPartnerLogoNode: ReactNode = null;
      if (partner?.data?.partner_logo) {
        dealPartnerLogoNode = (
          <div className="columns is-mobile is-centered is-gapless">
            <div className="column is-two-thirds">
              <PrismicImage
                image={partner?.data?.partner_logo ?? null}
                className="has-max-height-120"
                imgStyle={{ objectFit: 'contain' }}
                aria-label={partner?.data?.page_title ?? undefined}
                srcIsFixed
              />
            </div>
          </div>
        );
      }

      let dealExpiryNode: ReactNode = null;
      if (deal?.data?.deal_expiry) {
        const expiryDate = ToMoment(deal?.data?.deal_expiry);

        if (expiryDate) {
          const formattedDate = expiryDate.format('MMMM Do, YYYY');

          dealExpiryNode = (
            <div className="image-link-tile-deal-date">
              <strong>{globalResources.travel_deal_card_expiry_label}</strong>{' '}
              {formattedDate}
            </div>
          );
        }
      }

      const learnMoreButton = (
        <PrismicLink
          to={deal}
          className="button has-text-weight-bold is-caa-redorange"
        >
          {globalResources.travel_deal_card_learn_more_cta}
        </PrismicLink>
      );

      const bottomNavigationItems: ReactNode[] = ResolveTravelLinkItems(
        globalResources.travel_deal_card_nav_items,
        {}
      ).map((item, index) => (
        <li key={index}>
          <PrismicLink to={item.link_dest}>{item.link_text}</PrismicLink>
        </li>
      ));

      let bottomNavigation = null;
      if (bottomNavigationItems) {
        bottomNavigation = (
          <ul className={'image-link-tile-bottom-links'}>
            {bottomNavigationItems}
          </ul>
        );
      }

      return (
        <ImageLinkTile
          className={'is-travel-deal-tile'}
          image={deal?.data?.deal_image}
          crown_label={globalResources.travel_deal_card_header_text}
          crown_icon={'tag'}
          crown_className={'is-caa-yellow'}
          custom_content={
            <React.Fragment>
              <div className="tile-content-main">
                {dealNameNode}
                {dealPartnerLogoNode}
                {dealExpiryNode}
              </div>
              {learnMoreButton}
              {bottomNavigation}
            </React.Fragment>
          }
        />
      );
    }
  }
);
export default TravelDeal;

export interface TravelDealProps {
  deal?: PrismicTravelDeal | null;
}
