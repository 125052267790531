import React, { Component, PropsWithChildren, ReactNode } from 'react';

export default class ImageLinkTileList extends Component<
  PropsWithChildren<ImageLinkTileListProps>
> {
  render(): ReactNode {
    const { children } = this.props;
    const childrenArray = Array.isArray(children)
      ? children
      : children
      ? [children]
      : [];
    const numberOfItems = childrenArray.length;
    const { forceLayout } = this.props;

    if (childrenArray.length === 0) {
      return null;
    }

    const thirdClassNames = 'column is-half-tablet is-one-third-desktop';
    const quarterClassNames =
      'column is-half-tablet is-one-third-desktop is-one-quarter-widescreen';

    let columnClassNames =
      numberOfItems < 3 || (numberOfItems % 3 === 0 && numberOfItems % 4 !== 0)
        ? thirdClassNames
        : quarterClassNames;

    if (forceLayout === 'thirds') {
      columnClassNames = thirdClassNames;
    } else if (forceLayout === 'quarters') {
      columnClassNames = quarterClassNames;
    }

    return (
      <div className="columns is-centered is-multiline neo-image-link-tiles">
        {childrenArray.map((tile, index) => (
          <div className={columnClassNames} key={index}>
            {tile}
          </div>
        ))}
      </div>
    );
  }
}

interface ImageLinkTileListProps {
  forceLayout?: 'thirds' | 'quarters';
}
