import React, { Component, ReactNode } from 'react';
import { PrismicPage, PrismicPageDataBodyMembershipTiles, PrismicPlan } from '../../graphql';
import MembershipTile from '../controls/MembershipTile';
import ImageLinkTileList from '../controls/ImageLinkTileList';
import { graphql } from 'gatsby';
import SectionTitle from '../controls/SectionTitle';

export const MembershipTilesSliceKey = 'membership_tiles';
export default class MembershipTilesSlice extends Component<MembershipTilesSliceProps> {
  render(): ReactNode {
    const { sliceData } = this.props;
    const items = Array.isArray(sliceData.items) ? sliceData.items : [];

    if (!items.length) {
      return <React.Fragment />;
    }

    const linkItems: ReactNode[] = items.map((value, index) => {
      let tile: ReactNode = null;
      if (value?.plan?.document) {
        const planDoc = value.plan.document as PrismicPlan;
        const image = planDoc.data.image ?? planDoc.data.plan_image;
        const showSpecs = value.show_specifications_list === 'Yes';
        const showTileCTA = value.show_tile_cta === 'Yes';

        tile = (
          <div key={index} className="tile">
            <MembershipTile
              index={index}
              image={image}
              membership_title={planDoc.data.membership_title}
              feature_title={planDoc.data.feature_title}
              feature_desc={planDoc.data.feature_desc}
              membership_short_desc={planDoc.data.membership_short_description}
              button_label={planDoc.data.button_label_short}
              button_link_dest={planDoc.data.button_link_short_dest}
              membership_specs={planDoc.data.membership_specs}
              link_dest={planDoc.data.link_dest}
              link_cta={planDoc.data.link_cta}
              showSpecs={showSpecs}
              showTileCTA={showTileCTA}
            />
          </div>
        );
      }

      return tile;
    });

    return (
      <div className="container-fullwidth neo-bg-gradient">
        <div className="has-neo-arc" />
        <div className="container">
          <SectionTitle component={sliceData.primary?.section_title} />

          <section className="neo-image-link-tiles">
            <ImageLinkTileList>{linkItems}</ImageLinkTileList>
          </section>
        </div>
      </div>
    );
  }
}

export interface MembershipTilesSliceProps {
  sliceData: PrismicPageDataBodyMembershipTiles;
  pageData?: PrismicPage;
}

// noinspection JSUnusedGlobalSymbols
export const SliceMembershipTilesFragment = graphql`
  fragment SliceMembershipTilesFragment on PrismicPageDataBodyMembershipTiles {
    id
    slice_type
    primary {
      section_title {
        html
      }
    }
    items {
      plan {
        document {
          ... on PrismicPlan {
            data {
              image {
                alt
                url
                gatsbyImageData
              }
              plan_image {
                alt
                url
                gatsbyImageData
              }
              membership_title {
                html
              }
              feature_title
              feature_desc
              membership_short_description
              button_label_short
              button_link_short_dest {
                url
                target
                link_type
              }
              membership_specs {
                html
              }
              link_cta
              link_dest {
                url
                target
                link_type
              }
            }
          }
        }
      }
      show_specifications_list
      show_tile_cta
    }
  }
`;
