import React, { Component, ReactNode } from 'react';
import { PrismicMerchandise } from '../../../graphql';
import ImageLinkTile from '../../controls/ImageLinkTile';
import WithGlobalResources, {
  InjectedGlobalResourcesProps,
} from '../../higher-order/WithGlobalResources';
import { ToMoment } from '../../../utils/PrismicHelpers';
import PrismicStructuredText from '../../controls/PrismicStructuredText';
import { Moment } from 'moment-timezone/moment-timezone';
import moment from 'moment-timezone';

const Merchandise = WithGlobalResources(
  class MerchandiseImpl extends Component<
    MerchandiseProps & InjectedGlobalResourcesProps
  > {
    render(): ReactNode {
      const { merchandise, globalResources } = this.props;

      if (!merchandise) {
        return null;
      }

      let merchandiseNameNode: ReactNode = null;
      if (merchandise?.data?.page_title) {
        merchandiseNameNode = (
          <div className="image-link-tile-merchandise-name">
            {merchandise?.data?.page_title}
          </div>
        );
      }

      let merchandiseDescNode: ReactNode = null;
      if (merchandise?.data?.product_short_description) {
        merchandiseDescNode = (
          <div className="image-link-tile-merchandise-description">
            <PrismicStructuredText
              text={merchandise?.data?.product_short_description}
            />
          </div>
        );
      }

      let priceNode: ReactNode = null;
      let nonMemberPriceNode: ReactNode = null;

      if (merchandise?.data?.member_price) {
        let hasSale = false;
        let saleDate: Moment | undefined;
        if (merchandise?.data?.sale_price) {
          saleDate = ToMoment(merchandise?.data?.sale_expiry);
          hasSale = !!(!saleDate || saleDate?.isAfter(moment(), 'day'));
        }

        if (merchandise?.data?.non_member_price) {
          nonMemberPriceNode = (
            <div className="image-link-tile-merchandise-price-non-member">
              <span className={'has-text-weight-semibold'}>
                {globalResources.merchandise_card_non_member_price_label}
              </span>{' '}
              {merchandise?.data?.non_member_price}
            </div>
          );
        }

        if (hasSale) {
          priceNode = (
            <div className="image-link-tile-merchandise-price is-sale">
              <div className="image-link-tile-merchandise-price-sale">
                {globalResources.merchandise_card_sale_label}
              </div>
              <div
                className="image-link-tile-merchandise-price-main"
                aria-label={
                  globalResources.merchandise_card_member_price_sr_hint ||
                  undefined
                }
              >
                {merchandise?.data?.sale_price}
              </div>
              <div className="image-link-tile-merchandise-price-regular">
                {globalResources.merchandise_card_regular_price_label}{' '}
                <span className="has-text-strikethrough">
                  {merchandise?.data?.member_price}
                </span>
              </div>
            </div>
          );
        } else {
          priceNode = (
            <div className="image-link-tile-merchandise-price">
              <div
                className="image-link-tile-merchandise-price-main"
                aria-label={
                  globalResources.merchandise_card_non_member_price_label ||
                  undefined
                }
              >
                {merchandise?.data?.member_price}
              </div>
            </div>
          );
        }
      } else if (merchandise?.data?.non_member_price) {
        priceNode = (
          <div className="image-link-tile-merchandise-price">
            <div className="image-link-tile-merchandise-price-main">
              {merchandise?.data?.non_member_price}
            </div>
          </div>
        );
      }

      const ctaNode = (
        <div className={'cta'} key={'cta'}>
          {globalResources.merchandise_card_learn_more_cta}
        </div>
      );

      return (
        <ImageLinkTile
          className={'is-merchandise-tile'}
          link_dest={merchandise}
          image={(merchandise?.data?.product_images || [])[0]?.image}
          displayImageMobile={true}
          custom_content={
            <React.Fragment>
              <div className="tile-content-main">
                {merchandiseNameNode}
                {priceNode}
                {merchandiseDescNode}
                {nonMemberPriceNode}
              </div>
              {ctaNode}
            </React.Fragment>
          }
        />
      );
    }
  }
);
export default Merchandise;

export interface MerchandiseProps {
  merchandise?: PrismicMerchandise | null;
}
