import React, { Component, ReactNode } from 'react';

export default class KeyValueList extends Component<KeyValueListProps> {
  render(): ReactNode {
    const {
      keyValues,
      classNames,
      columnsClassNames,
      keyColumnClassNames,
      valueColumnClassNames,
    } = this.props;

    if (!keyValues?.length) {
      return null;
    }

    const resolvedColumnsClassNames = columnsClassNames
      ? columnsClassNames
      : 'is-variable is-2';
    const resolvedKeyColumnClassNames = keyColumnClassNames
      ? keyColumnClassNames
      : 'is-narrow  has-text-weight-semibold';
    const resolvedValueColumnClassNames = valueColumnClassNames
      ? valueColumnClassNames
      : '';

    return (
      <div className={`neo-key-value-list ${classNames || ''}`}>
        {keyValues.map((kvp, index) => (
          <div
            className={`columns ${
              kvp.columnsClassNames
                ? kvp.columnsClassNames
                : resolvedColumnsClassNames
            }`}
            key={index}
          >
            <div
              className={`column ${
                kvp.keyClassNames
                  ? kvp.keyClassNames
                  : resolvedKeyColumnClassNames
              }`}
            >
              {kvp.key}
            </div>
            <div
              className={`column ${
                kvp.valueClassNames
                  ? kvp.valueClassNames
                  : resolvedValueColumnClassNames
              }`}
            >
              {kvp.value}
            </div>
          </div>
        ))}
      </div>
    );
  }
}

export interface KeyValueListProps {
  keyValues: KeyValuePair[] | undefined;
  columnsClassNames?: string;
  keyColumnClassNames?: string;
  valueColumnClassNames?: string;
  classNames?: string;
}

export interface KeyValuePair {
  key: string;
  keyClassNames?: string;
  value: ReactNode;
  valueClassNames?: string;
  columnsClassNames?: string;
}
