import {
  Maybe,
  PrismicLinkType,
  PrismicTravelPartner,
  PrismicTravelStore,
  Scalars,
} from '../graphql';
import { IsValidPrismicLink } from './PrismicHelpers';

export const ResolveTravelLinkItems = (
  input: Maybe<Maybe<NavLinkItem>[]> | undefined,
  context: TravelLinkContext
): ResolvedLinkItem[] => {
  if (input === null || input === undefined) {
    return [];
  }

  return (input
    .map(link => {
      if (link === undefined || link === null) {
        return undefined;
      }

      const linkData = {
        link_text: link.link_text,
        link_icon: link.link_icon,
        link_dest: link.link_dest,
      } as NavLinkItem;

      if (link.link_dest?.link_type === 'Web') {
        if (link.link_dest.url === 'https://{storeUrl}') {
          if (context.store) {
            linkData.link_dest = {
              link_type: 'Document',
              uid: context.store.uid,
              url: context.store.url,
              target: link.link_dest.target,
            };
          } else {
            linkData.link_dest = undefined;
          }
        } else {
          linkData.link_dest = {
            link_type: 'Web',
            url: link.link_dest.url
              ?.replace('{storeId}', context?.store?.id || '')
              ?.replace('{partnerId}', context?.travelPartner?.id || ''),
            target: link.link_dest.target,
          };
        }
      }

      return linkData;
    })
    .filter(
      l => l?.link_text && IsValidPrismicLink(l?.link_dest)
    ) as any) as ResolvedLinkItem[];
};

interface NavLinkItem {
  link_text?: Maybe<Scalars['String']>;
  link_icon?: Maybe<Scalars['String']>;
  link_dest?: Maybe<PrismicLinkType>;
}

interface ResolvedLinkItem {
  link_text: Scalars['String'];
  link_icon?: Scalars['String'];
  link_dest: PrismicLinkType;
}

interface TravelLinkContext {
  travelPartner?: Maybe<PrismicTravelPartner>;
  store?: Maybe<PrismicTravelStore>;
}
