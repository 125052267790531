import React, { Component, ReactNode } from 'react';
import PrismicLink from '../controls/PrismicLink';
import WithGlobalResources, {
  InjectedGlobalResourcesProps,
} from '../higher-order/WithGlobalResources';
import { PrismicLinkType } from '../../graphql';

const SimpleLinkTile = WithGlobalResources(
  class SimpleLinkTilesImpl extends Component<
    InjectedGlobalResourcesProps & ISimpleLinkTileData
  > {
    render() {
      let linkClasses = 'simple-link-tile';
      let text: ReactNode = undefined;
      const ctaLabel: ReactNode = (
        <div className={'cta'}>
          {this.props.ctaLabel
            ? this.props.ctaLabel
            : this.props.globalResources.default_cta_text}
        </div>
      );
      if (this.props.text) {
        text = (
          <div className="tile-text">
            <p>{this.props.text}</p>
          </div>
        );
      }
      if (this.props.mainColor) {
        linkClasses = linkClasses.concat(' ', this.props.mainColor);
      }
      return (
        <PrismicLink to={this.props.link_dest!} className={linkClasses}>
          <div className="tile-content">
            {text}
            {ctaLabel}
          </div>
        </PrismicLink>
      );
    }
  }
);
export default SimpleLinkTile;

export interface ISimpleLinkTileData {
  mainColor?: 'blue' | 'red' | 'green';
  text?: string;
  ctaLabel?: string;
  link_dest?: PrismicLinkType | null;
}
