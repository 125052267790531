import React, { Component, ReactNode } from 'react';
import { PrismicAgent } from '../../../graphql';
import ImageLinkTile from '../../controls/ImageLinkTile';
import WithGlobalResources, { InjectedGlobalResourcesProps } from '../../higher-order/WithGlobalResources';
import { parsePhoneNumberFromString } from 'libphonenumber-js';
import PrismicFontAwesomeIcon from '../../controls/PrismicFontAwesomeIcon';
import { IsValidPrismicLink } from '../../../utils/PrismicHelpers';
import PrismicLink from '../../controls/PrismicLink';

const Agent = WithGlobalResources(
  class AgentImpl extends Component<AgentProps & InjectedGlobalResourcesProps> {
    render(): ReactNode {
      const { agent, globalResources } = this.props;

      if (!agent) {
        return null;
      }

      let agentNameNode: ReactNode = null;
      if (agent?.data?.name) {
        agentNameNode = <div className="image-link-tile-agent-name">{agent?.data?.name}</div>;
      }

      let agentTitleNode: ReactNode = null;
      if (agent?.data?.title) {
        agentTitleNode = <div className="image-link-tile-agent-title">{agent?.data?.title}</div>;
      }

      let agentContactButtonNode: ReactNode = null;
      let bookAppointmentButtonNode: ReactNode = null;

      if (IsValidPrismicLink(agent?.data?.agent_contact_form_url)) {
        agentContactButtonNode = (
          <PrismicLink
            to={agent?.data?.agent_contact_form_url}
            className="button has-text-weight-bold is-caa-redorange"
          >
            {globalResources.agent_contact_agent_button}
          </PrismicLink>
        );
      }

      if (IsValidPrismicLink(agent?.data?.book_an_appointment_url)) {
        bookAppointmentButtonNode = (
          <PrismicLink
            to={agent?.data?.book_an_appointment_url}
            className="button has-text-weight-bold is-caa-redorange"
          >
            {globalResources.agent_appointment_cta_text}
          </PrismicLink>
        );
      }

      let agentPhoneNode: ReactNode = null;
      if (agent?.data?.agent_phone) {
        const phoneNumber = parsePhoneNumberFromString(agent?.data?.agent_phone, 'CA');

        if (phoneNumber) {
          phoneNumber.ext = agent?.data?.agent_phone_extension || undefined;

          if (phoneNumber.ext === '') {
            phoneNumber.ext = undefined;
          }

          agentPhoneNode = (
            <div className="image-link-tile-agent-phone">
              <a href={phoneNumber?.getURI()}>
                <PrismicFontAwesomeIcon icon={'phone-rotary'} />
                <span>{phoneNumber?.formatNational()}</span>
              </a>
            </div>
          );
        }
      }

      const generateTagLabel = () => {
        const storeList = agent?.data?.caa_travel_store_list || [];
        let storeString =
          // @ts-ignore
          agent?.data?.caa_travel_store?.document?.data?.page_title || undefined;
        if (storeList.length && storeString) {
          storeList.map((store) => {
            storeString = storeString.concat(
              ', ',
              // @ts-ignore
              store?.section?.document?.data?.page_title
            );
          });
        }
        return storeString;
      };
      const storeLocation = generateTagLabel();

      return (
        <ImageLinkTile
          className={'is-agent-tile'}
          image={agent?.data?.agent_photo}
          tag_icon={'map-marker-alt'}
          tag_label={storeLocation}
          crown_label={globalResources.agent_card_header_text}
          crown_icon={'user-headset'}
          custom_content={
            <React.Fragment>
              <div className="tile-content-main">
                {agentNameNode}
                {agentTitleNode}
              </div>
              {agentContactButtonNode}
              {bookAppointmentButtonNode}
              {agentPhoneNode}
            </React.Fragment>
          }
        />
      );
    }
  }
);
export default Agent;

export interface AgentProps {
  agent?: PrismicAgent | null;
}
