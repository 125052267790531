import {
  PrismicTaxonomyTravelTypeConnection,
  PrismicTravelTrip,
  PrismicTravelTripsSearchPage,
  PrismicTravelTripsSearchPageDataBodySearchEnginePlaceholder,
  PrismicTravelPartnerConnection,
  PrismicTaxonomyTravelDestinationConnection,
} from '../../../graphql';
import React, { Component, ReactNode } from 'react';
import { graphql, StaticQuery } from 'gatsby';
import WithGlobalResources, { InjectedGlobalResourcesProps } from '../../higher-order/WithGlobalResources';
import TagBasedSearch, {
  NodeListToOptions,
  TagBasedSearchFieldsConfig,
  TagBasedSearchOption,
} from '../../controls/TagBasedSearch';
import WithTravelTripData, { InjectedTravelTripDataProps } from '../../higher-order/WithTravelTripData';
import { FilterTravelTripDataBasedOnTagsAndDate } from '../../../utils/AgentDataHelpers';
import TravelTrip from '../../fragments/travel-trip/TravelTrip';
import ImageLinkTileList from '../../controls/ImageLinkTileList';
import PrismicStructuredText from '../../controls/PrismicStructuredText';
import { HashMap } from '../../../utils/UtilityTypes';
import { ToMoment } from '../../../utils/PrismicHelpers';
import SectionTitle from '../../controls/SectionTitle';

export const TravelTripsSearchSliceKey = '!internal_travel_trips_search_slice';

const TravelTripsSearchSlice = WithGlobalResources(
  WithTravelTripData(
    class TravelTripsSearchSliceImpl extends Component<
      TravelTripsSearchSliceProps & InjectedGlobalResourcesProps & InjectedTravelTripDataProps
    > {
      private fieldConfig?: TagBasedSearchFieldsConfig<TravelTripsSearchParams>;

      render(): ReactNode {
        return <StaticQuery query={TravelTripsSearchSliceQuery} render={(results) => this.renderWithData(results)} />;
      }

      renderWithData(data: TravelTripsSearchSliceQueryResults): ReactNode {
        const { sliceData, travelTripData } = this.props;

        const quickLookUp: HashMap<boolean> = {};
        const travelTripStartDates: TagBasedSearchOption[] = [];

        if (!this.fieldConfig) {
          travelTripData.forEach((travel) => {
            if (travel?.data?.precise_date_start) {
              const startDate = ToMoment(travel?.data?.precise_date_start);

              if (startDate) {
                const short = startDate.format('YYYY-MM');

                if (quickLookUp[short]) {
                  return;
                }

                const long = startDate.format('MMMM YYYY');

                quickLookUp[short] = true;
                travelTripStartDates.push({
                  label: long,
                  value: short,
                });
              }
            }
          });

          travelTripStartDates.sort((a, b) => {
            if (a.value < b.value) {
              return -1;
            }

            if (a.value > b.value) {
              return 1;
            }

            return 0;
          });

          this.fieldConfig = {
            travelType: {
              label: sliceData?.primary?.travel_type_field_label || '',
              options: NodeListToOptions(data?.allPrismicTaxonomyTravelType?.nodes, (node) => node.data?.name).filter(
                (option) =>
                  travelTripData.some((ta) =>
                    ta.data?.travel_types?.some((tt) => tt?.travel_type?.document?.id === option.value)
                  )
              ),
              allowMultiple: true,
            },
            month: {
              label: sliceData?.primary?.month_of_travel_field_label || '',
              options: travelTripStartDates,
              allowMultiple: true,
            },
            destination: {
              label: sliceData?.primary?.destination_field_label || '',
              options: NodeListToOptions(
                data?.allPrismicTaxonomyTravelDestination?.nodes,
                (node) => node?.data?.name
              ).filter((option) =>
                travelTripData.some((ta) =>
                  ta.data?.travel_destinations?.some((tt) => tt?.travel_destination?.document?.id === option.value)
                )
              ),
              allowMultiple: true,
            },
            travelPartner: {
              label: sliceData?.primary?.travel_partner_field_label || '',
              options: NodeListToOptions(data?.allPrismicTravelPartner?.nodes, (node) => node?.data?.page_title),
              allowMultiple: true,
            },
          };
        }

        return (
          <div className="container neo-tag-search-page">
            <SectionTitle component={sliceData.primary?.section_title} />

            <TagBasedSearch
              fields={this.fieldConfig}
              search={this.executeSearch}
              render={this.renderSearchResults}
              maxPerPage={6}
            />
          </div>
        );
      }

      executeSearch = (params: TravelTripsSearchParams): PrismicTravelTrip[] => {
        const { travelTripData } = this.props;

        return FilterTravelTripDataBasedOnTagsAndDate(travelTripData, {
          travelMonth: params.month,
          travelPartnerId: params.travelPartner,
          travelDestinationId: params.destination,
          travelTypeId: params.travelType,
        });
      };
      renderSearchResults = (results: PrismicTravelTrip[]): ReactNode => {
        const { sliceData } = this.props;

        if (results.length === 0) {
          return (
            <div className="content has-text-centered">
              <PrismicStructuredText text={sliceData?.primary?.no_results_found_content} />
            </div>
          );
        }

        return (
          <ImageLinkTileList forceLayout="thirds">
            {results.map((trip, index) => (
              <TravelTrip key={index} trip={trip} />
            ))}
          </ImageLinkTileList>
        );
      };
    }
  )
);
export default TravelTripsSearchSlice;

const TravelTripsSearchSliceQuery = graphql`
  query TravelTripsSearchSliceQuery {
    allPrismicTaxonomyTravelDestination(sort: { fields: data___name, order: ASC }) {
      nodes {
        id
        data {
          name
        }
      }
    }
    allPrismicTaxonomyTravelType(sort: { fields: data___name, order: ASC }) {
      nodes {
        id
        data {
          name
        }
      }
    }
    allPrismicTravelPartner(sort: { fields: data___page_title }) {
      nodes {
        id
        data {
          page_title
        }
      }
    }
  }
`;

interface TravelTripsSearchParams {
  travelType: string[];
  month: string[];
  destination: string[];
  travelPartner: string[];
}

interface TravelTripsSearchSliceQueryResults {
  allPrismicTaxonomyTravelDestination?: PrismicTaxonomyTravelDestinationConnection;
  allPrismicTaxonomyTravelType?: PrismicTaxonomyTravelTypeConnection;
  allPrismicTravelPartner?: PrismicTravelPartnerConnection;
}

export interface TravelTripsSearchSliceProps {
  sliceData: PrismicTravelTripsSearchPageDataBodySearchEnginePlaceholder;
  pageData?: PrismicTravelTripsSearchPage;
}
