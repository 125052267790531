import { PrismicEmailSubscriptionPageDataBodyFormPlaceholder, PrismicSearchPage, Site } from '../../../graphql';
import React, { Component, ReactNode, RefObject } from 'react';
import { MakeRandomId } from '../../../utils/RandomId';
import cloneDeep from 'lodash/cloneDeep';
import PrismicFontAwesomeIcon from '../../controls/PrismicFontAwesomeIcon';
import PrismicStructuredText from '../../controls/PrismicStructuredText';
import ReCAPTCHA from 'react-google-recaptcha';
import { graphql, StaticQuery } from 'gatsby';
import {
  EmailSubscriptionClient,
  EmailSubscriptions,
  ModifyEmailSubscriptionsRequest,
} from '../../../services/ApiClient';
import SectionTitle from '../../controls/SectionTitle';

export const EmailSubscriptionFormSliceKey = '!internal_email_subscription_page_form_slice';

const EmailSubscriptionFormSlice = class EmailSubscriptionFormSliceImpl extends Component<
  EmailSubscriptionFormSliceProps,
  EmailSubscriptionFormSliceState
> {
  private readonly MembershipNumberRegex = /^(620290[0-9]{10})$/;
  // eslint-disable-next-line no-control-regex
  private readonly EmailRegex =
    /((([a-z]|\d|[!#$%&'*+\-/=?^_`{|}~]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+(\.([a-z]|\d|[!#$%&'*+\-/=?^_`{|}~]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+)*)|((\x22)((((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(([\x01-\x08\x0b\x0c\x0e-\x1f\x7f]|\x21|[\x23-\x5b]|[\x5d-\x7e]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(\\([\x01-\x09\x0b\x0c\x0d-\x7f]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]))))*(((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(\x22)))@((([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-||_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.)+(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+|(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+([a-z]+|\d|-|\.{0,1}|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])?([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))$/;

  private readonly formDivRef: RefObject<HTMLDivElement> = React.createRef();

  private readonly membershipInputRef: RefObject<HTMLInputElement> = React.createRef();

  private readonly emailInputRef: RefObject<HTMLInputElement> = React.createRef();

  constructor(props: Readonly<EmailSubscriptionFormSliceProps>) {
    super(props);

    this.state = {
      elementIdPrefix: MakeRandomId('EmailSubscriptionForm', 10),
      isSubmitting: false,
      formFields: {
        membershipNumber: {
          value: '',
          isDirty: false,
        },
        email: {
          value: '',
          isDirty: false,
        },
        forceUnsubscribe: {
          value: false,
          isDirty: false,
        },
        subscribeToMemberBulletin: {
          value: false,
          isDirty: false,
        },
        subscribeToSoloTravel: {
          value: false,
          isDirty: false,
        },
        subscribeToTravel: {
          value: false,
          isDirty: false,
        },
        subscribeToMagazine: {
          value: false,
          isDirty: false,
        },
        recaptchaChallenge: {
          value: undefined,
          isDirty: false,
        },
      },
    };
  }

  componentDidMount(): void {
    this.validateForm();
  }

  render(): ReactNode {
    return <StaticQuery query={EmailSubscriptionFormSliceQuery} render={(results) => this.renderWithData(results)} />;
  }

  renderWithData(data: EmailSubscriptionFormSliceQueryResults): ReactNode {
    const { sliceData } = this.props;
    const { isSubmitSuccess } = this.state;

    let formNode: ReactNode = null;

    if (isSubmitSuccess === true) {
      formNode = (
        <div className="content">
          <PrismicStructuredText text={sliceData?.primary?.success_message} />
        </div>
      );
    } else if (isSubmitSuccess === false) {
      formNode = (
        <div className="content">
          <PrismicStructuredText text={sliceData?.primary?.error_message} />
        </div>
      );
    } else {
      formNode = this.renderForm(data!.site!.siteMetadata!.recaptchaSiteKey!);
    }

    return (
      <div className="container neo-email-subscription-form" ref={this.formDivRef}>
        <SectionTitle component={sliceData.primary?.section_title} />
        {formNode}
      </div>
    );
  }

  componentDidUpdate(
    _prevProps: Readonly<EmailSubscriptionFormSliceProps>,
    prevState: Readonly<EmailSubscriptionFormSliceState>,
    _snapshot?: any
  ): void {
    const prevValues = this.fieldsToValueCollection(prevState.formFields);
    const nextValues = this.fieldsToValueCollection(this.state.formFields);

    // tslint:disable-next-line:prefer-array-literal
    const fieldKeys = Object.keys(this.state.formFields) as Array<keyof FormFields>;

    for (const field of fieldKeys) {
      if (prevValues[field] !== nextValues[field]) {
        this.validateForm();
        break;
      }
    }

    if (prevState.isSubmitSuccess !== this.state.isSubmitSuccess && this.formDivRef.current) {
      this.formDivRef.current.scrollIntoView(true);
    }
  }

  fieldsToValueCollection(fields: FormFields): FormValuesCollection {
    const collection: Partial<FormValuesCollection> = {};

    Object.keys(fields).forEach((key) => {
      const field = key as keyof FormFields;
      collection[field] = fields[field].value;
    });

    return collection as FormValuesCollection;
  }

  renderForm(recaptchaSiteKey: string): ReactNode {
    const { sliceData } = this.props;
    const { elementIdPrefix, formFields, isSubmitting } = this.state;

    // tslint:disable-next-line:prefer-array-literal
    const fieldNames = Object.keys(formFields) as Array<keyof FormFields>;
    const formIsValid = !fieldNames.some((field) => formFields[field].error !== undefined);

    return (
      <form onSubmit={this.handleSubmit}>
        <div className="field">
          <label
            id={`${elementIdPrefix}__membershipNumber__label`}
            htmlFor={`${elementIdPrefix}__membershipNumber__input`}
            className="label"
          >
            {sliceData?.primary?.membership_number_field_label}
          </label>
          <div className={`control has-icons-left ${formFields.membershipNumber.isDirty ? 'has-icons-right' : ''}`}>
            <input
              id={`${elementIdPrefix}__membershipNumber__input`}
              aria-labelledby={`${elementIdPrefix}__membershipNumber__label`}
              className={`input ${
                formFields.membershipNumber.isDirty
                  ? formFields.membershipNumber.error
                    ? 'is-caa-redorange'
                    : 'is-caa-green'
                  : ''
              }`}
              type="text"
              inputMode="numeric"
              value={formFields.membershipNumber.value}
              onChange={(event) => this.onTextFieldChange(event, 'membershipNumber')}
              ref={this.membershipInputRef}
            />
            <span className="icon is-small is-left">
              <PrismicFontAwesomeIcon icon="id-card" />
            </span>
            {formFields.membershipNumber.isDirty ? (
              formFields.membershipNumber.error ? (
                <span className="icon is-small has-text-caa-red is-right">
                  <PrismicFontAwesomeIcon icon="exclamation-circle" />
                </span>
              ) : (
                <span className="icon is-small has-text-caa-green is-right">
                  <PrismicFontAwesomeIcon icon="check" />
                </span>
              )
            ) : null}
          </div>
          {formFields.membershipNumber.isDirty && formFields.membershipNumber.error ? (
            <p className="help is-caa-redorange">{formFields.membershipNumber.error}</p>
          ) : null}
        </div>
        <div className="field">
          <label id={`${elementIdPrefix}__email__label`} htmlFor={`${elementIdPrefix}__email__input`} className="label">
            {sliceData?.primary?.email_field_label}
          </label>
          <div className={`control has-icons-left ${formFields.email.isDirty ? 'has-icons-right' : ''}`}>
            <input
              id={`${elementIdPrefix}__email__input`}
              aria-labelledby={`${elementIdPrefix}__email__label`}
              className={`input ${
                formFields.email.isDirty ? (formFields.email.error ? 'is-caa-redorange' : 'is-caa-green') : ''
              }`}
              type="email"
              inputMode="email"
              value={formFields.email.value}
              onChange={(event) => this.onTextFieldChange(event, 'email')}
              ref={this.emailInputRef}
            />
            <span className="icon is-small is-left">
              <PrismicFontAwesomeIcon icon="at" />
            </span>
            {formFields.email.isDirty ? (
              formFields.email.error ? (
                <span className="icon is-small has-text-caa-red is-right">
                  <PrismicFontAwesomeIcon icon="exclamation-circle" />
                </span>
              ) : (
                <span className="icon is-small has-text-caa-green is-right">
                  <PrismicFontAwesomeIcon icon="check" />
                </span>
              )
            ) : null}
          </div>
          {formFields.email.isDirty && formFields.email.error ? (
            <p className="help is-caa-redorange">{formFields.email.error}</p>
          ) : null}
        </div>
        <div
          className={`neo-email-subscription-form-newsletter-list ${
            formFields.forceUnsubscribe.value ? 'is-unsubscribing' : ''
          }`}
        >
          <div className="content">
            <PrismicStructuredText text={sliceData?.primary?.newsletters_list_intro} />
          </div>
          <div className="field">
            <input
              id={`${elementIdPrefix}__subscribeToMemberBulletin__input`}
              aria-labelledby={`${elementIdPrefix}__subscribeToMemberBulletin__label`}
              className="switch is-rounded is-caa-forestgreen"
              type="checkbox"
              checked={formFields.subscribeToMemberBulletin.value}
              disabled={formFields.forceUnsubscribe.value}
              onChange={(event) => this.onCheckboxFieldChange(event, 'subscribeToMemberBulletin')}
            />
            <label
              id={`${elementIdPrefix}__subscribeToMemberBulletin__label`}
              htmlFor={`${elementIdPrefix}__subscribeToMemberBulletin__input`}
              className="label"
            >
              {sliceData?.primary?.member_bulletin_title}
            </label>
            <div className="content">
              <PrismicStructuredText text={sliceData?.primary?.member_bulletin_description} />
            </div>
          </div>
          <div className="field">
            <input
              id={`${elementIdPrefix}__subscribeToTravel__input`}
              aria-labelledby={`${elementIdPrefix}__subscribeToTravel__label`}
              className="switch is-rounded is-caa-forestgreen"
              type="checkbox"
              checked={formFields.subscribeToTravel.value}
              disabled={formFields.forceUnsubscribe.value}
              onChange={(event) => this.onCheckboxFieldChange(event, 'subscribeToTravel')}
            />
            <label
              id={`${elementIdPrefix}__subscribeToTravel__label`}
              htmlFor={`${elementIdPrefix}__subscribeToTravel__input`}
              className="label"
            >
              {sliceData?.primary?.travel_title}
            </label>
            <div className="content">
              <PrismicStructuredText text={sliceData?.primary?.travel_description} />
            </div>
          </div>
          <div className="field">
            <input
              id={`${elementIdPrefix}__subscribeToSoloTravel__input`}
              aria-labelledby={`${elementIdPrefix}__subscribeToSoloTravel__label`}
              className="switch is-rounded is-caa-forestgreen"
              type="checkbox"
              checked={formFields.subscribeToSoloTravel.value}
              disabled={formFields.forceUnsubscribe.value}
              onChange={(event) => this.onCheckboxFieldChange(event, 'subscribeToSoloTravel')}
            />
            <label
              id={`${elementIdPrefix}__subscribeToSoloTravel__label`}
              htmlFor={`${elementIdPrefix}__subscribeToSoloTravel__input`}
              className="label"
            >
              {sliceData?.primary?.solo_travel_title}
            </label>
            <div className="content">
              <PrismicStructuredText text={sliceData?.primary?.solo_travel_description} />
            </div>
          </div>
          <div className="field">
            <input
              id={`${elementIdPrefix}__subscribeToMagazine__input`}
              aria-labelledby={`${elementIdPrefix}__subscribeToMagazine__label`}
              className="switch is-rounded is-caa-forestgreen"
              type="checkbox"
              checked={formFields.subscribeToMagazine.value}
              disabled={formFields.forceUnsubscribe.value}
              onChange={(event) => this.onCheckboxFieldChange(event, 'subscribeToMagazine')}
            />
            <label
              id={`${elementIdPrefix}__subscribeToMagazine__label`}
              htmlFor={`${elementIdPrefix}__subscribeToMagazine__input`}
              className="label"
            >
              {sliceData?.primary?.magazine_title}
            </label>
            <div className="content">
              <PrismicStructuredText text={sliceData?.primary?.magazine_description} />
            </div>
          </div>
        </div>
        <div className="field">
          <input
            id={`${elementIdPrefix}__forceUnsubscribe__input`}
            aria-labelledby={`${elementIdPrefix}__forceUnsubscribe__label`}
            className="switch is-rounded is-caa-redorange"
            type="checkbox"
            checked={formFields.forceUnsubscribe.value}
            onChange={(event) => this.onCheckboxFieldChange(event, 'forceUnsubscribe')}
          />
          <label
            id={`${elementIdPrefix}__forceUnsubscribe__label`}
            htmlFor={`${elementIdPrefix}__forceUnsubscribe__input`}
            className="label"
          >
            {sliceData?.primary?.unsubscribe_label}
          </label>
          <p className="help">{sliceData?.primary?.unsubscribe_sublabel}</p>
        </div>
        <div className="field recaptcha-field">
          <div className="control">
            <ReCAPTCHA
              sitekey={recaptchaSiteKey}
              theme="light"
              hl="en"
              size="normal"
              onChange={(token) => this.onRecaptchaToken(token)}
            />
          </div>
          {formFields.recaptchaChallenge.isDirty && formFields.recaptchaChallenge.error ? (
            <p className="help is-caa-redorange">{formFields.recaptchaChallenge.error}</p>
          ) : null}
        </div>
        <div className="field">
          <div className="control">
            <button
              className="button is-medium is-caa-forestgreen has-text-weight-bold"
              type="submit"
              disabled={!formIsValid || isSubmitting}
            >
              {isSubmitting ? <PrismicFontAwesomeIcon icon={'spinner'} spin={true} /> : null}
              {sliceData?.primary?.submit_button_label}
            </button>
          </div>
        </div>
      </form>
    );
  }

  handleSubmit = (event: React.FormEvent) => {
    const { formFields } = this.state;

    event.preventDefault();

    this.validateForm();

    // tslint:disable-next-line:prefer-array-literal
    const fieldNames = Object.keys(formFields) as Array<keyof FormFields>;
    const formIsValid = !fieldNames.some((field) => formFields[field].error !== undefined);

    if (!formIsValid) {
      return;
    }

    this.setState({
      isSubmitting: true,
    });

    const emailSubscriptionRequest: ModifyEmailSubscriptionsRequest = new ModifyEmailSubscriptionsRequest({
      membershipNumber: formFields.membershipNumber.value.replace(/[^0-9]/g, ''),
      email: formFields.email.value,
      subscriptions: new EmailSubscriptions({
        memberBulletin: formFields.subscribeToMemberBulletin.value,
        travel: formFields.subscribeToTravel.value,
        soloTravel: formFields.subscribeToSoloTravel.value,
        magazine: formFields.subscribeToMagazine.value,
      }),
      forceUnsubscribe: formFields.forceUnsubscribe.value,
      recaptchaChallenge: formFields.recaptchaChallenge.value || '',
      registerFromUri: window.location.href,
    });

    const client = new EmailSubscriptionClient();

    client
      .postModify(emailSubscriptionRequest)
      .then((_result) => {
        this.setState({
          isSubmitting: false,
          isSubmitSuccess: true,
        });
      })
      .catch((error) => {
        console.error(error);
        this.setState({
          isSubmitting: false,
          isSubmitSuccess: false,
        });
      });
  };

  validateForm = () => {
    const { formFields } = this.state;
    const fields = cloneDeep(formFields);

    this.validateMembershipNumber(fields.membershipNumber);
    this.validateEmail(fields.email);
    this.validateRecaptcha(fields.recaptchaChallenge);

    if (fields.forceUnsubscribe.value) {
      fields.subscribeToMemberBulletin.value = false;
      fields.subscribeToTravel.value = false;
      fields.subscribeToSoloTravel.value = false;
      fields.subscribeToMagazine.value = false;
    }

    this.setState({
      formFields: fields,
    });
  };

  validateMembershipNumber = (field: FormFieldState<string>) => {
    if (field.value === '') {
      field.error = undefined;
      return;
    }

    const viewNormalizedValue = field.value
      .replace(/([^0-9\s])/g, '')
      .replace(/^\s+/g, '')
      .replace(/\s+/g, ' ');

    if (viewNormalizedValue !== field.value) {
      field.value = viewNormalizedValue;

      if (this.membershipInputRef.current) {
        this.membershipInputRef.current.value = viewNormalizedValue;
      }
    }

    const modelNormalizedValue = viewNormalizedValue.replace(/[\s]/g, '');

    if (modelNormalizedValue.match(this.MembershipNumberRegex) === null) {
      field.error = 'Your membership number is invalid';
      return;
    }

    field.error = undefined;
  };

  validateEmail = (field: FormFieldState<string>) => {
    if (field.value.trim() === '') {
      field.error = 'This field is required';
      return;
    }

    const viewNormalizedValue = field.value.replace(/\s/g, '');

    if (viewNormalizedValue !== field.value) {
      field.value = viewNormalizedValue;

      if (this.emailInputRef.current) {
        this.emailInputRef.current.value = viewNormalizedValue;
      }
    }

    if (viewNormalizedValue.match(this.EmailRegex) === null) {
      field.error = 'You must specify a valid email address';
      return;
    }

    field.error = undefined;
  };

  validateRecaptcha = (field: FormFieldState<string | undefined>) => {
    if (field.value === undefined || field.value.trim() === '') {
      field.error = 'You must pass the CAPTCHA challenge in order to submit this form';
      return;
    }

    field.error = undefined;
  };

  onCheckboxFieldChange = (event: React.ChangeEvent, fieldName: keyof FormFields) => {
    if (!event.target) {
      return;
    }

    const target = event.target as HTMLInputElement;
    const fieldValues = cloneDeep(this.state.formFields);
    fieldValues[fieldName].value = target.checked || false;
    fieldValues[fieldName].isDirty = true;

    this.setState({
      formFields: fieldValues,
    });
  };

  onRecaptchaToken(token: string | null) {
    const fieldValues = cloneDeep(this.state.formFields);
    fieldValues.recaptchaChallenge.value = token || undefined;
    fieldValues.recaptchaChallenge.isDirty = true;

    this.setState({
      formFields: fieldValues,
    });
  }

  onTextFieldChange = (event: React.ChangeEvent, fieldName: keyof FormFields) => {
    if (!event.target) {
      return;
    }

    const target = event.target as HTMLInputElement;
    const fieldValues = cloneDeep(this.state.formFields);
    fieldValues[fieldName].value = target.value || '';
    fieldValues[fieldName].isDirty = true;

    this.setState({
      formFields: fieldValues,
    });
  };
};
export default EmailSubscriptionFormSlice;

export interface EmailSubscriptionFormSliceProps {
  sliceData: PrismicEmailSubscriptionPageDataBodyFormPlaceholder;
  pageData?: PrismicSearchPage;
}

interface EmailSubscriptionFormSliceState {
  elementIdPrefix: string;
  formFields: FormFields;
  isSubmitting: boolean;
  isSubmitSuccess?: boolean;
}

interface FormFields {
  membershipNumber: FormFieldState<string>;
  email: FormFieldState<string>;
  forceUnsubscribe: FormFieldState<boolean>;
  subscribeToMemberBulletin: FormFieldState<boolean>;
  subscribeToSoloTravel: FormFieldState<boolean>;
  subscribeToTravel: FormFieldState<boolean>;
  subscribeToMagazine: FormFieldState<boolean>;
  recaptchaChallenge: FormFieldState<string | undefined>;
}

interface FormFieldState<T> {
  value: T;
  isDirty: boolean;
  error?: string;
}

type FormValuesCollection = Record<keyof FormFields, any>;

interface EmailSubscriptionFormSliceQueryResults {
  site: Site;
}

const EmailSubscriptionFormSliceQuery = graphql`
  query EmailSubscriptionFormSliceQuery {
    site {
      siteMetadata {
        recaptchaSiteKey
      }
    }
  }
`;
