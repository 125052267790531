import {
  PrismicLinkType,
  PrismicPage,
  PrismicPageDataBodyBreadcrumbs,
} from '../../graphql';
import React, { Component, ReactNode } from 'react';
import BreadcrumbLink, { IBreadcrumbData } from '../controls/BreadcrumbLink';
import { graphql } from 'gatsby';
import { IsValidPrismicLink } from '../../utils/PrismicHelpers';

export const BreadcrumbsSliceKey = 'breadcrumbs';
export default class BreadcrumbsSlice extends Component<BreadcrumbsSliceProps> {
  render(): ReactNode {
    const { sliceData, pageData } = this.props;

    const breadcrumbData: IBreadcrumbData[] = [];

    if (sliceData.primary?.include_home === 'yes') {
      breadcrumbData.push({
        link_dest: {
          link_type: `Document`,
          url: '/',
          target: '',
        },
        icon: 'home',
        ariaLabel: 'Home',
        iconSize: '2x',
      });
    }

    if (Array.isArray(sliceData.items)) {
      for (const item of sliceData.items) {
        if (!IsValidPrismicLink(item?.parent_page)) {
          continue;
        }

        const document = item!.parent_page.document as PrismicPage;

        breadcrumbData.push({
          link_dest: item!.parent_page,
          text: document?.data?.page_title || '',
        });
      }
    }

    if (
      sliceData.primary?.include_self === 'yes' &&
      IsValidPrismicLink((pageData as any) as PrismicLinkType)
    ) {
      breadcrumbData.push({
        link_dest: {
          link_type: `Document`,
          url: pageData?.url,
          uid: pageData?.uid,
          target: '',
        },
        text: pageData?.data?.page_title || '',
      });
    }

    const linkNodes: ReactNode[] = [];

    let index = 0;
    for (const breadcrumbProps of breadcrumbData) {
      linkNodes.push(
        <li key={index}>
          <BreadcrumbLink {...breadcrumbProps} />
        </li>
      );
      index = index + 1;
    }

    return (
      <div className="container neo-breadcrumbs">
        <ul className="neo-breadcrumbs-list">{linkNodes}</ul>
      </div>
    );
  }
}

export interface BreadcrumbsSliceProps {
  sliceData: PrismicPageDataBodyBreadcrumbs;
  pageData?: PrismicPage;
}

// noinspection JSUnusedGlobalSymbols
export const SliceBreadcrumbsFragment = graphql`
  fragment SliceBreadcrumbsFragment on PrismicSlicesBreadcrumbs {
    ... on PrismicPageDataBodyBreadcrumbs {
      id
      slice_type
      primary {
        include_home
        include_self
      }
      items {
        parent_page {
          document {
            ...BreadcrumbPageTitleFragment
          }
          ...PrismicLinkFragment
        }
      }
    }
    ... on PrismicErrorPageDataBodyBreadcrumbs {
      id
      slice_type
      primary {
        include_home
        include_self
      }
      items {
        parent_page {
          document {
            ...BreadcrumbPageTitleFragment
          }
          ...PrismicLinkFragment
        }
      }
    }
    ... on PrismicEmailSubscriptionPageDataBodyBreadcrumbs {
      id
      slice_type
      primary {
        include_home
        include_self
      }
      items {
        parent_page {
          document {
            ...BreadcrumbPageTitleFragment
          }
          ...PrismicLinkFragment
        }
      }
    }
    ... on PrismicGaragesSearchPageDataBodyBreadcrumbs {
      id
      slice_type
      primary {
        include_home
        include_self
      }
      items {
        parent_page {
          document {
            ...BreadcrumbPageTitleFragment
          }
          ...PrismicLinkFragment
        }
      }
    }
    ... on PrismicMerchandiseSearchPageDataBodyBreadcrumbs {
      id
      slice_type
      primary {
        include_home
        include_self
      }
      items {
        parent_page {
          document {
            ...BreadcrumbPageTitleFragment
          }
          ...PrismicLinkFragment
        }
      }
    }
    ... on PrismicSearchPageDataBodyBreadcrumbs {
      id
      slice_type
      primary {
        include_home
        include_self
      }
      items {
        parent_page {
          document {
            ...BreadcrumbPageTitleFragment
          }
          ...PrismicLinkFragment
        }
      }
    }
    ... on PrismicTravelAgentsSearchPageDataBodyBreadcrumbs {
      id
      slice_type
      primary {
        include_home
        include_self
      }
      items {
        parent_page {
          document {
            ...BreadcrumbPageTitleFragment
          }
          ...PrismicLinkFragment
        }
      }
    }
    ... on PrismicTravelDealsSearchPageDataBodyBreadcrumbs {
      id
      slice_type
      primary {
        include_home
        include_self
      }
      items {
        parent_page {
          document {
            ...BreadcrumbPageTitleFragment
          }
          ...PrismicLinkFragment
        }
      }
    }
    ... on PrismicTravelPartnersSearchPageDataBodyBreadcrumbs {
      id
      slice_type
      primary {
        include_home
        include_self
      }
      items {
        parent_page {
          document {
            ...BreadcrumbPageTitleFragment
          }
          ...PrismicLinkFragment
        }
      }
    }
    ... on PrismicTravelStoreSearchPageDataBodyBreadcrumbs {
      id
      slice_type
      primary {
        include_home
        include_self
      }
      items {
        parent_page {
          document {
            ...BreadcrumbPageTitleFragment
          }
          ...PrismicLinkFragment
        }
      }
    }
    ... on PrismicTravelTalksSearchPageDataBodyBreadcrumbs {
      id
      slice_type
      primary {
        include_home
        include_self
      }
      items {
        parent_page {
          document {
            ...BreadcrumbPageTitleFragment
          }
          ...PrismicLinkFragment
        }
      }
    }
    ... on PrismicTravelTripsSearchPageDataBodyBreadcrumbs {
      id
      slice_type
      primary {
        include_home
        include_self
      }
      items {
        parent_page {
          document {
            ...BreadcrumbPageTitleFragment
          }
          ...PrismicLinkFragment
        }
      }
    }
  }
`;

// noinspection JSUnusedGlobalSymbols
export const BreadcrumbPageTitleFragment = graphql`
  fragment BreadcrumbPageTitleFragment on PrismicAllDocumentTypes {
    ... on PrismicPage {
      data {
        page_title
      }
    }
    ... on PrismicEmailSubscriptionPage {
      data {
        page_title
      }
    }
    ... on PrismicSearchPage {
      data {
        page_title
      }
    }
    ... on PrismicMerchandise {
      data {
        page_title
      }
    }
    ... on PrismicMerchandiseSearchPage {
      data {
        page_title
      }
    }
    ... on PrismicSearchPage {
      data {
        page_title
      }
    }
    ... on PrismicTravelAgentsSearchPage {
      data {
        page_title
      }
    }
    ... on PrismicTravelDeal {
      data {
        page_title
      }
    }
    ... on PrismicTravelDealsSearchPage {
      data {
        page_title
      }
    }
    ... on PrismicTravelPartner {
      data {
        page_title
      }
    }
    ... on PrismicTravelPartnersSearchPage {
      data {
        page_title
      }
    }
    ... on PrismicTravelStore {
      data {
        page_title
      }
    }
    ... on PrismicTravelStoreSearchPage {
      data {
        page_title
      }
    }
    ... on PrismicTravelTalk {
      data {
        page_title
      }
    }
    ... on PrismicTravelTalksSearchPage {
      data {
        page_title
      }
    }
    ... on PrismicTravelTrip {
      data {
        page_title
      }
    }
    ... on PrismicTravelTripsSearchPage {
      data {
        page_title
      }
    }
  }
`;
