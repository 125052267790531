import React, { Component, ReactNode } from 'react';
import { PrismicPage, PrismicPageDataBodyTstWidget } from '../../graphql';
import { MakeRandomId } from '../../utils/RandomId';
import PrismicImage from '../controls/PrismicImage';
import { graphql } from 'gatsby';
import SectionTitle from '../controls/SectionTitle';

export const TSTWidgetSliceKey = 'tst_widget';
export default class TSTWidgetSlice extends Component<TSTWidgetSliceProps, TSTWidgetSliceState> {
  private readonly widgetRef: React.RefObject<HTMLDivElement> = React.createRef();

  constructor(props: Readonly<TSTWidgetSliceProps>) {
    super(props);
    this.state = {
      widgetNodeId: MakeRandomId('neo-tst-widget', 10),
      randomIdAssignedOnMount: false,
    };
  }

  componentDidMount(): void {
    if (!this.state.randomIdAssignedOnMount) {
      if (typeof document !== 'undefined') {
        document.addEventListener('click', this.preventDefaultOnDatepickerClick);
      }

      this.setState({
        widgetNodeId: MakeRandomId('neo-tst-widget', 10),
        randomIdAssignedOnMount: true,
      });
    }
  }

  componentDidUpdate(
    _prevProps: Readonly<TSTWidgetSliceProps>,
    prevState: Readonly<TSTWidgetSliceState>,
    _snapshot?: any
  ): void {
    if (
      !(!prevState.randomIdAssignedOnMount && this.state.randomIdAssignedOnMount) &&
      !this.widgetRef.current?.children?.length
    ) {
      return;
    }

    const { sliceData } = this.props;

    const TST = (window as any).TST;
    const { widgetNodeId } = this.state;

    const queueTST = () => {
      if (!TST || !TST.caaNeo || !TST.caaNeo.queueCallback) {
        console.warn('TST: TST not properly defined, retrying in 100ms');
        setTimeout(queueTST, 100);
        return;
      }

      TST.caaNeo.queueCallback(() => {
        try {
          if (this.widgetRef.current && this.widgetRef.current.id === widgetNodeId) {
            TST.SearchWidget.create({
              tag: `#${widgetNodeId}`,
              products: ['hotel', 'flight', 'car', 'cruise', 'prepackaged'],
              focus: sliceData?.primary?.tab_focus || 'hotel',
              tabDirection: 'horizontal',
              navOptions: { autoHide: false },
              flightTimes: false,
              zoomEffect: false,
              ignoreFontAwesome: true,
              extras: { autoComplete: true, datePicker: true },
            });
          } else {
            setTimeout(queueTST, 100);
          }
        } catch (ex) {
          setTimeout(queueTST, 100);
        }
      });
    };

    queueTST();
  }

  componentWillUnmount(): void {
    if (typeof document !== 'undefined') {
      document.removeEventListener('click', this.preventDefaultOnDatepickerClick);
    }
  }

  render(): ReactNode {
    const { sliceData } = this.props;
    const { widgetNodeId } = this.state;

    let bgImage: ReactNode = undefined;

    if (sliceData.primary?.background_image) {
      bgImage = <PrismicImage className={'container-background'} image={sliceData.primary.background_image} />;
    }

    return (
      <div className="container-limited neo-tst-widget-slice has-container-background is-background-overlay-white-gradient">
        <div className="container">
          <SectionTitle component={sliceData.primary?.section_title} />
          <div className="widget-content">
            <div className={`neo-tst-widget`} id={widgetNodeId} ref={this.widgetRef} />
          </div>
        </div>
        {bgImage}
      </div>
    );
  }

  preventDefaultOnDatepickerClick = (event: MouseEvent) => {
    if (event.target) {
      const currentEl = event.target as HTMLElement | null;
      const elClassNames = currentEl?.className;

      if (
        elClassNames?.startsWith &&
        (elClassNames?.startsWith('ui-datepicker') || elClassNames?.startsWith('ui-autocomplete'))
      ) {
        event.preventDefault();
      }
    }
  };
}

export interface TSTWidgetSliceProps {
  sliceData: PrismicPageDataBodyTstWidget;
  pageData?: PrismicPage;
}

export interface TSTWidgetSliceState {
  widgetNodeId: string;
  randomIdAssignedOnMount: boolean;
}

// noinspection JSUnusedGlobalSymbols
export const SliceTstWidgetFragment = graphql`
  fragment SliceTstWidgetFragment on PrismicPageDataBodyTstWidget {
    id
    slice_type
    primary {
      section_title {
        html
      }
      tab_focus
      background_image {
        alt
        url
        gatsbyImageData(layout: FULL_WIDTH)
      }
    }
  }
`;
