import React, { Component, ReactNode } from 'react';
import { PrismicPage, PrismicPageDataBodyLogoLinkList } from '../../graphql';
import { graphql } from 'gatsby';
import PrismicLink from '../controls/PrismicLink';
import PrismicImage from '../controls/PrismicImage';
import SectionTitle from '../controls/SectionTitle';

export const LogoLinkListSliceKey = 'logo_link_list';
export default class LogoLinkListSlice extends Component<LogoLinkListSliceProps> {
  render(): ReactNode {
    const { ...sliceData } = this.props.sliceData;

    let logoClassName: string;

    switch (true) {
      case sliceData.items?.length === 5:
        logoClassName = 'neo-logo-wrap-fifth';
        break;
      case sliceData.items?.length === 6:
        logoClassName = 'neo-logo-wrap-sixth';
        break;
      case typeof sliceData.items?.length !== 'undefined' && sliceData.items?.length >= 7:
        logoClassName = 'neo-logo-wrap-full';
        break;
      default:
        logoClassName = 'neo-logo-wrap-quarter';
        break;
    }

    const logoElements = sliceData.items?.map((item, index) => {
      return (
        <div className={`neo-logo-wrap ${logoClassName}`} key={index}>
          <div className="neo-logo">
            <PrismicLink to={item?.link_dest}>
              <PrismicImage
                className="has-max-height-120"
                image={item?.image ?? null}
                imgStyle={{ objectFit: 'contain' }}
              />
            </PrismicLink>
          </div>
        </div>
      );
    });
    return (
      <div className="container neo-logo-list">
        <SectionTitle component={sliceData.primary?.section_title} />
        <div className="columns">{logoElements}</div>
      </div>
    );
  }
}

export interface LogoLinkListSliceProps {
  sliceData: PrismicPageDataBodyLogoLinkList;
  pageData?: PrismicPage;
}

export const LogoLinkListFragment = graphql`
  fragment SliceLogoLinkListFragment on PrismicPageDataBodyLogoLinkList {
    id
    slice_type
    primary {
      section_title {
        html
      }
    }
    items {
      link_dest {
        ...PrismicLinkFragment
      }
      image {
        alt
        url
        gatsbyImageData(width: 960, placeholder: NONE, layout: CONSTRAINED)
      }
    }
  }
`;
