import React, { Component, ReactNode } from 'react';
import { PrismicPage, PrismicPageDataBodyRewardsPartners, PrismicRewardsPartner } from '../../graphql';
import { graphql } from 'gatsby';
import shuffle from 'lodash/shuffle';
import PrismicLink from '../controls/PrismicLink';
import PrismicImage from '../controls/PrismicImage';
import { IsValidPrismicLink } from '../../utils/PrismicHelpers';
import WithRewardsPartnerData, { InjectedRewardsPartnerDataProps } from '../higher-order/WithRewardsPartnerData';
import SectionTitle from '../controls/SectionTitle';

export const RewardsPartnersSliceKey = 'rewards_partners';
const RewardsPartnersSlice = WithRewardsPartnerData(
  class RewardsPartnersSliceImpl extends Component<
    RewardsPartnersSliceProps & InjectedRewardsPartnerDataProps,
    RewardsPartnersSliceState
  > {
    constructor(props: Readonly<RewardsPartnersSliceProps & InjectedRewardsPartnerDataProps>) {
      super(props);

      this.state = {
        showRandomItems: false,
      };
    }

    componentDidMount(): void {
      this.setState({
        showRandomItems: true,
      });
    }

    render(): ReactNode {
      const { sliceData, rewardsPartnerData } = this.props;

      let ctaElement: ReactNode = null;
      if (sliceData.primary?.cta_title && IsValidPrismicLink(sliceData.primary?.cta_dest)) {
        ctaElement = (
          <div className="neo-section-cta">
            <PrismicLink to={sliceData.primary.cta_dest}>{sliceData.primary.cta_title}</PrismicLink>
          </div>
        );
      }

      const partners = this.selectTagPartners(rewardsPartnerData);
      const partnerElements = partners.map((partner, index) => {
        return (
          <div className={'column is-half-tablet neo-rewards-partner-tile'} key={index}>
            <div className="neo-rewards-partner-logo">
              <PrismicLink to={partner.data?.partner_portal_link} aria-label={partner.data?.partner_name || ''}>
                <PrismicImage
                  className="has-max-height-120"
                  image={partner.data?.partner_logo ?? null}
                  imgStyle={{ objectFit: 'contain' }}
                />
              </PrismicLink>
            </div>
            <div className="neo-rewards-partner-description">
              <div className="neo-rewards-partner-tag">{partner.data?.partner_tag_line}</div>
            </div>
          </div>
        );
      });

      return (
        <div className="container neo-rewards-partners">
          <SectionTitle component={sliceData.primary?.section_title} />
          {ctaElement}
          <div className="columns">{partnerElements}</div>
        </div>
      );
    }

    selectTagPartners(results: PrismicRewardsPartner[]): PrismicRewardsPartner[] {
      const { sliceData } = this.props;

      let partners: PrismicRewardsPartner[] = [];
      const maxPopulate =
        sliceData?.primary?.populate_max !== null && sliceData?.primary?.populate_max !== undefined
          ? sliceData?.primary?.populate_max
          : 4;

      if (maxPopulate > 0 && this.state.showRandomItems) {
        const tagId = sliceData?.primary?.tag?.document?.id;

        partners = shuffle(results || [])
          .filter((node) => {
            if (!tagId) {
              return true;
            }

            return node?.data?.tags?.some((tag) => tag?.tag?.document?.id === tagId);
          })
          .slice(0, maxPopulate);
      }

      return partners.concat(
        (sliceData.items
          ?.filter((p) => !!p && !!p.forced_partner?.document?.id)
          .map((p) => p?.forced_partner?.document) as PrismicRewardsPartner[]) || []
      );
    }
  }
);
export default RewardsPartnersSlice;

export interface RewardsPartnersSliceProps {
  sliceData: PrismicPageDataBodyRewardsPartners;
  pageData?: PrismicPage;
}

export interface RewardsPartnersSliceState {
  showRandomItems: boolean;
}

// noinspection JSUnusedGlobalSymbols
export const SliceRewardsPartnersFragment = graphql`
  fragment SliceRewardsPartnersFragment on PrismicPageDataBodyRewardsPartners {
    id
    slice_type
    primary {
      cta_title
      cta_dest {
        ...PrismicLinkFragment
      }
      tag {
        document {
          ... on PrismicTag {
            id
          }
        }
      }
      populate_max
      section_title {
        html
      }
    }
    items {
      forced_partner {
        document {
          ... on PrismicRewardsPartner {
            ...RewardsPartnerFragment
          }
        }
      }
    }
  }
`;
