import React, { Component, ReactNode } from 'react';
import { IsBrowserEnvironment } from '../../../utils/BrowserHelpers';
import { graphql, StaticQuery } from 'gatsby';
import { PrismicAlertBar } from '../../../graphql';
import PrismicStructuredText from '../../controls/PrismicStructuredText';
import PrismicFontAwesomeIcon from '../../controls/PrismicFontAwesomeIcon';
import moment from 'moment-timezone';

const NEO_ALERTBAR_STORAGE_KEY = 'neo-alertbar-dismissed';

export default class AlertBar extends Component<AlertBarProps, AlertBarState> {
  constructor(props: Readonly<AlertBarProps>) {
    super(props);

    this.state = {
      isDismissible: false,
      isDismissed: false,
    };
  }

  componentDidMount(): void {
    this.verifyIsDismissedAlready();
  }

  componentDidUpdate(
    _prevProps: Readonly<AlertBarProps>,
    prevState: Readonly<AlertBarState>,
    _snapshot?: any
  ): void {
    if (this.state.contentId !== prevState.contentId) {
      this.verifyIsDismissedAlready();
    }
  }

  render(): ReactNode {
    if (this.props.previewData) {
      return this.renderWithData({
        prismicAlertBar: this.props.previewData,
      });
    }

    return (
      <StaticQuery
        query={AlertBarStaticQuery}
        render={results => this.renderWithData(results)}
      />
    );
  }

  renderWithData(data: AlertBarStaticQueryResults): ReactNode {
    if (!data?.prismicAlertBar?.prismicId) {
      return null;
    }

    const dismissId = `${data.prismicAlertBar.prismicId}|${data.prismicAlertBar
      .last_publication_date || moment().format()}`;

    if (this.state.contentId !== dismissId) {
      this.setState({
        contentId: dismissId,
        isDismissible: data.prismicAlertBar.data?.dismissible === 'yes',
      });
    }

    const isEnabled = data.prismicAlertBar.data?.alert_enabled === 'yes';

    if (!isEnabled || this.state.isDismissed) {
      return null;
    }

    return (
      <div className="neo-alert">
        <div className="container">
          <div className="columns is-variable is-2 is-mobile">
            <div className="column">
              <PrismicStructuredText
                text={data.prismicAlertBar?.data?.alert_body}
              />
            </div>
            {this.state.isDismissible ? (
              <div className="column is-narrow is-align-self-center">
                <button
                  className="button is-caa-darkredorange"
                  onClick={this.onDismissButtonClick}
                  aria-label="close"
                >
                  <PrismicFontAwesomeIcon icon="fas-times" />
                </button>
              </div>
            ) : null}
          </div>
        </div>
      </div>
    );
  }

  verifyIsDismissedAlready(): void {
    const { contentId, isDismissible } = this.state;

    if (IsBrowserEnvironment && contentId && isDismissible) {
      const dismissedValue = window.sessionStorage.getItem(
        NEO_ALERTBAR_STORAGE_KEY
      );

      if (dismissedValue && dismissedValue === contentId) {
        this.setState({
          isDismissed: true,
        });
      }
    }
  }

  onDismissButtonClick = () => {
    const { contentId, isDismissible } = this.state;

    if (IsBrowserEnvironment && contentId && isDismissible) {
      window.sessionStorage.setItem(NEO_ALERTBAR_STORAGE_KEY, contentId);

      this.setState({
        isDismissed: true,
      });
    }
  };
}

const AlertBarStaticQuery = graphql`
  query AlertBarStaticQuery {
    prismicAlertBar {
      prismicId
      last_publication_date
      data {
        alert_enabled
        dismissible
        alert_body {
          html
        }
      }
    }
  }
`;

interface AlertBarStaticQueryResults {
  prismicAlertBar?: PrismicAlertBar;
}

interface AlertBarProps {
  previewData?: PrismicAlertBar;
}

interface AlertBarState {
  contentId?: string;
  isDismissible: boolean;
  isDismissed: boolean;
}
