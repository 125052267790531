import {
  PrismicTaxonomyTravelType,
  PrismicTravelDeal,
  PrismicTravelDealsSearchPage,
  PrismicTravelPartner,
} from '../../../graphql';
import React, { Component, ReactNode } from 'react';
import PrismicStructuredText from '../../controls/PrismicStructuredText';
import PrismicImage from '../../controls/PrismicImage';
import WithGlobalResources, { InjectedGlobalResourcesProps } from '../../higher-order/WithGlobalResources';
import PrismicLink from '../../controls/PrismicLink';
import { IsValidPrismicLink, ToMoment } from '../../../utils/PrismicHelpers';
import { graphql, StaticQuery } from 'gatsby';
import KeyValueList, { KeyValuePair } from '../../controls/KeyValueList';
import { ResolveTravelLinkItems } from '../../../utils/DynamicLinksHelper';

export const TravelDealContentSliceKey = '!internal_travel_deal_content_slice';

const TravelDealContentSlice = WithGlobalResources(
  class TravelDealContentSliceImpl extends Component<TravelDealContentSliceProps & InjectedGlobalResourcesProps> {
    render(): ReactNode {
      return <StaticQuery query={TravelDealContentSliceQuery} render={(results) => this.renderWithData(results)} />;
    }

    renderWithData(data: TravelDealContentSliceQueryResults): ReactNode {
      const { pageData, globalResources } = this.props;

      const partner = (pageData?.data?.travel_partner?.document || undefined) as PrismicTravelPartner | undefined;

      let titleElement: ReactNode = null;

      if (pageData?.data?.page_title) {
        titleElement = (
          <div className="neo-section-title">
            <h1>{pageData?.data?.page_title}</h1>
          </div>
        );
      }

      const actionButtons: ReactNode[] = [];
      const keyValuePairs: KeyValuePair[] = [];

      const contentElement: ReactNode = <PrismicStructuredText text={pageData?.data?.description} />;

      let benefitsElement: ReactNode = null;
      if (pageData?.data?.member_benefits?.html) {
        benefitsElement = (
          <div className="neo-text-block is-caa-forestgreen">
            <PrismicStructuredText text={pageData?.data?.member_benefits} />
          </div>
        );
      }

      let legalElement: ReactNode = null;
      if (pageData?.data?.legal_text?.html) {
        legalElement = (
          <div className="is-size-7">
            <PrismicStructuredText text={pageData?.data?.legal_text} />
          </div>
        );
      }

      if (pageData?.data?.deal_expiry) {
        const expiryTime = ToMoment(pageData?.data?.deal_expiry);

        if (expiryTime) {
          const formattedDate = expiryTime.format('MMMM Do, YYYY');

          keyValuePairs.push({
            key: globalResources.travel_deal_page_expiry_header || '',
            value: formattedDate,
          });
        }
      }

      let partnersNode: ReactNode = null;
      if (IsValidPrismicLink(pageData?.data?.travel_partner)) {
        partnersNode = (
          <div className="columns is-variable is-centered is-2 multiline">
            <div className="column is-half has-text-centered">
              <PrismicLink to={pageData?.data?.travel_partner} title={partner?.data?.page_title || undefined}>
                <PrismicImage
                  image={partner?.data?.partner_logo ?? null}
                  className="has-max-height-180"
                  imgStyle={{ objectFit: 'contain' }}
                  aria-label={partner?.data?.page_title ?? undefined}
                />
              </PrismicLink>
            </div>
          </div>
        );
      }

      ResolveTravelLinkItems(globalResources.travel_deal_page_additional_cta, {
        travelPartner: partner,
      }).forEach((item) => {
        actionButtons.push(
          <PrismicLink to={item.link_dest} className="button is-medium is-caa-lightgray has-text-weight-bold">
            {item.link_text}
          </PrismicLink>
        );
      });

      if (IsValidPrismicLink(pageData?.data?.cta_link_dest) && pageData?.data?.cta_title) {
        actionButtons.push(
          <PrismicLink
            to={pageData?.data?.cta_link_dest}
            className="button is-medium is-caa-forestgreen has-text-weight-bold"
          >
            {pageData?.data?.cta_title}
          </PrismicLink>
        );
      }

      const actionButtonsNode = actionButtons.length ? (
        <div className="field is-grouped is-grouped-centered is-grouped-multiline">
          {actionButtons.map((node, index) => (
            <div key={index} className="control">
              {node}
            </div>
          ))}
        </div>
      ) : null;

      let typeTagsNode: ReactNode = null;

      if (pageData?.data?.travel_types?.length) {
        typeTagsNode = (
          <div className="neo-travel-deal-tags">
            <p className="is-size-6 has-text-weight-bold">{globalResources.travel_deal_page_tags_listing_header}</p>
            <div className="columns is-variable is-2 is-mobile">
              {pageData?.data?.travel_types
                ?.filter((d) => d?.travel_type?.document?.id)
                .map((item, index) => {
                  const travelType = item?.travel_type?.document as PrismicTaxonomyTravelType | undefined;

                  return (
                    <div className="column is-narrow" key={index}>
                      <PrismicLink
                        to={data.prismicTravelDealsSearchPage}
                        fragmentQuery={{
                          travelType: travelType?.id,
                        }}
                        className="button is-small has-text-weight-semibold"
                      >
                        {travelType?.data?.name}
                      </PrismicLink>
                    </div>
                  );
                })}
            </div>
          </div>
        );
      }

      return (
        <div className="container neo-travel-deal">
          {titleElement}

          <div className="columns is-variable is-8">
            <div className="column is-two-thirds content">
              <KeyValueList keyValues={keyValuePairs} />
              {contentElement}
              {benefitsElement}
              {legalElement}
              {partnersNode}
              {actionButtonsNode}
            </div>
            <div className="column has-border-left-caa-lightgray has-no-border-mobile">
              <div className="neo-travel-deal-image">
                <PrismicImage image={pageData?.data?.deal_image ?? null} />
              </div>
            </div>
          </div>

          {typeTagsNode}
        </div>
      );
    }
  }
);
export default TravelDealContentSlice;

const TravelDealContentSliceQuery = graphql`
  query TravelDealContentSliceQuery {
    prismicTravelDealsSearchPage {
      id
      url
      uid
    }
  }
`;

interface TravelDealContentSliceQueryResults {
  prismicTravelDealsSearchPage?: PrismicTravelDealsSearchPage;
}

export interface TravelDealContentSliceProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  sliceData: any;
  pageData?: PrismicTravelDeal;
}
