import { PrismicPage, PrismicPageDataBodyTextContent } from '../../graphql';
import React, { Component, ReactNode } from 'react';
import PrismicStructuredText from '../controls/PrismicStructuredText';
import PrismicMarkdownText from '../controls/PrismicMarkdownText';
import { graphql } from 'gatsby';
import SectionTitle from '../controls/SectionTitle';

export const TextContentSliceKey = 'text_content';
export default class TextContentSlice extends Component<TextContentSliceProps> {
  render(): ReactNode {
    const { sliceData } = this.props;

    const isMarkdown = sliceData.primary?.rendering_mode === 'Markdown';
    const bodyColor = sliceData.primary?.color || 'inherits';
    const isSmallText = sliceData.primary?.small_text ?? false;

    if (
      !sliceData.primary?.section_title?.html &&
      ((!isMarkdown && !sliceData.primary?.rich_text?.html) || (isMarkdown && !sliceData.primary?.markdown?.text))
    ) {
      return <React.Fragment />;
    }

    let contentElement: ReactNode = null;
    if (isMarkdown) {
      contentElement = <PrismicMarkdownText text={sliceData.primary?.markdown?.text} />;
    } else {
      contentElement = <PrismicStructuredText text={sliceData.primary?.rich_text} />;
    }
    let contentAlign = sliceData.primary?.item_align || '';
    if (contentAlign) {
      contentAlign = ` content-align-${contentAlign.toLowerCase()}`;
    }
    return (
      <div className="container neo-text-content">
        <SectionTitle component={sliceData.primary?.section_title} />

        <div className="columns">
          <div className={`column is-10 content${contentAlign}`} style={{ color: bodyColor }}>
            {isSmallText ? <small>{contentElement}</small> : contentElement}
          </div>
        </div>
      </div>
    );
  }
}

export interface TextContentSliceProps {
  sliceData: PrismicPageDataBodyTextContent;
  pageData?: PrismicPage;
}

// noinspection JSUnusedGlobalSymbols
export const SliceTextContentFragment = graphql`
  fragment SliceTextContentFragment on PrismicSlicesTextContent {
    ... on PrismicPageDataBodyTextContent {
      id
      slice_type
      primary {
        section_title {
          html
        }
        color
        small_text
        rich_text {
          html
        }
        rendering_mode
        item_align
        markdown {
          text
        }
      }
    }
    ... on PrismicErrorPageDataBodyTextContent {
      id
      slice_type
      primary {
        section_title {
          html
        }
        color
        small_text
        rich_text {
          html
        }
        rendering_mode
        item_align
        markdown {
          text
        }
      }
    }
    ... on PrismicEmailSubscriptionPageDataBodyTextContent {
      id
      slice_type
      primary {
        section_title {
          html
        }
        color
        small_text
        rich_text {
          html
        }
        rendering_mode
        item_align
        markdown {
          text
        }
      }
    }
    ... on PrismicGaragesSearchPageDataBodyTextContent {
      id
      slice_type
      primary {
        section_title {
          html
        }
        color
        small_text
        rich_text {
          html
        }
        rendering_mode
        item_align
        markdown {
          text
        }
      }
    }
    ... on PrismicMerchandiseSearchPageDataBodyTextContent {
      id
      slice_type
      primary {
        section_title {
          html
        }
        color
        small_text
        rich_text {
          html
        }
        rendering_mode
        item_align
        markdown {
          text
        }
      }
    }
    ... on PrismicSearchPageDataBodyTextContent {
      id
      slice_type
      primary {
        section_title {
          html
        }
        color
        small_text
        rich_text {
          html
        }
        rendering_mode
        item_align
        markdown {
          text
        }
      }
    }
    ... on PrismicTravelAgentsSearchPageDataBodyTextContent {
      id
      slice_type
      primary {
        section_title {
          html
        }
        color
        small_text
        rich_text {
          html
        }
        rendering_mode
        item_align
        markdown {
          text
        }
      }
    }
    ... on PrismicTravelDealsSearchPageDataBodyTextContent {
      id
      slice_type
      primary {
        section_title {
          html
        }
        color
        small_text
        rich_text {
          html
        }
        rendering_mode
        item_align
        markdown {
          text
        }
      }
    }
    ... on PrismicTravelPartnersSearchPageDataBodyTextContent {
      id
      slice_type
      primary {
        section_title {
          html
        }
        color
        small_text
        rich_text {
          html
        }
        rendering_mode
        item_align
        markdown {
          text
        }
      }
    }
    ... on PrismicTravelStoreSearchPageDataBodyTextContent {
      id
      slice_type
      primary {
        section_title {
          html
        }
        color
        small_text
        rich_text {
          html
        }
        rendering_mode
        item_align
        markdown {
          text
        }
      }
    }
    ... on PrismicTravelTalksSearchPageDataBodyTextContent {
      id
      slice_type
      primary {
        section_title {
          html
        }
        color
        small_text
        rich_text {
          html
        }
        rendering_mode
        item_align
        markdown {
          text
        }
      }
    }
    ... on PrismicTravelTripDataBodyTextContent {
      id
      slice_type
      primary {
        section_title {
          html
        }
        color
        small_text
        rich_text {
          html
        }
        rendering_mode
        item_align
        markdown {
          text
        }
      }
    }
    ... on PrismicTravelTripsSearchPageDataBodyTextContent {
      id
      slice_type
      primary {
        section_title {
          html
        }
        color
        small_text
        rich_text {
          html
        }
        rendering_mode
        item_align
        markdown {
          text
        }
      }
    }
    ... on PrismicTravelTalkDataBodyTextContent {
      id
      slice_type
      primary {
        section_title {
          html
        }
        color
        small_text
        rich_text {
          html
        }
        rendering_mode
        item_align
        markdown {
          text
        }
      }
    }
    ... on PrismicTravelDealDataBodyTextContent {
      id
      slice_type
      primary {
        section_title {
          html
        }
        color
        small_text
        rich_text {
          html
        }
        rendering_mode
        item_align
        markdown {
          text
        }
      }
    }
    ... on PrismicTravelStoreDataBodyTextContent {
      id
      slice_type
      primary {
        section_title {
          html
        }
        color
        small_text
        rich_text {
          html
        }
        rendering_mode
        item_align
        markdown {
          text
        }
      }
    }
    ... on PrismicMerchandiseDataBodyTextContent {
      id
      slice_type
      primary {
        section_title {
          html
        }
        color
        small_text
        rich_text {
          html
        }
        rendering_mode
        item_align
        markdown {
          text
        }
      }
    }
    ... on PrismicTravelPartnerDataBodyTextContent {
      id
      slice_type
      primary {
        section_title {
          html
        }
        color
        small_text
        rich_text {
          html
        }
        rendering_mode
        item_align
        markdown {
          text
        }
      }
    }
  }
`;
