import { PrismicTravelTalk, PrismicTravelTalkConnection } from '../../graphql';
import React, { Component, ComponentType } from 'react';
import { graphql, StaticQuery } from 'gatsby';
import { Subset } from '../../utils/UtilityTypes';
import { ToMoment } from '../../utils/PrismicHelpers';
import moment from 'moment-timezone';

const WithTravelTalkData = function WithTravelTalkDataFn<
  P extends InjectedTravelTalkDataProps
>(
  ComposedComponent: ComponentType<P>
): ComponentType<Subset<P, InjectedTravelTalkDataProps>> {
  return class extends Component<Subset<P, InjectedTravelTalkDataProps>> {
    render() {
      return (
        <StaticQuery
          query={TravelTalkDataQuery}
          render={queryResult => {
            const {
              allPrismicTravelTalk,
            } = queryResult as TravelTalkDataQueryResults;

            return (
              <ComposedComponent
                {...(this.props as any)}
                travelTalkData={
                  allPrismicTravelTalk?.nodes?.filter(node => {
                    return !(
                      node.data?.event_start_time &&
                      ToMoment(node.data?.event_start_time)?.isBefore(moment())
                    );
                  }) || []
                }
              />
            );
          }}
        />
      );
    }
  };
};
export default WithTravelTalkData;

const TravelTalkDataQuery = graphql`
  query TravelTalkDataQuery {
    allPrismicTravelTalk(
      filter: { is_active: { eq: true } }
      sort: { fields: data___event_start_time, order: ASC }
    ) {
      nodes {
        ...TravelTalkFragment
      }
    }
  }
`;

interface TravelTalkDataQueryResults {
  allPrismicTravelTalk: PrismicTravelTalkConnection;
}

// noinspection JSUnusedGlobalSymbols
export const TravelTalkFragment = graphql`
  fragment TravelTalkFragment on PrismicTravelTalk {
    id
    uid
    url
    data {
      page_title
      event_start_time
      rsvp_link_dest {
        ...PrismicLinkFragment
      }
      event_short_description {
        html
      }
      event_image {
        alt
        url
        gatsbyImageData(width: 960, placeholder: NONE, layout: CONSTRAINED)
      }
      caa_travel_store {
        document {
          ... on PrismicTravelStore {
            id
            data {
              page_title
            }
            uid
            url
          }
        }
      }
      travel_types {
        travel_type {
          document {
            ... on PrismicTaxonomyTravelType {
              id
            }
          }
        }
      }
      travel_partners {
        travel_partner {
          document {
            ... on PrismicTravelPartner {
              id
            }
          }
        }
      }
      travel_destinations {
        travel_destination {
          document {
            ... on PrismicTaxonomyTravelDestination {
              id
            }
          }
        }
      }
    }
  }
`;

export interface InjectedTravelTalkDataProps {
  travelTalkData: PrismicTravelTalk[];
}
