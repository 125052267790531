import React, { Component, ReactNode } from 'react';
import { PrismicRewardsOffer, PrismicRewardsPartner } from '../../../graphql';
import ImageLinkTile from '../../controls/ImageLinkTile';
import WithGlobalResources, {
  InjectedGlobalResourcesProps,
} from '../../higher-order/WithGlobalResources';
import PrismicStructuredText from '../../controls/PrismicStructuredText';
import {
  IsValidPrismicLink,
} from '../../../utils/PrismicHelpers';
import PrismicImage from '../../controls/PrismicImage';

const RewardsOffer = WithGlobalResources(
  class RewardsOfferImpl extends Component<
    RewardsOfferProps & InjectedGlobalResourcesProps
  > {
    render(): ReactNode {
      const { offer, showDescription, globalResources } = this.props;

      if (!offer) {
        return null;
      }

      const partner = (offer?.data?.partner?.document || undefined) as
        | PrismicRewardsPartner
        | undefined;

      let offerTitleNode: ReactNode = null;
      if (offer?.data?.offer_title_text) {
        offerTitleNode = (
          <div className="image-link-tile-offer-title">
            {offer?.data?.offer_title_text}
          </div>
        );
      }

      let offerDescriptionNode: ReactNode = null;
      if (showDescription !== false && offer?.data?.offer_description?.html) {
        offerDescriptionNode = (
          <div className="image-link-tile-offer-description">
            <PrismicStructuredText text={offer?.data?.offer_description} />
          </div>
        );
      }

      let offerPartnerLogoNode: ReactNode = null;
      if (partner?.data?.partner_logo) {
        offerPartnerLogoNode = (
          <div className="columns is-mobile is-centered is-gapless">
            <div className="column is-two-thirds">
              <PrismicImage
                image={partner?.data?.partner_logo}
                className="has-max-height-120"
                imgStyle={{ objectFit: 'contain' }}
                aria-label={partner?.data?.partner_name ?? undefined}
              />
            </div>
          </div>
        );
      }

      let legalElement: ReactNode = null;
      if (offer?.data?.legal_text?.html) {
        legalElement = (
          <div className="is-size-7">
            <PrismicStructuredText text={offer?.data?.legal_text} />
          </div>
        );
      }

      let ctaNode: ReactNode = null;
      if (IsValidPrismicLink(offer?.data?.partner_portal_link)) {
        ctaNode = (
          <div className="cta">
            {globalResources.rewards_offer_card_view_cta}
          </div>
        );
      }

      return (
        <ImageLinkTile
          className={'is-rewards-offer-tile'}
          image={offer?.data?.offer_image}
          link_dest={offer?.data?.partner_portal_link}
          custom_content={
            <React.Fragment>
              <div className="tile-content-main">
                {offerTitleNode}
                {offerDescriptionNode}
                {offerPartnerLogoNode}
                {legalElement}
              </div>
              {ctaNode}
            </React.Fragment>
          }
        />
      );
    }
  }
);
export default RewardsOffer;

export interface RewardsOfferProps {
  offer?: PrismicRewardsOffer | null;
  showDescription?: boolean;
}
