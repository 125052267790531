import {
  PrismicTravelStore,
  PrismicTravelStoreDataBodyAgent,
  PrismicTravelStoreDataBodyAgentItem,
} from '../../../graphql';
import React, { Component, ReactNode } from 'react';
import WithGlobalResources, { InjectedGlobalResourcesProps } from '../../higher-order/WithGlobalResources';
import RandomAgent from '../../fragments/travel-agent/RandomAgent';
import { graphql, StaticQuery } from 'gatsby';
import PrismicLink from '../../controls/PrismicLink';

const documentType = (docType: string) => (item: PrismicTravelStoreDataBodyAgentItem) =>
  item.agent_tag?.document?.type === docType;
const documentId = (item: PrismicTravelStoreDataBodyAgentItem) => item.agent_tag?.document?.id;

export const AgentsSliceKey = 'agent';
const AgentsSlice = WithGlobalResources(
  class AgentsSliceSlice extends Component<AgentsSliceProps & InjectedGlobalResourcesProps> {
    render(): ReactNode {
      return <StaticQuery query={AgentsSliceQuery} render={() => this.renderWithData()} />;
    }

    renderWithData(): ReactNode {
      const { sliceData } = this.props;
      console.log(sliceData?.primary.title);
      const titleElement: ReactNode = (
        <div
          className="neo-section-title"
          dangerouslySetInnerHTML={{
            __html: sliceData?.primary.title?.html || '',
          }}
        />
      );

      let ctaElement: ReactNode = null;
      if (sliceData?.primary.subtitle_link_url) {
        ctaElement = (
          <div className="neo-section-cta">
            <PrismicLink to={sliceData?.primary.subtitle_link_url}>
              {sliceData?.primary.subtitle_link_title}
            </PrismicLink>
          </div>
        );
      }

      return (
        <RandomAgent
          agentType={sliceData?.primary.agent_type}
          travelStoreIds={sliceData?.items.filter(documentType('travel_store')).map(documentId)}
          travelTypes={sliceData?.items.filter(documentType('taxonomy_travel_type')).map(documentId)}
          travelDestinations={sliceData?.items.filter(documentType('taxonomy_travel_destination')).map(documentId)}
          travelPartners={sliceData?.items.filter(documentType('travel_partner')).map(documentId)}
          numOfResults={sliceData?.primary.max_agent_count || -1}
          returnEmptyIfNoMatch={true}
          render={(resultsNode) => (
            <div className="container neo-travel-store-agents">
              {titleElement}
              {ctaElement}
              {resultsNode}
            </div>
          )}
        />
      );
    }
  }
);
export default AgentsSlice;

const AgentsSliceQuery = graphql`
  query TravelStoreAgentSliceQuery {
    prismicTravelAgentsSearchPage {
      id
      url
      uid
    }
  }
`;

// noinspection JSUnusedGlobalSymbols
export const SliceAgentFragment = graphql`
  fragment SliceAgentFragment on PrismicSlicesAgent {
    ... on PrismicPageDataBodyAgent {
      id
      slice_type
      primary {
        subtitle_link_url {
          ...PrismicLinkFragment
        }
        subtitle_link_title
        max_agent_count
        agent_type
        title {
          html
        }
      }
      items {
        agent_tag {
          document {
            ... on PrismicTravelStore {
              id
              type
            }
            ... on PrismicTravelPartner {
              id
              type
            }
            ... on PrismicTaxonomyTravelDestination {
              id
              type
            }
            ... on PrismicTaxonomyTravelType {
              id
              type
            }
          }
        }
      }
    }

    ... on PrismicTravelStoreDataBodyAgent {
      id
      slice_type
      primary {
        subtitle_link_url {
          ...PrismicLinkFragment
        }
        subtitle_link_title
        max_agent_count
        agent_type
        title {
          html
        }
      }
      items {
        agent_tag {
          document {
            ... on PrismicTravelStore {
              id
              type
            }
            ... on PrismicTravelPartner {
              id
              type
            }
            ... on PrismicTaxonomyTravelDestination {
              id
              type
            }
            ... on PrismicTaxonomyTravelType {
              id
              type
            }
          }
        }
      }
    }
  }
`;

export interface AgentsSliceProps {
  sliceData: PrismicTravelStoreDataBodyAgent;
  pageData?: PrismicTravelStore;
}
