import React, { Component, ReactNode } from 'react';
import { PrismicPage, PrismicPageDataBodyImageLinkTiles } from '../../graphql';
import ImageLinkTile from '../controls/ImageLinkTile';
import ImageLinkTileList from '../controls/ImageLinkTileList';
import { IsValidPrismicLink } from '../../utils/PrismicHelpers';
import { graphql } from 'gatsby';
import SectionTitle from '../controls/SectionTitle';

export const ImageLinkTilesSliceKey = 'image_link_tiles';
export default class ImageLinkTilesSlice extends Component<ImageLinkTilesSliceProps> {
  render(): ReactNode {
    const { sliceData } = this.props;

    let items = Array.isArray(sliceData.resolved_items)
      ? sliceData.resolved_items
      : Array.isArray(sliceData.items)
      ? sliceData.items
      : [];
    items = items.filter((item) => item?.link_title && IsValidPrismicLink(item?.link_dest));

    if (!items.length) {
      return <React.Fragment />;
    }

    const linkItems: ReactNode[] = items.map((value, index) => {
      return (
        <ImageLinkTile
          key={index}
          index={index}
          image={value!.image}
          link_title={value!.link_title}
          link_desc={value!.link_desc}
          link_dest={value!.link_dest}
          link_cta={value!.link_cta}
        />
      );
    });

    return (
      <div className="container">
        <SectionTitle component={sliceData.primary?.section_title} />
        <section className="neo-image-link-tiles">
          <ImageLinkTileList>{linkItems}</ImageLinkTileList>
        </section>
      </div>
    );
  }
}

export interface ImageLinkTilesSliceProps {
  sliceData: PrismicPageDataBodyImageLinkTiles;
  pageData?: PrismicPage;
}

// noinspection JSUnusedGlobalSymbols
export const SliceImageLinkTilesFragment = graphql`
  fragment SliceImageLinkTilesFragment on PrismicSlicesImageLinkTiles {
    ... on PrismicPageDataBodyImageLinkTiles {
      id
      slice_type
      primary {
        section_title {
          html
        }
      }
      resolved_items {
        link_cta
        link_desc {
          html
        }
        link_dest {
          ...PrismicLinkFragment
        }
        link_title
        image {
          alt
          url
          gatsbyImageData(width: 960, layout: CONSTRAINED)
        }
      }
    }
    ... on PrismicEmailSubscriptionPageDataBodyImageLinkTiles {
      id
      slice_type
      primary {
        section_title {
          html
        }
      }
      resolved_items {
        link_cta
        link_desc {
          html
        }
        link_dest {
          ...PrismicLinkFragment
        }
        link_title
        image {
          alt
          url
          gatsbyImageData(width: 960, layout: CONSTRAINED)
        }
      }
    }
    ... on PrismicErrorPageDataBodyImageLinkTiles {
      id
      slice_type
      primary {
        section_title {
          html
        }
      }
      resolved_items {
        link_cta
        link_desc {
          html
        }
        link_dest {
          ...PrismicLinkFragment
        }
        link_title
        image {
          alt
          url
          gatsbyImageData(width: 960, layout: CONSTRAINED)
        }
      }
    }
    ... on PrismicGaragesSearchPageDataBodyImageLinkTiles {
      id
      slice_type
      primary {
        section_title {
          html
        }
      }
      resolved_items {
        link_cta
        link_desc {
          html
        }
        link_dest {
          ...PrismicLinkFragment
        }
        link_title
        image {
          alt
          url
          gatsbyImageData(width: 960, layout: CONSTRAINED)
        }
      }
    }
    ... on PrismicMerchandiseSearchPageDataBodyImageLinkTiles {
      id
      slice_type
      primary {
        section_title {
          html
        }
      }
      resolved_items {
        link_cta
        link_desc {
          html
        }
        link_dest {
          ...PrismicLinkFragment
        }
        link_title
        image {
          alt
          url
          gatsbyImageData(width: 960, layout: CONSTRAINED)
        }
      }
    }
    ... on PrismicSearchPageDataBodyImageLinkTiles {
      id
      slice_type
      primary {
        section_title {
          html
        }
      }
      resolved_items {
        link_cta
        link_desc {
          html
        }
        link_dest {
          ...PrismicLinkFragment
        }
        link_title
        image {
          alt
          url
          gatsbyImageData(width: 960, layout: CONSTRAINED)
        }
      }
    }
    ... on PrismicTravelAgentsSearchPageDataBodyImageLinkTiles {
      id
      slice_type
      primary {
        section_title {
          html
        }
      }
      resolved_items {
        link_cta
        link_desc {
          html
        }
        link_dest {
          ...PrismicLinkFragment
        }
        link_title
        image {
          alt
          url
          gatsbyImageData(width: 960, layout: CONSTRAINED)
        }
      }
    }
    ... on PrismicTravelDealsSearchPageDataBodyImageLinkTiles {
      id
      slice_type
      primary {
        section_title {
          html
        }
      }
      resolved_items {
        link_cta
        link_desc {
          html
        }
        link_dest {
          ...PrismicLinkFragment
        }
        link_title
        image {
          alt
          url
          gatsbyImageData(width: 960, layout: CONSTRAINED)
        }
      }
    }
    ... on PrismicTravelPartnersSearchPageDataBodyImageLinkTiles {
      id
      slice_type
      primary {
        section_title {
          html
        }
      }
      resolved_items {
        link_cta
        link_desc {
          html
        }
        link_dest {
          ...PrismicLinkFragment
        }
        link_title
        image {
          alt
          url
          gatsbyImageData(width: 960, layout: CONSTRAINED)
        }
      }
    }
    ... on PrismicTravelStoreSearchPageDataBodyImageLinkTiles {
      id
      slice_type
      primary {
        section_title {
          html
        }
      }
      resolved_items {
        link_cta
        link_desc {
          html
        }
        link_dest {
          ...PrismicLinkFragment
        }
        link_title
        image {
          alt
          url
          gatsbyImageData(width: 960, layout: CONSTRAINED)
        }
      }
    }
    ... on PrismicTravelTalksSearchPageDataBodyImageLinkTiles {
      id
      slice_type
      primary {
        section_title {
          html
        }
      }
      resolved_items {
        link_cta
        link_desc {
          html
        }
        link_dest {
          ...PrismicLinkFragment
        }
        link_title
        image {
          alt
          url
          gatsbyImageData(width: 960, layout: CONSTRAINED)
        }
      }
    }
    ... on PrismicTravelTripsSearchPageDataBodyImageLinkTiles {
      id
      slice_type
      primary {
        section_title {
          html
        }
      }
      resolved_items {
        link_cta
        link_desc {
          html
        }
        link_dest {
          ...PrismicLinkFragment
        }
        link_title
        image {
          alt
          url
          gatsbyImageData(width: 960, layout: CONSTRAINED)
        }
      }
    }
    ... on PrismicTravelTripDataBodyImageLinkTiles {
      id
      slice_type
      primary {
        section_title {
          html
        }
      }
      resolved_items {
        link_cta
        link_desc {
          html
        }
        link_dest {
          ...PrismicLinkFragment
        }
        link_title
        image {
          alt
          url
          gatsbyImageData(width: 960, layout: CONSTRAINED)
        }
      }
    }
    ... on PrismicTravelStoreDataBodyImageLinkTiles {
      id
      slice_type
      primary {
        section_title {
          html
        }
      }
      resolved_items {
        link_cta
        link_desc {
          html
        }
        link_dest {
          ...PrismicLinkFragment
        }
        link_title
        image {
          alt
          url
          gatsbyImageData(width: 960, layout: CONSTRAINED)
        }
      }
    }
    ... on PrismicTravelTalkDataBodyImageLinkTiles {
      id
      slice_type
      primary {
        section_title {
          html
        }
      }
      resolved_items {
        link_cta
        link_desc {
          html
        }
        link_dest {
          ...PrismicLinkFragment
        }
        link_title
        image {
          alt
          url
          gatsbyImageData(width: 960, layout: CONSTRAINED)
        }
      }
    }
    ... on PrismicTravelDealDataBodyImageLinkTiles {
      id
      slice_type
      primary {
        section_title {
          html
        }
      }
      resolved_items {
        link_cta
        link_desc {
          html
        }
        link_dest {
          ...PrismicLinkFragment
        }
        link_title
        image {
          alt
          url
          gatsbyImageData(width: 960, layout: CONSTRAINED)
        }
      }
    }
    ... on PrismicMerchandiseDataBodyImageLinkTiles {
      id
      slice_type
      primary {
        section_title {
          html
        }
      }
      resolved_items {
        link_cta
        link_desc {
          html
        }
        link_dest {
          ...PrismicLinkFragment
        }
        link_title
        image {
          alt
          url
          gatsbyImageData(width: 960, layout: CONSTRAINED)
        }
      }
    }
    ... on PrismicTravelPartnerDataBodyImageLinkTiles {
      id
      slice_type
      primary {
        section_title {
          html
        }
      }
      resolved_items {
        link_cta
        link_desc {
          html
        }
        link_dest {
          ...PrismicLinkFragment
        }
        link_title
        image {
          alt
          url
          gatsbyImageData(width: 960, layout: CONSTRAINED)
        }
      }
    }
  }
`;
