import {
  PrismicTaxonomyTravelTypeConnection,
  PrismicTravelPartnerConnection,
  PrismicTravelStoreConnection,
  PrismicTravelTalk,
  PrismicTravelTalksSearchPage,
  PrismicTravelTalksSearchPageDataBodySearchEnginePlaceholder,
} from '../../../graphql';
import React, { Component, ReactNode } from 'react';
import { graphql, StaticQuery } from 'gatsby';
import WithGlobalResources, { InjectedGlobalResourcesProps } from '../../higher-order/WithGlobalResources';
import TagBasedSearch, { NodeListToOptions, TagBasedSearchFieldsConfig } from '../../controls/TagBasedSearch';
import WithTravelTalkData, { InjectedTravelTalkDataProps } from '../../higher-order/WithTravelTalkData';
import { FilterTravelDataBasedOnTags } from '../../../utils/AgentDataHelpers';
import TravelTalk from '../../fragments/travel-talk/TravelTalk';
import ImageLinkTileList from '../../controls/ImageLinkTileList';
import PrismicStructuredText from '../../controls/PrismicStructuredText';
import { cloneDeep } from 'lodash';
import SectionTitle from '../../controls/SectionTitle';

export const TravelTalksSearchSliceKey = '!internal_travel_talks_search_slice';

const TravelTalksSearchSlice = WithGlobalResources(
  WithTravelTalkData(
    class TravelTalksSearchSliceImpl extends Component<
      TravelTalksSearchSliceProps & InjectedGlobalResourcesProps & InjectedTravelTalkDataProps
    > {
      private fieldConfig?: TagBasedSearchFieldsConfig<TravelTalksSearchParams>;

      render(): ReactNode {
        return <StaticQuery query={TravelTalksSearchSliceQuery} render={(results) => this.renderWithData(results)} />;
      }

      renderWithData(data: TravelTalksSearchSliceQueryResults): ReactNode {
        const { sliceData, travelTalkData } = this.props;

        let fieldConfig;
        if (!this.fieldConfig) {
          fieldConfig = {
            travelType: {
              label: sliceData?.primary?.travel_type_field_label || '',
              options: NodeListToOptions(data?.allPrismicTaxonomyTravelType?.nodes, (node) => node.data?.name).filter(
                (option) =>
                  travelTalkData.some((ta) =>
                    ta.data?.travel_types?.some((tt) => tt?.travel_type?.document?.id === option.value)
                  )
              ),
              allowMultiple: true,
            },
            store: {
              label: sliceData?.primary?.store_field_label || '',
              options: NodeListToOptions(
                data?.allPrismicTravelStore?.nodes.filter((n) => (n.data?.visibility ?? 'Visible') === 'Visible'),
                (node) => node?.data?.page_title
              ).filter((option) =>
                travelTalkData.some((ta) => ta.data?.caa_travel_store?.document?.id === option.value)
              ),
              allowMultiple: true,
            },
            travelPartner: {
              label: sliceData?.primary?.travel_partner_field_label || '',
              options: NodeListToOptions(data?.allPrismicTravelPartner?.nodes, (node) => node?.data?.page_title),
              allowMultiple: true,
            },
          };
          this.fieldConfig = cloneDeep(fieldConfig);
        } else {
          fieldConfig = cloneDeep(this.fieldConfig);
        }

        return (
          <div className="container neo-tag-search-page">
            <SectionTitle component={sliceData.primary?.section_title} />

            <TagBasedSearch
              fields={fieldConfig}
              search={this.executeSearch}
              render={this.renderSearchResults}
              filter={this.filterFields}
              maxPerPage={6}
            />
          </div>
        );
      }

      executeSearch = (params: TravelTalksSearchParams): PrismicTravelTalk[] => {
        const { travelTalkData } = this.props;

        return FilterTravelDataBasedOnTags(travelTalkData, {
          travelPartnerId: params.travelPartner,
          travelStoreId: params.store,
          travelTypeId: params.travelType,
        });

      };
      renderSearchResults = (results: PrismicTravelTalk[]): ReactNode => {
        const { sliceData } = this.props;

        if (results.length === 0) {
          return (
            <div className="content has-text-centered">
              <PrismicStructuredText text={sliceData?.primary?.no_results_found_content} />
            </div>
          );
        }

        return (
          <ImageLinkTileList forceLayout="thirds">
            {results.map((talk, index) => (
              <TravelTalk key={index} talk={talk} />
            ))}
          </ImageLinkTileList>
        );
      };

      filterFields = (
        fields: TagBasedSearchFieldsConfig<TravelTalksSearchParams>,
        results: PrismicTravelTalk[]
      ): TagBasedSearchFieldsConfig<TravelTalksSearchParams> => {
        const fieldConfig = cloneDeep(this.fieldConfig) ?? fields;
        return {
        ...fields,
        store: {
          ...fields.store,
          options: fieldConfig.store.options.filter((so) =>
            results.some((res) => res.data?.caa_travel_store?.document?.id === so.value)
          ),
        },
        travelPartner: {
          ...fields.travelPartner,
          options: fieldConfig.travelPartner.options.filter((tpo) =>
            results.some((res) =>
              res.data?.travel_partners?.some((tp) => tp?.travel_partner?.document?.id === tpo.value)
            )
          ),
        },
        travelType: {
          ...fields.travelType,
          options: fieldConfig.travelType.options.filter((tto) =>
            results.some((res) => res.data?.travel_types?.some((tt) => tt?.travel_type?.document?.id === tto.value))
          ),
        },
      }}
      ;
    }
  )
);
export default TravelTalksSearchSlice;

const TravelTalksSearchSliceQuery = graphql`
  query TravelTalksSearchSliceQuery {
    allPrismicTaxonomyTravelType(sort: { fields: data___name, order: ASC }) {
      nodes {
        id
        data {
          name
        }
      }
    }
    allPrismicTravelPartner(sort: { fields: data___page_title }) {
      nodes {
        id
        data {
          page_title
        }
      }
    }
    allPrismicTravelStore(sort: { fields: data___page_title, order: ASC }) {
      nodes {
        id
        data {
          location_type
          visibility
          page_title
        }
      }
    }
  }
`;

interface TravelTalksSearchParams {
  travelType: string[];
  store: string[];
  travelPartner: string[];
}

interface TravelTalksSearchSliceQueryResults {
  allPrismicTaxonomyTravelType?: PrismicTaxonomyTravelTypeConnection;
  allPrismicTravelPartner?: PrismicTravelPartnerConnection;
  allPrismicTravelStore?: PrismicTravelStoreConnection;
}

export interface TravelTalksSearchSliceProps {
  sliceData: PrismicTravelTalksSearchPageDataBodySearchEnginePlaceholder;
  pageData?: PrismicTravelTalksSearchPage;
}
