import React from 'react';
import { graphql, PageProps, StaticQuery } from 'gatsby';

import ErrorPageTemplate from './ErrorPage';
import { PrismicErrorPage } from '../graphql';

type NotFoundPageProps = Partial<PageProps<PrismicErrorPage>>;

const NotFoundPage = ({ location }: NotFoundPageProps) => (
  <StaticQuery
    query={NotFoundPageQuery}
    render={(results) => (
      // FIXME: The error is ignored because this is the original implementation. The correct pattern to apply here probably
      // have to be different.
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      <ErrorPageTemplate location={location} data={results} />
    )}
  />
);

export default NotFoundPage;

const NotFoundPageQuery = graphql`
  query PrismicNotFoundPageQuery {
    prismicErrorPage(data: { error_code: { eq: 404 } }) {
      ...ErrorPageQueryFragment
    }
  }
`;
