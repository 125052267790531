import React, {ImgHTMLAttributes} from 'react';
import {GatsbyImageProps} from 'gatsby-plugin-image';

type HTMLImageElementStyle = ImgHTMLAttributes<HTMLImageElement>['style'];

const PrismicImage = ({
                        alt,
                        image,
                        allowSvgEmbed = true,
                        srcIsFixed,
                        onLoad,
                        onError,
                        onClick,
                        imgStyle,
                        ...gatsbyImageProps
                      }: PrismicImageProps) => {
  if (!image) {
    return null;
  }

  const makeStyle = (customStyle?: HTMLImageElementStyle, isFixed = false): HTMLImageElementStyle => {
    const defaultStyle: HTMLImageElementStyle = {
      width: '100%',
      height: isFixed ? '100%' : 'auto',
      objectFit: 'cover',
      objectPosition: 'center',
    };

    if (isFixed) {
      return {
        ...defaultStyle,
        position: 'absolute',
        top: 0,
        left: 0,
        ...customStyle,
      };
    }

    return {
      ...defaultStyle,
      ...customStyle,
    };
  };
  const altText = alt ?? image.alt ?? undefined;
  const publicSrc = image?.url ?? undefined;
  const {className, style, title} = gatsbyImageProps;

  return (
    <div
      className={`${className ?? ''} neo-img-wrapper`}
      style={{
        position: 'relative',
        overflow: 'hidden',
        ...style,
      }}
    >
      <img
        src={publicSrc}
        alt={altText}
        title={title}
        onLoad={onLoad}
        onError={onError}
        onClick={onClick}
        style={makeStyle(imgStyle, srcIsFixed)}
      />
    </div>
  );
};

export interface PrismicImageProps
  extends Omit<GatsbyImageProps, 'alt' | 'image'> {
  // TODO: Replace any with all of the image types in graphql.d.ts. There is no single image type.
  image?: any;
  alt?: string;
  srcIsFixed?: boolean;
  allowSvgEmbed?: boolean;
}

export default PrismicImage;
