import { PrismicGarage, PrismicGarageConnection } from '../../graphql';
import React, { Component, ComponentType } from 'react';
import { graphql, StaticQuery } from 'gatsby';
import { Subset } from '../../utils/UtilityTypes';

const WithGarageData = function WithGarageDataFn<
  P extends InjectedGarageDataProps
>(
  ComposedComponent: ComponentType<P>
): ComponentType<Subset<P, InjectedGarageDataProps>> {
  return class extends Component<Subset<P, InjectedGarageDataProps>> {
    render() {
      return (
        <StaticQuery
          query={GarageDataQuery}
          render={queryResult => {
            const { allPrismicGarage } = queryResult as GarageDataQueryResults;

            return (
              <ComposedComponent
                {...(this.props as any)}
                garageData={allPrismicGarage.nodes || []}
              />
            );
          }}
        />
      );
    }
  };
};
export default WithGarageData;

const GarageDataQuery = graphql`
  query GarageDataQuery {
    allPrismicGarage(sort: { fields: data___name, order: ASC }) {
      nodes {
        ...GarageFragment
      }
    }
  }
`;

interface GarageDataQueryResults {
  allPrismicGarage: PrismicGarageConnection;
}

// noinspection JSUnusedGlobalSymbols
export const GarageFragment = graphql`
  fragment GarageFragment on PrismicGarage {
    id
    data {
      name
      service_type {
        document {
          ... on PrismicTaxonomyGarageServiceType {
            id
            data {
              name
            }
          }
        }
      }
      city {
        document {
          ... on PrismicTaxonomyGarageCity {
            id
            data {
              name
            }
          }
        }
      }
      location {
        latitude
        longitude
      }
      address
      address_short
      phone
      website {
        ...PrismicLinkFragment
      }
    }
  }
`;

export interface InjectedGarageDataProps {
  garageData: PrismicGarage[];
}
