import {
  PrismicAgent,
  PrismicTaxonomyTravelDestinationConnection,
  PrismicTaxonomyTravelTypeConnection,
  PrismicTravelAgentsSearchPage,
  PrismicTravelAgentsSearchPageDataBodySearchEnginePlaceholder,
  PrismicTravelPartnerConnection,
  PrismicTravelStoreConnection,
} from '../../../graphql';
import React, { Component, ReactNode } from 'react';
import { graphql, StaticQuery } from 'gatsby';
import WithGlobalResources, { InjectedGlobalResourcesProps } from '../../higher-order/WithGlobalResources';
import TagBasedSearch, { NodeListToOptions, TagBasedSearchFieldsConfig } from '../../controls/TagBasedSearch';
import WithAgentData, { InjectedAgentDataProps } from '../../higher-order/WithAgentData';
import { FilterTravelDataBasedOnTags } from '../../../utils/AgentDataHelpers';
import Agent from '../../fragments/travel-agent/Agent';
import ImageLinkTileList from '../../controls/ImageLinkTileList';
import PrismicStructuredText from '../../controls/PrismicStructuredText';
import SectionTitle from '../../controls/SectionTitle';

export const TravelAgentsSearchSliceKey = '!internal_travel_agents_search_slice';

const TravelAgentsSearchSlice = WithGlobalResources(
  WithAgentData(
    class TravelAgentsSearchSliceImpl extends Component<
      TravelAgentsSearchSliceProps & InjectedGlobalResourcesProps & InjectedAgentDataProps
    > {
      private fieldConfig?: TagBasedSearchFieldsConfig<TravelAgentsSearchParams>;

      render(): ReactNode {
        return <StaticQuery query={TravelAgentsSearchSliceQuery} render={(results) => this.renderWithData(results)} />;
      }

      renderWithData(data: TravelAgentsSearchSliceQueryResults): ReactNode {
        const { sliceData, agentData } = this.props;

        if (!this.fieldConfig) {
          this.fieldConfig = {
            travelType: {
              label: sliceData?.primary?.travel_type_field_label || '',
              options: NodeListToOptions(data?.allPrismicTaxonomyTravelType?.nodes, (node) => node.data?.name).filter(
                (option) =>
                  agentData.some((ta) =>
                    ta.data?.travel_types?.some((tt) => tt?.travel_type?.document?.id === option.value)
                  )
              ),
              allowMultiple: true,
              isVisible: sliceData?.primary.travel_type_filter ?? true,
            },
            destinationType: {
              label: sliceData?.primary?.travel_destination_field_label || '',
              options: NodeListToOptions(
                data?.allPrismicTaxonomyTravelDestination?.nodes,
                (node) => node.data?.name
              ).filter((option) =>
                agentData.some((ta) =>
                  ta.data?.travel_destinations?.some((tt) => tt?.travel_destinatioon?.document?.id === option.value)
                )
              ),
              allowMultiple: true,
              isVisible: sliceData?.primary.travel_destination_filter ?? true,
            },
            store: {
              label: sliceData?.primary?.store_field_label || '',
              options: NodeListToOptions(
                data?.allPrismicTravelStore?.nodes?.filter((n) => (n.data?.visibility ?? 'Visible') === 'Visible'),
                (node) => node?.data?.page_title
              ).filter(
                (option) =>
                  agentData.some((ta) =>
                    ta.data?.caa_travel_store_list?.some((tt) => tt?.section?.document?.id === option.value)
                  )
              ),
              allowMultiple: true,
              isVisible: sliceData?.primary.store_filter ?? true,
            },
            travelPartner: {
              label: sliceData?.primary?.travel_partner_field_label || '',
              options: NodeListToOptions(data?.allPrismicTravelPartner?.nodes, (node) => node.data?.page_title).filter(
                (option) =>
                  agentData.some((ta) =>
                    ta.data?.travel_partners?.some((tt) => tt?.travel_partner?.document?.id === option.value)
                  )
              ),
              allowMultiple: true,
              isVisible: sliceData?.primary.travel_partner_filter ?? true,
            },
          };
        }

        return (
          <div className="container neo-tag-search-page">
            <SectionTitle component={sliceData.primary?.section_title} />

            {this.fieldConfig && (
              <TagBasedSearch
                fields={this.fieldConfig}
                search={this.executeSearch}
                render={this.renderSearchResults}
                maxPerPage={6}
              />
            )}
          </div>
        );
      }

      executeSearch = (params: TravelAgentsSearchParams): PrismicAgent[] => {
        const { agentData } = this.props;

        const agentList = FilterTravelDataBasedOnTags(agentData, {
          travelStoreId: params.store,
          travelTypeId: params.travelType,
          travelDestinationId: params.destinationType,
          travelPartnerId: params.travelPartner,
        });

        // 'Travel' is the value from the agentType schema definition
        const result = agentList.filter((item) => item.data.type === 'Travel');

        return result.sort(() => Math.random() - 0.5);
      };

      renderSearchResults = (results: PrismicAgent[]): ReactNode => {
        const { sliceData } = this.props;

        if (results.length === 0) {
          return (
            <div className="content has-text-centered">
              <PrismicStructuredText text={sliceData?.primary?.no_results_found_content} />
            </div>
          );
        }

        return (
          <ImageLinkTileList forceLayout="thirds">
            {results.map((agent, index) => (
              <Agent key={index} agent={agent} />
            ))}
          </ImageLinkTileList>
        );
      };
    }
  )
);
export default TravelAgentsSearchSlice;

const TravelAgentsSearchSliceQuery = graphql`
  query TravelAgentsSearchSliceQuery {
    allPrismicTaxonomyTravelType(sort: { fields: data___name, order: ASC }) {
      nodes {
        id
        data {
          name
        }
      }
    }
    allPrismicTaxonomyTravelDestination(sort: { fields: data___name, order: ASC }) {
      nodes {
        id
        data {
          name
        }
      }
    }
    allPrismicTravelPartner(sort: { fields: data___page_title }) {
      nodes {
        id
        data {
          page_title
        }
      }
    }
    allPrismicTravelStore(sort: { fields: data___page_title, order: ASC }) {
      nodes {
        id
        data {
          page_title
          visibility
          location_type
        }
      }
    }
  }
`;

interface TravelAgentsSearchParams {
  travelType: string[] | undefined;
  store: string[];
  destinationType: string[];
  travelPartner: string[];
}

interface TravelAgentsSearchSliceQueryResults {
  allPrismicTaxonomyTravelType?: PrismicTaxonomyTravelTypeConnection;
  allPrismicTaxonomyTravelDestination?: PrismicTaxonomyTravelDestinationConnection;
  allPrismicTravelPartner?: PrismicTravelPartnerConnection;
  allPrismicTravelStore?: PrismicTravelStoreConnection;
}

export interface TravelAgentsSearchSliceProps {
  sliceData: PrismicTravelAgentsSearchPageDataBodySearchEnginePlaceholder;
  pageData?: PrismicTravelAgentsSearchPage;
}
