import React, { Component, ReactNode } from 'react';
import {
  ComparativeTableSliceProps,
  IComparativeTable,
  IPlanData,
  IPlanSpecification,
  IPlanSpecificationData,
} from '../ComparativeTableSlice';
import ComparativeTablePlanHeader from './ComparativeTablePlanHeader';

class SpecValuesColumns extends Component<ISpecRowValues> {
  render() {
    let nodeArray: ReactNode[] = [];
    const plansSpecs: string[] = this.props.plansSpecs;
    const specName = this.props.specName;
    if (plansSpecs && specName) {
      nodeArray = plansSpecs.map((planSpec, index) => {
        const reactNode: ReactNode = (
          <td
            key={`specValue${index}`}
            dangerouslySetInnerHTML={{
              __html: planSpec,
            }}
          />
        );
        return reactNode;
      });
    }
    return nodeArray;
  }
}

class TableHeaders extends Component<IPlanHeaders> {
  render() {
    let nodeArray: ReactNode[] = [];
    const plansData: IPlanData[] = this.props.plansData || [];

    if (plansData.length) {
      nodeArray = plansData.map((planData, index) => {
        return (
          <th key={`headerValue${index}`} scope="col">
            <ComparativeTablePlanHeader
              key={`table-plan-header-${index}`}
              planData={planData}
              index={index}
            />
          </th>
        );
      });
    }

    return nodeArray;
  }
}

export default class DesktopComparativeTable extends Component<
  ComparativeTableSliceProps & IComparativeTable
> {
  render(): ReactNode {
    const { sliceData } = this.props;
    const plansData: IPlanData[] = this.props.plansData || [];
    const uniqueSpecs: IPlanSpecification[] = this.props.uniqueSpecs || [];

    let tableInstructions: ReactNode = null;

    if (sliceData.primary?.table_instruction) {
      tableInstructions = (
        <caption className={'instructions'}>
          <span>{sliceData.primary.table_instruction}</span>
        </caption>
      );
    }

    const plansSpecs: IPlanSpecificationData[][] = plansData.map(planData => {
      let specs: IPlanSpecificationData[] = [];
      if (planData.specs) {
        specs = planData.specs;
      }
      return specs;
    });

    let tableSpecRows: ReactNode[] = [];

    if (uniqueSpecs.length) {
      tableSpecRows = uniqueSpecs.map((spec, index) => {
        const plansSpecValues = [];
        const bgColor = spec.add_on_bg_color;
        const isActive = this.props.activeItemsIndex?.includes(index) ?? false;
        for (const planSpecs of plansSpecs) {
          let value = '&mdash;';

          // if actual plan has the actual spec defined, get value.
          const specData = planSpecs.find(
            (planSpec: IPlanSpecificationData) => planSpec.name === spec.name
          );
          if (specData?.value?.html) {
            value = specData.value.html;
          }
          plansSpecValues.push(value);
        }

        const titleNodeWithDescription: ReactNode = (
          <th key={`specName${index}`} scope={'row'}>
            <a
              className={` 
                is-accordion-item-button
                ${isActive ? 'is-active' : ''}
              `}
              aria-controls={`${this.props.elementIdPrefix}__${index}__Content`}
              aria-expanded={isActive ? 'true' : 'false'}
              onClick={_event => this.props.setActiveItem?.(index, _event)}
            >
              {spec.name}
            </a>
            <div
              key={`specName${index}`}
              id={`${this.props.elementIdPrefix}__${index}__Content`}
              className={`neo-collapsable-region ${
                isActive ? 'is-active' : ''
              }`}
              aria-labelledby={`${this.props.elementIdPrefix}__${index}__Toggle`}
            >
              <div className={'content'}>{spec.description?.text}</div>
            </div>
          </th>
        );

        const titleNode: ReactNode = (
          <th key={`specName${index}`} scope={'row'}>
            {spec.name}
          </th>
        );

        return (
          <tr key={`spec${index}`} className={`is-${bgColor}`}>
            {spec.description?.text ? titleNodeWithDescription : titleNode}
            <SpecValuesColumns
              key={`specValueColumns${index}`}
              specName={spec.name}
              plansSpecs={plansSpecValues}
            />
          </tr>
        );
      });
    }

    return (
      <div className="table-container">
        <div className="neo-comparative-table">
          <table className={'table'}>
            {tableInstructions}
            <thead>
              <tr>
                <td />
                <TableHeaders plansData={plansData} />
              </tr>
            </thead>
            <tbody>{tableSpecRows}</tbody>
          </table>
        </div>
      </div>
    );
  }
}

export interface ISpecRowValues {
  specName?: string | null;
  plansSpecs: string[];
}
export interface IPlanHeaders {
  plansData?: IPlanData[];
}
