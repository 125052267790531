import React from 'react';
import 'typeface-bitter';
import 'typeface-source-code-pro';
import L from 'leaflet';
import { componentResolverFromMap, PrismicPreviewProvider } from 'gatsby-plugin-prismic-previews';

import './src/scss/style.scss';
import { prismicLinkResolver as linkResolver } from './utils';
import PageTemplate from './src/templates/Page';
import EmailSubscriptionPageTemplate from './src/templates/EmailSubscriptionPage';
import ErrorPageTemplate from './src/templates/ErrorPage';
import GaragesSearchPageTemplate from './src/templates/GaragesSearchPage';
import MerchandiseTemplate from './src/templates/Merchandise';
import MerchandiseSearchPageTemplate from './src/templates/MerchandiseSearchPage';
import SearchPageTemplate from './src/templates/SearchPage';
import TravelAgentsSearchPageTemplate from './src/templates/TravelAgentsSearchPage';
import TravelDealTemplate from './src/templates/TravelDeal';
import TravelDealsSearchPageTemplate from './src/templates/TravelDealsSearchPage';
import TravelPartnerTemplate from './src/templates/TravelPartner';
import TravelPartnersSearchPageTemplate from './src/templates/TravelPartnersSearchPage';
import TravelStoreTemplate from './src/templates/TravelStore';
import TravelStoreSearchPageTemplate from './src/templates/TravelStoreSearchPage';
import TravelTalkTemplate from './src/templates/TravelTalk';
import TravelTalksSearchPageTemplate from './src/templates/TravelTalksSearchPage';
import TravelTripTemplate from './src/templates/TravelTrip';
import TravelTripsSearchPageTemplate from './src/templates/TravelTripsSearchPage';

delete L.Icon.Default.prototype._getIconUrl;

L.Icon.Default.mergeOptions({
  iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png').default,
  iconUrl: require('leaflet/dist/images/marker-icon.png').default,
  shadowUrl: require('leaflet/dist/images/marker-shadow.png').default,
});

// Append the TST Widget Script
window.TST ??= {
  config: {
    host: 'caaneo.tstllc.net',
  },
  onReady: function () {
    // eslint-disable-next-line no-undef
    window.TST.init(TST.config);

    window.TST.caaNeo.__isReady = true;
    const queue = window.TST.caaNeo.__actionQueue;
    window.TST.caaNeo.__actionQueue = [];

    for (const func of queue) {
      try {
        func();
      } catch (ex) {
        console.error(ex);
      }
    }
  },
  caaNeo: {
    __isReady: false,
    __importQueued: false,
    __actionQueue: [],
    queueCallback(func) {
      if (window.TST.caaNeo.__isReady) {
        func();
        return;
      }

      if (!window.TST.caaNeo.__importQueued) {
        window.TST.caaNeo.__importQueued = true;

        import('@tsttravel/ui-tsttravel-search-widget/js/tst-api').then(() => {
          // NOOP: TST has been loaded asynchronously
        });
      }

      window.TST.caaNeo.__actionQueue.push(func);
    },
  },
};

window.___CAANEO___ = { isFontAwesomeReady: false };

export const onClientEntry = () => {
  const script = document.createElement("script")
  script.src = "https://cdnjs.cloudflare.com/polyfill/v3/polyfill.min.js?features=Array.prototype.map,NodeList.prototype.forEach,NodeList.prototype.@@iterator,fetch,requestIdleCallback"
  script.async = true
  document.body.appendChild(script)
}

export const onInitialClientRender = () => {
  const importThirdParties = () => {
    import('./gatsby-browser-fontawesome').then((fa) => {
      fa.default();
    });
  };

  if (typeof window.requestIdleCallback === 'function') {
    window.requestIdleCallback(importThirdParties);
  } else {
    importThirdParties();
  }
};

export const wrapRootElement = ({ element }) => (
  <PrismicPreviewProvider
    repositoryConfigs={[
      {
        repositoryName: process.env.GATSBY_PRISMIC_REPO_NAME,
        linkResolver,
        componentResolver: componentResolverFromMap({
          page: PageTemplate,
          email_subscription_page: EmailSubscriptionPageTemplate,
          error_page: ErrorPageTemplate,
          garages_search_page: GaragesSearchPageTemplate,
          merchandise: MerchandiseTemplate,
          merchandise_search_page: MerchandiseSearchPageTemplate,
          search_page: SearchPageTemplate,
          travel_agents_search_page: TravelAgentsSearchPageTemplate,
          travel_deal: TravelDealTemplate,
          travel_deals_search_page: TravelDealsSearchPageTemplate,
          travel_partner: TravelPartnerTemplate,
          travel_partners_search_page: TravelPartnersSearchPageTemplate,
          travel_store: TravelStoreTemplate,
          travel_stores_search_page: TravelStoreSearchPageTemplate,
          travel_talk: TravelTalkTemplate,
          travel_talks_search_page: TravelTalksSearchPageTemplate,
          travel_trip: TravelTripTemplate,
          travel_trips_search_page: TravelTripsSearchPageTemplate,
        }),
      },
    ]}
  >
    {element}
  </PrismicPreviewProvider>
);
