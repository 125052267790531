import { PrismicPage, PrismicPageDataBodyYoutubeVideo } from '../../graphql';
import React, { Component, ReactNode } from 'react';
import ReactPlayer, { Config } from 'react-player';
import { graphql } from 'gatsby';
import SectionTitle from '../controls/SectionTitle';
import PrismicStructuredText from '../controls/PrismicStructuredText';

export const VideoEmbedSliceKey = 'youtube_video';
export default class VideoEmbedSlice extends Component<VideoEmbedSliceProps> {
  render(): ReactNode {
    const { sliceData } = this.props;

    if (!sliceData.primary?.video_url) {
      return <React.Fragment />;
    }

    let videoCaptionNode: ReactNode = null;
    let videoCaptionNodeId: string | undefined = undefined;

    if (sliceData.primary?.caption?.html) {
      videoCaptionNodeId = sliceData.id;

      videoCaptionNode = (
        <div id={videoCaptionNodeId} className="neo-video-caption has-text-centered is-size-6">
          <PrismicStructuredText text={sliceData.primary?.caption} />
        </div>
      );
    }

    let playerEmbedNode: ReactNode = (
      <div
        className="neo-video-unknown-embed"
        dangerouslySetInnerHTML={{
          __html: sliceData.primary?.video_url?.html || '',
        }}
      />
    );

    if (sliceData.primary?.video_url?.embed_url && sliceData.primary?.video_url?.type === 'video') {
      switch (sliceData.primary?.video_url?.provider_name) {
        case 'YouTube':
        case 'Vimeo':
        case 'Facebook':
        case 'Dailymotion':
          {
            const playerConfig: Config = {
              youtube: {
                playerVars: {
                  modestbranding: 1,
                  showInfo: 0,
                  hl: 'en',
                  rel: 0,
                },
              },
            };

            playerEmbedNode = (
              <div className="neo-video-aspect-box" aria-labelledby={videoCaptionNodeId}>
                <ReactPlayer
                  className="neo-video-player"
                  controls={true}
                  config={playerConfig}
                  url={sliceData.primary.video_url.embed_url!}
                  width="100%"
                  height="100%"
                />
              </div>
            );
          }
          break;

        default:
          playerEmbedNode = (
            <div
              className="neo-video-aspect-box"
              aria-labelledby={videoCaptionNodeId}
              dangerouslySetInnerHTML={{
                __html: sliceData.primary?.video_url?.html || '',
              }}
            />
          );
          break;
      }
    }

    return (
      <div className="container neo-video-embed">
        <SectionTitle component={sliceData.primary?.section_title} />
        <div className="neo-video-wrapper">{playerEmbedNode}</div>
        {videoCaptionNode}
      </div>
    );
  }
}

export interface VideoEmbedSliceProps {
  sliceData: PrismicPageDataBodyYoutubeVideo;
  pageData?: PrismicPage;
}

// noinspection JSUnusedGlobalSymbols
export const SliceYoutubeVideoFragment = graphql`
  fragment SliceYoutubeVideoFragment on PrismicSlicesYoutubeVideo {
    ... on PrismicPageDataBodyYoutubeVideo {
      id
      slice_type
      primary {
        section_title {
          html
        }
        video_url {
          embed_url
          type
          provider_name
          html
        }
        caption {
          html
        }
      }
    }
    ... on PrismicTravelStoreDataBodyYoutubeVideo {
      id
      slice_type
      primary {
        section_title {
          html
        }
        video_url {
          embed_url
          type
          provider_name
          html
        }
        caption {
          html
        }
      }
    }
    ... on PrismicTravelTalkDataBodyYoutubeVideo {
      id
      slice_type
      primary {
        section_title {
          html
        }
        video_url {
          embed_url
          type
          provider_name
          html
        }
        caption {
          html
        }
      }
    }
    ... on PrismicTravelTripDataBodyYoutubeVideo {
      id
      slice_type
      primary {
        section_title {
          html
        }
        video_url {
          embed_url
          type
          provider_name
          html
        }
        caption {
          html
        }
      }
    }
    ... on PrismicMerchandiseDataBodyYoutubeVideo {
      id
      slice_type
      primary {
        section_title {
          html
        }
        video_url {
          embed_url
          type
          provider_name
          html
        }
        caption {
          html
        }
      }
    }
    ... on PrismicTravelDealDataBodyYoutubeVideo {
      id
      slice_type
      primary {
        section_title {
          html
        }
        video_url {
          embed_url
          type
          provider_name
          html
        }
        caption {
          html
        }
      }
    }
    ... on PrismicTravelPartnerDataBodyYoutubeVideo {
      id
      slice_type
      primary {
        section_title {
          html
        }
        video_url {
          embed_url
          type
          provider_name
          html
        }
        caption {
          html
        }
      }
    }
  }
`;
