import {
  PrismicTaxonomyTravelTypeConnection,
  PrismicMerchandiseSearchPage,
  PrismicMerchandiseSearchPageDataBodySearchEnginePlaceholder,
  PrismicTaxonomyMerchandiseCategoryConnection,
} from '../../../graphql';
import React, { Component, ReactNode } from 'react';
import { graphql, StaticQuery } from 'gatsby';
import WithGlobalResources, { InjectedGlobalResourcesProps } from '../../higher-order/WithGlobalResources';
import TagBasedSearch, { NodeListToOptions, TagBasedSearchFieldsConfig } from '../../controls/TagBasedSearch';
import WithMerchandiseData, {
  ExtendedPrismicMerchandise,
  InjectedMerchandiseDataProps,
} from '../../higher-order/WithMerchandiseData';
import Merchandise from '../../fragments/merchandise/Merchandise';
import ImageLinkTileList from '../../controls/ImageLinkTileList';
import PrismicStructuredText from '../../controls/PrismicStructuredText';
import SectionTitle from '../../controls/SectionTitle';

export const MerchandiseSearchSliceKey = '!internal_merchandise_search_slice';

const MerchandiseSearchSlice = WithGlobalResources(
  WithMerchandiseData(
    class MerchandiseSearchSliceImpl extends Component<
      MerchandiseSearchSliceProps & InjectedGlobalResourcesProps & InjectedMerchandiseDataProps
    > {
      private fieldConfig?: TagBasedSearchFieldsConfig<MerchandiseSearchParams>;

      render(): ReactNode {
        return <StaticQuery query={MerchandiseSearchSliceQuery} render={(results) => this.renderWithData(results)} />;
      }

      renderWithData(data: MerchandiseSearchSliceQueryResults): ReactNode {
        const { sliceData, merchandiseData } = this.props;

        if (!this.fieldConfig) {
          this.fieldConfig = {
            merchCat: {
              label: sliceData?.primary?.merch_category_field_label || '',
              options: NodeListToOptions(
                data?.allPrismicTaxonomyMerchandiseCategory?.nodes,
                (node) => node?.data?.name
              ).filter((option) =>
                merchandiseData.some((ta) =>
                  ta.data?.merchandise_categories?.some((tt) => tt?.merchandise_category?.document?.id === option.value)
                )
              ),
              allowMultiple: true,
            },
            sortPrice: {
              label: sliceData?.primary?.sort_price_field_label || '',
              options: [
                {
                  label: sliceData?.primary?.sort_price_asc_option || '',
                  value: 'ASC',
                },
                {
                  label: sliceData?.primary?.sort_price_desc_option || '',
                  value: 'DESC',
                },
              ],
              allowMultiple: false,
              isSearchable: false,
              defaultValue: ['ASC'],
              requireValue: true,
            },
            saleStatus: {
              label: sliceData?.primary?.sale_status_field_label || '',
              options: [
                {
                  label: sliceData?.primary?.sale_status_show_all_option || '',
                  value: 'ALL',
                },
                {
                  label: sliceData?.primary?.sale_status_show_sales_only_options || '',
                  value: 'SALES_ONLY',
                },
              ],
              allowMultiple: false,
              isSearchable: false,
              defaultValue: ['ALL'],
              requireValue: true,
            },
          };
        }

        return (
          <div className="container neo-tag-search-page">
            <SectionTitle component={sliceData.primary?.section_title} />

            <TagBasedSearch
              fields={this.fieldConfig}
              search={this.executeSearch}
              render={this.renderSearchResults}
              maxPerPage={6}
            />
          </div>
        );
      }

      executeSearch = (params: MerchandiseSearchParams): ExtendedPrismicMerchandise[] => {
        const { merchandiseData } = this.props;

        const salesOnly = params.saleStatus[0] === 'SALES_ONLY';
        const isAsc = params.sortPrice[0] !== 'DESC';

        const results = merchandiseData.filter((node) => {
          let isMatch = true;

          if (isMatch && salesOnly && !node.hasSaleActive) {
            isMatch = false;
          }

          if (isMatch && params.merchCat.length) {
            isMatch =
              node?.data?.merchandise_categories?.some((i) =>
                params.merchCat.some((id) => i?.merchandise_category?.document?.id === id)
              ) || false;
          }

          return isMatch;
        });

        results.sort((a, b) => {
          if (a.numericPrice === b.numericPrice) {
            return 0;
          }

          if (a.numericPrice === undefined) {
            return 1;
          }

          if (b.numericPrice === undefined) {
            return -1;
          }

          if (a.numericPrice! < b.numericPrice!) {
            return isAsc ? -1 : 1;
          }

          if (a.numericPrice! > b.numericPrice!) {
            return isAsc ? 1 : -1;
          }

          return 0;
        });

        return results;
      };
      renderSearchResults = (results: ExtendedPrismicMerchandise[]): ReactNode => {
        const { sliceData } = this.props;

        if (results.length === 0) {
          return (
            <div className="content has-text-centered">
              <PrismicStructuredText text={sliceData?.primary?.no_results_found_content} />
            </div>
          );
        }

        return (
          <ImageLinkTileList forceLayout="thirds">
            {results.map((merch, index) => (
              <Merchandise merchandise={merch} key={index} />
            ))}
          </ImageLinkTileList>
        );
      };
    }
  )
);
export default MerchandiseSearchSlice;

const MerchandiseSearchSliceQuery = graphql`
  query MerchandiseSearchSliceQuery {
    allPrismicTaxonomyMerchandiseCategory(sort: { fields: data___name, order: ASC }) {
      nodes {
        id
        data {
          name
        }
      }
    }
    allPrismicTaxonomyTravelType(sort: { fields: data___name, order: ASC }) {
      nodes {
        id
        data {
          name
        }
      }
    }
  }
`;

interface MerchandiseSearchParams {
  merchCat: string[];
  sortPrice: string[];
  saleStatus: string[];
}

interface MerchandiseSearchSliceQueryResults {
  allPrismicTaxonomyMerchandiseCategory?: PrismicTaxonomyMerchandiseCategoryConnection;
  allPrismicTaxonomyTravelType?: PrismicTaxonomyTravelTypeConnection;
}

export interface MerchandiseSearchSliceProps {
  sliceData: PrismicMerchandiseSearchPageDataBodySearchEnginePlaceholder;
  pageData?: PrismicMerchandiseSearchPage;
}
