import { Maybe, PrismicLinkType, PrismicTravelTrip, Scalars } from '../graphql';
import { ToMoment } from './PrismicHelpers';

const NormalizeTravelFilters = <TFilter extends TravelFilterInterface>(
  filters: TFilter
): NormalizedTravelFilters<TFilter> => {
  const normalizedFilters: NormalizedTravelFilters<TFilter> = {};

  Object.keys(filters).forEach((key) => {
    const field = key as keyof TFilter;
    const rawValue = filters[field] as FilterString;

    if (rawValue === null || rawValue === undefined) {
      return;
    }

    normalizedFilters[field] = NormalizeFilterString(rawValue);
  });

  return normalizedFilters;
};

export const NormalizeFilterString = (rawValue: FilterString): string[] => {
  if (typeof rawValue === 'string') {
    return [rawValue];
  }

  if (Array.isArray(rawValue)) {
    return rawValue.filter((s) => typeof s === 'string') as string[];
  }

  return [];
};

/**
 *  Filter data for all the following tags (defined by business rules)
 *  - travel_partner
 *  - travel_destination
 *  - travel_type
 *  - travel_store
 *
 *  By comparing the filter values (ids) and the target tag of the node
 *  (located in the tagging tab of prismic), we can filter out any nodes
 *  that don't match these criteria.
 *
 *  The filter works like this:
 *
 *  (travel_partner_1 or travel_partner_2 ...) and (travel_destination_1 or travel_destination_2 ...)
 *  and (travel_type_1 or travel_type_2 ...) and (travel_store_1 or travel_store_2 ...)
 *
 *  So it an OR based search by type, and an AND based search between types.
 *
 * @param node Node to be filtered.
 * @param filters The filters that will be applied. They contained the tags.
 * @returns True if a node corresponds to all filters.
 */
export const FilterOneTravelNodeBasedOnTags = (node: TravelTagged, filters: BasicTravelFilters) => {
  const normFilters = NormalizeTravelFilters(filters);

  let isMatch = true;
  if (isMatch && normFilters.travelDestinationId?.length) {
    isMatch =
      node?.data?.travel_destinations?.some((i) =>
        normFilters.travelDestinationId?.some(
          (id) => (i?.travel_destinatioon?.document?.id || i?.travel_destination?.document?.id) === id
        )
      ) || false;
  }

  if (isMatch && normFilters.travelPartnerId?.length) {
    isMatch =
      node?.data?.travel_partners?.some((i) =>
        normFilters.travelPartnerId?.some((id) => i?.travel_partner?.document?.id === id)
      ) ||
      normFilters.travelPartnerId.some((id) => node?.data?.travel_partner?.document?.id === id) ||
      false;
  }
  if (isMatch && normFilters.travelTypeId?.length) {
    isMatch =
      node?.data?.travel_types?.some((i) =>
        normFilters.travelTypeId?.some((id) => i?.travel_type?.document?.id === id)
      ) || false;
  }
  if (isMatch && normFilters.agentType?.length) {
    isMatch = normFilters.agentType?.some((i) => i === node?.data?.type) || false;
  }
  if (isMatch && normFilters.travelStoreId?.length) {
    isMatch = normFilters.travelStoreId?.some(
      (id) =>
        node?.data?.caa_travel_store?.document?.id === id ||
        node?.data?.caa_travel_store_list?.some((store) => {
          return store?.section?.document?.id === id;
        })
    );
  }
  return isMatch;
};

export const FilterTravelDataBasedOnTags = <P extends TravelTagged>(data: P[], filters: BasicTravelFilters): P[] => {
  return data.filter((node) => FilterOneTravelNodeBasedOnTags(node, filters));
};

export const FilterTravelTripDataBasedOnTagsAndDate = <P extends PrismicTravelTrip>(
  data: P[],
  filters: TravelTripFilters
): P[] => {
  return data.filter((node) => {
    let isMatch = true;

    const normFilters = NormalizeTravelFilters(filters);

    if (normFilters.travelMonth?.length) {
      const startDate = ToMoment(node.data?.precise_date_start);

      if (!startDate) {
        return false;
      }

      isMatch = normFilters.travelMonth!.some((month) => {
        return ToMoment(`${month}-01`)?.isSame(startDate, 'month');
      });
    }

    return isMatch && FilterOneTravelNodeBasedOnTags(node, normFilters);
  });
};

type OptionalString = string | undefined | null;
export type FilterString = OptionalString[] | OptionalString;

export interface TravelTripFilters extends BasicTravelFilters {
  travelMonth?: FilterString;
}

export interface BasicTravelFilters {
  travelDestinationId?: FilterString;
  travelPartnerId?: FilterString;
  travelTypeId?: FilterString;
  travelStoreId?: FilterString;
  agentType?: FilterString;
  [key: string]: FilterString;
}

interface TravelFilterInterface {
  [key: string]: FilterString;
}

type NormalizedTravelFilters<TFilter extends TravelFilterInterface> = Partial<Record<keyof TFilter, string[]>>;

export interface TravelTagged {
  data?: {
    type?: Maybe<Scalars['String']>;
    travel_destinations?:
      | Maybe<{
          travel_destination?: Maybe<PrismicLinkType>;
          travel_destinatioon?: Maybe<PrismicLinkType>;
        }>[]
      | null;
    travel_partners?:
      | Maybe<{
          travel_partner?: Maybe<PrismicLinkType>;
        }>[]
      | null;
    travel_types?:
      | Maybe<{
          travel_type?: Maybe<PrismicLinkType>;
        }>[]
      | null;
    caa_travel_store?: Maybe<PrismicLinkType>;
    caa_travel_store_list?: Maybe<{ section?: Maybe<PrismicLinkType> }>[] | null;
    travel_partner?: Maybe<PrismicLinkType>;
  } | null;
}
