import React, { Component } from 'react';
import { GatsbyLinkProps, graphql, Link } from 'gatsby';
import { UidToSlug } from '../../utils/LinkResolver';
import { PrismicAllDocumentTypes, PrismicLinkType } from '../../graphql';
import { ParsedUrlQueryInput, stringify as toQueryString } from 'querystring';

export default class PrismicLink<TState> extends Component<
  PrismicLinkProps<TState>
> {
  render() {
    const { to, children, target, query, fragmentQuery, ref, ...linkProps } =
      this.props;

    if (to === null || to === undefined) {
      return children;
    }

    const toAsLink = to as PrismicLinkType;
    const toAsDocument = to as PrismicAllDocumentTypes;

    const isDocumentLink =
      toAsLink?.link_type === `Document` ||
      toAsLink?.uid ||
      (toAsDocument.id && toAsDocument.url);

    if (isDocumentLink) {
      let internalUrl = to.url;

      if (!internalUrl && toAsLink.uid) {
        internalUrl = UidToSlug(toAsLink.uid);
      }

      if (typeof query === 'string') {
        internalUrl += `?${query}`;
      }

      if (typeof query === 'object') {
        const properQuery = toQueryString(query);
        if (properQuery !== '') {
          internalUrl += `?${properQuery}`;
        }
      }

      if (typeof fragmentQuery === 'string') {
        internalUrl += `?${fragmentQuery}`;
      }

      if (typeof fragmentQuery === 'object') {
        const properQuery = toQueryString(fragmentQuery);
        if (properQuery !== '') {
          internalUrl += `?${properQuery}`;
        }
      }

      return (
        <Link
          to={internalUrl || ''}
          target={target || toAsLink?.target || undefined}
          {...linkProps}
        >
          {children}
        </Link>
      );
    }

    const {
      activeClassName,
      activeStyle,
      innerRef,
      partiallyActive,
      replace,
      getProps,
      state,
      ...anchorProps
    } = linkProps;

    return (
      // Ignored because it doesn't seem to be related to the code, but to type definition in Gatsby
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      <a
        href={to.url || ''}
        target={target || toAsLink?.target || undefined}
        rel="noopener"
        {...anchorProps}
      >
        {children}
      </a>
    );
  }
}

export interface PrismicLinkProps<TState>
  extends Omit<GatsbyLinkProps<TState>, 'to'> {
  to?: PrismicLinkType | PrismicAllDocumentTypes | null;
  query?: string | ParsedUrlQueryInput;
  fragmentQuery?: string | ParsedUrlQueryInput;
}

export type PrismicLinkFragment = Pick<
  PrismicLinkType,
  'link_type' | 'url' | 'uid' | 'target'
>;

// noinspection JSUnusedGlobalSymbols
export const PrismicLinkFragment = graphql`
  fragment PrismicLinkFragment on PrismicLinkType {
    link_type
    target
    url
    uid
  }
`;

// noinspection JSUnusedGlobalSymbols
export const PrismicDocumentLinkFragment = graphql`
  fragment PrismicDocumentLinkFragment on PrismicAllDocumentTypes {
    ... on PrismicPage {
      id
      uid
      url
    }
    ... on PrismicEmailSubscriptionPage {
      id
      uid
      url
    }
    ... on PrismicSearchPage {
      id
      uid
      url
    }
    ... on PrismicMerchandise {
      id
      uid
      url
    }
    ... on PrismicMerchandiseSearchPage {
      id
      uid
      url
    }
    ... on PrismicTravelAgentsSearchPage {
      id
      uid
      url
    }
    ... on PrismicTravelDeal {
      id
      uid
      url
    }
    ... on PrismicTravelDealsSearchPage {
      id
      uid
      url
    }
    ... on PrismicTravelPartner {
      id
      uid
      url
    }
    ... on PrismicTravelPartnersSearchPage {
      id
      uid
      url
    }
    ... on PrismicTravelStore {
      id
      uid
      url
    }
    ... on PrismicTravelStoreSearchPage {
      id
      uid
      url
    }
    ... on PrismicTravelTalk {
      id
      uid
      url
    }
    ... on PrismicTravelTalksSearchPage {
      id
      uid
      url
    }
    ... on PrismicTravelTrip {
      id
      uid
      url
    }
    ... on PrismicTravelTripsSearchPage {
      id
      uid
      url
    }
  }
`;
